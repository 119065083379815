import { gql } from '@apollo/client';

import { clientConfigFragment } from './fragments.js';

export const getClientDetails = gql`
  query getClientDetails($clientId: ID!) {
    client(id: $clientId) {
      _id
      name
    }
  }
`;

export const getClientConfigs = gql`
  query getClientConfigs($clientId: ID!, $glCodesInput: ClientGLCodesInput) {
    client(id: $clientId) {
      _id
      glCodes(input: $glCodesInput) {
        code
        name
      }
      configs {
        ...clientConfigFragment
      }
    }
  }

  ${clientConfigFragment}
`;
