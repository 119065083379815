import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddItems from '../../components/add-items/index.js';
import {
  setPOsModal,
  setReportModal,
  setProjectModal,
  setAddSupplierModal,
} from '../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const modals = useSelector((state) => state.projects.modals);

  const openProjectModal = useCallback(
    () => dispatch(setProjectModal({ type: 'add' })),
    [dispatch, setProjectModal],
  );

  const openReportModal = useCallback(
    () => dispatch(setReportModal('add')),
    [dispatch, setReportModal],
  );

  const openSupplierModal = useCallback(
    () => dispatch(setAddSupplierModal()),
    [dispatch, setAddSupplierModal],
  );

  const openNonWOPOModal = useCallback(
    () =>
      dispatch(
        setPOsModal({
          addPORequest: { project: null },
        }),
      ),
    [dispatch, setPOsModal],
  );

  return (
    <AddItems
      {...props}
      modals={modals}
      openProjectModal={openProjectModal}
      openReportModal={openReportModal}
      openSupplierModal={openSupplierModal}
      openNonWOPOModal={openNonWOPOModal}
    />
  );
}
