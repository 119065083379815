import { gql } from '@apollo/client';

import { fileFragment } from '../fragments.js';
import { projectAssetsDetailsFragment } from './get-project-assets.js';
import { jobCostsFragment } from './get-project-job-costs.js';
import { fullProjectFragment } from './get-project.js';

export const prefetchProjectFragment = gql`
  fragment prefetchProjectFragment on Project {
    ...fullProjectFragment
    ...jobCostsFragment
    searchFiles {
      hits {
        ...fileFragment
      }
    }
    searchAssets {
      hits {
        ...projectAssetsDetailsFragment
      }
    }
  }

  ${fullProjectFragment}
  ${jobCostsFragment}
  ${projectAssetsDetailsFragment}
  ${fileFragment}
`;

export const prefetchProject = gql`
  query prefetchProject($id: ID) {
    project(projectId: $id) {
      ...prefetchProjectFragment
    }
  }

  ${prefetchProjectFragment}
`;
