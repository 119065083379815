import { gql } from '@apollo/client';

export const propertyAssetFragment = gql`
  fragment propertyAssetFragment on Asset {
    _id
    description
    equipmentType
    manufacturer
    model
    serial
    warrantyEnd
  }
`;

export const getPropertyAssets = gql`
  query getPropertyUsers($propertyId: ID!) {
    property(id: $propertyId) {
      _id
      assets {
        ...propertyAssetFragment
      }
    }
  }

  ${propertyAssetFragment}
`;
