import PropTypes from 'prop-types';

export const addressObjectPropTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    addressTwo: PropTypes.string,
    formatted_address: PropTypes.string,
    address_parts: PropTypes.shape({
      route: PropTypes.string,
      locality: PropTypes.string,
      postal_code: PropTypes.string,
      street_number: PropTypes.string,
      administrative_area_level_1: PropTypes.string,
    }),
  }),
]);
