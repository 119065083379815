import types from '../types.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import { DUE_TODAY } from '../../constants/projects.js';
import { MAIN } from '../../constants/routes.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  activeProject: null,
  typeFilter: DUE_TODAY,
  tableSearchString: '',
  activeTab: 'updates',
  modals: {
    responseTeam: null,
    timeLogs: null,
    materials: null,
    project: null,
  },
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_PROJECTS_FILTER: {
      return {
        ...state,
        typeFilter: action.payload,
      };
    }
    case types.SET_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: action.payload,
      };
    }
    case types.SET_PROJECT_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case types.SET_TIMELOGS_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          timeLogs: action.payload,
        },
      };
    }
    case types.SET_MATERIALS_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          materials: action.payload,
        },
      };
    }
    case types.SET_RESPONSE_TEAM_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          responseTeam: action.payload,
        },
      };
    }
    case types.SET_EDIT_PROJECT_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          editProject: action.payload,
        },
      };
    }
    case MAIN: {
      return {
        ...state,
        tableSearchString: '',
      };
    }
    case types.SET_PROJECTS_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_PROJECT_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          project: action.payload,
        },
      };
    }
    case types.SET_PROJECTS_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_PROJECTS_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_PROJECTS_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_PROJECTS_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
