import React from 'react';
import { useSelector } from 'react-redux';
import { useMRProjectInfoQuery } from '../../hooks/mr-pm-project.js';
import MrPmProjectInfo from '../../components/mr-pm-project/info-navigator.js';

export default function (props) {
  const projectId = useSelector((state) => state.location.payload.id);

  const { loading, info } = useMRProjectInfoQuery(projectId);

  return <MrPmProjectInfo {...props} loading={loading} info={info} />;
}
