import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getFirstLetterOfNames } from 'poly-client-utils';

import { Avatar, Conversation, Grid, Message, S } from 'poly-book';
import { noDataToDisplay } from '../../../../util/general.js';
import { Loader, renderButton } from './common.js';
import { OpenStaffSidebarUsername } from '../../../../utils/wrappers.js';

function Manager({
  _id,
  email,
  firstName,
  lastName,
  onButtonClick,
  selected,
  hover,
  setHover,
}) {
  const onClick = onButtonClick(
    { _id, firstName, lastName, email },
    !!selected,
  );

  return (
    <Grid.Row>
      <Grid.Cell vertical="center">
        <Message>
          <Message.Header
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            {...{ onClick }}
          >
            <OpenStaffSidebarUsername
              {...{ _id, firstName, lastName, isManager: true }}
            >
              <Avatar
                type="text"
                content={getFirstLetterOfNames(firstName, lastName)}
              />
            </OpenStaffSidebarUsername>
          </Message.Header>
          <Message.Content>
            <S type="title" textColor="#6f7a9f">
              Manager
            </S>
          </Message.Content>
        </Message>
      </Grid.Cell>
      <Grid.Cell vertical="center">
        <S type="content" bold>
          —
        </S>
        <br />
        <S type="content" bold>
          {email || '—'}
        </S>
      </Grid.Cell>
      <Grid.Cell vertical="center">
        {renderButton(selected, onClick, hover)}
      </Grid.Cell>
    </Grid.Row>
  );
}

Manager.propTypes = {
  _id: PropTypes.string.isRequired,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  setHover: PropTypes.func.isRequired,
  hover: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
};

Manager.defaultProps = {
  email: null,
  selected: false,
};

function ManagerContainer(props) {
  const [hover, setHover] = useState(false);

  return <Manager {...props} hover={hover} setHover={setHover} />;
}

function ManagersTab({
  projectManager,
  allManagers,
  handleManagersClick,
  addLoading,
}) {
  return (
    <>
      {addLoading && <Loader />}
      {projectManager && (
        <>
          <Conversation.Item badge>
            <S type="badge" uppercase>
              Assigned To
            </S>
          </Conversation.Item>
          <Conversation.Item>
            <Grid columns="1fr 1fr 36px" simple>
              <ManagerContainer
                {...projectManager}
                key={projectManager._id}
                onButtonClick={handleManagersClick}
                selected
              />
            </Grid>
          </Conversation.Item>
        </>
      )}
      <Conversation.Item badge>
        <S type="badge" uppercase>
          Available
        </S>
      </Conversation.Item>
      <Conversation.Item>
        {allManagers.length > 0 ? (
          <Grid columns="1fr 1fr 36px" simple>
            {allManagers.map((manager) => (
              <ManagerContainer
                {...manager}
                key={manager._id}
                onButtonClick={handleManagersClick}
              />
            ))}
          </Grid>
        ) : (
          noDataToDisplay('Data')()
        )}
      </Conversation.Item>
    </>
  );
}

ManagersTab.propTypes = {
  projectManager: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    onButtonClick: PropTypes.func,
    selected: PropTypes.bool,
  }),
  allManagers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ),
  handleManagersClick: PropTypes.func.isRequired,
  addLoading: PropTypes.bool,
};

ManagersTab.defaultProps = {
  allManagers: [],
  projectManager: null,
  addLoading: false,
};

export default ManagersTab;
