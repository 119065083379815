import { handleActions } from 'redux-actions';
import { InvoicePOStatuses } from 'poly-constants';

import { INVOICE_LOG } from '../../constants/routes.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import {
  setInvoiceLogPaginationDropdownLabel,
  setInvoiceLogPaginationCurrentPage,
  setInvoiceLogPaginationPageSize,
  setInvoiceLogTableSearchString,
  setInvoiceLogPaginationTotal,
} from '../actions/index.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  tableSearchString: '',
  type: InvoicePOStatuses.posted,
};

export default handleActions(
  {
    [INVOICE_LOG]: (state) => ({ ...state, tableSearchString: '' }),
    [setInvoiceLogTableSearchString]: (state, { payload }) => ({
      ...state,
      tableSearchString: payload,
    }),
    [setInvoiceLogPaginationTotal]: (state, { payload }) => ({
      ...state,
      total: payload,
    }),
    [setInvoiceLogPaginationCurrentPage]: (state, { payload }) => ({
      ...state,
      currentPage: payload,
    }),
    [setInvoiceLogPaginationPageSize]: (state, { payload }) => ({
      ...state,
      pageSize: payload,
    }),
    [setInvoiceLogPaginationDropdownLabel]: (state, { payload }) => ({
      ...state,
      paginationDropdownLabel: payload,
    }),
  },
  defaultState,
);
