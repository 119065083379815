import * as R from 'ramda';
import React from 'react';
import { L, S } from 'poly-book';
import { highlightTextToReactElement } from 'poly-client-utils';

import { MODAL_STATUSES } from '../../constants/invoices.js';
import { InvoiceNumberError } from '../../constants/alerts.js';
import { formatCurrency, isExist } from '../../util/general.js';
import { APPROVED } from '../../constants/purchase-orders.js';

export const invoiceLinkFormatter = (data) => (
  <L href={R.path(['invoiceFile', 'url'], data)} target="_blank" bold>
    {R.propOr('—', 'invoiceNumber', data)}
  </L>
);

export const InvoiceFormatter = (search) =>
  function (props) {
    return (
      <L href={R.path(['invoiceFile', 'url'], props)} target="_blank" bold>
        {isExist(search)
          ? highlightTextToReactElement(
              search,
              R.propOr('—', 'invoiceNumber', props),
            )
          : R.propOr('—', 'invoiceNumber', props)}
      </L>
    );
  };

export const checkInvoiceNumberError = (error) =>
  R.equals(error, `GraphQL error: ${InvoiceNumberError}`);

const blockedPlaceholder = (
  <S type="content" bold textColor="#ee0d45">
    {R.toUpper(MODAL_STATUSES.BLOCKED)}
  </S>
);

export const invoiceAmountFormatter = R.ifElse(
  R.propEq('status', MODAL_STATUSES.BLOCKED),
  R.always(blockedPlaceholder),
  R.compose(formatCurrency, R.prop('total')),
);

/**
 * calculateTotal :: [Invoice] -> Number
 */
export const calculateTotal = R.ifElse(
  R.isEmpty,
  R.always(0),
  R.pipe(
    R.reject(R.propEq('status', MODAL_STATUSES.BLOCKED)),
    R.map(R.prop('total')),
    R.sum,
  ),
);

/**
 * checkIfAllInvoicesAttached :: PurchaseOrder -> Boolean
 */
export const checkIfAllInvoicesAttached = R.compose(
  R.both(
    R.propEq('status', APPROVED),
    R.converge(R.equals, [
      R.propOr(0, 'amount'),
      R.pipe(R.propOr([], 'invoices'), calculateTotal),
    ]),
  ),
  R.defaultTo({}),
);
