import { gql } from '@apollo/client';

import { prefetchProjectFragment } from './projects/prefetch.js';
import { prefetchPMProjectFragment } from './projects/prefetch-pm.js';

export const invoicesPOChanged = gql`
  subscription invoicesPOChanged($input: CollectionSearchParams!) {
    searchPOInvoiceChanged(input: $input) {
      id
      type
    }
  }
`;

export const purchaseOrdersChanged = gql`
  subscription purchaseOrdersChanged($input: CollectionSearchParams!) {
    searchPurchaseOrderChanged(input: $input) {
      id
      type
    }
  }
`;

export const usersChanged = gql`
  subscription usersChanged($input: CollectionSearchParams!) {
    searchUserChanged(input: $input) {
      id
      type
    }
  }
`;

export const suppliersChanged = gql`
  subscription suppliersChanged($input: CollectionSearchParams!) {
    searchSupplierChanged(input: $input) {
      id
      type
    }
  }
`;

export const projectsChanged = gql`
  subscription projectsChanged($input: CollectionSearchParams!) {
    searchProjectChanged(input: $input) {
      id
      type
    }
  }
`;

export const recurringProjectChanged = gql`
  subscription recurringProjectsChanged($input: CollectionSearchParams!) {
    searchRecurringProjectChanged(input: $input) {
      id
      type
    }
  }
`;

export const maintenancePlansChanged = gql`
  subscription maintenancePlansChanged($input: CollectionSearchParams!) {
    searchMaintenancePlanChanged(input: $input) {
      id
      type
    }
  }
`;

export const propertiesChanged = gql`
  subscription propertiesChanged($input: CollectionSearchParams!) {
    searchPropertyChanged(input: $input) {
      id
      type
    }
  }
`;

export const PROJECT_CHANGED = gql`
  subscription PROJECT_CHANGED($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      type
      document {
        ...prefetchProjectFragment
      }
    }
  }
  ${prefetchProjectFragment}
`;

export const PROJECT_PM_CHANGED = gql`
  subscription PROJECT_PM_CHANGED($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      type
      document {
        ...prefetchPMProjectFragment
      }
    }
  }
  ${prefetchPMProjectFragment}
`;
