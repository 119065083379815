import types from '../types.js';

const defaultState = {};

export default (state = defaultState, action = {}) => {
  if (action.type === types.SET_USER) {
    return {
      ...action.payload,
    };
  }
  return state;
};
