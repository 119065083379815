import { gql } from '@apollo/client';

export const getProperties = gql`
  query getProperties($input: CollectionSearchParams) {
    searchProperties(input: $input) {
      total
      hits {
        _id
        status
        name
        address {
          formatted_address
        }
      }
    }
  }
`;
