import { gql } from '@apollo/client';

export const addRecurringWO = gql`
  mutation addRecurringWO($input: CreateRecurringProjectInput!) {
    createRecurringProject(input: $input) {
      recurringProject {
        _id
        projectId
      }
    }
  }
`;
