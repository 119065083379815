import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import AddTimeLogs from '../../../../components/project/job-costs/add-timelog.js';
import { setTimelogsModal } from '../../../../redux/actions/index.js';
import {
  AddTechnicianTimeSuccess,
  EditTechnicianTimeSuccess,
  GeneralError,
} from '../../../../constants/alerts.js';
import { useTechnicians } from '../../../../hooks/staff.js';
import useValidation from '../../../../hooks/useValidation.js';
import {
  useAddTimeLogs,
  useEditTimelogs,
} from '../../../../hooks/project-job-costs.js';

function AddEditTimelogs(props) {
  const dispatch = useDispatch();

  const { timeLogsModal } = props;

  const [technicianLoading, setTechnicianLoading] = useState(false);
  const [selectedTechnician, setTechnician] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [loadingStateValue, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [searchTerm, setSearchTermState] = useState();
  const [timeLogIdState, setTimeLogId] = useState(null);

  useEffect(() => {
    if (timeLogsModal.data) {
      const {
        _id,
        startDate: startDateFromModal,
        duration,
        assignedTo: { _id: value, fullName: label },
      } = timeLogsModal.data;

      const time = moment.duration(duration);
      const mins = time.clone().subtract(time.hours(), 'h').minutes();

      setStartDate(new Date(startDateFromModal));
      setHours(time.hours());
      setMinutes(mins);
      setTimeLogId(_id);
      setTechnician({
        value,
        label,
      });
    }

    return () => {
      setTechnician(null);
      setHours(null);
      setMinutes(null);
    };
  }, []);

  const projectId = useSelector((state) => state.projects.activeProject._id);

  const activeProjectId = useSelector(
    (state) => state.projects.activeProject.projectId,
  );
  const clientId = useSelector((state) => state.user.clientId);

  const { loading, technicians } = useTechnicians(searchTerm, clientId);

  const { createTimelog } = useAddTimeLogs();
  const { updateTimelog } = useEditTimelogs();

  const isAdd = timeLogsModal?.type === 'add';

  const setTechnicianSearch = useCallback(
    (searchTermValue) => setSearchTermState(searchTermValue),
    [setSearchTermState],
  );

  useEffect(() => {
    setTechnicianLoading(loading);
  }, [loading]);

  const { errors, setError, validate, validateField } = useValidation({
    validationRules: () => ({
      technician: [{ rule: 'required', message: 'Please Select Technician' }],
      startDate: [{ rule: 'required' }],
      time: [
        {
          rule: ({ mins, hrs }) => hrs === 0 && mins === 0,
          message: "Time Can't be Zero",
        },
        {
          rule: ({ mins, hrs }) => hrs === null || mins === null,
          message: 'Please Select Hours and Minutes',
        },
      ],
    }),
  });

  const closeModal = useCallback(
    () => dispatch(setTimelogsModal(null)),
    [dispatch, setTimelogsModal],
  );

  const onTechnicianChange = useCallback(
    (technician) => {
      setTechnician(technician);
      validateField('technician', technician?.value);
    },
    [setTechnician, validateField],
  );

  const onStartDateChange = useCallback(
    (dataValue) => {
      setStartDate(dataValue);
      validateField('startDate', dataValue);
    },
    [setStartDate, validateField],
  );

  const onHoursChange = useCallback(
    (hoursValue) => {
      validateField('time', { mins: minutes, hrs: hoursValue?.value });
      setHours(hoursValue.value);
    },
    [setHours, validateField, minutes],
  );

  const onMinutesChange = useCallback(
    (minutesValue) => {
      validateField('time', { hrs: hours, mins: minutesValue?.value });
      setMinutes(minutesValue.value);
    },
    [setMinutes, validateField, hours],
  );

  const submitTimeLog = useCallback(() => {
    setLoading(true);

    const errorsValue = validate({
      technician: selectedTechnician?.value,
      startDate,
      time: { hrs: hours, mins: minutes },
    });

    if (errorsValue.isInvalid) {
      setLoading(false);
      return false;
    }

    const endDate = new Date(startDate);
    endDate.setHours(startDate.getHours() + hours);
    endDate.setMinutes(startDate.getMinutes() + minutes);

    const dataObj = {
      projectId,
      assignedTo: selectedTechnician.value,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    const mutationPromise = isAdd
      ? createTimelog(dataObj)
      : updateTimelog(timeLogIdState, dataObj);

    return mutationPromise
      .then(() => {
        setLoading(false);
        setError({});
        dispatch(setTimelogsModal(null));
        toast.success(
          isAdd ? AddTechnicianTimeSuccess : EditTechnicianTimeSuccess,
        );
      })
      .catch((error) => {
        setLoading(false);
        setError({ server: error.message });
        toast.error(GeneralError);
      });
  }, [
    projectId,
    selectedTechnician,
    startDate,
    hours,
    minutes,
    setError,
    setLoading,
    dispatch,
    isAdd,
    validate,
  ]);

  const techniciansObj = technicians?.map((technician) => ({
    value: technician._id,
    label: technician.fullName,
  }));

  const showModal =
    timeLogsModal.type === 'add' || timeLogsModal.type === 'edit';

  return showModal ? (
    <AddTimeLogs
      {...props}
      loading={loadingStateValue}
      activeProjectId={activeProjectId}
      selectedTechnician={selectedTechnician}
      technicians={techniciansObj}
      startDate={startDate}
      minutes={minutes}
      hours={hours}
      isAdd={isAdd}
      onTechnicianChange={onTechnicianChange}
      onStartDateChange={onStartDateChange}
      onHoursChange={onHoursChange}
      onMinutesChange={onMinutesChange}
      closeModal={closeModal}
      submitTimeLog={submitTimeLog}
      errors={errors}
      technicianLoading={technicianLoading}
      setTechnicianSearch={setTechnicianSearch}
    />
  ) : null;
}

AddEditTimelogs.propTypes = {
  timeLogsModal: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      _id: PropTypes.string,
      startDate: PropTypes.string,
      duration: PropTypes.number,
      assignedTo: PropTypes.shape({
        fullName: PropTypes.string,
        _id: PropTypes.string,
      }),
    }),
  }),
};

export default function (props) {
  const timeLogsModal = useSelector((state) => state.projects.modals.timeLogs);

  return timeLogsModal ? (
    <AddEditTimelogs {...props} timeLogsModal={timeLogsModal} />
  ) : null;
}
