import * as R from 'ramda';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes, { arrayOf, number, shape, string } from 'prop-types';
import { ACTIVE, STATUSES } from '../../constants/staff.js';
import StaffModal from '../../components/staff/add-edit-modal.js';
import { setStaffModal } from '../../redux/actions/index.js';
import {
  handleSubmitStaffUser,
  selectTechnicianSkills,
  selectUserGroup,
  selectUserStatus,
} from './add-edit-modal-handlers.js';
import {
  useCreateUser,
  useUpdateUserMutation,
  useResendUserEnrollmentEmail,
  useUserGroupsQuery,
} from '../../hooks/staff.js';
import useValidation from '../../hooks/useValidation.js';
import { prepareUserTypes } from '../../utils/staff/index.js';
import { useOpenUserStaffSidebar } from '../../utils/staff/useOpenUserStaffSidebar.js';

// userStatuses :: Statuses -> Object
const userStatuses = R.pipe(
  R.values,
  R.map((value) => ({ value, label: value })),
)(STATUSES);

// isTechnicianUserGroup :: ID, [UserTypeOption] -> Bool
//   UserTypeOption = {value: String, label: String, isTechnician: Bool}
const isTechnicianUserGroup = (userGroupId, userTypes) =>
  R.compose(
    R.propOr(false, 'isTechnician'),
    R.find(R.propEq('value', userGroupId)),
  )(userTypes);

function AddEditStaffModal({
  modal,
  selectedUser,
  clientId,
  currentUserClientConfig,
}) {
  const dispatch = useDispatch();

  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [userGroupId, setUserGroupId] = useState('');
  const [technicianSkills, setTechnicianSkills] = useState([]);
  const [userStatus, setUserStatus] = useState(ACTIVE);
  const [loading, setLoading] = useState(false);
  const [isAdd, setAdd] = useState(false);
  const [areSkillsEnabled, setAreSkillsEnabled] = useState(false);

  const { userGroups, userTypesLoading } = useUserGroupsQuery();

  const userTypes = prepareUserTypes(userGroups);
  useEffect(() => {
    setAreSkillsEnabled(
      !!currentUserClientConfig.useTechnicianSkills &&
        isTechnicianUserGroup(userGroupId, userTypes),
    );
    setAdd(modal.type === 'add');
  }, [modal, currentUserClientConfig, userGroupId, userTypes]);

  useEffect(() => {
    if (!isAdd && selectedUser) {
      if (selectedUser.status) {
        setUserStatus(selectedUser.status);
      }

      setUserGroupId(selectedUser.userGroupId);
      setTechnicianSkills(selectedUser?.technicianSkills || []);
    }
  }, [selectedUser, setUserGroupId, setUserStatus, setTechnicianSkills, isAdd]);

  const { createUser } = useCreateUser();

  const { updateUser } = useUpdateUserMutation(shouldRefetch);

  const { resendUserEnrollmentEmail } = useResendUserEnrollmentEmail();
  const handleOpenStaffSidebar = useOpenUserStaffSidebar();

  const {
    errors,
    resetError,
    setError,
    onChange,
    validateOnBlur,
    validate,
    validateField,
  } = useValidation({
    validationRules: () => ({
      firstName: [{ rule: 'required' }],
      lastName: [{ rule: 'required' }],
      userGroupId: [{ rule: 'required' }],
      email: [{ rule: 'email' }, { rule: 'required' }],
      officePhone: [{ rule: 'phone' }],
      mobile: [{ rule: 'phone' }],
      ...(areSkillsEnabled && { technicianSkills: [{ rule: 'required' }] }),
    }),
  });

  const closeModal = useCallback(
    () => dispatch(setStaffModal(null)),
    [dispatch],
  );

  const onSubmitHandler = handleSubmitStaffUser({
    userGroupId,
    userGroups,
    isAdd,
    validate,
    setError,
    dispatch,
    setLoading,
    userStatus,
    selectedUser,
    areSkillsEnabled,
    technicianSkills,
    clientId,
    createUser,
    updateUser,
    resendUserEnrollmentEmail,
    handleOpenStaffSidebar,
  });

  const selectedUserGroupHandler = selectUserGroup({
    selectedUser,
    isAdd,
    validateField,
    shouldRefetch,
    setShouldRefetch,
    setUserGroupId,
  });

  const selectTechnicianSkillsHandler = selectTechnicianSkills({
    setTechnicianSkills,
    validateField,
  });

  const selectUserStatusHandler = selectUserStatus({ setUserStatus });

  return (
    <StaffModal
      areSkillsEnabled={areSkillsEnabled}
      closeModal={closeModal}
      errors={errors}
      isAdd={isAdd}
      loading={loading}
      onChange={onChange}
      onSubmit={onSubmitHandler}
      resetError={resetError}
      selectTechnicianSkills={selectTechnicianSkillsHandler}
      selectUserGroup={selectedUserGroupHandler}
      selectUserStatus={selectUserStatusHandler}
      selectedUser={selectedUser}
      technicianSkills={technicianSkills}
      userGroupId={userGroupId}
      userStatus={userStatus}
      userStatuses={userStatuses}
      userTypes={userTypes}
      userTypesLoading={userTypesLoading}
      validateOnBlur={validateOnBlur}
    />
  );
}

AddEditStaffModal.propTypes = {
  currentUserClientConfig: PropTypes.shape({
    noManagerForRWONeeded: PropTypes.bool,
    projectPrioritiesHours: arrayOf(shape({ priority: string, hours: number })),
    site: PropTypes.objectOf(PropTypes.bool),
    supplierSource: PropTypes.string,
    useTechnicianSkills: PropTypes.bool,
    useTechnicianTeams: PropTypes.bool,
  }),
  modal: PropTypes.shape({ type: PropTypes.string }),
  selectedUser: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    isSignedUp: PropTypes.bool,
    status: PropTypes.string,
    _id: PropTypes.string,
    technicianSkills: PropTypes.arrayOf(PropTypes.string),
    mobile: PropTypes.string,
    officePhone: PropTypes.string,
    userGroupId: PropTypes.string,
    avatar: PropTypes.string,
    url: PropTypes.string,
  }),
  clientId: PropTypes.string,
};

export default function () {
  const modal = useSelector((state) => state.staff.modals.addEdit);

  const selectedUser = useSelector((state) => state.staff.modals.sidebar.user);

  const clientId = useSelector((state) => state.user.clientId);

  const currentUserClientConfig = useSelector(
    (state) => state.user.clientConfig,
  );

  return (
    modal && (
      <AddEditStaffModal
        modal={modal}
        selectedUser={selectedUser}
        clientId={clientId}
        currentUserClientConfig={currentUserClientConfig}
      />
    )
  );
}
