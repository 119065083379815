import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { collectionNames } from 'poly-constants';
import { FilesTab } from 'poly-site-ui';
import { fileColumns } from '../../../../utils/files.js';
import { useMRProjectInfoQuery } from '../../../../hooks/mr-pm-project.js';

export default function (props) {
  const dispatch = useDispatch();

  const projectId = useSelector((state) => state.location.payload.id);

  const { info } = useMRProjectInfoQuery(projectId);

  const collection = collectionNames.maintenancePlans;
  const fileColumnsValue = fileColumns(dispatch);

  return (
    <FilesTab
      {...props}
      id={info.maintenancePlanId}
      fileColumns={fileColumnsValue}
      collection={collection}
      documentId={projectId}
    />
  );
}
