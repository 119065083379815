import types from '../types.js';

export const signInRequestLoading = (isLoading) => ({
  type: types.SIGN_IN_REQUEST,
  payload: isLoading,
});

export const signInError = (error) => ({
  type: types.SIGN_IN_ERROR,
  payload: { error },
});

export const setForgotPasswordMessage = (message) => ({
  type: types.SET_FORGOT_PASSWORD_MESSAGE,
  payload: message,
});
