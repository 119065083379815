import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ConfirmModal } from 'poly-site-ui';

import { CLOSED } from '../../constants/purchase-orders.js';
import { ClosePOSuccess, GeneralError } from '../../constants/alerts.js';
import { setPOsModal } from '../../redux/actions/index.js';
import { useUpdatePORequest } from '../../hooks/purchase-orders.js';

function ClosePOModal(props) {
  const dispatch = useDispatch();

  const { goToPO, _id, cardNumber, ...order } = props.modalInfo;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { updatePORequest } = useUpdatePORequest();

  const closeModal = useCallback(
    () => dispatch(setPOsModal({ closePO: null })),
    [dispatch, setPOsModal],
  );

  const onAdditionalBtnClick = useCallback(() => {
    dispatch(setPOsModal({ closePO: null }));
    if (goToPO) {
      dispatch(
        setPOsModal({
          editPORequest: { ...order, ...props.modalInfo },
        }),
      );
    }
  }, [dispatch, setPOsModal, goToPO, props.modalInfo]);

  const onConfirm = useCallback(async () => {
    setLoading(true);

    return updatePORequest(_id, { status: CLOSED })
      .then(() => {
        setLoading(false);
        setError(null);
        dispatch(setPOsModal({ closePO: null }));
        toast.success(ClosePOSuccess(cardNumber));
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        toast.error(GeneralError);
      });
  }, [
    _id,
    setLoading,
    updatePORequest,
    setError,
    dispatch,
    cardNumber,
    setPOsModal,
  ]);

  const heading = `PO# ${cardNumber}`;

  return (
    <ConfirmModal
      {...props}
      loading={loading}
      error={error}
      closeModal={closeModal}
      onConfirm={onConfirm}
      onAdditionalBtnClick={onAdditionalBtnClick}
      showModal
      withAdditionalBtn
      heading={heading}
      subHeading="Confirmation"
      text="Would you like to close the PO?"
      confirmBtnText="Yes, Close the PO"
      additionalBtnText="No, Do Not Close"
    />
  );
}

ClosePOModal.propTypes = {
  modalInfo: PropTypes.shape({
    _id: PropTypes.string,
    goToPO: PropTypes.bool,
    cardNumber: PropTypes.string,
  }),
};

export default function (props) {
  const modalInfo = useSelector((state) => state.purchaseOrders.modals.closePO);

  return modalInfo ? <ClosePOModal {...props} modalInfo={modalInfo} /> : null;
}
