import { gql } from '@apollo/client';

export const propertySupplierFragment = gql`
  fragment propertySupplierFragment on PropertySupplier {
    relationTypes
    supplier {
      _id
      type
      status
      company {
        name
        address {
          formatted_address
        }
      }
    }
  }
`;

export const getPropertySuppliersQuery = gql`
  query getPropertySuppliers($propertyId: ID!, $input: PropertySuppliersInput) {
    property(id: $propertyId) {
      _id
      suppliers(input: $input) {
        ...propertySupplierFragment
      }
    }
  }

  ${propertySupplierFragment}
`;
