import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import ProjectInfo from '../../components/mr-project/info.js';
import { setEditProjectModal } from '../../redux/actions/index.js';
import { useMRProjectDetails } from '../../hooks/project-details.js';

export default function (props) {
  const dispatch = useDispatch();

  const projectId = useSelector((state) => state.location.payload.id);
  const projectState = useSelector((state) => state.projects.activeProject);

  const { loading, project } = useMRProjectDetails(projectId);

  const openEditProjectModal = useCallback(() => {
    const data = {
      ...R.pick(
        [
          'priority',
          'description',
          'location',
          'property',
          'technicians',
          'status',
        ],
        project,
      ),
      service: project.serviceType,
      details: project.invoiceDescription,
    };

    dispatch(setEditProjectModal({ type: 'edit', data }));
  }, [dispatch, setEditProjectModal, project]);

  const disableEdit = !projectState;

  return (
    <ProjectInfo
      {...props}
      openEditProjectModal={openEditProjectModal}
      disableEdit={disableEdit}
      projectId={projectId}
      loading={loading}
    />
  );
}
