import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'poly-utils/src/general.js';
import { toast } from 'react-toastify';
import { GeneralError } from '../../../constants/alerts.js';
import { CANCELLED, scopeTypes } from '../../../constants/purchase-orders.js';
import AddEditPORequest from '../../../components/purchase-order/add-edit-modal.js';
import { removeCurrencyFormatting, getValue } from '../../../util/general.js';
import { useApprovePOPartLogic } from '../add-po-request.js';
import { setPOsModal } from '../../../redux/actions/index.js';
import { useGlCodeSelectLogic } from '../common.js';
import { useUpdatePORequest } from '../../../hooks/purchase-orders.js';
import {
  usePOFileHandler,
  usePOPermissionsHelper,
} from '../../../utils/purchase-orders/pos-hooks.js';
import useValidation from '../../../hooks/useValidation.js';
import { getAvailableStatuses } from '../edit-po-request.js';

function EditApproveModal(props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const { modalInfo } = props;

  const {
    allSuppliers,
    supplierLoading,
    noSupplierResults,
    setLoading,
    setSupplierSearch,
    setSelectedScope,
    setSupplier,
    loading,
    selectedSupplier,
    selectedScope,
  } = useApprovePOPartLogic();

  const { updatePORequest } = useUpdatePORequest();

  const { isAvailableForAll, ifHasPermission } = usePOPermissionsHelper({
    orderProp: 'modalInfo',
    userProp: 'user',
    restProps: { user, modalInfo },
  });

  const { file, wasAdded, wasRemoved, onAddFile, onRemoveFile, setFile } =
    usePOFileHandler();

  const setFileDebounced = useCallback(
    debounce(400)(() => {
      setFile(
        modalInfo.attachments.length > 0
          ? {
              name: modalInfo.attachments[0].fileName,
              src: modalInfo.attachments[0].url,
              size: modalInfo.attachments[0].fileSize,
            }
          : null,
      );
    }),
    [],
  );

  useEffect(() => {
    setSupplier({
      value: modalInfo.supplier._id,
      label: modalInfo.supplier.company.name,
    });
    setSelectedScope(
      scopeTypes.find((item) => item.value === modalInfo.isInScope),
    );
    setFileDebounced();
  }, []);

  const {
    errors,
    setError,
    onChange,
    validateOnBlur,
    resetError,
    validate,
    validateField,
  } = useValidation({
    validationRules: {
      supplier: [{ rule: 'required', message: 'Please Select Supplier' }],
      amount: [{ rule: 'currency' }, { rule: 'required' }],
      description: [{ rule: 'required' }],
    },
  });

  const { code, glCodes, onSelectCode } = useGlCodeSelectLogic(modalInfo);

  const number = modalInfo.cardNumber;
  const amount = modalInfo?.amount;

  const description = modalInfo?.description;
  const project = modalInfo.project?.projectId;

  const status = modalInfo?.status;
  const previousFileUrl = modalInfo.attachments[0]?.url || null;

  const shouldDisplayStatus = isAvailableForAll || ifHasPermission;

  const availableStatuses = getAvailableStatuses({
    modalInfo,
    ifHasPermission,
  });

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const glCode = code.value;

      const errorsValue = validate({
        supplier: selectedSupplier,
        amount: getValue('amount', e),
        description: getValue('description', e),
      });

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const data = {
        id: modalInfo._id,
        title: modalInfo.cardNumber,
        supplierId: selectedSupplier.value,
        amount: removeCurrencyFormatting(getValue('amount', e)),
        isInScope: selectedScope.value,
        description: getValue('description', e),
        glCode,
      };

      if (wasRemoved && !!previousFileUrl) {
        data.removeFileAttachments = [previousFileUrl];
      }

      if (wasAdded && !!file) {
        data.addFileAttachments = [{ upload: file.file }];
      }

      setLoading(false);
      setError(null);
      return dispatch(
        setPOsModal({
          editApprovePO: null,
          confirmApproveOne: data,
        }),
      );
    },
    [
      selectedSupplier,
      previousFileUrl,
      selectedScope,
      setLoading,
      wasRemoved,
      modalInfo,
      wasAdded,
      validate,
      setError,
      dispatch,
      file,
      code,
    ],
  );

  const closeModal = useCallback(
    () => dispatch(setPOsModal({ editApprovePO: null })),
    [setPOsModal, dispatch],
  );

  const selectSupplier = useCallback(
    (supplier) => {
      setSupplier(supplier);
      validateField('supplier', supplier.value);
    },
    [setSupplier, validateField],
  );

  const onRejectPO = useCallback(async () => {
    setLoading(true);
    const id = modalInfo._id;
    const input = {
      status: CANCELLED,
    };

    return updatePORequest(id, input)
      .then(() => {
        setLoading(false);
        setError(null);
        dispatch(setPOsModal({ editApprovePO: null }));
        toast.success('Purchase Order Request was rejected');
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
        toast.error(GeneralError);
      });
  }, [updatePORequest, setLoading, setError, dispatch, modalInfo, setPOsModal]);

  return (
    <AddEditPORequest
      {...props}
      type="approve"
      file={file}
      loading={loading}
      errors={errors}
      onChange={onChange}
      closeModal={closeModal}
      onSubmit={onSubmit}
      number={number}
      status={status}
      project={project}
      amount={amount}
      description={description}
      code={code}
      glCodes={glCodes}
      supplierLoading={supplierLoading}
      allSuppliers={allSuppliers}
      selectedSupplier={selectedSupplier}
      selectSupplier={selectSupplier}
      setSupplierSearch={setSupplierSearch}
      scopeTypes={scopeTypes}
      selectedScope={selectedScope}
      setSelectedScope={setSelectedScope}
      validateOnBlur={validateOnBlur}
      resetError={resetError}
      noSupplierResults={noSupplierResults}
      onAddFile={onAddFile}
      onRemoveFile={onRemoveFile}
      onSelectCode={onSelectCode}
      onRejectPO={onRejectPO}
      shouldDisplayStatus={shouldDisplayStatus}
      availableStatuses={availableStatuses}
    />
  );
}

const filePropTypes = PropTypes.shape({
  url: PropTypes.string,
  fileName: PropTypes.string,
  fileSize: PropTypes.number,
});
EditApproveModal.propTypes = {
  modalInfo: PropTypes.shape({
    supplier: PropTypes.shape({
      _id: PropTypes.string,
      company: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    isInScope: PropTypes.bool,
    cardNumber: PropTypes.string,
    amount: PropTypes.number,
    description: PropTypes.string,
    status: PropTypes.string,
    _id: PropTypes.string,
    project: PropTypes.shape({
      projectId: PropTypes.string,
    }),
    attachments: PropTypes.arrayOf(filePropTypes),
  }),
};

export default function (props) {
  const modalInfo = useSelector(
    (state) => state.purchaseOrders.modals.editApprovePO,
  );

  return modalInfo ? (
    <EditApproveModal {...props} modalInfo={modalInfo} />
  ) : null;
}
