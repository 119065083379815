import types from '../types.js';

export const setCompletedPMsTableSearchString = (query) => ({
  type: types.SET_COMPLETED_PMS_TABLE_SEARCH_STRING,
  payload: query,
});

export const setCompletedPMsPaginationCurrentPage = (current) => ({
  type: types.SET_COMPLETED_PMS_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setCompletedPMsPaginationTotal = (total) => ({
  type: types.SET_COMPLETED_PMS_PAGINATION_TOTAL,
  payload: total,
});

export const setCompletedPMsPaginationPageSize = (size) => ({
  type: types.SET_COMPLETED_PMS_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setCompletedPMsPaginationDropdownLabel = (label) => ({
  type: types.SET_COMPLETED_PMS_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});

export const setCompletedPMsDate = (date) => ({
  type: types.SET_COMPLETED_PMS_DATE,
  payload: date,
});

export const setCompletedPMsServiceType = (serviceType) => ({
  type: types.SET_COMPLETED_PMS_SERVICE_TYPE,
  payload: serviceType,
});

export const setCompletedPMsActiveTab = (tab) => ({
  type: types.SET_COMPLETED_PMS_ACTIVE_TAB,
  payload: tab,
});
