import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MasterRecurrentReportFilterComponent from '../../components/master-recurring-work-orders/filter.js';
import { ALL_RESULTS } from '../../constants/pagination.js';
import {
  setReportModal,
  setReportProjectFilter,
  setReportProjectsPaginationCurrentPage,
  setReportProjectsPaginationPageSize,
  setReportProjectsPaginationTotal,
} from '../../redux/actions/index.js';

export default function () {
  const dispatch = useDispatch();

  const label = useSelector((state) => state.report.paginationDropdownLabel);
  const activeFilter = useSelector((state) => state.report.typeFilter);
  const locationType = useSelector((state) => state.location.type);

  const onClick = useCallback(
    (filter, total) => {
      if (label === ALL_RESULTS) {
        dispatch(setReportProjectsPaginationPageSize(total));
      }

      dispatch(setReportProjectFilter(filter));
      dispatch(setReportProjectsPaginationTotal(total));
      dispatch(setReportProjectsPaginationCurrentPage(1));
    },
    [
      dispatch,
      setReportProjectFilter,
      setReportProjectsPaginationTotal,
      setReportProjectsPaginationCurrentPage,
      setReportProjectsPaginationPageSize,
    ],
  );

  const onAddProject = useCallback(
    () => dispatch(setReportModal('add')),
    [dispatch, setReportModal],
  );

  return (
    <MasterRecurrentReportFilterComponent
      onClick={onClick}
      onAddProject={onAddProject}
      locationType={locationType}
      activeFilter={activeFilter}
    />
  );
}
