import { logOutUser } from 'poly-client-utils';

import { routeLogin } from '../actions/index.js';
import { setDynamicTitle } from '../../utils/authorization/index.js';

const logOut = (client) => (dispatch) =>
  logOutUser({
    client,
    localStorage,
    customHandlerOnLogout: () => {
      setDynamicTitle();
      dispatch(routeLogin());
    },
  });

export default logOut;
