import React from 'react';

import Footer from '../containers/purchase-order/list/footer.js';
import PurchaseOrders from '../containers/purchase-order/list/list.js';
import ConfirmModal from '../containers/purchase-order/list/confirm-modal.js';
import EditApproveModal from '../containers/purchase-order/list/edit-approve-modal.js';

function PurchaseOrdersLayout() {
  return (
    <>
      <PurchaseOrders />
      <Footer />
      <EditApproveModal />
      <ConfirmModal />
    </>
  );
}

export default PurchaseOrdersLayout;
