import { useMutation } from '@apollo/client';
import {
  createContactUser,
  getPropertyUsers,
  usersChanged,
  updateContactUser,
} from '../../queries/index.js';
import { useReactiveEntities } from '../useReactiveEntities.js';

export const useCreateContactUser = (refetchQueries) => {
  const [mutate] = useMutation(createContactUser, {
    alias: 'useCreateContactUser',
    notifyOnNetworkStatusChange: true,
    refetchQueries: refetchQueries || [],
  });

  return {
    createContactUser: (input) =>
      mutate({
        variables: { input },
      }),
  };
};

export const usePropertyUsers = (propertyId) => {
  const query = {
    nested: {
      path: 'links',
      query: propertyId ? { term: { 'links.propertyId': propertyId } } : null,
    },
  };

  const { data, loading } = useReactiveEntities({
    gqlQuery: getPropertyUsers,
    gqlChangedQuery: usersChanged,
    query,
    additionalVars: { propertyId },
    alias: 'usePropertyUsers',
    skipQuery: !propertyId,
  });

  return {
    property: data?.property,
    usersLoading: loading,
    users: data?.property.searchContacts?.hits || [],
    defaultAddress: data?.property.address,
    data,
  };
};

export const useUpdateContact = (refetchQueries) => {
  const [mutate] = useMutation(updateContactUser, {
    alias: 'useUpdateContact',
    notifyOnNetworkStatusChange: true,
    refetchQueries: refetchQueries || [],
  });

  return {
    updateContactUser: (update) =>
      mutate({
        variables: {
          update,
        },
      }),
  };
};
