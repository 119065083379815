import types from '../types.js';

export const setPropertiesFilter = (filter) => ({
  type: types.SET_PROPERTIES_FILTER,
  payload: filter,
});

export const setPropertyActiveTab = (tab) => ({
  type: types.SET_PROPERTY_ACTIVE_TAB,
  payload: tab,
});

export const setPropertySidebar = (user) => ({
  type: types.SET_PROPERTY_SIDEBAR,
  payload: user,
});

export const setPropertiesTableSearchString = (query) => ({
  type: types.SET_PROPERTIES_TABLE_SEARCH_STRING,
  payload: query,
});

export const setPropertiesPaginationCurrentPage = (current) => ({
  type: types.SET_PROPERTIES_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setPropertiesPaginationTotal = (total) => ({
  type: types.SET_PROPERTIES_PAGINATION_TOTAL,
  payload: total,
});

export const setPropertiesPaginationPageSize = (size) => ({
  type: types.SET_PROPERTIES_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setPropertiesPaginationDropdownLabel = (label) => ({
  type: types.SET_PROPERTIES_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});

export const setPropertyPeopleModal = (modal) => ({
  type: types.SET_PROPERTY_PEOPLE_MODAL,
  payload: modal,
});
