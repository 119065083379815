import types from '../types.js';

export const setPOsModal = (modal) => ({
  type: types.SET_POS_MODAL,
  payload: modal,
});

export const setSelectedPOs = (ids) => ({
  type: types.SET_SELECTED_POS,
  payload: ids,
});

export const togglePO = (id) => ({
  type: types.TOGGLE_PO,
  payload: id,
});

export const setPOsTableSearchString = (query) => ({
  type: types.SET_POS_TABLE_SEARCH_STRING,
  payload: query,
});

export const setPOsPaginationCurrentPage = (current) => ({
  type: types.SET_POS_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setPOsPaginationTotal = (total) => ({
  type: types.SET_POS_PAGINATION_TOTAL,
  payload: total,
});

export const setPOsPaginationPageSize = (size) => ({
  type: types.SET_POS_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setPOsPaginationDropdownLabel = (label) => ({
  type: types.SET_POS_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});

export const setPOActiveTab = (tab) => ({
  type: types.SET_PO_ACTIVE_TAB,
  payload: tab,
});
