import { gql } from '@apollo/client';

export const updateTimelogs = gql`
  mutation updateTimelogs($id: ID!, $update: TimeLogUpdate!) {
    updateTimeLog(id: $id, update: $update) {
      _id
      assignedTo {
        _id
        fullName
      }
      createdAt
      duration
      endDate
      startDate
    }
  }
`;
