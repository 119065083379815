import { useQuery } from '@apollo/client';
import { propertyQuery } from '../queries/index.js';

export const usePropertyDetails = (propertyId) => {
  const { data, loading } = useQuery(propertyQuery, {
    alias: 'usePropertyDetails',
    variables: { id: propertyId },
    notifyOnNetworkStatusChange: true,
  });

  return {
    property: data?.property,
    loading,
  };
};
