import types from '../types.js';

const defaultState = {
  isPageChecking: false,
};

export default (state = defaultState, action = {}) => {
  if (action.type === types.SET_IS_PAGE_CHECKING) {
    return {
      ...state,
      isPageChecking: action.payload,
    };
  }

  return state;
};
