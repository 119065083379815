import * as R from 'ramda';
import { assocBy } from 'poly-utils';
import { SupplierEmailsTypes } from 'poly-constants';

import { initialFormData } from './constants.js';
import { WEEK } from '../../constants/report/master-recurring.js';
import {
  checkRepeats,
  formatRepeatsArray,
  formatRepeatsEvery,
  periodsValuesMap,
} from '../../utils/mr-projects/index.js';

const prepareSupplierForDisplay = R.applySpec({
  name: R.path(['company', 'name']),
  email: R.compose(
    R.find(R.propEq('type', SupplierEmailsTypes.GENERAL)),
    R.path(['company', 'emails']),
  ),
  _id: R.prop('_id'),
});

const prepareManagerForDisplay = R.when(
  R.identity,
  R.applySpec({
    firstName: R.prop('firstName'),
    lastName: R.prop('lastName'),
    email: R.path(['emails', '0', 'address']),
    _id: R.prop('_id'),
  }),
);

export const prepareEditMRProjectFormData = R.compose(
  R.mergeRight(initialFormData),
  assocBy('repeatEvery', ({ repeatTime, startDate, weekDay }) =>
    repeatTime === WEEK
      ? weekDay
      : formatRepeatsEvery(startDate, repeatTime, weekDay),
  ),
  assocBy('repeats', ({ repeatTime, startDate }) => {
    const checked = checkRepeats(repeatTime);
    return R.is(Array, checked)
      ? checked
      : formatRepeatsArray([checked, checked], startDate);
  }),
  R.applySpec({
    selectedStatus: R.prop('status'),
    endDate: R.ifElse(
      R.prop('endDate'),
      R.compose((date) => new Date(date), R.prop('endDate')),
      R.always(null),
    ),
    isNever: R.complement(R.prop('endDate')),
    repeatTime: R.compose(
      R.prop(R.__, R.invertObj(periodsValuesMap)),
      R.path(['schedule', 'repeats']),
    ),
    repeatNum: R.pathOr(1, ['schedule', 'every']),
    weekDay: R.path(['schedule', 'weekDay']),
    startDate: R.compose((date) => new Date(date), R.prop('startDate')),
    selectedService: R.path(['serviceType', '_id']),
    selectedProperty: R.applySpec({
      value: R.path(['property', '_id']),
      label: R.path(['property', 'name']),
    }),
    invoiceDescription: R.prop('invoiceDescription'),
    description: R.prop('description'),
    location: R.prop('location'),

    projectManager: R.compose(prepareManagerForDisplay, R.prop('manager')),
    projectSuppliers: R.compose(
      R.map(prepareSupplierForDisplay),
      R.propOr([], 'suppliers'),
    ),
    projectTechnician: R.compose(
      prepareManagerForDisplay,
      R.path(['technicians', 0]),
    ),
  }),
);
