import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FilterComponent from '../../components/suppliers/filter.js';
import { ALL_RESULTS } from '../../constants/pagination.js';
import {
  setSuppliersPaginationCurrentPage,
  setSuppliersPaginationPageSize,
  setSuppliersPaginationTotal,
  setAddSupplierModal,
  setSuppliersFilter,
} from '../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const activeFilter = useSelector((state) => state.suppliers.typeFilter);
  const label = useSelector((state) => state.suppliers.paginationDropdownLabel);

  const onClick = useCallback(
    (filter, total) => {
      if (label === ALL_RESULTS) {
        dispatch(setSuppliersPaginationPageSize(total));
      }
      dispatch(setSuppliersFilter(filter));
      dispatch(setSuppliersPaginationTotal(total));
      dispatch(setSuppliersPaginationCurrentPage(1));
    },
    [dispatch, label],
  );

  const openSupplierModal = useCallback(
    () => dispatch(setAddSupplierModal()),
    [dispatch],
  );

  return (
    <FilterComponent
      {...props}
      activeFilter={activeFilter}
      onClick={onClick}
      openSupplierModal={openSupplierModal}
      label={label}
    />
  );
}
