import React from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'poly-book';
import { AbsoluteLoader } from 'poly-site-ui';

import AuthContainer from '../hooks/auth-container.js';
import ProjectStatsTable from '../containers/projects/stats-list.js';
import Navigation from '../containers/navbar/navigation-container.js';
import { LayoutContent } from '../styled.js';
import ProjectsStatsFilter from '../containers/projects/filter/stats-filter.js';

function ProjectsStats() {
  const clientId = useSelector((state) => state.user.clientId);
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <ProjectsStatsFilter />
        </Layout.Header>
        <LayoutContent>
          {clientId ? (
            <ProjectStatsTable clientId={clientId} />
          ) : (
            <AbsoluteLoader />
          )}
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default ProjectsStats;
