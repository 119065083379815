import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
import { Loader } from 'poly-site-ui';
import SupplierFilesContainer from './tabs/files.js';
import SupplierPeopleContainer from './tabs/people/index.js';
import SupplierUpdatesContainer from './tabs/updates.js';
import SupplierNavigationContainer from './navigation.js';
import SupplierProjectsContainer from './tabs/projects.js';
import { usePrefetchSupplier } from '../../hooks/prefetch.js';

const tabs = {
  people: SupplierPeopleContainer,
  wos: SupplierProjectsContainer,
  recurring: SupplierProjectsContainer,
  updates: SupplierUpdatesContainer,
  files: SupplierFilesContainer,
};

function SupplierDataContainer({ activeTab, loading }) {
  const Container = tabs[activeTab];

  return (
    <>
      <SupplierNavigationContainer />
      <section>{loading ? <Loader /> : <Container />}</section>
    </>
  );
}

SupplierDataContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default function (props) {
  const activeTab = useSelector((state) => state.suppliers.activeTab);
  const supplierId = useSelector((state) => state.location.payload.id);

  const { data, loading } = usePrefetchSupplier(supplierId);

  return (
    <SupplierDataContainer
      {...props}
      {...data}
      activeTab={activeTab}
      loading={loading}
    />
  );
}
