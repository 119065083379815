import { gql } from '@apollo/client';

export const postMultiplePOInvoices = gql`
  mutation postMultiplePOInvoices($input: POInvoicePostInput!) {
    postMultiplePOInvoices(input: $input) {
      poInvoices {
        _id
      }
    }
  }
`;
