import * as R from 'ramda';

import types from '../types.js';
import { ALL_STAFF } from '../../constants/staff.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import { STAFF } from '../../constants/routes.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  typeFilter: ALL_STAFF,
  tableSearchString: '',
  modals: {
    sidebar: {
      tabs: {
        tabsNames: [],
        tabsQueries: {},
        activeTab: null,
        activeQuery: null,
      },
      userId: null,
      user: null,
    },
    addEdit: null,
  },
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_STAFF_FILTER: {
      return {
        ...state,
        typeFilter: action.payload,
      };
    }
    case types.SET_STAFF_SIDEBAR: {
      return {
        ...state,
        modals: {
          ...state.modals,
          sidebar: {
            ...state.modals.sidebar,
            userId: action.payload.userId,
            tabs: {
              ...action.payload.tabs,
              activeQuery:
                action.payload.tabs.tabsQueries[action.payload.tabs.activeTab],
            },
          },
        },
      };
    }
    case types.SET_STAFF_SIDEBAR_ACTIVE_TAB: {
      return {
        ...state,
        modals: {
          ...state.modals,
          sidebar: {
            ...state.modals.sidebar,
            tabs: {
              ...state.modals.sidebar.tabs,
              activeTab: action.payload,
              activeQuery:
                state.modals.sidebar.tabs.tabsQueries[action.payload],
            },
          },
        },
      };
    }
    case STAFF: {
      return {
        ...state,
        tableSearchString: '',
      };
    }
    case types.SET_STAFF_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_STAFF_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_STAFF_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_STAFF_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_STAFF_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    case types.SET_STAFF_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          sidebar: {
            ...state.modals.sidebar,
            user: R.pathOr(null, ['payload', 'user'], action),
          },
          addEdit: action.payload
            ? R.pick(['type'], action.payload)
            : action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
