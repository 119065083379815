import React from 'react';
import { TableSearchComponent } from 'poly-site-ui';
import {
  setApprovedInvoicesPaginationCurrentPage,
  setApprovedInvoicesTableSearchString,
} from '../../redux/actions/index.js';

export default function (props) {
  return (
    <TableSearchComponent
      {...props}
      setTableSearchString={setApprovedInvoicesTableSearchString}
      setPaginationCurrentPage={setApprovedInvoicesPaginationCurrentPage}
    />
  );
}
