import types from '../types.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import { COMPLETED_WOS, MAIN } from '../../constants/routes.js';
import { COMPLETED } from '../../constants/projects.js';
import { ALL_SERVICE_TYPES_VALUE } from '../../components/date-filter/service-type-selector.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  typeFilter: COMPLETED,
  locationType: MAIN,
  activeTab: 'projects',
  tableSearchString: '',
  dateFilter: {
    startDate: null,
    endDate: null,
  },
  serviceTypeId: ALL_SERVICE_TYPES_VALUE,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_COMPLETED_WOS_DATE: {
      return {
        ...state,
        dateFilter: action.payload,
      };
    }
    case types.SET_COMPLETED_WOS_SERVICE_TYPE: {
      return {
        ...state,
        serviceTypeId: action.payload,
      };
    }
    case types.SET_COMPLETED_WOS_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case COMPLETED_WOS: {
      return {
        ...state,
        tableSearchString: '',
        dateFilter: {
          startDate: null,
          endDate: null,
        },
      };
    }
    case types.SET_COMPLETED_WOS_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_COMPLETED_WOS_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_COMPLETED_WOS_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_COMPLETED_WOS_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_COMPLETED_WOS_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
