import { gql } from '@apollo/client';

export const supplierProjectsFragment = gql`
  fragment supplierProjectsFragment on ProjectsSearchResult {
    hits {
      _id
      projectId
      description
      endDate
      status
      technicians {
        _id
      }
    }
  }
`;

export const getSupplierProjects = gql`
  query getSupplierProjects($supplierId: ID!, $input: CollectionSearchParams!) {
    supplier(id: $supplierId) {
      _id
      searchProjects(input: $input) {
        ...supplierProjectsFragment
      }
    }
  }
  ${supplierProjectsFragment}
`;
