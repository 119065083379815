import ReactQuill from 'react-quill';
import styled from 'styled-components';

export const ReactQuillS = styled(ReactQuill)`
  .ql-container {
    font-family: inherit;
    font-size: 14px;
    color: #5e6271;
  }

  .ql-editor {
    min-height: 98px;
    max-height: 230px;
    padding: 15px;
    border-radius: 3px;
    border: 1px solid #e3e5ed;
  }

  .ql-disabled .ql-editor {
    padding: 0;
    border: none;
  }

  /* Placeholder Styles */
  .ql-editor.ql-blank::before {
    font-style: normal;
    color: #babfd2;
  }

  .ql-editor ol,
  .ql-editor ul {
    padding-left: 0;
  }

  #toolbar {
    display: flex;
    z-index: 1;
  }

  .ql-bubble .ql-picker {
    position: static;
  }

  .ql-bubble .ql-transparent {
    opacity: 1;
  }

  .ql-bubble .ql-toolbar .ql-formats {
    display: inline-flex;
    margin: 0;
    padding: 5px;
    position: relative;
  }

  .ql-bubble .ql-toolbar .ql-formats:not(:last-child) {
    border-right: 1px solid #23303c;
  }

  .ql-bubble .ql-toolbar .ql-formats button,
  .ql-bubble .ql-toolbar .ql-picker-label {
    width: 28px;
    height: 28px;
    display: flex;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    color: #d3dbea;
    border: 1px solid transparent;
    transition: background-color 0.2s ease, border-color 0.2s ease;
  }

  .ql-bubble .ql-toolbar .ql-picker-label {
    display: inline-block;
  }

  .ql-bubble .ql-toolbar .ql-formats button:hover,
  .ql-bubble .ql-toolbar .ql-picker-label:hover {
    background-color: #415266;
    border-color: #23303c;
  }

  .ql-bubble .ql-toolbar .ql-formats button.ql-active,
  .ql-bubble .ql-toolbar .ql-picker-label.ql-active {
    background-color: #2a3542;
    border-color: #23303c;
  }

  .ql-bubble .ql-toolbar .ql-picker-label {
    padding: 2px;
  }

  .ql-bubble .ql-tooltip {
    z-index: 5;
  }

  .ql-bubble .ql-tooltip,
  .ql-bubble .ql-color-picker .ql-picker-options {
    background-color: #354350;
    border-radius: 3px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  }

  .ql-bubble .ql-tooltip.ql-flip .ql-color-picker .ql-picker-options {
    top: -1px;
    bottom: auto;
    transform: translateY(-100%);
  }

  .ql-bubble .ql-color-picker .ql-picker-options {
    top: auto;
    bottom: -1px;
    left: 0;
    transform: translateY(100%);
  }

  .ql-bubble .ql-picker.ql-expanded .ql-picker-options {
    width: auto;
    display: flex;
  }

  .ql-bubble .ql-color-picker .ql-picker-item {
    width: 15px;
    height: 15px;
    margin: 5px;
    position: relative;
    border-radius: 50%;
    border: 1px solid #d3dbea;
  }

  .ql-bubble .ql-color-picker .ql-picker-item.ql-selected::after {
    width: 50%;
    height: 100%;
    display: block;
    content: '';
    position: absolute;
    top: -25%;
    right: 25%;
    border: 2px solid #fff;
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
  }

  #toolbar button.ql-bigger,
  #toolbar button.ql-smaller {
    font-weight: bold;
  }

  #toolbar button.ql-smaller {
    padding-top: 5px;
    font-size: 10px;
  }

  #toolbar button.ql-done {
    width: auto;
    height: auto;
    display: inline-flex;
    flex-shrink: 0;
    padding: 0 15px;
    position: relative;
    font-family: inherit;
    text-transform: uppercase;
    cursor: pointer;
    color: #d3dbea;
    background-color: #2a3542;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 2;
    line-height: 36px;
  }

  .ql-editor .ql-size-small {
    font-size: 12px;
  }

  .ql-editor .ql-size-large {
    font-size: 16px;
  }

  .ql-editor .ql-size-huge {
    font-size: 18px;
  }

  .ql-container.ql-bubble:not(.ql-disabled) a::before,
  .ql-container.ql-bubble:not(.ql-disabled) a::after {
    display: none;
  }
`;
