import * as R from 'ramda';
import React from 'react';
import {
  fileNameFormatter,
  fileTypeFormatter,
  setFileModal,
} from 'poly-site-ui';
import { UPDATE_FILE_PERMISSION, DELETE_FILE_PERMISSION } from 'poly-security';

import { dateFormatter, EditIcon, RemoveIcon } from '../util/job-costs.js';

const setEditFileModal = ({ data }) =>
  setFileModal({
    type: 'edit',
    data: {
      useMutation: true,
      file: {
        _id: data._id,
        name: data.fileName,
      },
    },
  });

const getAlign = (horizontal) => ({
  align: { vertical: 'center', horizontal },
});

export const fileColumns = (dispatch) => [
  ['Type', R.prop('fileType'), fileTypeFormatter],
  ['Name', R.identity, fileNameFormatter],
  ['Created', R.prop('createdAt'), dateFormatter],
  [
    '',
    R.identity,
    (data) => (
      <EditIcon
        {...{
          dispatch,
          action: setEditFileModal,
          permissions: UPDATE_FILE_PERMISSION,
          ...data,
        }}
      />
    ),
    getAlign('center'),
  ],
  [
    ' ',
    R.identity,
    (data) => (
      <RemoveIcon
        {...{
          dispatch,
          action: setFileModal,
          permissions: DELETE_FILE_PERMISSION,
          ...data,
        }}
      />
    ),
    getAlign('center'),
  ],
];
