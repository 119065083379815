import { gql } from '@apollo/client';

export const approveMultiplePOs = gql`
  mutation approveMultiplePOs($ids: [ID!]!) {
    approveMultiplePurchaseOrders(ids: $ids) {
      _id
      cardNumber
    }
  }
`;
