import types from '../types.js';

export const setReportPmProjectFilter = (filter) => ({
  type: types.SET_REPORT_PM_PROJECT_FILTER,
  payload: filter,
});

export const setReportPmTableSearchString = (query) => ({
  type: types.SET_REPORT_PM_TABLE_SEARCH_STRING,
  payload: query,
});
export const setReportPmProjectActiveTab = (tab) => ({
  type: types.SET_MR_PM_PROJECT_ACTIVE_TAB,
  payload: tab,
});

export const setReportPmPaginationCurrentPage = (current) => ({
  type: types.SET_MR_PM_PROJECTS_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setReportPmPaginationTotal = (total) => ({
  type: types.SET_MR_PM_PROJECTS_PAGINATION_TOTAL,
  payload: total,
});

export const setReportPmPaginationPageSize = (size) => ({
  type: types.SET_MR_PM_PROJECTS_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setReportPmPaginationDropdownLabel = (label) => ({
  type: types.SET_MR_PM_PROJECTS_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});
