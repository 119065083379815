import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AssetInfo from '../../components/asset/info.js';
import { setAssetModal } from '../../redux/actions/asset.js';
import { useAssetDetailsSub } from '../../hooks/assets.js';

export default function (props) {
  const dispatch = useDispatch();

  const assetId = useSelector((state) => state.location.payload.id);

  const isEditModalOpened = useSelector(
    (state) => state.asset.modal?.type === 'edit',
  );

  const openEditAssetModal = useCallback(
    () => dispatch(setAssetModal({ type: 'edit' })),
    [dispatch, setAssetModal],
  );

  const propsOfComponent = { ...props, assetId };

  const { restProps } = useAssetDetailsSub(propsOfComponent);

  return (
    <AssetInfo
      {...props}
      {...restProps}
      isEditModalOpened={isEditModalOpened}
      openEditAssetModal={openEditAssetModal}
    />
  );
}
