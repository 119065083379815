import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../components/pm-projects/footer.js';
import { setInvoicesModal } from '../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const selectedInvoices = useSelector(
    (state) => state.approvedInvoices.selectedInvoices,
  );

  const show = selectedInvoices.length > 0;

  const onClick = useCallback(
    () => dispatch(setInvoicesModal({ postInvoice: true })),
    [dispatch, setInvoicesModal],
  );

  return <Footer {...props} title="Post to AP" show={show} onClick={onClick} />;
}
