import { updatesSubscription } from 'poly-site-ui';
import { getPropertyUpdates } from '../../queries/index.js';
import { useReactiveUpdates } from '../useReactiveUpdates.js';

export const usePropertyUpdatesQuery = ({
  additionalVars,
  queryEndpointName,
  from,
  size,
}) => {
  const { loading, result } = useReactiveUpdates({
    gqlQuery: getPropertyUpdates,
    gqlSubscription: updatesSubscription,
    additionalVars,
    queryEndpointName,
    from,
    size,
    alias: 'usePropertyUpdatesQuery',
  });

  return {
    loading,
    result,
  };
};
