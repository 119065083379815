import { useQuery } from '@apollo/client';

import { getClientDetails } from '../queries/index.js';

export const useClientDetails = (clientId) => {
  const { data, loading } = useQuery(getClientDetails, {
    alias: 'useClientDetails',
    variables: { clientId },
    notifyOnNetworkStatusChange: true,
    skip: !clientId,
  });

  return {
    client: data?.client,
    clientLoading: loading,
  };
};
