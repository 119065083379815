import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { S, Button, Header } from 'poly-book';

const StyledFooter = styled(Header)`
  position: fixed;
  top: auto;
  bottom: 0;
  opacity: 0;
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.1);
  z-index: 3;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateY(100%);

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      transform: translateY(0);
    `};
`;

const StyledRow = styled(Header.Row)`
  padding: 25px 0;
`;

function Footer({ title, show, onClick }) {
  return (
    <StyledFooter {...{ show }}>
      <Header.Sub>
        <StyledRow>
          <Button mode="orange" {...{ onClick }}>
            <S type="title">{title}</S>
          </Button>
        </StyledRow>
      </Header.Sub>
    </StyledFooter>
  );
}

Footer.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Footer;
