import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SupportModal from '../../components/modals/support.js';
import { setSupportModal } from '../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const show = useSelector((state) => state.userMenu.modals.support);

  const closeModal = useCallback(
    () => dispatch(setSupportModal(false)),
    [dispatch],
  );

  if (!show) return null;

  return <SupportModal {...props} closeModal={closeModal} />;
}
