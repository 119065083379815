import { createActions } from 'redux-actions';

export const {
  toggleApprovedInvoice,
  setApprovedSelectedInvoices,
  setApprovedInvoicesPaginationTotal,
  setApprovedInvoicesTableSearchString,
  setApprovedInvoicesPaginationPageSize,
  setApprovedInvoicesPaginationCurrentPage,
  setApprovedInvoicesPaginationDropdownLabel,
} = createActions(
  'TOGGLE_APPROVED_INVOICE',
  'SET_APPROVED_SELECTED_INVOICES',
  'SET_APPROVED_INVOICES_PAGINATION_TOTAL',
  'SET_APPROVED_INVOICES_TABLE_SEARCH_STRING',
  'SET_APPROVED_INVOICES_PAGINATION_PAGE_SIZE',
  'SET_APPROVED_INVOICES_PAGINATION_CURRENT_PAGE',
  'SET_APPROVED_INVOICES_PAGINATION_DROPDOWN_LABEL',
);
