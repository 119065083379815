import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Input } from 'poly-book';

import { formValuesPropTypes } from './prop-types.js';

export function FormInput({ name, placeholder, formValues, errors, onChange }) {
  return (
    <Input
      name={name}
      aria-label={name}
      placeholder={placeholder}
      defaultValue={R.prop(name, formValues)}
      invalid={R.prop(name, errors)}
      {...{ onChange }}
    />
  );
}

FormInput.displayName = 'AssetModalFormInput';
FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  formValues: formValuesPropTypes,
  errors: formValuesPropTypes,
};
