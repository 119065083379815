import { gql } from '@apollo/client';
import { userFragment } from '../fragments.js';

export const getUser = gql`
  query getUser($id: ID) {
    user(id: $id) {
      ...userFragment
    }
  }

  ${userFragment}
`;
