import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from 'poly-book';
import styled from 'styled-components';

import { Table } from '../../table/table.js';

const WidgetBlock = styled(Widget.Item)`
  display: grid;
  grid-gap: 20px;
  grid-auto-rows: min-content;
  grid-template-rows: auto 1fr;
`;

function Projects({ projects, ...props }) {
  return (
    <WidgetBlock>
      <Table
        gridColumns="
        minmax(75px, 150px)
        minmax(150px, 1fr)
        minmax(75px, 150px)
        minmax(80px, 160px)
      "
        {...projects}
        {...props}
      />
    </WidgetBlock>
  );
}

Projects.propTypes = {
  projects: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    columns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
    formats: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  }).isRequired,
};

export default Projects;
