import { InvoicePOStatuses } from 'poly-constants';

import types from '../types.js';
import { INVOICES } from '../../constants/routes.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  tableSearchString: '',
  type: InvoicePOStatuses.pending,
  selectedInvoices: [],
  modals: {
    createInvoice: null,
    editInvoice: null,
    approveInvoice: false,
    blockInvoice: null,
    postInvoice: false,
  },
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_INVOICES_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          ...action.payload,
        },
      };
    }
    case types.SET_SELECTED_INVOICES: {
      return {
        ...state,
        selectedInvoices: action.payload,
      };
    }
    case types.TOGGLE_INVOICE: {
      const index = state.selectedInvoices.indexOf(action.payload);

      return {
        ...state,
        selectedInvoices:
          index === -1
            ? [...state.selectedInvoices, action.payload]
            : state.selectedInvoices.filter((id) => id !== action.payload),
      };
    }
    case INVOICES: {
      return {
        ...state,
        tableSearchString: '',
      };
    }
    case types.SET_INVOICES_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_INVOICES_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_INVOICES_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_INVOICES_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_INVOICES_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
