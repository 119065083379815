import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ConfirmModal } from 'poly-site-ui';

import { useRemoveTimelogs } from '../../../../hooks/project-job-costs.js';
import {
  DeleteTechnicianTimeSuccess,
  GeneralError,
} from '../../../../constants/alerts.js';
import { setTimelogsModal } from '../../../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const projectId = useSelector((state) => state.location.payload.id);
  const timeLogsModal = useSelector((state) => state.projects.modals.timeLogs);
  const activeProjectId = useSelector(
    (state) => state.projects.activeProject.projectId,
  );

  const { removeTimelogs } = useRemoveTimelogs();

  const closeModal = useCallback(
    () => dispatch(setTimelogsModal(null)),
    [dispatch, setTimelogsModal],
  );

  const onConfirm = useCallback(() => {
    setLoading(true);

    removeTimelogs(timeLogsModal.data._id, projectId)
      .then(() => {
        setLoading(false);
        dispatch(setTimelogsModal(null));
        toast.success(DeleteTechnicianTimeSuccess);
      })
      .catch(() => {
        setLoading(false);
        toast.error(GeneralError);
      });
  }, [dispatch, setLoading, timeLogsModal, removeTimelogs, projectId]);

  const showModal = timeLogsModal?.type === 'remove';
  const subHeading = 'Delete Timelog Entry?';
  const text =
    'Are you sure that you want to delete the selected timelog entry?';

  if (!showModal) return null;

  return (
    <ConfirmModal
      {...props}
      showModal={showModal}
      closeModal={closeModal}
      heading={activeProjectId}
      subHeading={subHeading}
      text={text}
      onConfirm={onConfirm}
      loading={loading}
    />
  );
}
