import React from 'react';
import PropTypes from 'prop-types';

import CompletedProjects from '../containers/completed-projects/data.js';

function CompletedProjectsLayout({ filters }) {
  return <CompletedProjects filters={filters} />;
}

CompletedProjectsLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.arrayOf(PropTypes.object),
};

export default CompletedProjectsLayout;
