import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'poly-site-ui';
import Assets from '../../../components/property/tabs/assets.js';
import {
  entityFormatter,
  noDataToDisplayWidget,
  fieldFormatter,
} from '../../../util/general.js';
import { assetLinkFormatter } from '../../../util/assets/formatters.js';
import { warrantyEndFormatter } from '../../../utils/assets.js';
import { usePropertyAssets } from '../../../hooks/property/assets.js';

const assetsColumns = [
  ['Equipment Type', R.prop('equipmentType'), fieldFormatter],
  ['Description', R.prop('description'), fieldFormatter],
  ['Manufacturer', R.prop('manufacturer'), fieldFormatter],
  ['Model', R.prop('model'), fieldFormatter],
  ['Serial Number', R.identity, assetLinkFormatter],
  ['Warranty Exp', R.prop('warrantyEnd'), warrantyEndFormatter()],
];

export default function (props) {
  const propertyId = useSelector((state) => state.location.payload.id);

  const { assetsLoading, assets } = usePropertyAssets(propertyId);

  if (assetsLoading) return <Loader />;
  if (!assets.length) return noDataToDisplayWidget('Assets')();

  const assetsObj = entityFormatter(assets, assetsColumns);

  return <Assets {...props} assets={assetsObj} />;
}
