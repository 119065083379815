import React from 'react';
import { useSelector } from 'react-redux';
import { TableSearchComponent, isDateFilterNil } from 'poly-site-ui';
import {
  setTechnicianStatsTableSearchString,
  setTechnicianStatsPaginationCurrentPage,
} from '../../redux/actions/index.js';

export default function () {
  const technicianStats = useSelector((state) => state.technicianStats);
  const { tableSearchString } = technicianStats.tableSearchString;
  const disabled = isDateFilterNil(technicianStats);

  return (
    <TableSearchComponent
      tableSearchString={tableSearchString}
      disabled={disabled}
      setTableSearchString={setTechnicianStatsTableSearchString}
      setPaginationCurrentPage={setTechnicianStatsPaginationCurrentPage}
    />
  );
}
