import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { SearchGrid } from 'poly-site-ui';

import { suppliersDescriptionFormatter } from '../../../utils/global-search/index.js';
import { highlightSupplierStatus } from '../../../utils/highlight/index.js';

const columnConfig = (search) => [
  [R.identity, suppliersDescriptionFormatter(search)],
  [R.prop('status'), highlightSupplierStatus(search)],
];
function SuppliersGlobalSearch({ hits, searchTerm }) {
  const gridColumns = `
      auto
      minmax(80px, 130px)
    `;

  return (
    <SearchGrid
      rows={hits}
      columnConfig={columnConfig}
      searchTerm={searchTerm}
      gridColumns={gridColumns}
    />
  );
}

SuppliersGlobalSearch.propTypes = {
  searchTerm: PropTypes.string,
  hits: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
};

export default SuppliersGlobalSearch;
