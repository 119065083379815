import React, { useEffect } from 'react';
import { Toaster, SiteGlobalStyle, SiteOldVersionDetector } from 'poly-site-ui';

import store from './redux/store.js';
import Switcher from './switcher.js';
import AppProviders from './util/app-providers.js';
import StaffModal from './containers/staff/add-edit-modal.js';
import checkCurrentUserP from './redux/thunks/checkToken.js';

function App() {
  useEffect(() => {
    store.dispatch(checkCurrentUserP);
  }, []);

  return (
    <AppProviders>
      <SiteGlobalStyle />
      <Switcher />
      <Toaster />
      <StaffModal />
      <SiteOldVersionDetector />
    </AppProviders>
  );
}

export default App;
