import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_PROJECT_PERMISSION } from 'poly-security';
import { Tabs, Widget } from 'poly-book';
import { Loader } from 'poly-site-ui';
import { AddAssetButton } from '../../project/tabs/assets/add-asset-to-project-btn.js';
import { RecurringProjectAssetsDetails } from '../../project/tabs/assets/assets-tabs/assets-details.js';
import { RecurringProjectsAssetsHistory } from '../../project/tabs/assets/assets-tabs/wo-history.js';
import { RecurringProjectAssetFiles } from '../../project/tabs/assets/assets-tabs/files.js';
import { setReportProjectAssetsActiveTab } from '../../../redux/actions/index.js';
import { useRecurringProjectAssets } from '../../../hooks/project-assets.js';

const subTabs = [
  { value: 'details', title: 'Assets Details' },
  { value: 'files', title: 'Files' },
  { value: 'history', title: 'WO History' },
];

const tabsContent = {
  details: RecurringProjectAssetsDetails,
  files: RecurringProjectAssetFiles,
  history: RecurringProjectsAssetsHistory,
};

function AssetsContainer({ activeTab, dispatch }) {
  const Container = tabsContent[activeTab];

  return (
    <Widget.Item>
      <Tabs>
        <Tabs.Header>
          {subTabs.map(({ value, title }) => (
            <Tabs.Tab
              bold
              key={value}
              href={`#${value}`}
              active={value === activeTab}
              onClick={() => dispatch(setReportProjectAssetsActiveTab(value))}
            >
              {title}
            </Tabs.Tab>
          ))}
        </Tabs.Header>
        <Tabs.Content>
          <Container />
        </Tabs.Content>
      </Tabs>
    </Widget.Item>
  );
}

AssetsContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default function (props) {
  const dispatch = useDispatch();

  const activeTab = useSelector((state) => state.report.assets.activeTab);
  const projectId = useSelector((state) => state.location.payload.id);

  const { data, loading, project } = useRecurringProjectAssets(projectId);

  if (loading) return <Loader />;
  if (!project.assets.length)
    return (
      <AddAssetButton
        dispatch={dispatch}
        isRecurring
        projectId={projectId}
        project={project}
        permissions={UPDATE_PROJECT_PERMISSION}
      />
    );

  return (
    <AssetsContainer
      {...props}
      {...data}
      activeTab={activeTab}
      dispatch={dispatch}
    />
  );
}
