import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { POST_PO_INVOICE_PERMISSION } from 'poly-security';
import { AbsoluteLoader } from 'poly-site-ui';
import { PostInvoiceError } from '../../constants/alerts.js';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import TableCheckbox from '../../components/checkboxes/table-checkbox.js';
import HeaderCheckbox from '../../components/checkboxes/header-checkbox.js';
import {
  highlightCurrency,
  highlightField,
} from '../../utils/highlight/index.js';
import { useInvoicesFilters } from '../../utils/invoices/invoices-filters.js';
import { checkPermissionByOneAccessItem } from '../../utils/user/index.js';
import { InvoiceFormatter } from '../../utils/invoices/index.js';
import usePagination from '../../hooks/usePagination.js';
import { noDataToDisplay } from '../../util/general.js';
import {
  POFormatter,
  projectPOFormatter,
  propertySelector,
  scopeFormatter,
} from '../../utils/purchase-orders/index.js';
import {
  setApprovedInvoicesPaginationCurrentPage,
  setApprovedInvoicesPaginationDropdownLabel,
  setApprovedInvoicesPaginationPageSize,
  setApprovedInvoicesPaginationTotal,
  setApprovedSelectedInvoices,
  toggleApprovedInvoice,
} from '../../redux/actions/index.js';
import { useInvoicesQuery } from '../../hooks/invoices.js';

const STATE_PATH = ['approvedInvoices', 'selectedInvoices'];

const getColumns = ({
  invoices,
  dispatch,
  tableSearchString: search,
  user,
}) => {
  const commonProps = {
    disabled: !checkPermissionByOneAccessItem(
      POST_PO_INVOICE_PERMISSION,
      user,
      R.pick(['clientId'], user),
    ),
    showErrorMessage: () => toast.error(PostInvoiceError),
  };
  return [
    [
      <HeaderCheckbox
        statePath={STATE_PATH}
        pageItems={invoices.map(({ _id }) => _id)}
        onChange={R.compose(dispatch, setApprovedSelectedInvoices)}
        {...commonProps}
      />,
      R.prop('_id'),
      (id) => (
        <TableCheckbox
          statePath={STATE_PATH}
          onChange={R.compose(dispatch, toggleApprovedInvoice)}
          id={id}
          {...commonProps}
        />
      ),
    ],
    ['PO#', R.prop('purchaseOrder'), POFormatter(search)],
    ['Invoice#', R.identity, InvoiceFormatter(search)],
    ['Amount', R.prop('total'), highlightCurrency(search)],
    ['Scope', R.path(['purchaseOrder', 'isInScope']), scopeFormatter(search)],
    ['WO#', R.prop('project'), projectPOFormatter(search)],
    [
      'Supplier',
      R.path(['supplier', 'company', 'name']),
      highlightField(search),
    ],
    ['Property', propertySelector, highlightField(search)],
  ];
};

export default function (props) {
  const dispatch = useDispatch();

  const approvedInvoices = useSelector((state) => state.approvedInvoices);
  const user = useSelector((state) => state.user);

  const {
    type,
    currentPage,
    pageSize,
    tableSearchString,
    paginationDropdownLabel,
  } = approvedInvoices;

  const from = (currentPage - 1) * pageSize;

  const { searchTerm, query, size } = useInvoicesFilters(approvedInvoices);

  const { invoices, total, invoicesLoading, restInvoicesProps } =
    useInvoicesQuery({ searchTerm, query, pageSize: size, from });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setApprovedInvoicesPaginationTotal,
    setPageSize: setApprovedInvoicesPaginationPageSize,
    setCurrent: setApprovedInvoicesPaginationCurrentPage,
    setPaginationDropdownLabel: setApprovedInvoicesPaginationDropdownLabel,
    total,
    currentPage,
  });

  const cols = getColumns({ invoices, dispatch, tableSearchString, user });

  const columns = R.map(R.nth(1), cols);
  const headers = R.map(R.nth(0), cols);
  const formats = R.map(R.compose(R.defaultTo(R.identity), R.nth(2)), cols);
  const colsPropsValue = R.map(R.nth(3), cols);
  const gridColumns = `
        16px
        minmax(80px, 150px)
        minmax(80px, 150px)
        100px
        100px
        minmax(80px, 150px)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
      `;

  if (invoicesLoading) return <AbsoluteLoader />;
  if (!invoices.length)
    return noDataToDisplay('Invoices Ready for AP Posting')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...restInvoicesProps}
      props={colsPropsValue}
      user={user}
      type={type}
      rows={invoices}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      tableSearchString={tableSearchString}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      invoicesLoading={invoicesLoading}
      searchTerm={searchTerm}
    />
  );
}
