import React, { useCallback } from 'react';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { UPDATE_PROJECT_PERMISSION } from 'poly-security';
import { ProjectType } from 'poly-constants';

import { Loader, ProjectAssetDetailsTabBase } from 'poly-site-ui';
import { setProjectAssetsModal } from '../../../../../redux/actions/index.js';
import { noDataToDisplayWidget } from '../../../../../util/general.js';
import {
  useProjectAssets,
  useRecurringProjectAssets,
} from '../../../../../hooks/project-assets.js';

function AssetsDetailsLoader() {
  return <Loader size={26} minHeight={54} />;
}

const isNoData = R.anyPass([
  R.propSatisfies(R.isNil, 'project'),
  ({ assetsPath, ...props }) =>
    R.pathSatisfies(R.anyPass([R.isNil, R.isEmpty]), [
      'project',
      ...assetsPath,
    ])(props),
]);

export function RecurringProjectAssetsDetails(props) {
  const projectId = useSelector((state) => state.location.payload.id);

  const dispatch = useDispatch();

  const { loading, project } = useRecurringProjectAssets(projectId);

  const removeAssetHandler = useCallback(
    (asset) =>
      dispatch(
        setProjectAssetsModal({
          removeAsset: {
            asset,
            documentId: project._id,
            childType: project.childType,
            isRecurring: true,
          },
        }),
      ),
    [project, setProjectAssetsModal, dispatch],
  );

  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_PROJECT_PERMISSION,
  );

  const allowRemoveAsset =
    ifHasPermission && project?.type !== ProjectType.PREVENTIVE_MAINTENANCE;

  if (loading) return <AssetsDetailsLoader />;

  if (isNoData({ assetsPath: ['assets'], project }))
    return noDataToDisplayWidget('Assets Details')();

  return (
    <ProjectAssetDetailsTabBase
      {...props}
      project={project}
      removeAsset={removeAssetHandler}
      allowRemoveAsset={allowRemoveAsset}
      assetsPath={['assets']}
      projectId={projectId}
    />
  );
}

export default function ProjectAssetsDetails(props) {
  const projectId = useSelector((state) => state.location.payload.id);

  const dispatch = useDispatch();

  const { loading, project } = useProjectAssets(projectId);

  const removeAssetHandler = useCallback(
    (asset) =>
      dispatch(
        setProjectAssetsModal({
          removeAsset: {
            asset,
            documentId: project._id,
            childType: project.childType,
            isRecurring: false,
          },
        }),
      ),
    [project, setProjectAssetsModal, dispatch],
  );

  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_PROJECT_PERMISSION,
  );

  const allowRemoveAsset =
    ifHasPermission && project?.type !== ProjectType.PREVENTIVE_MAINTENANCE;

  if (loading) return <AssetsDetailsLoader />;

  if (isNoData({ assetsPath: ['searchAssets', 'hits'], project }))
    return noDataToDisplayWidget('Assets Details')();

  return (
    <ProjectAssetDetailsTabBase
      {...props}
      project={project}
      removeAsset={removeAssetHandler}
      allowRemoveAsset={allowRemoveAsset}
      assetsPath={['searchAssets', 'hits']}
      projectId={projectId}
    />
  );
}
