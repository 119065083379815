import types from '../types.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import { DUE_TODAY } from '../../constants/projects.js';
import { PMS } from '../../constants/routes.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  typeFilter: DUE_TODAY,
  tableSearchString: '',
  activeTab: 'updates',
  assets: {
    activeTab: 'details',
  },
  techniciansModal: null,
  selectedProjects: [],
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_PM_PROJECTS_FILTER: {
      return {
        ...state,
        typeFilter: action.payload,
      };
    }
    case PMS: {
      return {
        ...state,
        tableSearchString: '',
      };
    }
    case types.SET_PM_PROJECTS_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_PM_PROJECTS_TECHNICIANS_MODAL: {
      return {
        ...state,
        techniciansModal: action.payload,
      };
    }
    case types.TOGGLE_PM_PROJECT: {
      const index = state.selectedProjects.indexOf(action.payload);

      return {
        ...state,
        selectedProjects:
          index === -1
            ? [...state.selectedProjects, action.payload]
            : state.selectedProjects.filter((id) => id !== action.payload),
      };
    }
    case types.SET_SELECTED_PM_PROJECTS: {
      return {
        ...state,
        selectedProjects: action.payload,
      };
    }
    case types.SET_PM_PROJECT_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case types.SET_PM_PROJECT_ASSETS_ACTIVE_TAB: {
      return {
        ...state,
        assets: { activeTab: action.payload },
      };
    }
    case types.SET_PM_PROJECTS_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_PM_PROJECTS_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_PM_PROJECTS_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_PM_PROJECTS_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
