import React from 'react';
import PropTypes from 'prop-types';
import { FilterByStatus } from 'poly-site-ui';
import { Header, Heading, Holder } from 'poly-book';
import { UpRow } from '../../styled.js';
import TableSearchContainer from '../../containers/pm-projects/filter/search.js';
import { useProjectFilters } from '../../utils/projects/projects-filter.js';
import { PROJECT_FILTERS } from '../../constants/projects.js';
import { useProjectsCountQuery } from '../../hooks/projects.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';
import { exportPMDirectoryToXLS } from './export-to-xls.js';

function FilterWrapper(props) {
  const { searchTerm, sort, query } = useProjectFilters(props);

  const { count } = useProjectsCountQuery({ searchTerm, sort, query });

  return <FilterByStatus {...props} count={count} />;
}

function ProjectsFilter({ onClick, activeFilter, locationType }) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <Heading.h1 lighter>PM’s</Heading.h1>
        </Header.Block>
        <Header.Block>
          <PrintAndExportButtons
            printTitle="PM Projects"
            exportFunction={exportPMDirectoryToXLS}
          />
        </Header.Block>
      </Header.Row>
      <UpRow>
        <Holder margin={10} center>
          {PROJECT_FILTERS.map(({ type, ...props }) => (
            <FilterWrapper
              active={activeFilter === type}
              key={type}
              typeFilter={type}
              locationType={locationType}
              {...{ onClick }}
              {...props}
            />
          ))}
        </Holder>
        <Header.Block>
          <TableSearchContainer />
        </Header.Block>
      </UpRow>
    </Header.Sub>
  );
}

ProjectsFilter.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeFilter: PropTypes.string.isRequired,
  locationType: PropTypes.string.isRequired,
};

export default ProjectsFilter;
