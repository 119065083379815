const PROPERTY_TYPES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const { ACTIVE, INACTIVE } = PROPERTY_TYPES;

export const PROPERTIES_FILTERS = [
  {
    title: 'Active',
    type: ACTIVE,
    color: '#9bdc53',
    showPip: true,
  },
  {
    title: 'Inactive',
    type: INACTIVE,
    color: '#ff8c00',
    showPip: true,
  },
];
