import React from 'react';
import { useSelector } from 'react-redux';
import { NOT_FOUND } from 'redux-first-router';

import { NotFoundPage } from './pages/404.js';
import { LoginPage } from './pages/Login/LoginPage.js';
import { ChangePasswordPage } from './pages/ChangePassword/ChangePasswordPage.js';

import {
  ApprovedInvoices,
  ApprovedPOs,
  Asset,
  Assets,
  CompletedPMProjects,
  CompletedProjects,
  ContractExpirationReport,
  EyesWideOpen,
  ForgotPassword,
  InvoiceLog,
  Invoices,
  Main,
  MrPmProject,
  MRProject,
  PMProjects,
  POLog,
  Project,
  ProjectPM,
  ProjectsStats,
  Properties,
  Property,
  PurchaseOrder,
  PurchaseOrders,
  Report,
  ReportPM,
  Reports,
  Staff,
  Supplier,
  Suppliers,
  TechnicianStats,
} from './pages/index.js';

import {
  APPROVED_INVOICES,
  APPROVED_POS,
  ASSET,
  ASSETS,
  CHANGE_PASSWORD,
  COMPLETED_PMS,
  COMPLETED_WOS,
  COMPLETED_ON_HOLD_WOS,
  CONTRACT_EXPIRATION_REPORT,
  EYES_WIDE_OPEN,
  FORGOT_PASSWORD,
  INVOICE_LOG,
  INVOICES,
  LOGIN,
  MAIN,
  MR_PM_PROJECT,
  MR_PROJECT,
  PM_PROJECT,
  PMS,
  PO_LOG,
  PROJECT,
  PROPERTIES,
  PROPERTY,
  PURCHASE_ORDER,
  PURCHASE_ORDERS,
  REPORT,
  REPORT_PM,
  REPORTS,
  STAFF,
  SUPPLIER,
  SUPPLIERS,
  TECHNICIAN_STATS,
  PROJECTS_STATS,
  INCORRECT_DEVICE,
} from './constants/routes.js';
import CompletedOnHoldProjects from './pages/completed-projects-on-hold.js';
import { IncorrectDevicePage } from './pages/IncorrectDevice/IncorrectDevicePage.js';

const components = {
  [MAIN]: Main,
  [PMS]: PMProjects,
  [LOGIN]: LoginPage,
  [FORGOT_PASSWORD]: ForgotPassword,
  [CHANGE_PASSWORD]: ChangePasswordPage,
  [PROJECT]: Project,
  [PM_PROJECT]: ProjectPM,
  [PROPERTY]: Property,
  [PROPERTIES]: Properties,
  [REPORTS]: Reports,
  [REPORT]: Report,
  [REPORT_PM]: ReportPM,
  [MR_PM_PROJECT]: MrPmProject,
  [STAFF]: Staff,
  [ASSETS]: Assets,
  [ASSET]: Asset,
  [SUPPLIERS]: Suppliers,
  [SUPPLIER]: Supplier,
  [NOT_FOUND]: NotFoundPage,
  [MR_PROJECT]: MRProject,
  [PURCHASE_ORDERS]: PurchaseOrders,
  [PURCHASE_ORDER]: PurchaseOrder,
  [APPROVED_POS]: ApprovedPOs,
  [INVOICES]: Invoices,
  [APPROVED_INVOICES]: ApprovedInvoices,
  [COMPLETED_ON_HOLD_WOS]: CompletedOnHoldProjects,
  [COMPLETED_WOS]: CompletedProjects,
  [COMPLETED_PMS]: CompletedPMProjects,
  [EYES_WIDE_OPEN]: EyesWideOpen,
  [TECHNICIAN_STATS]: TechnicianStats,
  [INVOICE_LOG]: InvoiceLog,
  [PO_LOG]: POLog,
  [PROJECTS_STATS]: ProjectsStats,
  [CONTRACT_EXPIRATION_REPORT]: ContractExpirationReport,
  [INCORRECT_DEVICE]: IncorrectDevicePage,
};

function Switcher() {
  const type = useSelector((state) => state.location.type);
  const isPageChecking = useSelector(
    (state) => state.pageLoading.isPageChecking,
  );

  const Component = components[type];

  return isPageChecking ? <NotFoundPage isPageChecking /> : <Component />;
}

export default Switcher;
