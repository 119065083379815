import React from 'react';
import { Layout } from 'poly-book';

import AuthContainer from '../hooks/auth-container.js';
import AssetPageLayout from '../layouts/asset-page-layout.js';
import Navigation from '../containers/navbar/navigation-container.js';
import AssetInfoContainer from '../containers/navbar/asset-info-container.js';

function Asset() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <AssetInfoContainer />
        </Layout.Header>
        <Layout.Content columns="450px 1fr">
          <AssetPageLayout />
        </Layout.Content>
      </Layout>
    </AuthContainer>
  );
}

export default Asset;
