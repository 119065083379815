import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useManagers } from '../../../../hooks/staff.js';
import { useUpdateProject } from '../../../../hooks/projects.js';

import ManagersTab from '../../../../components/project/details/response-team/manager-tab.js';
import { DropDownLoader } from '../../../../util/dropdown.js';
import {
  AddResponseTeamSuccess,
  EditResponseTeamSuccess,
  GeneralError,
} from '../../../../constants/alerts.js';

function ManagersResponseTeam(props) {
  const { searchTerm, assignedManager, ...restProps } = props;

  const projectId = useSelector(
    (state) => state.projects.modals.responseTeam.projectDbId,
  );

  const clientId = useSelector((state) => state.user.clientId);

  const [addLoading, setLoading] = useState(false);
  const [projectManager, setProjectManager] = useState(null);

  useEffect(() => {
    if (assignedManager?._id) {
      setProjectManager({
        _id: assignedManager._id,
        firstName: assignedManager.firstName,
        lastName: assignedManager.lastName,
        email: R.path(['emails', 0, 'address'], assignedManager),
      });
    }
  }, [assignedManager]);

  const { loading, managers } = useManagers(searchTerm, clientId);

  const { updateProject } = useUpdateProject();

  const allManagers = managers?.filter(
    ({ _id }) => _id !== projectManager?._id,
  );

  if (loading) return <DropDownLoader />;

  const handleManagersClick = (data, isSelected) => () => {
    setLoading(true);
    const managerId = isSelected ? null : data._id;
    const clickedManager =
      !isSelected && managers.find((t) => t._id === data._id);

    updateProject(projectId, { managerId })
      .then(() => {
        setProjectManager(
          isSelected
            ? null
            : {
                _id: R.prop('_id', clickedManager),
                firstName: R.prop('firstName', clickedManager),
                lastName: R.prop('lastName', clickedManager),
                email: R.prop('email', clickedManager),
              },
        );
        setLoading(false);
        toast.success(
          isSelected ? EditResponseTeamSuccess : AddResponseTeamSuccess,
        );
      })
      .catch(() => {
        setLoading(false);
        toast.error(GeneralError);
      });
  };

  return (
    <ManagersTab
      {...restProps}
      allManagers={allManagers}
      projectManager={projectManager}
      addLoading={addLoading}
      handleManagersClick={handleManagersClick}
    />
  );
}

ManagersResponseTeam.propTypes = {
  searchTerm: PropTypes.string,
  assignedManager: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default ManagersResponseTeam;
