import * as R from 'ramda';
import {
  boolToYesNo,
  performExcelExport,
  createExcelExportCell,
  ExcelExportCellType,
} from 'poly-client-utils';

const COLUMN_WIDTH = [15, 10, 10, 15, 25, 25];

const TITLE_ROW = ['PO #', 'Amount', 'Scope', 'WO #', 'Supplier', 'Property'];

// getPORequestRowConfig :: Order -> [ExcelExportDataCell]
const getPORequestRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ExcelExportCellType.default,
    ExcelExportCellType.defaultCurrency,
    ...R.repeat(ExcelExportCellType.default, 4),
  ]),
  R.juxt([
    R.propOr('', 'cardNumber'),
    R.compose(Number, R.propOr(0, 'amount')),
    R.compose(boolToYesNo, R.propOr('', 'isInScope')),
    R.pathOr('', ['project', 'projectId']),
    R.pathOr('', ['supplier', 'company', 'name']),
    R.either(
      R.pathOr('', ['project', 'property', 'name']),
      R.pathOr('', ['property', 'name']),
    ),
  ]),
);

// getPORequestsExcelConfig :: [Orders] -> Object
export const getPORequestsExcelConfig = (fileName) => (projects) => ({
  exportFileName: `${fileName}.xlsx`,
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 6)],
      TITLE_ROW,
    ),
    ...R.map(getPORequestRowConfig, projects),
  ],
});

export const exportPORequestsToXLS = R.compose(
  performExcelExport,
  getPORequestsExcelConfig('po_requests_export'),
  R.pathOr([], ['data', 'searchPurchaseOrders', 'hits']),
);

export const exportPOWaitingForInvoiceToXLS = R.compose(
  performExcelExport,
  getPORequestsExcelConfig('po_waiting_for_invoice_export'),
  R.pathOr([], ['data', 'searchPurchaseOrders', 'hits']),
);
