import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, projectStatusUITransform } from 'poly-site-ui';
import { getUserCellPhoneNumber, getUserWorkPhoneNumber } from 'poly-utils';

import ProjectDetails from '../../../components/project/details/index.js';
import {
  companyPhoneFormatter,
  getEmailByType,
} from '../../../utils/suppliers/index.js';

import { SUPPLIERS_EMAILS } from '../../../constants/suppliers.js';
import { setResponseTeamModal } from '../../../redux/actions/index.js';
import { phoneFormatter } from '../../../utils/formatters/index.js';
import { useProjectDetails } from '../../../hooks/project-details.js';

// getProject :: Project -> Object
function getProject(project) {
  return {
    _id: project._id,
    status: project.status,
    serviceName: R.path(['serviceType', 'name'], project),
    parent: R.prop('parent', project),
    description: R.prop('description', project),
    details: R.prop('invoiceDescription', project),
    addressParts: R.path(['property', 'address', 'address_parts'], project),
    location: R.prop('location', project),
    property: R.prop('property', project),
    client: R.path(['client', 'name'], project),
    requester: R.compose(
      R.converge(R.mergeRight, [
        R.identity,
        R.compose(R.objOf('phone'), phoneFormatter, getUserWorkPhoneNumber),
      ]),
      R.prop('contact'),
    )(project),
    manager:
      (!!project.manager && {
        _id: R.path(['manager', '_id'], project),
        firstName: R.path(['manager', 'firstName'], project),
        lastName: R.path(['manager', 'lastName'], project),
        email: R.path(['manager', 'emails', 0, 'address'], project),
        phone: R.path(['manager', 'profile', 'cellPhoneNumber'], project),
      }) ||
      {},
    technicians: R.map(
      (technician) => ({
        _id: R.prop('_id', technician),
        firstName: R.path(['firstName'], technician),
        lastName: R.path(['lastName'], technician),
        email: R.path(['emails', 0, 'address'], technician),
        phone: getUserCellPhoneNumber(technician),
        userGroups: technician?.userGroups,
      }),
      project.technicians,
    ),
    suppliers: R.map(
      (supplier) => ({
        _id: supplier._id,
        name: R.path(['company', 'name'], supplier),
        email: getEmailByType(
          R.path(['company', 'emails'], supplier),
          SUPPLIERS_EMAILS.SERVICE,
        ),
        phone: companyPhoneFormatter(
          R.pathOr([], ['company', 'phones'], supplier),
        ),
      }),
      project.suppliers,
    ),
    startDate: project.startDate,
    endDate: project.endDate,
    type: project.type,
  };
}

function ProjectDetailsComposer(props) {
  const dispatch = useDispatch();

  const projectId = useSelector((state) => state.location.payload.id);

  const { project } = useProjectDetails(projectId);

  const openModal = () =>
    dispatch(setResponseTeamModal({ projectId, projectDbId: project._id }));

  if (!project) return <Loader />;

  const transformedProject = getProject(project);

  const statusUI = projectStatusUITransform(project);

  return (
    <ProjectDetails
      {...props}
      openModal={openModal}
      project={transformedProject}
      statusUI={statusUI}
    />
  );
}

export default ProjectDetailsComposer;
