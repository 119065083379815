import React from 'react';
import { useSelector } from 'react-redux';
import { usePropertyDetails } from '../../hooks/property-details.js';
import PropertyInfo from '../../components/property/property-info.js';

export default function (props) {
  const propertyId = useSelector((state) => state.location.payload.id);

  const { property, loading } = usePropertyDetails(propertyId);

  return <PropertyInfo {...props} property={property} loading={loading} />;
}
