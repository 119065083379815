import { gql } from '@apollo/client';

export const updateMaterials = gql`
  mutation updateMaterials($id: ID!, $update: MaterialSpendUpdate) {
    updateMaterialSpend(id: $id, update: $update) {
      _id
      createdAt
      price
      quantity
      total
    }
  }
`;
