import { gql } from '@apollo/client';

export const userProjectsCountQuery = gql`
  fragment Total on ProjectsSearchResult {
    total
  }

  query getUserProjects($id: ID, $input: CollectionSearchParams) {
    user(id: $id) {
      _id
      searchRelatedProjects(input: $input) {
        ...Total
      }
      searchTechnicianProjects(input: $input) {
        ...Total
      }
      searchManagerProjects(input: $input) {
        ...Total
      }
    }
  }
`;

export const userProjectsQuery = gql`
  fragment ProjectFields on ProjectsSearchResult {
    hits {
      _id
      type
      projectId
      description
    }
  }

  query getUserProjects($id: ID, $input: CollectionSearchParams) {
    user(id: $id) {
      _id
      searchRelatedProjects(input: $input) {
        ...ProjectFields
      }
      searchTechnicianProjects(input: $input) {
        ...ProjectFields
      }
      searchManagerProjects(input: $input) {
        ...ProjectFields
      }
    }
  }
`;
