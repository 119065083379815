import * as R from 'ramda';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useMutation, gql } from '@apollo/client';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditAssetSuccess } from '../../constants/alerts.js';
import { AssetModalForm } from '../../components/asset/asset-modal-form/asset-modal-form.js';
import { setAssetModal } from '../../redux/actions/asset.js';
import {
  assetModalValidationRules,
  prepareFormDataToMutation,
} from './add-asset-modal.js';
import useValidation from '../../hooks/useValidation.js';

// eslint-disable-next-line import/no-unused-modules
export const editAssetMutation = gql`
  mutation editAssetMutation($id: ID!, $input: UpdateAssetInput) {
    updateAsset(id: $id, input: $input) {
      asset {
        _id
      }
    }
  }
`;

// getAssetInitialValues :: Asset -> FormValues
const getAssetInitialValues = R.converge(R.mergeLeft, [
  R.applySpec({
    propertyId: R.path(['property', '_id']),
    startUp: R.ifElse(
      R.prop('startUp'),
      R.compose((date) => new Date(date), R.prop('startUp')),
      R.always(null),
    ),
  }),
  R.pick([
    'room',
    'model',
    'serial',
    'status',
    'location',
    'description',
    'manufacturer',
    'equipmentType',
  ]),
]);

export function EditAssetModal({ asset }) {
  const {
    errors,
    onChange: onChangeHandler,
    validate,
    setError,
  } = useValidation({
    validationRules: assetModalValidationRules,
  });

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const timer = useRef(null);

  const dispatch = useDispatch();
  const modal = useSelector(R.path(['asset', 'modal']));

  useEffect(() => {
    setFormValues(getAssetInitialValues(asset));
    return () => {
      clearTimeout(timer.current);
    };
  }, [asset]);

  const [editAsset] = useMutation(editAssetMutation);

  const closeModal = () => dispatch(setAssetModal(null));

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const submitErrors = validate(formValues);

    if (submitErrors.isInvalid) return setLoading(false);

    try {
      await editAsset({
        variables: {
          id: asset._id,
          input: prepareFormDataToMutation(formValues),
        },
      });
      setError({});
      closeModal();
      toast.success(EditAssetSuccess);
    } catch (err) {
      setError({ server: err.toString() });
    }
    return setLoading(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormValues((state) => ({ ...state, [name]: value }));
    timer.current = onChangeHandler(e);
  };

  const formProps = {
    errors,
    loading,
    formValues,
    onChange,
    onSubmit,
    closeModal,
    isModalOpen: !!modal,
    modalType: modal ? modal.type : null,
    title: asset.equipmentType,
  };

  if (loading || !formValues) {
    return null;
  }

  return <AssetModalForm {...formProps} />;
}

EditAssetModal.propTypes = {
  asset: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    equipmentType: PropTypes.string,
  }),
};
