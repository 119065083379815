import PropTypes from 'prop-types';
import { Quill } from 'react-quill';
import React from 'react';
import 'react-quill/dist/quill.bubble.css';

import { ReactQuillS } from './styles.js';

const FONT_SIZES = ['small', false, 'large', 'huge'];

const Italic = Quill.import('formats/italic');
Italic.tagName = 'i';
Quill.register(Italic, true);

function CustomToolbar() {
  const toolbarProps = {
    id: 'toolbar',
    onMouseDown: (e) => e.preventDefault(),
  };

  return (
    <div {...toolbarProps}>
      <span className="ql-formats">
        <button className="ql-bold" type="button" />
        <button className="ql-italic" type="button" />
        <button className="ql-underline" type="button" />
        <select className="ql-color">
          <option value="#000000" />
          <option value="#23303c" />
          <option value="#5c5f64" />
          <option value="#3246a0" />
          <option value="#5cb516" />
          <option value="#e9c83a" />
          <option value="#cd1444" />
          <option value="#772ca8" />
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bigger" type="button">
          A
        </button>
        <button className="ql-smaller" type="button">
          A
        </button>
        <button className="ql-list" value="bullet" type="button" />
        <button className="ql-list" value="ordered" type="button" />
        <button className="ql-link" type="button" />
      </span>
      <button className="ql-done ql-close" type="button">
        done
      </button>
    </div>
  );
}

function Editor(props) {
  return (
    <>
      {props.readOnly || <CustomToolbar />}
      <ReactQuillS
        theme="bubble"
        className="Poly-Editor"
        modules={props.readOnly ? { toolbar: false } : Editor.modules}
        formats={Editor.formats}
        ref={props.registerRef}
        {...props}
      />
    </>
  );
}

function onDoneClick() {
  const { index, length } = this.quill.getSelection();
  const tooltipElement = this.quill.theme.tooltip.root;

  if (tooltipElement.classList.contains('ql-editing')) {
    const href = tooltipElement.querySelector(
      '.ql-tooltip-editor input[type="text"]',
    ).value;
    this.quill.format('link', href);
  }

  this.quill.setSelection(index + length, 0);
}

function onBiggerClick() {
  let index = 2;
  const { size } = this.quill.getFormat();

  if (size) {
    index = FONT_SIZES.indexOf(size) + 1;
    index = index < FONT_SIZES.length ? index : index - 1;
  }

  this.quill.format('size', FONT_SIZES[index]);
}

function onSmallerClick() {
  let index = 0;
  const { size } = this.quill.getFormat();

  if (size) {
    index = FONT_SIZES.indexOf(size) - 1;
    index = index > 0 ? index : index + 1;
  }

  this.quill.format('size', FONT_SIZES[index]);
}

// function bigger

Editor.modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      done: onDoneClick,
      bigger: onBiggerClick,
      smaller: onSmallerClick,
    },
  },
};

Editor.formats = [
  'bold',
  'italic',
  'underline',
  'color',
  'list',
  'link',
  'size',
];

Editor.propTypes = {
  readOnly: PropTypes.bool,
  registerRef: PropTypes.func,
};

Editor.defaultProps = {
  readOnly: false,
  registerRef: () => {},
};

export default Editor;
