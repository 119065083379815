import { gql } from '@apollo/client';

export const recurringProjectsQuery = gql`
  query getRecurringProjects($input: CollectionSearchParams) {
    searchRecurringProjects(input: $input) {
      total
      hits {
        _id
        description
        startDate
        endDate
        projectId
        status
        property {
          _id
          name
        }
        technicians {
          _id
          fullName
        }
        manager {
          _id
          fullName
        }
        suppliers {
          _id
          company {
            name
          }
        }
        assets {
          _id
          equipmentType
        }
      }
    }
  }
`;
