import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
import { Loader } from 'poly-site-ui';
import EditProjectModal from './edit.js';
import { DataWrapper } from '../project/data.js';
import NavigationContainer from './navigation.js';
import ChecklistContainer from './tabs/checklist.js';
import FilesContainer from '../project/tabs/files.js';
import CostsContainer from '../project/tabs/job-costs/index.js';
import UpdatesContainer from '../project/tabs/updates.js';
import AssetsContainer from '../project/tabs/assets/main.js';
import AddAssetToProject from '../project/tabs/assets/add-asset-to-project.js';
import RemoveAssetConfirmModal from '../project/tabs/assets/confirm-remove-asset.js';
import { usePrefetchPMProject } from '../../hooks/prefetch.js';

const tabs = {
  updates: UpdatesContainer,
  costs: CostsContainer,
  assets: AssetsContainer,
  checklist: ChecklistContainer,
  files: FilesContainer,
};

function ProjectDataContainer({
  loading,
  activeTab,
  isAddAssetModal,
  isRemoveAssetModal,
}) {
  const Container = tabs[activeTab];

  return (
    <>
      <NavigationContainer />
      <DataWrapper>
        {loading ? <Loader /> : <Container />}
        <EditProjectModal />
        {isAddAssetModal && <AddAssetToProject />}
        {isRemoveAssetModal && <RemoveAssetConfirmModal />}
      </DataWrapper>
    </>
  );
}

ProjectDataContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  isAddAssetModal: PropTypes.bool.isRequired,
  isRemoveAssetModal: PropTypes.bool.isRequired,
};

function DataPM(props) {
  const { restProps } = usePrefetchPMProject(props);

  return <ProjectDataContainer {...props} {...restProps} />;
}

export default function (props) {
  const activeTab = useSelector((state) => state.PMProjects.activeTab);

  const projectId = useSelector(
    (state) => state.projects.activeProject?.projectId,
  );

  const _id = useSelector((state) => state.projects.activeProject?._id);

  const isAddAssetModal = useSelector(
    (state) => !!state.projectAssets.modals.addAsset,
  );

  const isRemoveAssetModal = useSelector(
    (state) => !!state.projectAssets.modals.removeAsset,
  );

  if (!projectId || !_id) return null;

  return (
    <DataPM
      {...props}
      _id={_id}
      activeTab={activeTab}
      projectId={projectId}
      isAddAssetModal={isAddAssetModal}
      isRemoveAssetModal={isRemoveAssetModal}
    />
  );
}
