import React from 'react';
import { Widget } from 'poly-book';

import AssetDataContainer from '../containers/asset/data.js';
import AssetDetailsContainer from '../containers/asset/details.js';

function AssetPageLayout() {
  return (
    <>
      <Widget>
        <AssetDetailsContainer />
      </Widget>
      <AssetDataContainer />
    </>
  );
}

export default AssetPageLayout;
