import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Arrow, Button, Dropdown, S } from 'poly-book';
import {
  CREATE_PURCHASE_ORDER_PERMISSION,
  CREATE_SUPPLIER_PERMISSION,
  CREATE_PROJECT_PERMISSION,
} from 'poly-security';

import { GlobalAddButton } from 'poly-site-ui';
import AddPORequest from '../../containers/purchase-order/add-po-request.js';
import ConfirmPORequest from '../../containers/purchase-order/confirm-modal.js';
import AddProject from '../../containers/project/add-project-container.js';
import AddMasterWO from '../../containers/master-recurring-work-orders/add-master-wo-modal.js';
import AddSupplier from '../../containers/supplier/add-modal.js';
import { closeAllSidebars } from '../../utils/sidebars/index.js';

const ItemsDropDown = styled.div`
  display: inline-flex;
  position: relative;
`;

ItemsDropDown.Button = styled(Button)`
  margin: 11px 0;
  border-color: transparent;

  > ${Arrow} {
    margin-top: 0;
    margin-left: 10px;
  }
`;

ItemsDropDown.Items = styled(Dropdown.Items)`
  width: auto;
  max-width: 250px;
  padding: 10px 0;
  left: auto;
  right: 0;

  ${S} {
    display: inline-block;
    margin-top: 5px;
  }
`;

const RightBorder = styled.div`
  display: inline-flex;
  align-self: stretch;
  align-items: center;
  padding-right: 20px;
  margin-right: 10px;
  border-right: 1px solid #52629e;
`;

function ModifiedItemComp({ title, onClick }) {
  return (
    <Dropdown.Item onClick={onClick}>
      <S type="title">{title}</S>
    </Dropdown.Item>
  );
}

ModifiedItemComp.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ModifiedItemComp.defaultProps = {
  onClick: () => undefined,
};

function AddItems({
  modals,
  openProjectModal,
  openSupplierModal,
  openNonWOPOModal,
  openReportModal,
}) {
  return (
    <RightBorder>
      <GlobalAddButton
        onClick={closeAllSidebars}
        permissions={[
          CREATE_PROJECT_PERMISSION,
          CREATE_SUPPLIER_PERMISSION,
          CREATE_PURCHASE_ORDER_PERMISSION,
        ]}
        items={[
          {
            title: 'Add Work Order',
            onClick: openProjectModal,
            permissions: [CREATE_PROJECT_PERMISSION],
          },
          {
            title: 'Add Master Recurring WO',
            onClick: openReportModal,
            permissions: [CREATE_PROJECT_PERMISSION],
          },
          {
            title: 'Add Supplier',
            onClick: openSupplierModal,
            permissions: [CREATE_SUPPLIER_PERMISSION],
          },
          {
            title: 'Add Non Work Order PO',
            onClick: openNonWOPOModal,
            permissions: [CREATE_PURCHASE_ORDER_PERMISSION],
          },
        ]}
      />
      {R.pathEq(['project', 'type'], 'add')(modals) && <AddProject />}
      <AddSupplier />
      <AddPORequest />
      <ConfirmPORequest />
      <AddMasterWO />
    </RightBorder>
  );
}

AddItems.propTypes = {
  modals: PropTypes.shape({
    invoices: PropTypes.shape({
      type: PropTypes.string,
    }),
    timeLogs: PropTypes.shape({
      type: PropTypes.string,
    }),
    materials: PropTypes.shape({
      type: PropTypes.string,
    }),
    project: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
  openProjectModal: PropTypes.func.isRequired,
  openSupplierModal: PropTypes.func.isRequired,
  openNonWOPOModal: PropTypes.func.isRequired,
  openReportModal: PropTypes.func.isRequired,
};

export default AddItems;
