import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_PROJECT_PERMISSION } from 'poly-security';
import { Tabs, Widget } from 'poly-book';

import { Loader } from 'poly-site-ui';
import { ProjectAssetFiles } from './assets-tabs/files.js';
import { AddAssetButton } from './add-asset-to-project-btn.js';
import { ProjectsAssetsHistory } from './assets-tabs/wo-history.js';
import AssetsDetailsContainer from './assets-tabs/assets-details.js';
import { setProjectAssetsActiveTab } from '../../../../redux/actions/index.js';
import { useProjectAssets } from '../../../../hooks/project-assets.js';

const subTabs = [
  { value: 'details', title: 'Assets details' },
  { value: 'files', title: 'Files' },
  { value: 'history', title: 'WO History' },
];

const tabsContent = {
  details: AssetsDetailsContainer,
  files: ProjectAssetFiles,
  history: ProjectsAssetsHistory,
};

function AssetsContainer({ activeTab, dispatch }) {
  const Container = tabsContent[activeTab];

  return (
    <Widget.Item>
      <Tabs>
        <Tabs.Header>
          {subTabs.map(({ value, title }) => (
            <Tabs.Tab
              bold
              key={value}
              href={`#${value}`}
              active={value === activeTab}
              onClick={() => dispatch(setProjectAssetsActiveTab(value))}
            >
              {title}
            </Tabs.Tab>
          ))}
        </Tabs.Header>
        <Tabs.Content>
          <Container />
        </Tabs.Content>
      </Tabs>
    </Widget.Item>
  );
}

AssetsContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default function AssetsComponent(props) {
  const dispatch = useDispatch();

  const activeTab = useSelector((state) => state.projectAssets.activeTab);
  const projectId = useSelector((state) => state.location.payload.id);

  const { project, loading } = useProjectAssets(projectId);

  if (loading) return <Loader />;

  return project.searchAssets.hits.length === 0 ? (
    <AddAssetButton
      dispatch={dispatch}
      project={project}
      projectId={projectId}
      permissions={UPDATE_PROJECT_PERMISSION}
    />
  ) : (
    <AssetsContainer {...props} activeTab={activeTab} dispatch={dispatch} />
  );
}
