import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import EditProject from '../../components/pm-project/edit.js';
import { EditProjectSuccess, GeneralError } from '../../constants/alerts.js';
import { setEditProjectModal } from '../../redux/actions/index.js';
import useValidation from '../../hooks/useValidation.js';
import { useRefHandlers } from '../../hooks/useRefHandlers.js';
import { useUpdateProject } from '../../hooks/projects.js';
import { usePropertiesQuery } from '../../hooks/properties.js';
import { getValue } from '../../util/general.js';

function EditPmModal(props) {
  const dispatch = useDispatch();

  const { projects, editProjectModal } = props;

  const [selectedProperty, setProperty] = useState(null);
  const [selectedStatus, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [id, setId] = useState('');

  useEffect(() => {
    const { _id, status, property } = editProjectModal.data;

    setId(_id);
    setStatus(status);
    setProperty({
      value: property._id,
      label: property.name,
    });
  }, []);

  const location = useSelector((state) => state.location);

  const user = useSelector((state) => state.user);

  const typeFilter = projects?.typeFilter;

  const clientId = user?.clientId;

  const projectId = location.payload.id;

  const setPropertySearch = useCallback(
    (value) => setSearch(value),
    [setSearch],
  );

  const { errors, setError } = useValidation({
    validationRules: {},
  });

  const { setRef, getRef } = useRefHandlers();

  const { updateProject } = useUpdateProject();

  const { propertyLoading, properties, noPropertyResults } = usePropertiesQuery(
    search,
    typeFilter,
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      const requestData = {
        propertyId: selectedProperty.value,
        status: selectedStatus,
        description: getValue('description', e),
        invoiceDescription: getRef().getEditor().getContents(),
        location: getValue('location', e),
      };

      return updateProject(id, requestData)
        .then(() => {
          setLoading(false);
          setError(null);
          dispatch(setEditProjectModal(null));
          toast.success(EditProjectSuccess);
        })
        .catch((error) => {
          setLoading(false);
          setError({ server: error.message });
          toast.error(GeneralError);
        });
    },
    [
      selectedProperty,
      updateProject,
      getRef,
      dispatch,
      setError,
      setLoading,
      selectedStatus,
      editProjectModal,
    ],
  );

  const closeModal = useCallback(
    () => dispatch(setEditProjectModal(null)),
    [dispatch, setEditProjectModal],
  );

  const selectProperty = useCallback(
    (propertyVal) => {
      setProperty(propertyVal);
    },
    [setProperty],
  );

  const selectStatus = useCallback(
    (statusVal) => {
      const selectedStatusValue = statusVal?.value;
      setStatus(selectedStatusValue);
    },
    [setStatus],
  );

  return (
    <EditProject
      {...props}
      clientId={clientId}
      projectId={projectId}
      setRef={setRef}
      onSubmit={onSubmit}
      closeModal={closeModal}
      selectProperty={selectProperty}
      selectStatus={selectStatus}
      setPropertySearch={setPropertySearch}
      selectedProperty={selectedProperty}
      selectedStatus={selectedStatus}
      properties={properties}
      loading={loading}
      errors={errors}
      propertyLoading={propertyLoading}
      noPropertyResults={noPropertyResults}
    />
  );
}

EditPmModal.propTypes = {
  projects: PropTypes.shape({
    typeFilter: PropTypes.string,
  }),
  editProjectModal: PropTypes.shape({
    data: PropTypes.shape({
      _id: PropTypes.string,
      status: PropTypes.string,
      property: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
};

export default function (props) {
  const projects = useSelector((state) => state.projects);
  const editProjectModal = projects.modals.editProject;

  const isModalOpen = !!editProjectModal;

  return isModalOpen ? (
    <EditPmModal
      {...props}
      isModalOpen
      projects={projects}
      editProjectModal={editProjectModal}
    />
  ) : null;
}
