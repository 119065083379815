import types from '../types.js';

const defaultState = {
  modals: {
    support: false,
  },
};

export default (state = defaultState, action = {}) => {
  if (action.type === types.SET_SUPPORT_MODAL) {
    return {
      ...state,
      modals: {
        ...state.modals,
        support: action.payload,
      },
    };
  }

  return state;
};
