import { ProjectType, WorkOrderStatus } from 'poly-constants';
import * as R from 'ramda';
import { getValue } from '../../util/general.js';

export function getRequestDataForWO({
  clientId,
  selectedPriority,
  selectedService,
  selectedProperty,
  selectedRequester,
  getRef,
  additionalEmails,
  projectManager,
  projectSuppliers,
  projectTechnician,
  event: e,
}) {
  return {
    clientId,
    type: ProjectType.WORK_ORDER,
    status: WorkOrderStatus.ACTIVE,
    priority: selectedPriority,
    serviceTypeId: selectedService,
    propertyId: selectedProperty.value,
    contactId: selectedRequester?.value,
    location: getValue('location', e),
    invoiceDescription: getRef().getEditor().getContents(),
    description: getValue('description', e),
    startDate: new Date(),
    additionalSupplierWOCEmails: R.map(additionalEmails?.value),
    managerId: projectManager?._id,
    suppliers: projectSuppliers.map(({ _id }) => ({
      supplierId: _id,
    })),
    technicians: projectTechnician ? [projectTechnician?._id] : [],
  };
}
