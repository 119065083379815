import { gql } from '@apollo/client';
import { supplierDetailsFields } from './fragments.js';

export const supplierDetailsSubscription = gql`
  subscription supplierDetailsChanged($input: SingleDocSubInput!) {
    supplierChanged(input: $input) {
      id
      type
      document {
        ...supplierDetailsFields
      }
    }
  }
  ${supplierDetailsFields}
`;
