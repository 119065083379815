import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { PropertySelectorBase, ServiceTypeSelectorBase } from 'poly-site-ui';
import {
  L,
  S,
  Pip,
  Icon,
  Grid,
  Input,
  Modal,
  Radio,
  Holder,
  Editor,
  Button,
  Heading,
  IconButton,
  FilterByStatus,
} from 'poly-book';
import { getUserCellPhoneNumber } from 'poly-utils';

import Select from '../select/index.js';
import MailTo from './mail-to.js';
import ServerError from '../server-error/index.js';
import InputNumber from '../input-number/index.js';
import { projectPriorityField } from './fields/priority.js';
import ButtonLoader from '../loader/button.js';
import CreateResponseTeam from '../../containers/project/create-response-team.js';
import { formatSearchPlaceholder } from '../../util/select.js';
import { Rows } from '../../util/forms/index.js';
import { EmailInputWithSearchUser } from './EmailInputWithSearchUser.js';

const MAX_WIDTH = 320;

const Header = Modal.Item.withComponent(Heading);

const Form = Modal.Item.withComponent('form');

const Table = Modal.Item.withComponent(Grid);

const Link = S.withComponent(L);

function ServiceTypeRenderer({ label }) {
  return (
    <S type="title" textColor="#ff8c00">
      {label}
    </S>
  );
}

ServiceTypeRenderer.propTypes = {
  label: PropTypes.string.isRequired,
};

const Content = styled(Modal.Item)`
  display: grid;
  grid-gap: 50px;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(2, 450px);

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const Scroll = styled.section`
  width: 100%;
  height: 100%;
  max-height: 460px;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
  overflow-y: auto;
`;

const Container = styled.section`
  height: 100%;
  display: grid;
  grid-row-gap: 25px;
  grid-template-rows: auto 1fr;
`;

const Fields = styled.section`
  display: grid;
  margin-top: -10px;
  grid-row-gap: 10px;
  grid-auto-rows: min-content;
  grid-template-rows: repeat(2, 1fr);
`;

function RequesterFields({ title, onClick, text }) {
  return (
    <Fields>
      <S textColor="#6f7a9f" size={12}>
        {title}
      </S>
      <Link {...{ onClick }} size={12} uppercase bold>
        {text}
      </Link>
    </Fields>
  );
}

// prepareRequesterToOption :: User -> SelectOption
export const prepareRequesterToOption = R.applySpec({
  _id: R.prop('_id'),
  value: R.prop('_id'),
  label: R.prop('fullName'),
  email: R.prop('email'),
  cellPhone: getUserCellPhoneNumber,
});

RequesterFields.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

const shouldShowModal = (modal, isAdd) => {
  const condition = isAdd ? 'add' : 'edit';
  return !!modal && modal.type === condition;
};

function SimpleTitle({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

SimpleTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddWorkOrder({
  isAdd,
  setRef,
  isProjectAssigned,
  showMailTo,
  loading,
  projectModal,
  closeModal,
  propertyLoading,
  requestersLoading,
  serviceLoading,

  selectedPriority,
  selectedProperty,
  selectedService,
  selectedRequester,

  serviceTypes,
  properties,
  onSubmit,
  setActiveTab,
  activeTab,

  managers,
  technicians,
  allSuppliers,

  setTechnician,
  setManager,
  setSuppliers,

  projectTechnician,
  projectManager,
  projectSuppliers,

  handleTechniciansClick,
  handleManagersClick,
  handleSuppliersClick,
  onChange,
  setFilterByStatus,

  errors,
  setSearch,
  onInputChange,
  isNewRequester,
  toggleIsNewRequester,
  requesters,
  additionalEmails,

  selectPriority,
  selectProperty,
  selectService,
  selectRequester,
  newRequester,
  onNewRequesterChange,
  setPropertySearch,
  setSearchRequesters,
  setServiceSearch,
  noPropertyResults,
  noRequesterResults,
  selectAdditionalEmails,
  setNewRequester,
}) {
  const requestersOptions = R.map(prepareRequesterToOption, requesters);

  const setFoundUser = (userContact) => {
    const userOption = prepareRequesterToOption(userContact);
    selectRequester(userOption);
    toggleIsNewRequester();
    setNewRequester({});
  };

  return (
    <Modal show={shouldShowModal(projectModal, isAdd)}>
      <Header margin={40}>
        <Heading.h3 lighter>Create New Work Order</Heading.h3>
        <IconButton onClick={closeModal}>
          <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
        </IconButton>
      </Header>
      <Form {...{ onSubmit }}>
        <Content margin={40}>
          <section>
            <Modal.Item margin={20}>
              <Heading.h5>WO Information</Heading.h5>
            </Modal.Item>
            <Table
              columns={`120px minmax(200px, ${MAX_WIDTH}px)`}
              margin={30}
              simple
            >
              <Rows
                items={[
                  projectPriorityField({
                    selectedPriority,
                    errors,
                    selectPriority,
                  }),
                  {
                    title: {
                      value: 'Type',
                      props: ['required'],
                    },
                    error: R.prop('service', errors),
                    item: (
                      <ServiceTypeSelectorBase
                        value={selectedService}
                        onChange={selectService}
                        setServiceSearch={setServiceSearch}
                        loading={serviceLoading}
                        serviceTypes={serviceTypes}
                        invalid={!!R.prop('service', errors)}
                      />
                    ),
                  },
                  {
                    title: {
                      value: 'Description',
                      props: ['required'],
                    },
                    error: R.prop('description', errors),
                    item: (
                      <Input
                        name="description"
                        placeholder="Description"
                        defaultValue={R.path(
                          ['data', 'description'],
                          projectModal,
                        )}
                        invalid={!!R.prop('description', errors)}
                        {...{ onChange }}
                      />
                    ),
                  },
                  {
                    title: {
                      value: 'Details',
                      props: ['', 'description'],
                    },
                    item: (
                      <Editor
                        registerRef={setRef}
                        placeholder="Details"
                        defaultValue={R.path(['data', 'details'], projectModal)}
                      />
                    ),
                  },
                  {
                    title: {
                      value: 'Location',
                    },
                    item: (
                      <Input
                        name="location"
                        placeholder="Enter location information"
                      />
                    ),
                  },
                  {
                    title: {
                      value: 'Property',
                      props: ['required'],
                    },
                    error: R.prop('property', errors),
                    item: (
                      <PropertySelectorBase
                        value={selectedProperty}
                        onChange={selectProperty}
                        setPropertySearch={setPropertySearch}
                        isLoading={propertyLoading}
                        noResultsText={noPropertyResults}
                        invalid={!!R.prop('property', errors)}
                        properties={properties}
                      />
                    ),
                  },
                  {
                    title: isNewRequester
                      ? {
                          value: 'New Requester',
                          props: ['required'],
                        }
                      : {
                          value: 'Requester',
                        },
                    error: R.prop('requesterName', errors),
                    item: isNewRequester ? (
                      <Input
                        name="requesterName"
                        placeholder="Enter Requester Name"
                        value={R.prop('name', newRequester)}
                        onChange={onNewRequesterChange('name')}
                        invalid={!!R.prop('requesterName', errors)}
                      />
                    ) : (
                      <Select
                        onChange={selectRequester}
                        onInputChange={setSearchRequesters}
                        onSelectResetsInput={false}
                        isLoading={requestersLoading}
                        value={selectedRequester}
                        placeholder={formatSearchPlaceholder('Requester')}
                        noResultsText={noRequesterResults}
                        options={requestersOptions}
                        optionRenderer={SimpleTitle}
                        valueRenderer={SimpleTitle}
                      />
                    ),
                  },
                  {
                    title: isNewRequester
                      ? {
                          value: 'Email',
                          props: ['required'],
                        }
                      : { key: 'selectedRequesterEmail' },
                    error: R.prop('requesterEmail', errors),
                    item: isNewRequester ? (
                      <EmailInputWithSearchUser
                        name="requesterEmail"
                        placeholder="Enter Unique Requester Email"
                        value={R.prop('email', newRequester)}
                        onChange={onNewRequesterChange('email')}
                        invalid={!!R.prop('requesterEmail', errors)}
                        setFoundUser={setFoundUser}
                      />
                    ) : (
                      <Input
                        name="requesterEmail"
                        placeholder="Enter Unique Requester Email"
                        value={R.prop('email', selectedRequester) || '—'}
                        disabled
                      />
                    ),
                  },
                  {
                    title: isNewRequester
                      ? {
                          value: 'Phone',
                          props: ['required'],
                        }
                      : { key: 'selectedRequesterPhone' },
                    error: R.prop('requesterPhone', errors),
                    item: isNewRequester ? (
                      <InputNumber
                        type="tel"
                        name="requesterPhone"
                        format="(###) ###-####"
                        mask="_"
                        placeholder="Enter Requester Phone"
                        value={R.prop('phone', newRequester)}
                        onChange={onNewRequesterChange('phone')}
                        invalid={!!R.prop('requesterPhone', errors)}
                      />
                    ) : (
                      <InputNumber
                        type="tel"
                        name="requesterPhone"
                        format="(###) ###-####"
                        mask="_"
                        placeholder="Enter Requester Phone"
                        value={R.prop('cellPhone', selectedRequester) || '—'}
                        disabled
                      />
                    ),
                  },
                  {
                    title: isNewRequester
                      ? { key: 'newRequesterInfo ' }
                      : { key: 'selectedRequesterInfo ' },
                    item: isNewRequester ? (
                      <RequesterFields
                        title="New requester will be added when WO is created"
                        onClick={toggleIsNewRequester}
                        text="Cancel"
                      />
                    ) : (
                      <RequesterFields
                        title="Didn't find the requester?"
                        onClick={toggleIsNewRequester}
                        text="+ Add Requester"
                      />
                    ),
                  },
                ]}
              />
            </Table>
          </section>
          <Container>
            <section>
              <Modal.Item margin={20}>
                <Heading.h5>Response Team</Heading.h5>
              </Modal.Item>
              <Modal.Item>
                <Holder margin={10}>
                  <FilterByStatus
                    type="button"
                    color="#93acfe"
                    active={!isProjectAssigned}
                    onClick={(event) => {
                      event.preventDefault();
                      setFilterByStatus('queue');
                      setSearch('');
                      setActiveTab({ target: { value: 'technicians' } });
                    }}
                  >
                    <Pip color="#93acfe" />
                    <S type="title">Add to Queue</S>
                  </FilterByStatus>
                  <FilterByStatus
                    type="button"
                    color="#9bdc53"
                    active={isProjectAssigned}
                    onClick={(event) => {
                      event.preventDefault();
                      setFilterByStatus('assign');
                    }}
                  >
                    <Pip color="#9bdc53" />
                    <S type="title">Assign Work Order</S>
                  </FilterByStatus>
                </Holder>
              </Modal.Item>
              {isProjectAssigned && (
                <>
                  <Modal.Item margin={25}>
                    <Input
                      name="search-by-name"
                      placeholder="Start Searching by Name"
                      onChange={onInputChange}
                    />
                  </Modal.Item>
                  <Modal.Item margin={30}>
                    <Holder margin={30}>
                      <S type="badge" uppercase>
                        Select:
                      </S>
                      <Holder margin={40}>
                        <Radio
                          name="members"
                          value="technicians"
                          id="technicians"
                          content={<S type="title">Technicians</S>}
                          onChange={setActiveTab}
                          defaultChecked
                        />
                        <Radio
                          name="members"
                          value="manager"
                          id="manager"
                          content={<S type="title">Manager</S>}
                          onChange={setActiveTab}
                        />
                        <Radio
                          name="members"
                          value="suppliers"
                          id="supplier"
                          content={<S type="title">Supplier</S>}
                          onChange={setActiveTab}
                        />
                      </Holder>
                    </Holder>
                  </Modal.Item>
                </>
              )}
            </section>
            {isProjectAssigned ? (
              <Wrapper>
                <Scroll>
                  <CreateResponseTeam
                    activeTab={activeTab}
                    managers={managers}
                    allTechnicians={technicians}
                    allSuppliers={allSuppliers}
                    setTechnician={setTechnician}
                    setManager={setManager}
                    setSuppliers={setSuppliers}
                    handleTechniciansClick={handleTechniciansClick}
                    handleManagersClick={handleManagersClick}
                    handleSuppliersClick={handleSuppliersClick}
                    projectTechnician={projectTechnician}
                    projectManager={projectManager}
                    projectSuppliers={projectSuppliers}
                  />
                </Scroll>
                {showMailTo && (
                  <MailTo
                    withTopLine
                    {...{ errors }}
                    value={additionalEmails}
                    onChange={selectAdditionalEmails}
                  />
                )}
              </Wrapper>
            ) : (
              <S type="content">This Work Order will be Added to Queue</S>
            )}
          </Container>
        </Content>
        <ServerError
          error={R.prop('server', errors)}
          style={{ maxWidth: 950 }}
        />
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">Create WO</S>
          </Button>
        </Modal.Buttons>
      </Form>
    </Modal>
  );
}

AddWorkOrder.propTypes = {
  projectModal: PropTypes.shape({
    type: PropTypes.oneOf(['add', 'edit']),
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  isAdd: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  propertyLoading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedRequester: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    email: PropTypes.string,
    cellPhone: PropTypes.string,
  }),
  selectedPriority: PropTypes.string,
  selectedProperty: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  selectedService: PropTypes.string,
  selectRequester: PropTypes.func.isRequired,
  selectPriority: PropTypes.func.isRequired,
  selectProperty: PropTypes.func.isRequired,
  selectService: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  serviceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  managers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
  ),
  technicians: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
  ),
  allSuppliers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
  activeTab: PropTypes.string.isRequired,
  setTechnician: PropTypes.func.isRequired,
  setManager: PropTypes.func.isRequired,
  setSuppliers: PropTypes.func.isRequired,
  projectTechnician: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  projectManager: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  projectSuppliers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  ).isRequired,
  handleTechniciansClick: PropTypes.func.isRequired,
  handleManagersClick: PropTypes.func.isRequired,
  handleSuppliersClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setFilterByStatus: PropTypes.func.isRequired,
  isProjectAssigned: PropTypes.bool.isRequired,
  showMailTo: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    server: PropTypes.string,
    priority: PropTypes.string,
    property: PropTypes.string,
    description: PropTypes.string,
  }),
  setRef: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  toggleIsNewRequester: PropTypes.func.isRequired,
  isNewRequester: PropTypes.bool.isRequired,
  requesters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      cellPhone: PropTypes.string,
    }),
  ),
  requestersLoading: PropTypes.bool.isRequired,
  serviceLoading: PropTypes.bool.isRequired,
  newRequester: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    cellPhone: PropTypes.string,
  }).isRequired,
  onNewRequesterChange: PropTypes.func.isRequired,
  setPropertySearch: PropTypes.func.isRequired,
  setSearchRequesters: PropTypes.func.isRequired,
  setServiceSearch: PropTypes.func.isRequired,
  selectAdditionalEmails: PropTypes.func.isRequired,
  noPropertyResults: PropTypes.string,
  noRequesterResults: PropTypes.string,
  additionalEmails: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  setNewRequester: PropTypes.func.isRequired,
};

AddWorkOrder.defaultProps = {
  noRequesterResults: null,
  noPropertyResults: null,
  selectedRequester: null,
  selectedPriority: null,
  selectedProperty: null,
  selectedService: null,
  projectTechnician: null,
  projectManager: null,
  technicians: null,
  managers: null,
  allSuppliers: null,
  serviceTypes: [],
  properties: [],
  requesters: [],
  errors: {},
};

export default AddWorkOrder;
