import types from '../types.js';

export const setReportProjectFilter = (filter) => ({
  type: types.SET_REPORT_PROJECT_FILTER,
  payload: filter,
});

export const setReportTableSearchString = (query) => ({
  type: types.SET_REPORT_TABLE_SEARCH_STRING,
  payload: query,
});

export const setReportProjectActiveTab = (tab) => ({
  type: types.SET_REPORT_PROJECT_ACTIVE_TAB,
  payload: tab,
});

export const setReportProjectAssetsActiveTab = (tab) => ({
  type: types.SET_REPORT_PROJECT_ASSETS_ACTIVE_TAB,
  payload: tab,
});

export const setReportProjectsPaginationCurrentPage = (current) => ({
  type: types.SET_REPORT_PROJECTS_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setReportProjectsPaginationTotal = (total) => ({
  type: types.SET_REPORT_PROJECTS_PAGINATION_TOTAL,
  payload: total,
});

export const setReportProjectsPaginationPageSize = (size) => ({
  type: types.SET_REPORT_PROJECTS_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setReportProjectsPaginationDropdownLabel = (size) => ({
  type: types.SET_REPORT_PROJECTS_PAGINATION_DROPDOWN_LABEL,
  payload: size,
});

export const setReportModal = (modal) => ({
  type: types.SET_REPORT_MODAL,
  payload: modal,
});
