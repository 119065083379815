import { gql } from '@apollo/client';

export const supplierCompanyFields = gql`
  fragment supplierCompanyFields on Company {
    name
    emails {
      email
      type
    }
    addressTwo
    address {
      formatted_address
      address_parts {
        street_number
        route
        locality
        administrative_area_level_1
        administrative_area_level_2
        country
        postal_code
        postal_code_suffix
        neighborhood
      }
      geo {
        type
        coordinates
      }
    }
    services {
      _id
      name
    }
    phones {
      phone
      type
      isPrimary
      legacyFormat
    }
    fax
  }
`;

export const supplierDetailsFields = gql`
  fragment supplierDetailsFields on Supplier {
    _id
    status
    remarks
    company {
      ...supplierCompanyFields
    }
  }

  ${supplierCompanyFields}
`;
