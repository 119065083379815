import {
  HOUSE,
  STREET,
  CITY,
  AREA,
  CODE,
  SUFFIX,
} from '../../constants/address.js';

/**
 * formattedAddToAddParts :: formatted_address -> address_parts
 *   formatted_address = String
 *   address_parts = Object
 */
export const formattedAddToAddParts = (formattedAddress) => {
  // formatted_address is consists:
  // Street Number Street Name, City, State Country
  const parts = formattedAddress ? formattedAddress.split(',') : '';
  const street = parts[0] || '';
  const withoutFirstSpace = (s) => (s[0] === ' ' ? s.replace(' ', '') : s);
  return {
    [HOUSE]: withoutFirstSpace(street.split(' ')[0] || ''),
    [STREET]: withoutFirstSpace(street.replace(street.split(' ')[0], '') || ''),
    [CITY]: withoutFirstSpace(parts[1] || ''),
    [AREA]: withoutFirstSpace(parts[2] || ''),
    [CODE]: '',
    [SUFFIX]: '',
  };
};
