import React from 'react';
import PropTypes from 'prop-types';
import { FilterByStatus } from 'poly-site-ui';
import { Header, Heading, Holder, S } from 'poly-book';
import { UpRow } from '../../styled.js';
import TableSearchContainer from '../../containers/properties/search.js';
import { PROPERTIES_FILTERS } from '../../constants/properties.js';
import { usePropertiesCountQuery } from '../../hooks/properties.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';
import { exportPropertiesToXLS } from './export-to-xls.js';

function FilterWrapper(props) {
  const { data, count } = usePropertiesCountQuery({
    typeFilter: props.typeFilter,
  });

  return <FilterByStatus {...props} {...data} count={count} />;
}

FilterWrapper.propTypes = {
  typeFilter: PropTypes.string,
};

function PropertiesFilter({ onClick, activeFilter }) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <Heading.h1 lighter>Property Directory</Heading.h1>
        </Header.Block>
        <Header.Block>
          <PrintAndExportButtons
            printTitle="Property Directory"
            exportFunction={exportPropertiesToXLS}
          />
        </Header.Block>
      </Header.Row>
      <UpRow>
        <Holder margin={20} center>
          <S type="badge" uppercase>
            Filter by:
          </S>
          <Holder margin={10}>
            {PROPERTIES_FILTERS.map(({ type, ...props }) => (
              <FilterWrapper
                active={activeFilter === type}
                key={type}
                typeFilter={type}
                {...{ onClick }}
                {...props}
              />
            ))}
          </Holder>
        </Holder>
        <Header.Block>
          <TableSearchContainer />
        </Header.Block>
      </UpRow>
    </Header.Sub>
  );
}

PropertiesFilter.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeFilter: PropTypes.string.isRequired,
};

export default PropertiesFilter;
