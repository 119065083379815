import { gql } from '@apollo/client';

import { checklistFragment } from '../fragments.js';

export const getPmChecklistQuery = gql`
  query getPmChecklistQuery($id: ID!) {
    project(projectId: $id) {
      _id
      maintenancePlan {
        _id
        ...checklistFragment
      }
    }
  }

  ${checklistFragment}
`;
