import * as R from 'ramda';
import {
  performExcelExport,
  createExcelExportCell,
  ExcelExportCellType,
} from 'poly-client-utils';
import { forceTitleCase } from 'poly-utils';
import { formatDateIfExists } from '../../util/general.js';

const COLUMN_WIDTH = [15, 25, 30, 35, 25, 15, 10];

const TITLE_ROW = [
  'WO #',
  'WO Type',
  'Description',
  'Property',
  'Assigned To',
  'Due Date',
  'Status',
];

// getPMDirectoryRowConfig :: Project -> [ExcelExportDataCell]
const getPMDirectoryRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 7),
  ]),
  R.juxt([
    R.propOr('', 'projectId'),
    R.compose(forceTitleCase, R.propOr('', 'type')),
    R.propOr('', 'description'),
    R.pathOr('', ['property', 'name']),
    R.pathOr('', ['technicians', 0, 'fullName']),
    R.compose(formatDateIfExists, R.propOr('', 'endDate')),
    R.compose(forceTitleCase, R.propOr('', 'status')),
  ]),
);

// getPMDirectoryExcelConfig :: [Project] -> Object
export const getPMDirectoryExcelConfig = (projects) => ({
  exportFileName: 'pm_directory_export.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 7)],
      TITLE_ROW,
    ),
    ...R.map(getPMDirectoryRowConfig, projects),
  ],
});

export const exportPMDirectoryToXLS = R.compose(
  performExcelExport,
  getPMDirectoryExcelConfig,
  R.pathOr([], ['data', 'searchProjects', 'hits']),
);
