import { useQuery, useSubscription } from '@apollo/client';
import { debounce, tryCallFunction } from 'poly-utils';
import { extractGQLOptionsFromProps } from 'poly-site-ui';
import { useEffect } from 'react';

function useEntitiesByCustomQuery(
  gqlQuery,
  additionalVars,
  query,
  queryOptions,
  skipQuery,
  alias,
  tableProps,
) {
  const { sort, from, pageSize, searchTerm } = tableProps;

  const {
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    called,
    client,
    fetchMore,
    observable,
    previousData,
    startPolling,
    stopPolling,
    updateQuery,
    variables,
  } = useQuery(gqlQuery, {
    alias,
    variables: {
      ...additionalVars,
      input: { from, size: pageSize, query, sort, searchTerm },
    },
    ...queryOptions,
    fetchPolicy: 'network-only',
    skip: skipQuery,
  });

  const result = {
    called,
    client,
    fetchMore,
    observable,
    previousData,
    startPolling,
    stopPolling,
    updateQuery,
    variables,
    query,
    sort,
    from,
    size: pageSize,
    queryOptions,
    additionalVars,
    searchTerm,
  };

  return {
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
}

export function useSearchChangedSubscription({
  gqlChangedQuery,
  refetch,
  result,
  extractSubscriptionOptions = extractGQLOptionsFromProps,
  debounceTime = 2000,
  skipQuery = false,
}) {
  const debounceUpdateQueryFn = debounce(debounceTime)(() =>
    tryCallFunction(refetch)(),
  );

  const handleRefetchFn = () => {
    if (!skipQuery) {
      debounceUpdateQueryFn();
    }
  };

  useEffect(() => {
    handleRefetchFn();
  }, []);

  useSubscription(gqlChangedQuery, {
    ...extractSubscriptionOptions(result),
    shouldResubscribe: true,
    onData: handleRefetchFn,
    skip: skipQuery,
  });
}

export function useReactiveEntities({
  gqlQuery,
  gqlChangedQuery,
  additionalVars = {},
  query = null,
  sort = {},
  from = 0,
  pageSize = 50,
  searchTerm = '',
  queryOptions = {},
  skipQuery = false,
  alias = 'useReactiveEntities',
}) {
  const tableProps = { sort, from, pageSize, searchTerm };

  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useEntitiesByCustomQuery(
      gqlQuery,
      additionalVars,
      query,
      queryOptions,
      skipQuery,
      alias,
      tableProps,
    );

  useSearchChangedSubscription({
    gqlChangedQuery,
    refetch,
    result,
    skipQuery,
  });

  return {
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
}
