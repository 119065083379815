import * as R from 'ramda';

import { SupplierEmailsTypes, SupplierPhoneTypes } from 'poly-constants';
import { useState, useCallback } from 'react';
import { removePhoneFormatting } from '../formatters/phone.js';

import { filterMultipleEmails } from './index.js';

export const useCommonState = () => {
  const [error, setErrorState] = useState(null);
  const [address, setAddressState] = useState('');
  const [loading, setLoadingState] = useState(false);
  const [typeSearch, setTypeSearchState] = useState('');
  const [nameSearch, setNameSearchState] = useState('');
  const [selectedType, setSelectedTypeState] = useState(null);
  const [activeField, setActiveFieldState] = useState('email');
  const [activeAccField, setActiveAccFieldState] = useState('accEmail');

  const setError = useCallback(
    (errValue) => setErrorState(errValue),
    [setErrorState],
  );

  const setAddress = useCallback(
    (addressValue) => setAddressState(addressValue),
    [setAddressState],
  );

  const setLoading = useCallback(
    (loadingValue) => setLoadingState(loadingValue),
    [setLoadingState],
  );

  const setTypeSearch = useCallback(
    (typeSearchValue) => setTypeSearchState(typeSearchValue),
    [setTypeSearchState],
  );

  const setNameSearch = useCallback(
    (nameSearchValue) => setNameSearchState(nameSearchValue),
    [setNameSearchState],
  );

  const setActiveField = useCallback(
    (activeFieldValue) => setActiveFieldState(activeFieldValue),
    [setActiveFieldState],
  );

  const setSelectedType = useCallback(
    (selectedTypeValue) => setSelectedTypeState(selectedTypeValue),
    [setSelectedTypeState],
  );

  const setActiveAccField = useCallback(
    (activeAccFieldValue) => setActiveAccFieldState(activeAccFieldValue),
    [setActiveAccFieldState],
  );

  return {
    error,
    address,
    loading,
    typeSearch,
    nameSearch,
    selectedType,
    activeField,
    activeAccField,
    setError,
    setAddress,
    setLoading,
    setTypeSearch,
    setNameSearch,
    setActiveField,
    setSelectedType,
    setActiveAccField,
  };
};

/**
 * pickValueByName :: String -> Event -> String
 */
export const pickValueByName = (name, event) =>
  R.path(['target', name, 'value'], event);

export const getCommonErrors = (validate, event, emails, accEmails, address) =>
  validate({
    address,
    name: pickValueByName('name', event),
    phone: pickValueByName('phone', event),
    email: pickValueByName('email', event),
    accEmail: pickValueByName('accEmail', event),
    ...filterMultipleEmails('email', emails, event),
    ...filterMultipleEmails('accEmail', accEmails, event),
  });

/**
 * getCommonMutationObj :: Event -> Ref -> {AddressParts} -> {Coordinates} -> ClientID -> Object
 */
export const getCommonMutationObj = (event, ref, address, supplierSource) => ({
  misc: { source: supplierSource },
  remarks: ref.getEditor().getContents(),
  company: {
    name: pickValueByName('name', event),
    address: R.omit(['addressTwo'], address),
    addressTwo: R.propOr('', 'addressTwo', address),
    phones: [
      {
        type: SupplierPhoneTypes.GENERAL,
        isPrimary: true,
        phone: removePhoneFormatting(pickValueByName('phone', event)),
        legacyFormat: false,
      },
    ],
    emails: [
      {
        email: pickValueByName('email', event),
        type: SupplierEmailsTypes.SERVICE,
      },
      {
        email: pickValueByName('accEmail', event),
        type: SupplierEmailsTypes.ACCOUNT,
      },
    ],
  },
});
