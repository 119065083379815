import { handleActions } from 'redux-actions';
import { InvoicePOStatuses } from 'poly-constants';

import { APPROVED_INVOICES } from '../../constants/routes.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import {
  setApprovedInvoicesPaginationDropdownLabel,
  setApprovedInvoicesPaginationCurrentPage,
  setApprovedInvoicesPaginationPageSize,
  setApprovedInvoicesTableSearchString,
  setApprovedInvoicesPaginationTotal,
  setApprovedSelectedInvoices,
  toggleApprovedInvoice,
} from '../actions/index.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  tableSearchString: '',
  type: InvoicePOStatuses.approved,
  selectedInvoices: [],
};

export default handleActions(
  {
    [APPROVED_INVOICES]: (state) => ({ ...state, tableSearchString: '' }),
    [setApprovedInvoicesTableSearchString]: (state, { payload }) => ({
      ...state,
      tableSearchString: payload,
    }),
    [setApprovedInvoicesPaginationTotal]: (state, { payload }) => ({
      ...state,
      total: payload,
    }),
    [setApprovedInvoicesPaginationCurrentPage]: (state, { payload }) => ({
      ...state,
      currentPage: payload,
    }),
    [setApprovedInvoicesPaginationPageSize]: (state, { payload }) => ({
      ...state,
      pageSize: payload,
    }),
    [setApprovedInvoicesPaginationDropdownLabel]: (state, { payload }) => ({
      ...state,
      paginationDropdownLabel: payload,
    }),
    [setApprovedSelectedInvoices]: (state, { payload }) => ({
      ...state,
      selectedInvoices: payload,
    }),
    [toggleApprovedInvoice]: (state, { payload }) => {
      const index = state.selectedInvoices.indexOf(payload);

      return {
        ...state,
        selectedInvoices:
          index === -1
            ? [...state.selectedInvoices, payload]
            : state.selectedInvoices.filter((id) => id !== payload),
      };
    },
  },
  defaultState,
);
