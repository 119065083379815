import {
  mrProjectFormatter,
  projectFormatter,
  projectPMFormatter,
} from '../../util/project-utils.js';
import { PREVENTIVE_MAINTENANCE } from '../../constants/projects.js';

export const getLinkByProjectType = ({ type, projectId }) => {
  if (!type) {
    return mrProjectFormatter(projectId);
  }
  if (type === PREVENTIVE_MAINTENANCE) {
    return projectPMFormatter({ projectId });
  }
  return projectFormatter(projectId);
};
