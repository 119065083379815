import styled from 'styled-components';
import { MultiselectDropdown } from 'poly-client-utils';

export const MultiSelect = styled(MultiselectDropdown)`
  .multiselect__control {
    border: 1px solid #e3e5ed;
    min-height: 42px;
    box-shadow: unset;
    border-color: ${({ invalid }) => (invalid ? '#ee0d45' : '#e3e5ed')};
  }

  .multiselect__control:hover {
    border: 1px solid #e3e5ed;
    min-height: 42px;
    box-shadow: unset;
  }

  .multiselect__multi-value__remove:hover {
    background-color: #6868687d;
    color: initial;
    cursor: pointer;
  }

  .multiselect__placeholder {
    color: #5e6271;
    font-weight: 500;
    font-size: 14px;
    padding-left: 5px;
  }

  .multiselect__dropdown-indicator {
    padding-left: 0;
    padding-right: 10px;
    color: hsl(216, 3%, 29%);
    svg {
      height: 18px;
    }
  }

  .multiselect__clear-indicator {
    padding: 4px;
    color: hsl(216, 3%, 29%);
    svg {
      width: 13px;
      height: 13px;
    }
  }

  .multiselect__indicator-separator {
    display: none;
  }
`;
