import { gql } from '@apollo/client';

import { updateFragment } from '../fragments.js';

export const getPOUpdates = gql`
  query getPOUpdates($id: ID!, $documentUpdatesInput: DocumentUpdatesInput!) {
    purchaseOrder(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...updateFragment
        }
        total
      }
    }
  }
  ${updateFragment}
`;
