import { createAction } from 'redux-actions';

import {
  MAIN,
  PMS,
  LOGIN,
  FORGOT_PASSWORD,
  PROPERTIES,
  REPORTS,
  REPORT,
  STAFF,
  ASSETS,
  SUPPLIERS,
  SUPPLIER,
  REPORT_PM,
  PURCHASE_ORDERS,
} from '../../constants/routes.js';

// ROUTING
export const routeMain = createAction(MAIN);
export const routePMS = createAction(PMS);
export const routeLogin = createAction(LOGIN);
export const routeForgotPassword = createAction(FORGOT_PASSWORD);
export const routeProperties = createAction(PROPERTIES);
export const routeReports = createAction(REPORTS);
export const routeMRProjects = createAction(REPORT);
export const routeStaff = createAction(STAFF);
export const routeAssets = createAction(ASSETS);
export const routeSuppliers = createAction(SUPPLIERS);
export const routeSupplier = createAction(SUPPLIER);
export const routeReportPM = createAction(REPORT_PM);
export const routePORequests = createAction(PURCHASE_ORDERS);
