import { gql } from '@apollo/client';

import { fullPMProjectFragment } from './get-pm-project.js';
import { jobCostsFragment } from './get-project-job-costs.js';
import { projectAssetsDetailsFragment } from './get-project-assets.js';
import { checklistFragment, fileFragment } from '../fragments.js';

export const prefetchPMProjectFragment = gql`
  fragment prefetchPMProjectFragment on Project {
    ...fullPMProjectFragment
    ...jobCostsFragment
    searchFiles {
      hits {
        ...fileFragment
      }
    }
    searchAssets {
      hits {
        ...projectAssetsDetailsFragment
      }
    }
    maintenancePlan {
      _id
      ...checklistFragment
    }
  }

  ${fullPMProjectFragment}
  ${jobCostsFragment}
  ${fileFragment}
  ${projectAssetsDetailsFragment}
  ${checklistFragment}
`;

export const prefetchPMProject = gql`
  query prefetchPMProject($id: ID) {
    project(projectId: $id) {
      ...prefetchPMProjectFragment
    }
  }

  ${prefetchPMProjectFragment}
`;
