import * as R from 'ramda';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  checkEmailError,
  getUserCellPhone,
  getUserWorkPhone,
  getUserFaxPhone,
} from '../../utils/staff/index.js';
import {
  useCreateContactUser,
  useUpdateContact,
} from '../../hooks/property/people.js';
import {
  GeneralError,
  AddPeopleSuccess,
  EditPeopleSuccess,
} from '../../constants/alerts.js';
import { getAddress, getFirstName, getLastName } from './helpers.js';

export const useCreateContactMutationHandler = ({
  onComplete,
  setLoading,
  isAdd,
  onModalClose,
  setError,
}) => {
  const { createContactUser } = useCreateContactUser();

  const { updateContactUser } = useUpdateContact();

  const mutation = isAdd ? createContactUser : updateContactUser;

  const finalMutation = ({ update }) =>
    mutation(update)
      .then((response) => {
        setLoading(false);

        if (onComplete) {
          const dataPath = isAdd
            ? ['data', 'createContactUser', 'user']
            : ['data', 'updateContactUser'];
          const userInfo = R.path(dataPath, response);
          onComplete({
            _id: update.userId,
            ...R.pick(
              [
                '_id',
                'firstName',
                'lastName',
                'status',
                'title',
                'profile',
                'remarks',
                'address',
                'addressTwo',
                'email',
              ],
              userInfo,
            ),
          });
        }
        onModalClose();
        toast.success(isAdd ? AddPeopleSuccess : EditPeopleSuccess);
      })
      .catch((error) => {
        setLoading(false);

        if (checkEmailError(R.propOr('', 'message', error))) {
          setError({ email: 'This email address is already in use' });
          return;
        }

        toast.error(GeneralError);
        setError({
          server: error.message,
        });
      });

  return {
    finalMutation,
  };
};

export const useEditModalHandler = ({ selectedUser, setAddress }) => {
  useEffect(() => {
    if (
      !selectedUser ||
      (!selectedUser.email && !selectedUser.remarks && !selectedUser.title)
    )
      return;

    setAddress(getAddress(selectedUser));

    if (!selectedUser.address) {
      setAddress('');
    }
  }, [selectedUser]);

  return {
    firstNameValue: getFirstName(selectedUser),
    lastNameValue: getLastName(selectedUser),
    workPhoneValue: getUserWorkPhone(selectedUser),
    mobileValue: getUserCellPhone(selectedUser),
    faxValue: getUserFaxPhone(selectedUser),
    titleValue: selectedUser?.title,
    remarksValue: selectedUser?.remarks,
    userId: selectedUser?._id,
    emailValue: selectedUser?.email,
  };
};
