import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
import { Loader } from 'poly-site-ui';
import MrPmProjectFiles from './tabs/files.js';
import MrPmProjectHistory from './tabs/history.js';
import MrPmProjectUpdates from './tabs/updates.js';
import MrPmProjectNavigation from './navigation.js';
import MrPmProjectChecklist from './tabs/checklist.js';
import { usePrefetchMasterPmProject } from '../../../hooks/prefetch.js';

const tabs = {
  history: MrPmProjectHistory,
  checklist: MrPmProjectChecklist,
  files: MrPmProjectFiles,
  updates: MrPmProjectUpdates,
};

function ProjectMrPmDataContainer({ activeTab, loading }) {
  const Container = tabs[activeTab];

  return (
    <>
      <MrPmProjectNavigation />
      <section>{loading ? <Loader /> : <Container />}</section>
    </>
  );
}

ProjectMrPmDataContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default function (props) {
  const activeTab = useSelector((state) => state.reportPM.activeTab);
  const id = useSelector((state) => state.location.payload.id);

  const { loading } = usePrefetchMasterPmProject(id);

  return (
    <ProjectMrPmDataContainer
      {...props}
      activeTab={activeTab}
      loading={loading}
    />
  );
}
