import { gql } from '@apollo/client';

export const serviceTypesQuery = gql`
  query serviceTypesQuery($input: CollectionSearchParams) {
    searchServiceTypes(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;
