import * as R from 'ramda';

import { isValidToken } from '../../queries/index.js';
import { setDynamicTitle } from '../../utils/authorization/index.js';
import { apolloClient } from '../reducers/apollo.js';
import logOut from './logOut.js';
import { setCurrentUserActionP } from './set-current-user-to-store.js';

const checkCurrentUserP = async (dispatch, getState) => {
  try {
    const tokenResponse = await apolloClient.query({ query: isValidToken });
    const isTokenValid = R.path(['data', 'isTokenValid'], tokenResponse);
    if (!isTokenValid) {
      dispatch(logOut(apolloClient));
    } else {
      dispatch(await setCurrentUserActionP());
      const { user } = getState();
      setDynamicTitle(user.clientId);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while checking user token', e);
  }
};

export default checkCurrentUserP;
