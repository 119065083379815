import React from 'react';
import { Layout } from 'poly-book';

import { LayoutContent } from '../styled.js';
import AuthContainer from '../hooks/auth-container.js';
import Navigation from '../containers/navbar/navigation-container.js';
import ApprovedInvoicesLayout from '../layouts/approved-invoices-layout.js';
import ApprovedInvoicesFilter from '../components/invoices/approved-invoices-filter.js';

function ApprovedInvoices() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <ApprovedInvoicesFilter />
        </Layout.Header>
        <LayoutContent>
          <ApprovedInvoicesLayout />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default ApprovedInvoices;
