import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProjectInfo from '../../components/pm-project/info.js';
import { useProjectPMDetails } from '../../hooks/project-details.js';
import {
  setEditProjectModal,
  setActiveProject,
  setProjectModal,
} from '../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const projectId = useSelector((state) => state.location.payload.id);

  const { project } = useProjectPMDetails(projectId);

  if (project) {
    dispatch(
      setActiveProject({
        _id: project._id,
        projectId,
      }),
    );
  }

  const openEditProjectModal = useCallback(() => {
    const data = R.pick(
      [
        '_id',
        'startDate',
        'serviceType',
        'description',
        'invoiceDescription',
        'location',
        'property',
        'technicians',
        'status',
        'type',
      ],
      project,
    );

    dispatch(setEditProjectModal({ type: 'edit', data }));
  }, [project, dispatch, setEditProjectModal]);

  const openProjectModal = useCallback(
    () =>
      dispatch(
        setProjectModal({
          type: 'add',
          data: { property: project.property },
        }),
      ),
    [project, dispatch, setProjectModal],
  );

  return (
    <ProjectInfo
      {...props}
      project={project}
      projectId={projectId}
      openProjectModal={openProjectModal}
      openEditProjectModal={openEditProjectModal}
    />
  );
}
