import { gql } from '@apollo/client';

import { invoiceFragment } from '../fragments.js';
import { supplierCompanyFields } from '../suppliers/fragments.js';

export const prefetchPurchaseOrder = gql`
  query prefetchPurchaseOrder($id: ID!) {
    purchaseOrder(id: $id) {
      _id
      cardNumber
      amount
      description
      isInScope
      glCode
      status
      supplier {
        _id
        company {
          ...supplierCompanyFields
        }
      }
      project {
        _id
        projectId
      }
      property {
        _id
        name
      }
      invoices {
        ...invoiceFragment
      }
    }
  }

  ${invoiceFragment}
  ${supplierCompanyFields}
`;
