import React from 'react';
import { useSelector } from 'react-redux';
import { Loader, PropertyDetailsCard } from 'poly-site-ui';
import { usePropertyDetails } from '../../hooks/property-details.js';

export default function (props) {
  const propertyId = useSelector((state) => state.location.payload.id);

  const { property, loading } = usePropertyDetails(propertyId);

  if (loading || !property) return <Loader />;

  const specialPropertyFields = property.clientConfig
    ? property.clientConfig.site.specialPropertyFields
    : false;

  return (
    <PropertyDetailsCard
      {...props}
      property={property}
      specialPropertyFields={specialPropertyFields}
    />
  );
}
