import * as R from 'ramda';
import {
  performExcelExport,
  createExcelExportCell,
  ExcelExportCellType,
} from 'poly-client-utils';
import { forceTitleCase } from 'poly-utils';

const COLUMN_WIDTH = [25, 40, 15];

const TITLE_ROW = ['Property', 'Address', 'Status'];

// getPropertiesRowConfig :: Property -> [ExcelExportDataCell]
const getPropertiesRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 3),
  ]),
  R.juxt([
    R.propOr('', 'name'),
    R.pathOr('', ['address', 'formatted_address']),
    R.compose(forceTitleCase, R.propOr('', 'status')),
  ]),
);

// getPropertiesExcelConfig :: [Property] -> Object
export const getPropertiesExcelConfig = (projects) => ({
  exportFileName: 'property_directory_export.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 3)],
      TITLE_ROW,
    ),
    ...R.map(getPropertiesRowConfig, projects),
  ],
});

export const exportPropertiesToXLS = R.compose(
  performExcelExport,
  getPropertiesExcelConfig,
  R.pathOr([], ['data', 'searchProperties', 'hits']),
);
