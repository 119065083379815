import React from 'react';
import { Layout } from 'poly-book';

import { LayoutContent } from '../styled.js';
import ApprovedPOsFilter from '../components/purchase-orders/approved-pos-filter.js';
import Navigation from '../containers/navbar/navigation-container.js';
import ApprovedPOsLayout from '../layouts/approved-pos-layout.js';
import AuthContainer from '../hooks/auth-container.js';

function ApprovedPOs() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <ApprovedPOsFilter />
        </Layout.Header>
        <LayoutContent>
          <ApprovedPOsLayout />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default ApprovedPOs;
