import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FilterComponent from '../../components/properties/properties-filter.js';
import { ALL_RESULTS } from '../../constants/pagination.js';
import {
  setPropertiesPaginationCurrentPage,
  setPropertiesPaginationPageSize,
  setPropertiesPaginationTotal,
  setPropertiesFilter,
} from '../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const activeFilter = useSelector((state) => state.properties.typeFilter);
  const label = useSelector(
    (state) => state.properties.paginationDropdownLabel,
  );

  const onClick = useCallback(
    (filter, total) => {
      if (label === ALL_RESULTS) {
        dispatch(setPropertiesPaginationPageSize(total));
      }
      dispatch(setPropertiesFilter(filter));
      dispatch(setPropertiesPaginationTotal(total));
      dispatch(setPropertiesPaginationCurrentPage(1));
    },
    [dispatch, label],
  );

  return (
    <FilterComponent {...props} onClick={onClick} activeFilter={activeFilter} />
  );
}
