import * as R from 'ramda';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProjectInfo from '../../components/project/project-info.js';
import {
  setActiveProject,
  setEditProjectModal,
} from '../../redux/actions/index.js';
import { useProjectDetails } from '../../hooks/project-details.js';

export default function (props) {
  const dispatch = useDispatch();

  const projectId = useSelector((state) => state.location.payload.id);
  const modals = useSelector((state) => state.projects.modals);

  const { project } = useProjectDetails(projectId);

  const openEditProjectModal = useCallback(() => {
    const data = {
      ...R.pick(
        [
          'priority',
          'description',
          'location',
          'property',
          'technicians',
          'status',
          'parent',
        ],
        project,
      ),
      service: project.serviceType,
      details: project.invoiceDescription,
      requester: project.contact,
    };

    dispatch(setEditProjectModal({ type: 'edit', data }));
  }, [dispatch, setEditProjectModal, project]);

  useEffect(() => {
    if (project) {
      dispatch(
        setActiveProject(
          R.pick(
            [
              '_id',
              'projectId',
              'manager',
              'property',
              'serviceType',
              'startDate',
              'type',
              'parent',
            ],
            project,
          ),
        ),
      );
    }
  }, [project]);

  return (
    <ProjectInfo
      {...props}
      openEditProjectModal={openEditProjectModal}
      project={project}
      modals={modals}
    />
  );
}
