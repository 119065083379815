import { gql } from '@apollo/client';

export const eyesWideOpenQuery = gql`
  query eyesWideOpenStats($year: Int!, $clientId: ID!) {
    client(id: $clientId) {
      _id
      eyesWideOpenStats(year: $year) {
        byMonth {
          monthIndex
          externalCount
          internalCount
          monthName
          total
        }
        externalCount
        internalCount
        total
      }
    }
  }
`;
