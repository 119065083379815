import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Heading, Holder, S, Title } from 'poly-book';
import { A, transformProjectEndDate } from 'poly-site-ui';

import BackArrow from '../back-arrow/index.js';
import InfoLoader from '../loader/info.js';
import { ProjectPriority } from '../projects/priority.js';
import { EditProjectButton, isProjectLoaded } from '../project/project-info.js';

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

function ProjectInfo({
  openEditProjectModal,
  openProjectModal,
  projectId,
  project,
}) {
  return (
    <Header.Sub>
      <HeaderRow>
        <Header.Block>
          <Holder margin={20}>
            <BackArrow />
            {isProjectLoaded(project) ? (
              <InfoLoader />
            ) : (
              <Title>
                <Title.Block>
                  <Title.Item>
                    <Heading.h1 lighter>{projectId}</Heading.h1>
                  </Title.Item>
                  <Title.Item>
                    <Heading.h4 lighter>PM Recurring Work Order</Heading.h4>
                  </Title.Item>
                </Title.Block>
                <Title.Block>
                  <Title.Item>
                    <S type="title" textColor="#4676b4">
                      {transformProjectEndDate(project)}
                    </S>
                  </Title.Item>
                  <Title.Item>
                    <ProjectPriority priority={project.priority} />
                  </Title.Item>
                </Title.Block>
              </Title>
            )}
          </Holder>
        </Header.Block>
        <Header.Block>
          <Holder margin={30} center>
            <A onClick={openProjectModal}>Add Corrective WO</A>
            <EditProjectButton {...{ project, openEditProjectModal }} />
          </Holder>
        </Header.Block>
      </HeaderRow>
    </Header.Sub>
  );
}

ProjectInfo.propTypes = {
  openEditProjectModal: PropTypes.func.isRequired,
  openProjectModal: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  project: PropTypes.shape({
    projectId: PropTypes.string,
    priority: PropTypes.string,
  }),
};

ProjectInfo.defaultProps = {
  project: {},
};

export default ProjectInfo;
