import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { initialPagination } from 'poly-client-utils';
import { Loader, projectStatusUITransform } from 'poly-site-ui';
import Projects from '../../../components/mr-project/tabs/wos.js';
import { projectFormatter } from '../../../util/project-utils.js';
import {
  setMRProjectWOSPaginationCurrentPage,
  setMRProjectWOSPaginationDropdownLabel,
  setMRProjectWOSPaginationPageSize,
  setMRProjectWOSPaginationTotal,
} from '../../../redux/actions/index.js';
import {
  entityFormatter,
  fieldFormatter,
  formatDateWithDashes,
  noDataToDisplayWidget,
} from '../../../util/general.js';
import {
  getMRChildrenQuery,
  searchProjectChanged,
} from '../../../queries/index.js';
import { useReactiveEntities } from '../../../hooks/useReactiveEntities.js';
import usePagination from '../../../hooks/usePagination.js';

const dateFormatter = (value) => fieldFormatter(formatDateWithDashes(value));

const columns = [
  ['WO #', R.prop('projectId'), projectFormatter],
  ['Description', R.prop('description'), fieldFormatter],
  ['End Date', R.prop('endDate'), dateFormatter],
  ['Status', R.identity, projectStatusUITransform],
];

export default function (props) {
  const mrProjectWOS = useSelector((state) => state.MRProjectWOS);

  const projectId = useSelector((state) => state.location.payload.id);

  const { currentPage, pageSize, paginationDropdownLabel } = mrProjectWOS;

  const from = (currentPage - 1) * pageSize;

  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: getMRChildrenQuery,
      gqlChangedQuery: searchProjectChanged,
      additionalVars: { id: projectId },
      from,
      pageSize,
    });

  const projects = data?.recurringProject.searchChildProjects.hits || [];
  const total = data?.recurringProject.searchChildProjects.total || 0;

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setMRProjectWOSPaginationTotal,
    setPageSize: setMRProjectWOSPaginationPageSize,
    setCurrent: setMRProjectWOSPaginationCurrentPage,
    setPaginationDropdownLabel: setMRProjectWOSPaginationDropdownLabel,
    total,
    currentPage,
  });

  if (loading) return <Loader />;
  if (!projects.length) return noDataToDisplayWidget('Projects')();

  const projectsValue = entityFormatter(projects, columns);

  return (
    <Projects
      {...props}
      {...data}
      {...result}
      currentPage={currentPage}
      subscribeToMore={subscribeToMore}
      refetch={refetch}
      networkStatus={networkStatus}
      projects={projectsValue}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      total={total}
      paginationDropdownLabel={paginationDropdownLabel}
      pagination={initialPagination}
    />
  );
}
