import { useQuery } from '@apollo/client';
import { getPropertySuppliersQuery } from '../../queries/index.js';

export const usePropertySuppliers = (propertyId) => {
  const { data, loading } = useQuery(getPropertySuppliersQuery, {
    alias: 'usePropertySuppliers',
    variables: { propertyId, input: { relationType: 'DIRECT' } },
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    suppliers: data?.property.suppliers || [],
  };
};
