import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FilterComponent from '../../../components/projects/projects-filter.js';
import { ALL_RESULTS } from '../../../constants/pagination.js';
import {
  setProjectsFilter,
  setProjectsPaginationTotal,
  setProjectsPaginationPageSize,
  setProjectsPaginationCurrentPage,
} from '../../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const activeFilter = useSelector((state) => state.projects.typeFilter);
  const label = useSelector((state) => state.projects.paginationDropdownLabel);
  const locationType = useSelector((state) => state.location.type);

  const onClick = useCallback(
    (filter, total) => {
      if (label === ALL_RESULTS) {
        dispatch(setProjectsPaginationPageSize(total));
      }

      dispatch(setProjectsFilter(filter));
      dispatch(setProjectsPaginationTotal(total));
      dispatch(setProjectsPaginationCurrentPage(1));
    },
    [dispatch, label],
  );

  return (
    <FilterComponent
      {...props}
      activeFilter={activeFilter}
      locationType={locationType}
      onClick={onClick}
    />
  );
}
