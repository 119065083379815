import React from 'react';
import { Layout } from 'poly-book';

import AuthContainer from '../hooks/auth-container.js';
import AssetsFilter from '../containers/assets-filter/index.js';
import AssetsPageLayout from '../layouts/assets-page-layout.js';
import Navigation from '../containers/navbar/navigation-container.js';
import { LayoutContent } from '../styled.js';

function Assets() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <AssetsFilter />
        </Layout.Header>
        <LayoutContent>
          <AssetsPageLayout />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default Assets;
