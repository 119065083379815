import React from 'react';
import { useSelector } from 'react-redux';

import CompletedPMProjectsList from './list.js';
import CompletedPMProjectsStats from './stats.js';

function CompletedPMProjectsData(props) {
  const activeTab = useSelector((state) => state.completedPMProjects.activeTab);

  const tabs = {
    projects: CompletedPMProjectsList,
    stats: CompletedPMProjectsStats,
  };

  const Container = tabs[activeTab];

  return <Container {...props} />;
}

export default CompletedPMProjectsData;
