import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { debounce } from 'poly-utils';
import {
  AddAssetToProjectSuccess,
  GeneralError,
} from '../../../../constants/alerts.js';
import AddAssetToProjectComp from '../../../../components/project/assets/add-asset-to-project.js';
import { setProjectAssetsModal } from '../../../../redux/actions/index.js';
import { useAssetsSearch } from '../../../../hooks/assets.js';
import {
  useUpdateProject,
  useUpdateRecurringProject,
} from '../../../../hooks/projects.js';

export default function (props) {
  const dispatch = useDispatch();

  const [mutationLoading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const addAsset = useSelector((state) => state.projectAssets.modals.addAsset);

  const { isRecurring, childType, documentId, projectId, propertyId } =
    addAsset;

  const { loading, assets } = useAssetsSearch(searchTerm, propertyId);
  const { updateProject } = useUpdateProject();
  const { updateMRProject } = useUpdateRecurringProject();

  const setAssetsSearchValue = debounce(400)(setSearchTerm);

  const setAssetsSearch = useCallback(
    (e) => setAssetsSearchValue(e.target.value),
    [setAssetsSearchValue],
  );

  const closeModal = useCallback(
    () => dispatch(setProjectAssetsModal({ addAsset: null })),
    [dispatch, setProjectAssetsModal],
  );

  const attachAssetToProject = useCallback(
    async (assetId) => {
      setLoading(true);
      const mutation = isRecurring ? updateMRProject : updateProject;

      const update = isRecurring
        ? { payload: { [childType]: { assetIds: [assetId] } } }
        : { assetIds: [assetId] };
      return mutation(documentId, update)
        .then(() => {
          setLoading(false);
          toast.success(AddAssetToProjectSuccess);
          closeModal();
        })
        .catch(() => {
          setLoading(false);
          toast.error(GeneralError);
        });
    },
    [
      closeModal,
      documentId,
      setLoading,
      updateProject,
      updateMRProject,
      isRecurring,
      childType,
    ],
  );

  return (
    <AddAssetToProjectComp
      {...props}
      assets={assets}
      loading={loading}
      projectId={projectId}
      closeModal={closeModal}
      setAssetsSearch={setAssetsSearch}
      attachAssetToProject={attachAssetToProject}
      mutationLoading={mutationLoading}
      searchTerm={searchTerm}
    />
  );
}
