import { TableSearchComponent } from 'poly-site-ui';
import React from 'react';
import {
  setPmProjectsTableSearchString,
  setPmProjectsPaginationCurrentPage,
} from '../../../redux/actions/index.js';

export default function (props) {
  return (
    <TableSearchComponent
      {...props}
      setTableSearchString={setPmProjectsTableSearchString}
      setPaginationCurrentPage={setPmProjectsPaginationCurrentPage}
    />
  );
}
