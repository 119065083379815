import 'isomorphic-unfetch';
import { handleApplicationMaintenance } from 'poly-client-utils';
import { createHandleApolloErrorAtSiteUI } from 'poly-site-ui';
import { createApolloClient } from 'poly-apollo-client';

import logOut from '../thunks/logOut.js';

export const apolloClient = createApolloClient(
  logOut,
  createHandleApolloErrorAtSiteUI(logOut),
  handleApplicationMaintenance,
);
