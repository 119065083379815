import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { useEntitySubscriptionQuery } from 'poly-client-utils/src/entitySubscription.js';
import { assetsQuery, getAssetProjectsQuery } from '../queries/index.js';
import { assetsSearchQuery } from '../queries/assets/search-assets.js';
import { STANDARD_SIZE, SORTING, MAX_SIZE } from '../constants/index.js';
import { assetDetailsQuery } from '../queries/assets/get-asset-details.js';
import { assetDetailsSubscription } from '../queries/assets/subscriptions.js';
import { ALL_PROPERTIES_VALUE } from '../containers/assets-filter/select.js';

export const useAssetsSearch = (searchTerm, propertyId) => {
  const { data, loading } = useQuery(assetsSearchQuery, {
    alias: 'useAssetsSearch',
    variables: {
      input: {
        searchTerm,
        size: STANDARD_SIZE,
        ...(propertyId ? { query: { term: { propertyId } } } : {}),
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  return {
    loading,
    assets: data?.searchAssets.hits || [],
  };
};

export const useAssetDetailsSub = (props) => {
  const { subscribeToMore, restProps } = useEntitySubscriptionQuery({
    gqlQuery: assetDetailsQuery,
    queryEndpointName: 'asset',
    gqlSubscription: assetDetailsSubscription,
    subscriptionEndpointName: 'assetChanged',
    queryParamsFromProps: R.pick(['assetId']),
    subscriptionParamsFromProps: R.compose(R.objOf('id'), R.prop('assetId')),
    propsOfComponent: props,
    alias: 'useAssetDetailsSub',
  });

  return {
    subscribeToMore,
    restProps,
  };
};

export const useAssetProjects = (assetId) => {
  const { data, loading } = useQuery(getAssetProjectsQuery, {
    alias: 'useAssetProjects',
    variables: {
      input: {
        from: 0,
        size: MAX_SIZE,
        query: { term: { assetIds: assetId } },
        ...SORTING,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    projects: data?.searchProjects.hits,
  };
};

export const useAssetsQuery = ({ propertyId, from, size, searchTerm }) => {
  const { data, loading, fetchMore } = useQuery(assetsQuery, {
    alias: 'useAssetsQuery',
    variables: {
      input: {
        ...(propertyId === ALL_PROPERTIES_VALUE
          ? {}
          : {
              query: {
                match: { propertyId },
              },
            }),
        from,
        size,
        searchTerm,
        ...SORTING,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const assets = data?.searchAssets?.hits;
  const total = data?.searchAssets?.total;

  return {
    fetchMore,
    loading,
    assets,
    total,
  };
};
