import { handleActions } from 'redux-actions';

import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import {
  setMRProjectWOSPaginationDropdownLabel,
  setMRProjectWOSPaginationCurrentPage,
  setMRProjectWOSPaginationPageSize,
  setMRProjectWOSPaginationTotal,
} from '../actions/index.js';

export default handleActions(
  {
    [setMRProjectWOSPaginationTotal]: (state, { payload }) => ({
      ...state,
      total: payload,
    }),
    [setMRProjectWOSPaginationCurrentPage]: (state, { payload }) => ({
      ...state,
      currentPage: payload,
    }),
    [setMRProjectWOSPaginationPageSize]: (state, { payload }) => ({
      ...state,
      pageSize: payload,
    }),
    [setMRProjectWOSPaginationDropdownLabel]: (state, { payload }) => ({
      ...state,
      paginationDropdownLabel: payload,
    }),
  },
  INITIAL_PAGINATION_STATE,
);
