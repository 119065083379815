import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removePropDeep } from 'poly-utils';
import SupplierInfo from '../../components/supplier/info.js';
import { companyPhoneFormatter } from '../../utils/suppliers/index.js';
import { setEditSupplierModal } from '../../redux/actions/index.js';
import { useSupplierDetails } from '../../hooks/suppliers.js';

// getAddress :: Supplier -> Object
const getAddress = R.compose(
  removePropDeep('__typename'),
  R.converge(R.mergeRight, [
    R.pathOr({}, ['company', 'address']),
    R.pipe(R.prop('company'), R.pick(['addressTwo'])),
  ]),
);

export default function (props) {
  const dispatch = useDispatch();

  const supplierId = useSelector((state) => state.location.payload.id);

  const { supplier, loading } = useSupplierDetails(supplierId);

  const openEditSupplierModal = useCallback(() => {
    const data = {
      status: supplier.status,
      name: supplier.company.name,
      address: getAddress(supplier),
      phone: companyPhoneFormatter(supplier.company.phones || []),
      emails: supplier.company.emails,
      remarks: supplier.remarks || '',
      fax: supplier.company.fax || '',
      service: supplier.company.services[0] || null,
    };

    dispatch(setEditSupplierModal({ data }));
  }, [dispatch, supplier, setEditSupplierModal]);

  return (
    <SupplierInfo
      {...props}
      supplier={supplier}
      loading={loading}
      openEditSupplierModal={openEditSupplierModal}
    />
  );
}
