import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PeopleModal from '../../../../components/contact/add-edit-modal.js';
import {
  setSupplierPeopleModal,
  setSupplierSidebar,
} from '../../../../redux/actions/index.js';
import { usePeopleEnhancer } from '../../../contact/modal-enhancer.js';

export default function (props) {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.suppliers.modals.people);
  const linkId = useSelector((state) => state.location.payload.id);

  const isAdd = modal?.type === 'add';

  const selectedUser = useSelector((state) => state.suppliers.modals.sidebar);
  const defaultAddress = R.pathOr('', ['data', 'defaultAddress'], modal);

  const onModalClose = useCallback(
    () => dispatch(setSupplierPeopleModal(null)),
    [dispatch, setSupplierPeopleModal],
  );

  const onComplete = useCallback(
    (user) => dispatch(setSupplierSidebar(user)),
    [dispatch, setSupplierSidebar],
  );

  const additionalProps = usePeopleEnhancer({
    modal,
    selectedUser,
    onModalClose,
    onComplete,
    defaultAddress,
    isAdd,
    linkId,
    linkProp: 'supplierId',
  });

  return modal && <PeopleModal {...props} {...additionalProps} />;
}
