import styled from 'styled-components';
import { Header, Layout } from 'poly-book';

export { ValidationMessage } from 'poly-site-ui';

export const LayoutContent = styled(Layout.Content)`
  grid-template-rows: min-content;
`;

export const UpRow = styled(Header.Row)`
  margin-top: -10px;
`;
