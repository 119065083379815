import * as R from 'ramda';

// convertGlCodeValueToLabel :: [GlCodeOption] -> String -> String
//  GlCodeOption = {label: String, value: String}
export const convertGlCodeValueToLabel = R.curry((glCodes, value) =>
  R.compose(R.prop('label'), R.find(R.propEq('value', value)))(glCodes),
);

// getGlCodeOptionsByClientGLCodes :: [GlCodeOption] -> [GlCodeOption]
export const getGlCodeOptionsByClientGLCodes = R.map(
  R.applySpec({
    label: R.converge(R.unapply(R.join(' ')), [
      R.prop('code'),
      R.compose(R.concat('('), R.concat(R.__, ')'), R.prop('name')),
    ]),
    value: R.prop('code'),
  }),
);

// getGlCodeOptionsByPurchaseOrder :: PurchaseOrder -> [GlCodeOption]
export const getGlCodeOptionsByPurchaseOrder = R.compose(
  getGlCodeOptionsByClientGLCodes,
  R.pathOr({}, ['client', 'glCodes']),
);
