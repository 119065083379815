import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NOTHING_UI_STRING } from 'poly-constants';
import { Loader } from 'poly-site-ui';
import PurchaseOrderDetails from '../../components/purchase-order/details.js';
import {
  isInScopeFormatter,
  propertySelector,
} from '../../utils/purchase-orders/index.js';
import { poStatusUIData } from '../../utils/purchase-orders/pos-utils.js';
import { calculateTotal } from '../../utils/invoices/index.js';
import { formatCurrency } from '../../util/general.js';
import { setPOsModal } from '../../redux/actions/index.js';
import { usePurchaseOrderSub } from '../../hooks/purchase-orders.js';

export default function (props) {
  const dispatch = useDispatch();

  const id = useSelector((state) => state.location.payload.id);

  const propsOfComponent = { ...props, entityId: id };

  const { restProps } = usePurchaseOrderSub(propsOfComponent);

  if (restProps.loading) return <Loader />;

  const purchaseOrder = restProps?.purchaseOrder;

  const allSum = purchaseOrder?.amount;
  const invoicesPart = calculateTotal(purchaseOrder?.invoices);

  const onOpenPOSupplierConfirmModal = () =>
    dispatch(
      setPOsModal({
        sendPOSupplierConfirm: {
          purchaseOrder: R.pick(
            ['cardNumber', 'amount', '_id', 'supplier'],
            purchaseOrder,
          ),
        },
      }),
    );

  const project = purchaseOrder?.project;
  const status = poStatusUIData(purchaseOrder?.status);

  const supplier = purchaseOrder?.supplier.company.name;
  const inScope = isInScopeFormatter(purchaseOrder?.isInScope);

  const description = purchaseOrder?.description;
  const property = propertySelector(purchaseOrder);

  const amount = formatCurrency(allSum);
  const spent = formatCurrency(invoicesPart);
  const balance = formatCurrency(allSum - invoicesPart);

  const glCode = purchaseOrder?.glCode || NOTHING_UI_STRING;

  const files = purchaseOrder.attachments.map((file) => ({
    url: file.url,
    fileName: file.fileName,
  }));

  return (
    <PurchaseOrderDetails
      {...props}
      {...restProps}
      onOpenPOSupplierConfirmModal={onOpenPOSupplierConfirmModal}
      project={project}
      status={status}
      supplier={supplier}
      inScope={inScope}
      description={description}
      property={property}
      amount={amount}
      spent={spent}
      balance={balance}
      files={files}
      glCode={glCode}
    />
  );
}
