import types from '../types.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import { COMPLETED_PMS, PMS } from '../../constants/routes.js';
import { COMPLETED } from '../../constants/projects.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  typeFilter: COMPLETED,
  locationType: PMS,
  activeTab: 'projects',
  tableSearchString: '',
  dateFilter: {
    startDate: null,
    endDate: null,
  },
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_COMPLETED_PMS_DATE: {
      return {
        ...state,
        dateFilter: action.payload,
      };
    }
    case types.SET_COMPLETED_PMS_SERVICE_TYPE: {
      return {
        ...state,
        serviceTypeId: action.payload,
      };
    }
    case types.SET_COMPLETED_PMS_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case COMPLETED_PMS: {
      return {
        ...state,
        tableSearchString: '',
        dateFilter: {
          startDate: null,
          endDate: null,
        },
      };
    }
    case types.SET_COMPLETED_PMS_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_COMPLETED_PMS_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_COMPLETED_PMS_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_COMPLETED_PMS_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_COMPLETED_PMS_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
