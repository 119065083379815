import * as R from 'ramda';
import React, { useState, useCallback, useEffect } from 'react';
import { v4 as uuidV4 } from 'uuid';
import {
  arrayOf,
  object,
  objectOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { debounce } from 'poly-utils/src/general.js';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { SupplierEmailsTypes } from 'poly-constants';
import {
  GeneralError,
  SupplierNameError,
  EditSupplierSuccess,
} from '../../constants/alerts.js';
import AddEditSupplier from '../../components/supplier/add-edit-modal.js';
import {
  checkSupplierNameError,
  filterMultipleEmails,
} from '../../utils/suppliers/index.js';
import { setEditSupplierModal } from '../../redux/actions/index.js';
import { useRefHandlers } from '../../hooks/useRefHandlers.js';
import {
  getCommonMutationObj,
  getCommonErrors,
  pickValueByName,
  useCommonState,
} from '../../utils/suppliers/common.js';
import {
  useSupplierNames,
  useSupplierServiceTypes,
  useUpdateSupplier,
} from '../../hooks/suppliers.js';
import useValidation from '../../hooks/useValidation.js';

function EditSupplierModal(props) {
  const dispatch = useDispatch();

  const { editSupplierModal } = props;

  const [emails, setEmails] = useState([]);
  const [accEmails, setAccEmails] = useState([]);
  const [selectedStatus, setSelectedStatusState] = useState('');

  const clientId = useSelector((state) => state.user.clientId);

  const supplierSource = useSelector(
    (state) => state.user.clientConfig.supplierSource,
  );

  const supplierId = useSelector((state) => state.location.payload.id);

  const selectStatus = useCallback(
    (statusValue) => setSelectedStatusState(statusValue),
    [setSelectedStatusState],
  );

  const {
    address,
    loading,
    typeSearch,
    nameSearch,
    selectedType,
    activeField,
    activeAccField,
    setAddress,
    setLoading,
    setTypeSearch,
    setNameSearch,
    setActiveField,
    setSelectedType,
    setActiveAccField,
  } = useCommonState();

  useEffect(() => {
    selectStatus({
      value: editSupplierModal.data.status || null,
      label: editSupplierModal.data.status || null,
    });

    const service = editSupplierModal.data?.service;

    if (service) {
      setSelectedType({
        value: service._id,
        label: service.name,
      });
    }

    setAddress(editSupplierModal.data.address || '');

    const emailsValue = editSupplierModal.data?.emails;
    const defaultEmails = [{ id: uuidV4(), email: '' }];

    const modifiedEmails = emailsValue
      .filter((emailItem) => emailItem.type === SupplierEmailsTypes.SERVICE)
      .map(({ email }) => ({ id: uuidV4(), email }));

    setEmails(modifiedEmails.length ? modifiedEmails : defaultEmails);

    const modifiedAccEmails = emailsValue
      .filter((emailItem) => emailItem.type === SupplierEmailsTypes.ACCOUNT)
      .map(({ email }) => ({ id: uuidV4(), email }));

    setAccEmails(modifiedAccEmails.length ? modifiedAccEmails : defaultEmails);
  }, []);

  const { names } = useSupplierNames(nameSearch);

  const { typesLoading, serviceTypes } = useSupplierServiceTypes({
    typeSearch,
    supplierSource,
  });

  const { updateSupplier } = useUpdateSupplier();

  const { errors, setError, onChange, validateOnBlur, resetError, validate } =
    useValidation({
      validationRules: () => ({
        name: [{ rule: 'required' }, { rule: 'onlyUSKeyboardCharacters' }],
        address: [{ rule: 'zipLength' }, { rule: 'zip' }, { rule: 'address' }],
        phone: [{ rule: 'phone' }, { rule: 'required' }],
        email: [{ rule: 'email' }, { rule: 'required' }],
        accEmail: [{ rule: 'email' }, { rule: 'required' }],
        ...filterMultipleEmails('email', emails),
        ...filterMultipleEmails('accEmail', accEmails),
      }),
    });

  const { setRef, getRef } = useRefHandlers();

  const onAddField = useCallback(() => {
    setError(null);
    setEmails(emails.concat([{ id: uuidV4(), email: '' }]));
  }, [setError, setEmails]);

  const onAddAccField = useCallback(() => {
    setError(null);
    setAccEmails(accEmails.concat([{ id: uuidV4(), email: '' }]));
  }, [setError, setAccEmails]);

  const onRemoveEmail = useCallback(
    (id) => {
      setError(null);
      setEmails(emails.filter((item) => item.id !== id));
    },
    [setError, setEmails],
  );

  const onRemoveAccEmail = useCallback(
    (id) => {
      setError(null);
      setAccEmails(accEmails.filter((item) => item.id !== id));
    },
    [setError, setAccEmails],
  );

  const debouncedSearch = useCallback(debounce(300)(setNameSearch), []);

  const onSetName = ({ target: { value } }) =>
    debouncedSearch(R.toLower(value.trim()));

  const closeModal = useCallback(
    () => dispatch(setEditSupplierModal(null)),
    [dispatch, setEditSupplierModal],
  );

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setLoading(true);

      const errorsValue = getCommonErrors(
        validate,
        event,
        emails,
        accEmails,
        address,
      );

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const update = {
        status: selectedStatus.value.toUpperCase(),
        ...getCommonMutationObj(event, getRef(), address, supplierSource),
      };

      const fax = pickValueByName('fax', event);
      const type = selectedType?.value;

      if (type) {
        update.company.servicesIds = [type];
      }

      if (fax) {
        update.company.fax = fax;
      }

      if (emails.length > 1) {
        R.drop(1, emails).forEach((item, index) => {
          const name = `email${index + 1}`;
          if (pickValueByName(name, event).length > 0) {
            update.company.emails.push({
              email: pickValueByName(name, event),
              type: SupplierEmailsTypes.SERVICE,
            });
          }
        });
      }

      if (accEmails.length > 1) {
        R.drop(1, accEmails).forEach((item, index) => {
          const name = `accEmail${index + 1}`;
          if (pickValueByName(name, event).length > 0) {
            update.company.emails.push({
              email: pickValueByName(name, event),
              type: SupplierEmailsTypes.ACCOUNT,
            });
          }
        });
      }

      return updateSupplier(
        supplierId,
        R.dissocPath(['misc', 'source'])(update),
      )
        .then(() => {
          setLoading(false);
          setError(null);
          dispatch(setEditSupplierModal(null));
          toast.success(EditSupplierSuccess);
        })
        .catch((err) => {
          setLoading(false);
          const name = update.company?.name;
          if (checkSupplierNameError(name, err.message)) {
            setError({ name: SupplierNameError(name) });
            return;
          }
          setError({ server: err.message });
          toast.error(GeneralError);
        });
    },
    [
      dispatch,
      updateSupplier,
      supplierId,
      setLoading,
      setError,
      validate,
      address,
      selectedStatus,
      emails,
      accEmails,
      selectedType,
      getRef,
      supplierSource,
    ],
  );

  const { name, phone, fax, remarks } = editSupplierModal.data;

  return (
    <AddEditSupplier
      {...props}
      isEdit
      selectedStatus={selectedStatus}
      selectStatus={selectStatus}
      errors={errors}
      loading={loading}
      name={name}
      phone={phone}
      address={address}
      emails={emails}
      accEmails={accEmails}
      activeField={activeField}
      activeAccField={activeAccField}
      fax={fax}
      remarks={remarks}
      setRef={setRef}
      closeModal={closeModal}
      onChange={onChange}
      validateOnBlur={validateOnBlur}
      resetError={resetError}
      onSubmit={onSubmit}
      onAddField={onAddField}
      onAddAccField={onAddAccField}
      onRemoveEmail={onRemoveEmail}
      onRemoveAccEmail={onRemoveAccEmail}
      setAddress={setAddress}
      setActiveField={setActiveField}
      setActiveAccField={setActiveAccField}
      selectedType={selectedType}
      setTypeSearch={setTypeSearch}
      setSelectedType={setSelectedType}
      serviceTypes={serviceTypes}
      typesLoading={typesLoading}
      names={names}
      onSetName={onSetName}
      clientId={clientId}
    />
  );
}

EditSupplierModal.propTypes = {
  editSupplierModal: shape({
    data: shape({
      status: string,
      service: shape({
        name: string,
        _id: string,
      }),
      remarks: oneOfType([
        shape({
          ops: arrayOf(objectOf(string)),
        }),
        string,
      ]),
      phone: string,
      name: string,
      fax: string,
      emails: arrayOf(objectOf(string)),
      address: oneOfType([object, string]),
    }),
  }),
};

export default function (props) {
  const editSupplierModal = useSelector(
    (state) => state.suppliers.editSupplierModal,
  );

  return editSupplierModal ? (
    <EditSupplierModal {...props} editSupplierModal={editSupplierModal} />
  ) : null;
}
