import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'poly-book';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { QUEUE } from '../../constants/projects.js';

const StyledContent = styled(Layout.Content)`
  grid-template-rows: min-content;
  ${({ addPadding }) => addPadding && 'padding-bottom: 100px;'};
`;

function Content({ addPadding, children }) {
  return <StyledContent {...{ addPadding }}>{children}</StyledContent>;
}

Content.propTypes = {
  addPadding: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Content.defaultProps = {
  addPadding: false,
};

function ContentContainer(props) {
  const pmProjects = useSelector((state) => state.PMProjects);

  const { selectedProjects, typeFilter } = pmProjects;
  const isFooterShown = typeFilter === QUEUE && selectedProjects.length > 0;

  return <Content {...props} addPadding={isFooterShown} />;
}

export default ContentContainer;
