import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';
import styled from 'styled-components';
import React from 'react';
import { PROJECTS_STATS } from '../../constants/routes.js';
import {
  convertToPercents,
  SelectDateRangeMessage,
} from '../../utils/projects/index.js';
import { eyesWideOpenTypes } from '../../constants/stats.js';

const LinkS = styled(Link)`
  font-weight: bold;
`;

function WOAmount({ amount, year, monthIndex = 'all', type }) {
  const additionalLinkProps = {
    to: { type: PROJECTS_STATS, payload: { year, type, monthIndex } },
    children: amount,
  };

  return <LinkS {...additionalLinkProps} />;
}

WOAmount.propTypes = {
  amount: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  monthIndex: PropTypes.number,
  type: PropTypes.string.isRequired,
};

export function SelectYearMessage() {
  return <SelectDateRangeMessage message="Please select year" />;
}

const Percents = styled.span`
  padding-left: 10px;
`;

function AmountWithPercents({ amount, total, year, monthIndex, type }) {
  return (
    <div>
      <WOAmount {...{ amount, year, monthIndex, type }} />
      <Percents>({convertToPercents(amount, total)})</Percents>
    </div>
  );
}

AmountWithPercents.propTypes = {
  amount: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  monthIndex: PropTypes.number,
  type: PropTypes.string.isRequired,
};

export function TotalWOAmount(props) {
  return (
    <WOAmount {...props} type={eyesWideOpenTypes.TOTAL} amount={props.total} />
  );
}

TotalWOAmount.propTypes = {
  total: PropTypes.number,
};

export function ClientAmountWithPercents(props) {
  return (
    <AmountWithPercents
      {...props}
      type={eyesWideOpenTypes.CLIENT}
      amount={props.externalCount}
    />
  );
}

ClientAmountWithPercents.propTypes = {
  externalCount: PropTypes.number,
};

export function FacilitiesAmountWithPercents(props) {
  return (
    <AmountWithPercents
      {...props}
      type={eyesWideOpenTypes.FACILITIES}
      amount={props.internalCount}
    />
  );
}

FacilitiesAmountWithPercents.propTypes = {
  internalCount: PropTypes.number,
};
