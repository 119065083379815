import { gql } from '@apollo/client';

import { maintenancePlanHistoryFragment } from './get-history.js';
import { checklistFragment, fileFragment } from '../fragments.js';

export const prefetchMasterPmProject = gql`
  query prefetchMasterPmProject($id: ID!) {
    maintenancePlan(id: $id) {
      _id
      maintenancePlanId
      ...checklistFragment
      projects {
        ...maintenancePlanHistoryFragment
      }
      searchFiles {
        hits {
          ...fileFragment
        }
      }
    }
  }

  ${maintenancePlanHistoryFragment}
  ${checklistFragment}
  ${fileFragment}
`;
