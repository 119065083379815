import React from 'react';
import { Layout } from 'poly-book';

import AuthContainer from '../hooks/auth-container.js';
import StaffFilter from '../containers/staff/filter.js';
import StaffContainer from '../containers/staff/list.js';
import Navigation from '../containers/navbar/navigation-container.js';
import { LayoutContent } from '../styled.js';

function Staff() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <StaffFilter />
        </Layout.Header>
        <LayoutContent>
          <StaffContainer />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default Staff;
