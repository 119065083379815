import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import {
  fileNameFormatter,
  FilesTabComp,
  fileTypeFormatter,
  Loader,
} from 'poly-site-ui';
import { filesCollections } from 'poly-constants';

import { MAX_SIZE, SORTING } from '../../../../../constants/index.js';
import { entityFormatter } from '../../../../../util/general.js';
import { useGetAssetIdByProject } from './wo-history.js';

const getAssetFiles = gql`
  query getAssetFiles($input: CollectionSearchParams) {
    searchFiles(input: $input) {
      hits {
        _id
        fileType
        fileName
        url
      }
    }
  }
`;

function FilesLoader() {
  return <Loader size={26} minHeight={54} />;
}

const fileColumns = [
  ['File Type', R.prop('fileType'), fileTypeFormatter],
  ['File Name', R.identity, fileNameFormatter],
];

function AssetsFilesWrapper({ entity }) {
  const { projectLoading, assetId } = useGetAssetIdByProject(entity);

  const { loading, data } = useQuery(getAssetFiles, {
    variables: {
      input: {
        from: 0,
        size: MAX_SIZE,
        query: {
          nested: {
            path: 'relations',
            query: {
              bool: {
                must: [
                  {
                    match: { 'relations.collection': filesCollections.ASSETS },
                  },
                  { match: { 'relations._id': assetId } },
                ],
              },
            },
          },
        },
        ...SORTING,
      },
    },
    skip: !assetId,
  });

  const files = entityFormatter(data?.searchFiles.hits || [], fileColumns);

  if (projectLoading || loading) return <FilesLoader />;

  return <FilesTabComp files={files} withAddNewBtn={false} />;
}

AssetsFilesWrapper.propTypes = { entity: PropTypes.string.isRequired };

export function ProjectAssetFiles() {
  return <AssetsFilesWrapper entity="project" />;
}

export function RecurringProjectAssetFiles() {
  return <AssetsFilesWrapper entity="recurringProject" />;
}
