import { PurchaseOrderStatus } from 'poly-constants';

import { SORTING } from '../../constants/index.js';

export const formQuery = ({ type }) => {
  const { APPROVED, PENDING_APPROVAL } = PurchaseOrderStatus;
  const queryType = {
    [PENDING_APPROVAL]: {
      bool: {
        must: {
          match: { status: PENDING_APPROVAL },
        },
      },
    },
    [APPROVED]: {
      bool: {
        must: {
          match: { status: APPROVED },
        },
      },
    },
  };

  return queryType[type];
};

export const usePOsFilters = (props) => {
  const searchTerm = props.tableSearchString || '';
  const query = formQuery(props);
  const { sort } = SORTING;

  return {
    searchTerm,
    query,
    sort,
  };
};
