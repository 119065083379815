import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from 'poly-site-ui';

import { GeneralError, BlockInvoiceSuccess } from '../../constants/alerts.js';

import { setInvoicesModal } from '../../redux/actions/index.js';
import { makeBold } from '../../util/general.js';
import { useBlockMultipleInvoices } from '../../hooks/invoices.js';

function InvoiceConfirm(props) {
  const { modalInfo } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { blockInvoices } = useBlockMultipleInvoices();

  const closeModal = useCallback(
    () => dispatch(setInvoicesModal({ blockInvoice: null })),
    [dispatch],
  );

  const onConfirm = useCallback(async () => {
    setLoading(true);
    return blockInvoices({ invoicesIds: [modalInfo.invoiceId] })
      .then(() => {
        setLoading(false);
        dispatch(setInvoicesModal({ blockInvoice: null }));
        toast.success(BlockInvoiceSuccess(modalInfo.invoiceNumber));
      })
      .catch(() => {
        toast.error(GeneralError);
        setLoading(false);
      });
  }, [
    dispatch,
    setLoading,
    blockInvoices,
    modalInfo.invoiceId,
    modalInfo.invoiceNumber,
  ]);

  const text = (
    <>
      You are about to Block Invoice {makeBold(modalInfo.invoiceNumber)}
      . Blocking will remove it from the Invoices Workflow.
      <br />
      Are you sure you want to Block this Invoice?
    </>
  );

  return (
    <ConfirmModal
      {...props}
      {...modalInfo}
      closeModal={closeModal}
      onConfirm={onConfirm}
      loading={loading}
      showModal
      subHeading="Confirmation"
      text={text}
    />
  );
}

InvoiceConfirm.propTypes = {
  modalInfo: PropTypes.shape({
    invoiceNumber: PropTypes.string,
    invoiceId: PropTypes.string,
  }),
};

export default function (props) {
  const modalInfo = useSelector((state) => state.invoices.modals.blockInvoice);

  return modalInfo ? <InvoiceConfirm {...props} modalInfo={modalInfo} /> : null;
}
