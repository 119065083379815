import types from '../types.js';

export const setAssetActiveTab = (tab) => ({
  type: types.SET_ASSET_ACTIVE_TAB,
  payload: tab,
});

export const setAssetModal = (payload) => ({
  type: types.SET_ASSET_MODAL,
  payload,
});
