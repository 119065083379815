import { gql } from '@apollo/client';

import { fileFragment, userFragment } from '../fragments.js';
import { propertySupplierFragment } from './get-suppliers.js';
import {
  propertyProjectsFragment,
  propertyRecurringProjectsFragment,
} from './get-project.js';
import { propertyAssetFragment } from './get-assets.js';

export const prefetchProperty = gql`
  query prefetchProperty(
    $propertyId: ID!
    $searchWOsInput: CollectionSearchParams!
    $searchChildRecurringInput: CollectionSearchParams!
    $searchRecurringInput: CollectionSearchParams!
  ) {
    property(id: $propertyId) {
      _id
      status
      path
      name
      workZone
      occupancy
      ownership
      squareFootage
      addressTwo
      address {
        formatted_address
        address_parts {
          street_number
          route
          locality
          administrative_area_level_1
          administrative_area_level_2
          country
          postal_code
          postal_code_suffix
          neighborhood
        }
        geo {
          coordinates
        }
      }
      region
      regionCode
      clientUnitNumber
      clientOuterId
      clientExternalId
      tier
      clientUnitName
      typeByClient
      square
      wos: searchProjects(input: $searchWOsInput) {
        ...propertyProjectsFragment
      }
      childRecurring: searchProjects(input: $searchChildRecurringInput) {
        ...propertyProjectsFragment
      }
      recurring: searchRecurringProjects(input: $searchRecurringInput) {
        ...propertyRecurringProjectsFragment
      }
      users {
        ...userFragment
      }
      assets {
        ...propertyAssetFragment
      }
      suppliers(input: { relationType: DIRECT }) {
        ...propertySupplierFragment
      }
      files {
        ...fileFragment
      }
    }
  }

  ${propertyProjectsFragment}
  ${userFragment}
  ${propertyAssetFragment}
  ${propertySupplierFragment}
  ${fileFragment}
  ${propertyRecurringProjectsFragment}
`;
