import React, { useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Footer from '../../components/pm-projects/footer.js';
import { setInvoicesModal } from '../../redux/actions/index.js';

const InvoiceFooter = memo((props) => {
  const dispatch = useDispatch();

  const selectedInvoices = useSelector(
    (state) => state.invoices.selectedInvoices,
  );

  const show = selectedInvoices.length > 0;

  const onClick = useCallback(
    () =>
      dispatch(
        setInvoicesModal({
          approveInvoice: true,
        }),
      ),
    [dispatch, setInvoicesModal],
  );

  return (
    <Footer {...props} title="Approve Invoices" show={show} onClick={onClick} />
  );
});

export default InvoiceFooter;
