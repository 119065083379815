import { gql } from '@apollo/client';

const childProjectFragment = gql`
  fragment childProjectFragment on ProjectsSearchResult {
    hits {
      _id
      type
      projectId
      description
      endDate
      status
      technicians {
        _id
      }
      manager {
        _id
      }
      suppliers {
        _id
      }
    }
    total
  }
`;

export const getMRChildrenQuery = gql`
  query mrProjectQuery($id: ID, $input: CollectionSearchParams) {
    recurringProject(projectId: $id) {
      _id
      searchChildProjects(input: $input) {
        ...childProjectFragment
      }
    }
  }

  ${childProjectFragment}
`;

export const searchProjectChanged = gql`
  subscription projectsChanged($input: CollectionSearchParams!) {
    searchProjectChanged(input: $input) {
      id
      type
    }
  }
`;
