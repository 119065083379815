import React from 'react';
import { Header, Heading } from 'poly-book';
import styled from 'styled-components';

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

function ReportsInfo() {
  return (
    <Header.Sub>
      <HeaderRow>
        <Header.Block>
          <Heading.h1 lighter>Report Directory</Heading.h1>
        </Header.Block>
      </HeaderRow>
    </Header.Sub>
  );
}

export default ReportsInfo;
