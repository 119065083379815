import { gql } from '@apollo/client';

export const assetsSearchQuery = gql`
  query assetsSearch($input: CollectionSearchParams) {
    searchAssets(input: $input) {
      hits {
        _id
        description
        equipmentType
        serial
      }
    }
  }
`;
