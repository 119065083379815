import { gql } from '@apollo/client';

export const maintenancePlanQuery = gql`
  query maintenancePlanQuery($id: ID!) {
    maintenancePlan(id: $id) {
      _id
      maintenancePlanId
      status
      description
      startDate
      endDate
      schedule {
        repeats
        every
        weekDay
        days
        expected {
          delayInDays
          monthDay
          thisMonth
        }
      }
      technician {
        _id
        fullName
      }
      manager {
        _id
        fullName
      }
      client {
        _id
        name
      }
      suppliers {
        _id
        company {
          name
          emails {
            email
            type
          }
          phones {
            phone
            isPrimary
            legacyFormat
          }
        }
      }
    }
  }
`;
