import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HiddenInput from '../HiddenInput/index.js';

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Label = styled.label`
  min-height: 12px;
  padding-left: 12px;
  position: relative;
  cursor: pointer;

  &:not(:empty) {
    padding-left: 22px;
  }

  &::before {
    width: 12px;
    height: 12px;
    display: block;
    content: '';
    position: absolute;
    top: calc(50% - 6px);
    left: 0;
    background-color: transparent;
    border: 1px solid #babfd2;
    border-radius: 50%;
  }
`;

const Input = styled(HiddenInput)`
  &:checked + ${Label}::before {
    border: 3px solid #ff8c00;
  }
`;

function Radio(props) {
  const { content, id } = props;

  return (
    <Wrapper>
      <Input type="radio" {...props} />
      <Label htmlFor={id}>{content}</Label>
    </Wrapper>
  );
}

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.node,
};

Wrapper.displayName = 'Wrapper';
Label.displayName = 'Label';
HiddenInput.displayName = 'HiddenInput';
Radio.displayName = 'Radio';

export default Radio;
