import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FilterComponent from '../../../components/pm-projects/filter.js';
import { ALL_RESULTS } from '../../../constants/pagination.js';
import {
  setPmProjectsFilter,
  setPmProjectsPaginationTotal,
  setPmProjectsPaginationPageSize,
  setPmProjectsPaginationCurrentPage,
} from '../../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const activeFilter = useSelector((state) => state.PMProjects.typeFilter);

  const label = useSelector(
    (state) => state.PMProjects.paginationDropdownLabel,
  );
  const locationType = useSelector((state) => state.location.type);

  const onClick = useCallback(
    (filter, total) => {
      if (label === ALL_RESULTS) {
        dispatch(setPmProjectsPaginationPageSize(total));
      }

      dispatch(setPmProjectsFilter(filter));
      dispatch(setPmProjectsPaginationTotal(total));
      dispatch(setPmProjectsPaginationCurrentPage(1));
    },
    [dispatch, label],
  );

  return (
    <FilterComponent
      {...props}
      onClick={onClick}
      activeFilter={activeFilter}
      locationType={locationType}
    />
  );
}
