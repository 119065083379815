import * as R from 'ramda';
import { handleActions } from 'redux-actions';

import {
  setProjectAssetsModal,
  setProjectAssetsActiveTab,
} from '../actions/index.js';

const defaultState = {
  activeTab: 'details',
  modals: {
    addAsset: null,
    removeAsset: null,
  },
};

export default handleActions(
  {
    [setProjectAssetsActiveTab]: (state, { payload }) => ({
      ...state,
      activeTab: payload,
    }),
    [setProjectAssetsModal]: (state, { payload }) => ({
      ...state,
      modals: R.mergeDeepRight(state.modals, payload),
    }),
  },
  defaultState,
);
