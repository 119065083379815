import { gql } from '@apollo/client';

import { purchaseOrderFragment } from '../fragments.js';

export const PURCHASE_ORDER_SUB = gql`
  subscription PURCHASE_ORDER_SUB($input: SingleDocSubInput!) {
    purchaseOrderChanged(input: $input) {
      id
      document {
        ...purchaseOrderFragment
      }
    }
  }
  ${purchaseOrderFragment}
`;

export const purchaseOrderQuery = gql`
  query purchaseOrderQuery($id: ID!) {
    purchaseOrder(id: $id) {
      ...purchaseOrderFragment
    }
  }

  ${purchaseOrderFragment}
`;
