import React from 'react';
import { Layout } from 'poly-book';

import MRProjectInfoContainer from '../containers/navbar/mr-project-info-container.js';
import MRProjectPageLayout from '../layouts/mr-project-page-layout.js';
import Navigation from '../containers/navbar/navigation-container.js';
import AuthContainer from '../hooks/auth-container.js';

function MRProject() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <MRProjectInfoContainer />
        </Layout.Header>
        <Layout.Content columns="450px 1fr">
          <MRProjectPageLayout />
        </Layout.Content>
      </Layout>
    </AuthContainer>
  );
}

export default MRProject;
