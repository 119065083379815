import { gql } from '@apollo/client';

export const addTimelogs = gql`
  mutation addTimelogs(
    $assignedTo: ID!
    $endDate: DateTime!
    $projectId: ID!
    $startDate: DateTime!
  ) {
    createTimeLog(
      assignedTo: $assignedTo
      endDate: $endDate
      projectId: $projectId
      startDate: $startDate
    ) {
      _id
      assignedTo {
        _id
        fullName
      }
      createdAt
      duration
      endDate
      startDate
    }
  }
`;
