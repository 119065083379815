import { gql } from '@apollo/client';

export const propertyQuery = gql`
  query getProperty($id: ID!) {
    property(id: $id) {
      _id
      status
      path
      name
      workZone
      occupancy
      ownership
      squareFootage
      addressTwo
      address {
        formatted_address
        address_parts {
          street_number
          route
          locality
          administrative_area_level_1
          administrative_area_level_2
          country
          postal_code
          postal_code_suffix
          neighborhood
        }
        geo {
          coordinates
        }
      }
      region
      regionCode
      clientUnitNumber
      clientOuterId
      clientExternalId
      tier
      clientUnitName
      typeByClient
      square
    }
  }
`;
