import { gql } from '@apollo/client';

import { supplierCompanyFields } from './fragments.js';

export const suppliersQuery = gql`
  query getSuppliers($input: CollectionSearchParams) {
    searchSuppliers(input: $input) {
      total
      hits {
        _id
        status
        type
        rating {
          average
          stars
        }
        company {
          ...supplierCompanyFields
        }
      }
    }
  }

  ${supplierCompanyFields}
`;
