import PropTypes from 'prop-types';
import React from 'react';
import { Pagination, Table as TableBase } from 'poly-site-ui';
import { useSaveDataForTableExport } from 'poly-site-ui/src/hooks/useSaveDataForTableExport.js';
import EditResponseTeamContainer from '../../containers/project/details/response-team/edit.js';

// dispatch props on update
export function Table({
  size,
  total,
  showPagination,
  currentPage,
  onChange,
  onShowSizeChange,
  paginationDropdownLabel,
  ...props
}) {
  return (
    <>
      <TableBase {...props} />
      {showPagination && (
        <Pagination
          {...{
            size,
            total,
            onChange,
            currentPage,
            onShowSizeChange,
          }}
          dropdownLabel={paginationDropdownLabel}
        />
      )}
      <EditResponseTeamContainer isAssignType />
    </>
  );
}

Table.propTypes = {
  size: PropTypes.number,
  total: PropTypes.number,
  currentPage: PropTypes.number,
  showPagination: PropTypes.bool,
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
  paginationDropdownLabel: PropTypes.string,
};
//
const defaultPropsForTable = {
  showPagination: false,
  currentPage: 1,
  size: 50,
  total: 0,
  onChange: () => {},
  onShowSizeChange: () => {},
  paginationDropdownLabel: '',
};

Table.defaultProps = defaultPropsForTable;
Table.displayName = 'TableWithPrintAndExport';

export function TableWithSavingDataForExport(props) {
  const allProps = useSaveDataForTableExport(props);

  return <Table {...allProps} />;
}

TableWithSavingDataForExport.displayName = 'TableWithSavingDataForExport';
TableWithSavingDataForExport.defaultProps = defaultPropsForTable;
