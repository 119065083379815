import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PatternFormat, NumericFormat } from 'react-number-format';
import { Input } from 'poly-book';

function CustomInput(props) {
  return <Input {...props} />;
}

function InputNumber(props) {
  const { defaultValue, ...restProps } = props;

  const [valueState, setValueState] = useState(defaultValue);

  const onChange = useCallback(
    ({ value }) => {
      setValueState(value);
      return value;
    },
    [setValueState],
  );

  return props.format ? (
    <PatternFormat
      {...restProps}
      value={valueState}
      onValueChange={onChange}
      customInput={CustomInput}
    />
  ) : (
    <NumericFormat
      {...restProps}
      value={valueState}
      onValueChange={onChange}
      customInput={CustomInput}
    />
  );
}

InputNumber.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
};

export default InputNumber;
