import { gql } from '@apollo/client';

import { updateFragment } from '../fragments.js';

export const getSupplierUpdates = gql`
  query getSupplierUpdates(
    $supplierId: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    supplier(id: $supplierId) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...updateFragment
        }
        total
      }
    }
  }
  ${updateFragment}
`;
