import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './index.js';
import { enhancer, middleware } from './router/index.js';

const preloadedState = window.REDUX_STATE;

const middlewares = applyMiddleware(thunkMiddleware, middleware);

const store = createStore(
  rootReducer,
  preloadedState,
  composeWithDevTools(enhancer, middlewares),
);

export default store;
