import * as R from 'ramda';
import {
  RecurringProjectStatuses,
  RecurringProjectStatusesText,
} from 'poly-constants';

export const FILTERS = [
  {
    title: 'Active',
    type: RecurringProjectStatuses.ACTIVE,
    color: '#9bdc53',
    showPip: true,
  },
  {
    title: 'Closed',
    type: RecurringProjectStatuses.CLOSED,
    color: '#babfd2',
    showPip: true,
  },
];

export const MASTER_PROJECT_STATUS_COLORS = {
  [RecurringProjectStatusesText.ACTIVE]: '#9bdc53',
  [RecurringProjectStatusesText.CLOSED]: '#babfd2',
  [RecurringProjectStatusesText.EXPIRED]: '#babfd2',
};

const REPEATS_VARS = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  QUARTER: 'Quarter',
  SEMI_ANNUAL: 'Semi-Annual',
  YEAR: 'Year',
};

export const { DAY, MONTH, WEEK, QUARTER, SEMI_ANNUAL, YEAR } = REPEATS_VARS;

export const REPEATS = R.values(REPEATS_VARS);

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
