import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from 'poly-book';
import { TableComponent } from 'poly-site-ui';

const ColumnsPropTypes = {
  headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  columns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  formats: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
};

function ProjectHistory({ historyColumns }) {
  return (
    <Widget.Item>
      <TableComponent
        {...historyColumns}
        gridColumns="repeat(3, minmax(100px, 1fr))"
        headerStyle={{ paddingTop: '0px' }}
      />
    </Widget.Item>
  );
}

ProjectHistory.propTypes = PropTypes.shape(ColumnsPropTypes).isRequired;

export default ProjectHistory;
