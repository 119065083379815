import * as R from 'ramda';
import {
  performExcelExport,
  createExcelExportCell,
  ExcelExportCellType,
} from 'poly-client-utils';

const COLUMN_WIDTH = [30, 55, 20, 25];

const TITLE_ROW = ['Supplier Name', 'Address', 'Service Types', 'Status'];

// getSupplierRowConfig :: Supplier -> [ExcelExportDataCell]
const getSupplierRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 4),
  ]),
  R.juxt([
    R.pathOr('', ['company', 'name']),
    R.pathOr('', ['company', 'address', 'formatted_address']),
    R.propOr('', ['type']),
    R.propOr('', ['status']),
  ]),
);

// getSuppliersDirectoryExcelConfig :: [Supplier] -> Object
export const getSuppliersDirectoryExcelConfig = (suppliers) => ({
  exportFileName: 'suppliers_directory_export.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 4)],
      TITLE_ROW,
    ),
    ...R.map(getSupplierRowConfig, suppliers),
  ],
});

export const exportSuppliersDirectoryToXLS = R.compose(
  performExcelExport,
  getSuppliersDirectoryExcelConfig,
  R.path(['data', 'searchSuppliers', 'hits']),
);
