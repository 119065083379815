import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../Icon/index.js';

const GAP = 2;
const WIDTH = 12;

const getWidth = ({ count }) => {
  const isFloat = (n) => Number(n) === n && n % 1 !== 0;
  const int = Math.floor(count) > 1 ? (count - 1) * GAP : 0;

  return count * WIDTH + int + Number(isFloat(count) && count > 1);
};

const Wrapper = styled.span`
  width: ${getWidth}px;
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: ${GAP}px;
  overflow: hidden;
`;

function Stars({ count, active, title }) {
  return (
    <Wrapper {...{ count, title }}>
      {Array.from(Array(Math.ceil(count)), (item, index) => (
        <Icon
          key={index}
          name="star"
          fill={active ? '#ff8c00' : '#babfd2'}
          dimensions={{ width: WIDTH }}
        />
      ))}
    </Wrapper>
  );
}

Stars.propTypes = {
  count: PropTypes.number,
  title: PropTypes.string,
  active: PropTypes.bool,
};

Stars.defaultProps = {
  count: 0,
  active: false,
};

Wrapper.displayName = 'StarsWrapper';
Stars.displayName = 'Stars';

export default Stars;
