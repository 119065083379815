import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUIMasterProjectStatusText } from 'poly-client-utils';
import { Loader } from 'poly-site-ui';
import { getUserCellPhoneNumber } from 'poly-utils';
import RecurringProjectDetails from '../../components/mr-project/details.js';
import {
  companyPhoneFormatter,
  getEmailByType,
} from '../../utils/suppliers/index.js';
import { SUPPLIERS_EMAILS } from '../../constants/suppliers.js';
import { formatDateWithDashes } from '../../util/general.js';
import { setActiveProject } from '../../redux/actions/index.js';
import {
  useMRProjectDetails,
  useMRProjectSubscriptionQuery,
} from '../../hooks/project-details.js';

const commonUserFields = {
  _id: R.prop('_id'),
  firstName: R.prop('firstName'),
  lastName: R.prop('lastName'),
  email: R.path(['emails', 0, 'address']),
  phone: getUserCellPhoneNumber,
};

// getManagerByProject :: Project -> Manager
export const getManagerByProject = R.compose(
  R.unless(
    R.isEmpty,
    R.applySpec({
      ...commonUserFields,
    }),
  ),
  R.defaultTo({}),
  R.prop('manager'),
);

// getTechniciansByProject :: Project -> [Technician]
export const getTechniciansByProject = R.compose(
  R.map(
    R.applySpec({
      ...commonUserFields,
      userGroups: R.propOr([], 'userGroups'),
    }),
  ),
  R.defaultTo([]),
  R.prop('technicians'),
);

// getProject :: Project -> Object
function getProject(project) {
  return {
    description: project.description,
    location: project.location,
    property: project.property,
    schedule: project.schedule,
    status: getUIMasterProjectStatusText(project),
    details: project.invoiceDescription,
    type: project.serviceType.name,
    client: project.client.name,
    manager: getManagerByProject(project),

    technicians: getTechniciansByProject(project),
    suppliers: R.map(
      (supplier) => ({
        _id: supplier._id,
        name: supplier.company.name,
        email: getEmailByType(
          supplier.company.emails,
          SUPPLIERS_EMAILS.SERVICE,
        ),
        phone: companyPhoneFormatter(supplier.company.phones || []),
      }),
      project.suppliers,
    ),
    startDate: formatDateWithDashes(project.startDate),
    endDate: formatDateWithDashes(project.endDate),
  };
}

export default function (props) {
  const dispatch = useDispatch();

  const projectId = useSelector((state) => state.location.payload.id);

  const { loading, project } = useMRProjectDetails(projectId);

  const propsOfComponent = { ...props, projectId, _id: project?._id };

  const { restProps } = useMRProjectSubscriptionQuery(propsOfComponent);

  if (loading || !project) return <Loader />;

  const projectObj = getProject(project);

  dispatch(
    setActiveProject(
      R.pick(
        [
          '_id',
          'startDate',
          'serviceType',
          'description',
          'invoiceDescription',
          'location',
          'property',
          'status',
          'type',
          'schedule',
          'endDate',
          'status',
          'serviceType',
          'suppliers',
          'client',
          'manager',
          'technicians',
        ],
        project,
      ),
    ),
  );

  return (
    <RecurringProjectDetails {...props} {...restProps} project={projectObj} />
  );
}
