export const STATUSES = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
};

export const MODAL_STATUSES = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
};

const { ACTIVE, BLOCKED } = MODAL_STATUSES;

export const STATUS_COLORS = {
  [ACTIVE]: '#9bdc53',
  [BLOCKED]: '#9ea2a9',
};
