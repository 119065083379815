import { gql } from '@apollo/client';

import { purchaseOrderFragment } from '../fragments.js';

export const purchaseOrdersQuery = gql`
  query purchaseOrdersQuery($input: CollectionSearchParams) {
    searchPurchaseOrders(input: $input) {
      total
      hits {
        ...purchaseOrderFragment
      }
    }
  }

  ${purchaseOrderFragment}
`;
