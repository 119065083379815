import React from 'react';
import ReportsContainer from '../../components/reports/list.js';

import {
  useApprovedPOsCountQuery,
  usePORequestsCountQuery,
} from '../../hooks/purchase-orders.js';
import {
  useApprovedInvoicesCountQuery,
  usePendingInvoicesCountQuery,
} from '../../hooks/invoices.js';

export default function (props) {
  const { requestsCount } = usePORequestsCountQuery();

  const { approvedPOsCount } = useApprovedPOsCountQuery();

  const { pendingInvoicesCount } = usePendingInvoicesCountQuery();

  const { approvedInvoicesCount } = useApprovedInvoicesCountQuery();

  return (
    <ReportsContainer
      {...props}
      requestsCount={requestsCount}
      approvedPOsCount={approvedPOsCount}
      pendingInvoicesCount={pendingInvoicesCount}
      approvedInvoicesCount={approvedInvoicesCount}
    />
  );
}
