import { gql } from '@apollo/client';

import { propertyProjectsFragment } from './get-project.js';

export const getPropertyChildRecurringProjectsQuery = gql`
  query getPropertyChildRecurringProjectsQuery(
    $propertyId: ID!
    $input: CollectionSearchParams!
  ) {
    property(id: $propertyId) {
      _id
      searchProjects(input: $input) {
        ...propertyProjectsFragment
      }
    }
  }

  ${propertyProjectsFragment}
`;
