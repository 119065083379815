import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from 'poly-book';
import { TableComponent } from 'poly-site-ui';

import { entityFormatter } from '../../../util/general.js';

function AssetsHistory({ projects, historyColumns }) {
  return (
    <Widget.Item>
      <TableComponent
        {...entityFormatter(projects, historyColumns)}
        gridColumns="repeat(3, minmax(100px, 1fr))"
        headerStyle={{ paddingTop: '0px' }}
      />
    </Widget.Item>
  );
}

AssetsHistory.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
  historyColumns: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
  ).isRequired,
};

export default AssetsHistory;
