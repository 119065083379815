import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { PolyThemeProvider, SidebarSiteLayout } from 'poly-book';
import { OutSidebarProvider } from 'poly-client-utils';
import { ExportTableProvider } from 'poly-site-ui';

import store from '../redux/store.js';
import { apolloClient } from '../redux/reducers/apollo.js';

function AppProviders({ children }) {
  return (
    <Provider store={store}>
      <PolyThemeProvider>
        <ApolloProvider client={apolloClient}>
          <ExportTableProvider>
            <OutSidebarProvider Layout={SidebarSiteLayout}>
              {children}
            </OutSidebarProvider>
          </ExportTableProvider>
        </ApolloProvider>
      </PolyThemeProvider>
    </Provider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProviders;
