import React from 'react';
import PropTypes from 'prop-types';
import { S, L, Modal, Heading, IconButton, Icon } from 'poly-book';

import { SUPPORT } from '../../constants/index.js';

function SupportModal({ closeModal }) {
  return (
    <Modal show>
      <Modal.Item>
        <Heading>
          <Heading.h3 lighter>Support</Heading.h3>
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <Modal.Item>
        <S type="content">
          If you need any technical or application support,
          <br />
          you can reach us via email at{' '}
          <L href="mailto:support@askpoly.com">{SUPPORT.mail}</L>
        </S>
      </Modal.Item>
      <Modal.Item>
        <S type="content">
          You can also reach our Client Support Team at{' '}
          <L href="tel:+18448243406">{SUPPORT.phone}</L>
        </S>
      </Modal.Item>
    </Modal>
  );
}

SupportModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default SupportModal;
