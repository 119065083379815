import { gql } from '@apollo/client';

export const sendPOSupplierConfirmation = gql`
  mutation sendPOSupplierConfirmation(
    $purchaseOrderId: ID!
    $emails: [Email!]!
    $instructions: String
  ) {
    sendPurchaseOrderConfirmationToSupplier(
      purchaseOrderId: $purchaseOrderId
      emails: $emails
      instructions: $instructions
    ) {
      success
    }
  }
`;
