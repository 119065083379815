import * as R from 'ramda';
import moment from 'moment';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-router';
import { CreateAssetSuccess } from '../../constants/alerts.js';
import { AssetModalForm } from '../../components/asset/asset-modal-form/asset-modal-form.js';
import { setAssetModal } from '../../redux/actions/asset.js';
import useValidation from '../../hooks/useValidation.js';

export const assetModalValidationRules = {
  description: [{ rule: 'required' }],
  manufacturer: [{ rule: 'required' }],
  model: [{ rule: 'required' }],
  serial: [{ rule: 'required' }],
  location: [{ rule: 'required' }],
  room: [{ rule: 'required' }],
  startUp: [{ rule: 'required' }],
  equipmentType: [{ rule: 'required' }],
  propertyId: [{ rule: 'required', message: 'Please Select Property' }],
};

// eslint-disable-next-line import/no-unused-modules
export const createAssetMutation = gql`
  mutation createAssetMutation($input: CreateAssetInput!) {
    createAsset(input: $input) {
      asset {
        _id
      }
    }
  }
`;

// prepareFormDataToMutation :: FormValues -> MutationInput
export const prepareFormDataToMutation = R.over(R.lensProp('startUp'), (date) =>
  moment.isMoment(date) ? date.toDate() : date,
);

export function AddAssetModal(props) {
  const {
    errors,
    onChange: onChangeHandler,
    validate,
    setError,
  } = useValidation({
    validationRules: assetModalValidationRules,
  });

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});

  const dispatch = useDispatch();
  const modal = useSelector(R.path(['asset', 'modal']));

  const [createAsset] = useMutation(createAssetMutation);

  const closeModal = () => dispatch(setAssetModal(null));

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const submitErrors = validate(formValues);

    if (submitErrors.isInvalid) return setLoading(false);

    try {
      const response = await createAsset({
        variables: { input: prepareFormDataToMutation(formValues) },
      });

      setError({});
      closeModal();
      toast.success(CreateAssetSuccess);
      setLoading(false);

      const assetId = R.path(['data', 'createAsset', 'asset', '_id'])(response);
      return push(`/asset/${assetId}`);
    } catch (err) {
      setError({ server: err.toString() });
      return setLoading(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormValues((state) => ({ ...state, [name]: value }));
    onChangeHandler(e);
  };

  const formProps = {
    errors,
    loading,
    formValues,
    onChange,
    onSubmit,
    closeModal,
    isModalOpen: !!modal,
    modalType: modal ? modal.type : null,
    title: 'New Asset',
  };

  return <AssetModalForm {...props} {...formProps} />;
}
