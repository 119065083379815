import styled from 'styled-components';

import { TableWithSavingDataForExport } from '../table/table.js';

const EyesWideOpenStatsComponent = styled(TableWithSavingDataForExport)`
  & {
    .grid-table-footer {
      padding: 20px 15px;
      font-weight: bold;
    }
  }
`;
export default EyesWideOpenStatsComponent;
