import * as R from 'ramda';
import { NOT_FOUND } from 'redux-first-router';

import { setIsPageChecking } from '../actions/index.js';
import { apolloClient } from '../reducers/apollo.js';

const checkIfPageExist =
  (query, resultPath, additionalChecker) => async (dispatch, getState) => {
    dispatch(setIsPageChecking(true));

    try {
      const result = await apolloClient.query({
        query,
        variables: {
          id: getState().location.payload.id,
        },
      });

      const entity = R.path(['data', ...resultPath])(result);

      if (R.isNil(entity)) {
        dispatch({ type: NOT_FOUND });
      }

      if (R.is(Function, additionalChecker) && additionalChecker(entity)) {
        dispatch({ type: NOT_FOUND });
      }
    } catch ({ networkError }) {
      if (R.prop('statusCode', networkError) !== 401) {
        dispatch({ type: NOT_FOUND });
      }
    } finally {
      dispatch(setIsPageChecking(false));
    }
  };

export default checkIfPageExist;
