import React from 'react';

import AssignModal from '../containers/pm-projects/technicians-modal/index.js';
import Footer from '../containers/pm-projects/list/footer.js';
import Projects from '../containers/pm-projects/list/index.js';

function ProjectsPageLayout() {
  return (
    <>
      <Projects />
      <Footer />
      <AssignModal />
    </>
  );
}

export default ProjectsPageLayout;
