import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Button, Grid, Heading, Icon, IconButton, Modal, S } from 'poly-book';
import { DatePicker } from 'poly-site-ui';

import { FormInput } from './form-input.js';
import ServerError from '../../server-error/index.js';
import ButtonLoader from '../../loader/button.js';
import { StatusSelect } from './status-select.js';
import { PropertySelect } from './property-select.js';
import { formValuesPropTypes } from './prop-types.js';
import { Rows } from '../../../util/forms/index.js';

const Form = Modal.Item.withComponent(Grid);

// checkErrors :: Errors -> Boolean
const checkErrors = R.compose(
  R.not,
  R.isEmpty,
  R.when(R.complement(R.isEmpty), R.compose(R.reject(R.isNil), R.values)),
  R.dissoc('isInvalid'),
);

export function AssetModalForm({
  title,
  errors,
  loading,
  onSubmit,
  onChange,
  modalType,
  closeModal,
  formValues,
  isModalOpen: show,
}) {
  return (
    <Modal {...{ show }}>
      <Modal.Item>
        <Heading>
          <Heading.h3 lighter>
            {modalType === 'edit' ? 'Edit Asset' : 'Create Asset'}
          </Heading.h3>
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <Modal.Item margin={20}>
        <Heading.h5>{title}</Heading.h5>
      </Modal.Item>
      <form {...{ onSubmit }}>
        <Form columns="120px minmax(200px, 350px)" margin={30} simple>
          <Rows
            items={[
              ...(modalType === 'edit'
                ? [
                    {
                      title: { value: 'Status', props: ['required'] },
                      item: (
                        <StatusSelect
                          name="status"
                          value={R.prop('status', formValues)}
                          onChange={onChange}
                        />
                      ),
                    },
                  ]
                : []),
              {
                title: { value: 'Property', props: ['required'] },
                error: R.prop('propertyId', errors),
                item: (
                  <PropertySelect
                    name="propertyId"
                    {...{ onChange, errors }}
                    value={R.prop('propertyId', formValues)}
                  />
                ),
              },
              {
                title: { value: 'Equipment Type', props: ['required'] },
                error: R.prop('equipmentType', errors),
                item: (
                  <FormInput
                    name="equipmentType"
                    placeholder="Equipment type"
                    {...{ onChange, errors, formValues }}
                  />
                ),
              },
              {
                title: { value: 'Description', props: ['required'] },
                error: R.prop('description', errors),
                item: (
                  <FormInput
                    name="description"
                    placeholder="Description"
                    {...{ onChange, errors, formValues }}
                  />
                ),
              },
              {
                title: { value: 'Start Up', props: ['required'] },
                error: R.prop('startUp', errors),
                item: (
                  <DatePicker
                    dateFormat="MM-dd-yyyy"
                    placeholderText="Start Up"
                    invalid={!!R.prop('startUp', errors)}
                    selected={R.prop('startUp', formValues)}
                    onChange={(date) =>
                      onChange({
                        target: { name: 'startUp', value: date },
                      })
                    }
                  />
                ),
              },
              {
                title: { value: 'Manufacturer', props: ['required'] },
                error: R.prop('manufacturer', errors),
                item: (
                  <FormInput
                    name="manufacturer"
                    placeholder="Manufacturer"
                    {...{ onChange, errors, formValues }}
                  />
                ),
              },
              {
                title: { value: 'Model Number', props: ['required'] },
                error: R.prop('model', errors),
                item: (
                  <FormInput
                    name="model"
                    placeholder="Model number"
                    {...{ onChange, errors, formValues }}
                  />
                ),
              },
              {
                title: { value: 'Serial Number', props: ['required'] },
                error: R.prop('serial', errors),
                item: (
                  <FormInput
                    name="serial"
                    placeholder="Serial number"
                    {...{ onChange, errors, formValues }}
                  />
                ),
              },
              {
                title: { value: 'Location', props: ['required'] },
                error: R.prop('location', errors),
                item: (
                  <FormInput
                    name="location"
                    placeholder="Location"
                    {...{ onChange, errors, formValues }}
                  />
                ),
              },
              {
                title: { value: 'Room', props: ['required'] },
                error: R.prop('room', errors),
                item: (
                  <FormInput
                    name="room"
                    placeholder="Room"
                    {...{ onChange, errors, formValues }}
                  />
                ),
              },
            ]}
          />
        </Form>
        <ServerError
          error={R.prop('server', errors)}
          style={{ maxWidth: 450 }}
        />
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button
            type="submit"
            mode="orange"
            disabled={loading || checkErrors(errors)}
          >
            {loading && <ButtonLoader />}
            <S type="title">Save Changes</S>
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
}
AssetModalForm.displayName = 'AssetModalForm';
AssetModalForm.propTypes = {
  errors: formValuesPropTypes,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  formValues: formValuesPropTypes,
  modalType: PropTypes.string,
  title: PropTypes.string,
  isModalOpen: PropTypes.bool,
};

AssetModalForm.defaultProps = {
  errors: {},
  loading: false,
  formValues: {},
  isModalOpen: false,
};
