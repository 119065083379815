import React from 'react';
import { Layout } from 'poly-book';

import AuthContainer from '../hooks/auth-container.js';
import PropertyPageLayout from '../layouts/property-page-layout.js';
import Navigation from '../containers/navbar/navigation-container.js';
import PropertyInfoContainer from '../containers/navbar/property-info-container.js';

function Property() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <PropertyInfoContainer />
        </Layout.Header>
        <Layout.Content columns="450px 1fr">
          <PropertyPageLayout />
        </Layout.Content>
      </Layout>
    </AuthContainer>
  );
}

export default Property;
