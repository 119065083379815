import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs } from 'poly-book';

import { setReportPmProjectActiveTab } from '../../../redux/actions/index.js';

const tabs = [
  { title: 'WO History', value: 'history' },
  { title: 'PM Checklist', value: 'checklist' },
  { title: 'Files', value: 'files' },
  { title: 'Updates', value: 'updates' },
];

function MrPmProjectNavigation({ activeTab, dispatch }) {
  return (
    <Tabs>
      <Tabs.Items>
        {tabs.map(({ value, title }) => (
          <Tabs.Item
            bold
            key={value}
            href={`#${value}`}
            active={value === activeTab}
            onClick={() => dispatch(setReportPmProjectActiveTab(value))}
          >
            {title}
          </Tabs.Item>
        ))}
      </Tabs.Items>
    </Tabs>
  );
}

MrPmProjectNavigation.propTypes = {
  activeTab: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default function (props) {
  const dispatch = useDispatch();

  const activeTab = useSelector((state) => state.reportPM.activeTab);

  return (
    <MrPmProjectNavigation
      {...props}
      dispatch={dispatch}
      activeTab={activeTab}
    />
  );
}
