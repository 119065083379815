import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Footer from '../../../components/pm-projects/footer.js';
import { setPmProjectsTechnicianModal } from '../../../redux/actions/index.js';

import { QUEUE } from '../../../constants/projects.js';

const PmFooter = memo((props) => {
  const dispatch = useDispatch();

  const selectedProjects = useSelector(
    (state) => state.PMProjects.selectedProjects,
  );
  const typeFilter = useSelector((state) => state.PMProjects.typeFilter);

  const show = typeFilter === QUEUE && selectedProjects.length > 0;

  const onClick = useCallback(
    () => dispatch(setPmProjectsTechnicianModal({})),
    [dispatch],
  );

  return <Footer {...props} title="Assign to" show={show} onClick={onClick} />;
});

export default PmFooter;
