import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { debounce } from 'poly-utils/src/general.js';
import {
  checkRepeats,
  formatRepeatsArray,
} from '../../utils/mr-projects/index.js';
import useValidation from '../../hooks/useValidation.js';
import { setEditProjectModal } from '../../redux/actions/index.js';

export const useCommonFormLogic = ({ clientConfig, initialFormData }) => {
  const [loading, setLoadingState] = useState(initialFormData.loading);

  const [projectManager, setProjectManagerState] = useState(
    initialFormData.projectManager,
  );
  const [projectSuppliers, setProjectSuppliersState] = useState(
    initialFormData.projectSuppliers,
  );
  const [projectTechnician, setProjectTechnicianState] = useState(
    initialFormData.projectTechnician,
  );

  const [filterByStatus, setFilterByStatusState] = useState(
    initialFormData.filterByStatus,
  );

  const [startDate, setStartDateState] = useState(initialFormData.startDate);
  const [endDate, setEndDateState] = useState(initialFormData.endDate);

  const [isNever, setIsNever] = useState(initialFormData.isNever);

  const [repeatNum, setRepeatNumState] = useState(initialFormData.repeatNum);
  const [repeatEvery, setRepeatEveryState] = useState(
    initialFormData.repeatEvery,
  );
  const [repeatTime, setRepeatTimeState] = useState(initialFormData.repeatTime);

  const [repeats, setRepeatsState] = useState(initialFormData.repeats);

  const [activeTab, setActiveTabState] = useState(initialFormData.activeTab);

  const [selectedProperty, setSelectedPropertyState] = useState(
    initialFormData.selectedProperty,
  );
  const [selectedService, setSelectedServiceState] = useState(
    initialFormData.selectedService,
  );
  const [selectedStatus, setSelectedStatusState] = useState(
    initialFormData.selectedStatus,
  );

  const { errors, setError, onChange, validate, validateField } = useValidation(
    {
      validationRules: () => ({
        service: [{ rule: 'required', message: 'Please Select Service Type' }],
        property: [{ rule: 'required', message: 'Please Select Property' }],
        description: [{ rule: 'required' }],
        manager: [
          {
            rule: () =>
              R.propEq('noManagerForRWONeeded', true, clientConfig)
                ? false
                : !projectManager,
            message: 'Master Recurring WO should have a manager',
          },
        ],
        endDate: [
          {
            rule: ({ dateEnd, never }) =>
              !dateEnd && !never && !endDate && !isNever,
            message: 'Please Select End Date',
          },
          {
            rule: ({ dateEnd, never }) => !never && startDate > dateEnd,
            message: 'End Date must be after Start Date.',
          },
        ],
        startDate: [{ rule: 'required' }],
      }),
    },
  );

  const setLoading = useCallback(
    (loadingValue) => setLoadingState(loadingValue),
    [setLoadingState],
  );

  const setManager = useCallback(
    (managerValue) => setProjectManagerState(managerValue),
    [setProjectManagerState],
  );

  const setSuppliers = useCallback(
    (suppliersValue) => setProjectSuppliersState(suppliersValue),
    [setProjectSuppliersState],
  );

  const setTechnician = useCallback(
    (technicianValue) => setProjectTechnicianState(technicianValue),
    [setProjectTechnicianState],
  );

  const setFilterByStatus = useCallback(
    (filterValue) => setFilterByStatusState(filterValue),
    [setFilterByStatusState],
  );

  const setEndDate = useCallback(
    (endDateInput) => {
      setEndDateState(endDateInput);
      validateField('endDate', { dateEnd: endDateInput, never: isNever });
      return { endDate: endDateInput };
    },
    [setEndDateState, validateField],
  );

  const setNever = useCallback(() => {
    setIsNever(!isNever);
    validateField('endDate', { dateEnd: endDate, never: !isNever });
    return { isNever: !isNever };
  }, [setIsNever, validateField]);

  const selectRepeatNum = useCallback(
    ({ value }) => {
      setRepeatNumState(value);
      return { repeatNum: value };
    },
    [setRepeatNumState],
  );

  const setRepeatEvery = useCallback(
    ({ value }) => {
      setRepeatEveryState(value);
      return { repeatEvery: value };
    },
    [setRepeatEveryState],
  );

  const setActiveTab = useCallback(
    ({ target: { value } }) => {
      setActiveTabState(value);
      return { activeTab: value };
    },
    [setActiveTabState],
  );

  const selectProperty = useCallback(
    (selectedPropertyValue) => {
      validateField('property', selectedPropertyValue?.value);
      setSelectedPropertyState(selectedPropertyValue);
      return { selectedProperty: selectedPropertyValue };
    },
    [setSelectedPropertyState],
  );

  const selectService = useCallback(
    (serviceValue) => {
      validateField('service', serviceValue.value);
      setSelectedServiceState(serviceValue.value);

      return {
        selectedService: serviceValue.value,
      };
    },
    [setSelectedServiceState],
  );

  const selectRepeatTime = useCallback(
    ({ value }) => {
      const checked = checkRepeats(value);
      const repeatsValue = R.is(Array, checked)
        ? checked
        : formatRepeatsArray([checked, checked], startDate);

      setRepeatTimeState(value);
      setRepeatsState(repeatsValue);

      return { repeatTime: value, repeats: repeatsValue };
    },
    [setRepeatTimeState, setRepeatsState],
  );

  const setStartDate = useCallback(
    (startDateValue) => {
      if (startDateValue) {
        const check = checkRepeats(repeatTime);
        const repeatsObj = R.is(Array, check)
          ? check
          : formatRepeatsArray([check, check], startDateValue);

        setRepeatsState(repeatsObj);
        setEndDateState(null);
      }
      setStartDateState(startDateValue);
      return { startDate: startDateValue, repeats, endDate: null };
    },
    [setStartDateState, setRepeatsState, setEndDateState, repeatTime],
  );

  const selectStatus = useCallback(
    ({ value }) => {
      setSelectedStatusState(value);
      return { selectedStatus: value };
    },
    [setSelectedStatusState],
  );

  const handleTechniciansClick = useCallback(
    (data, isSelected) => () => setTechnician(isSelected ? null : data),
    [setTechnician],
  );

  const handleManagersClick = useCallback(
    (data, isSelected) => () => {
      const projectManagerObj = isSelected ? null : data;
      setManager(projectManagerObj);
      validateField('manager', {
        projectManager: projectManagerObj,
        clientConfig,
      });
    },
    [setManager],
  );

  const handleSuppliersClick = useCallback(
    (data, isSelected) => () => {
      const suppliers = isSelected
        ? projectSuppliers.filter(({ _id }) => _id !== data._id)
        : [...projectSuppliers, data];
      setSuppliers(suppliers);
    },
    [setSuppliers],
  );

  const isProjectAssigned = filterByStatus === 'assign';

  return {
    loading,
    projectManager,
    projectSuppliers,
    filterByStatus,
    projectTechnician,
    startDate,
    endDate,
    isNever,
    repeatNum,
    repeatEvery,
    activeTab,
    selectedProperty,
    selectedService,
    repeatTime,
    repeats,
    setLoading,
    setManager,
    setSuppliers,
    selectedStatus,
    setFilterByStatus,
    setTechnician,
    setEndDate,
    setNever,
    selectRepeatNum,
    setRepeatEvery,
    setActiveTab,
    selectProperty,
    selectService,
    selectRepeatTime,
    setStartDate,
    selectStatus,
    handleTechniciansClick,
    handleManagersClick,
    handleSuppliersClick,
    isProjectAssigned,
    errors,
    setError,
    onChange,
    validate,
    weekDay: initialFormData.weekDay,
    invoiceDescription: initialFormData.invoiceDescription,
    description: initialFormData.description,
    location: initialFormData.location,
  };
};

export const useMasterWOCommonHandlers = () => {
  const [searchTerm, setSearchTermState] = useState('');
  const [search, setSearchState] = useState('');
  const [searchService, setSearchServiceState] = useState('');

  const dispatch = useDispatch();

  const setSearch = useCallback(
    (searchValue) => setSearchTermState(searchValue),
    [setSearchTermState],
  );

  const setPropertySearch = useCallback(
    (propertyValue) => setSearchState(propertyValue),
    [setSearchState],
  );

  const setServiceSearch = useCallback(
    (serviceValue) => setSearchServiceState(serviceValue),
    [setSearchServiceState],
  );

  const debouncedSearch = useCallback(debounce(300)(setSearch), []);

  const onInputChange = ({ target: { value } }) =>
    debouncedSearch(value.trim());

  const closeModal = useCallback(
    () => dispatch(setEditProjectModal(null)),
    [dispatch, setEditProjectModal],
  );

  return {
    searchTerm,
    search,
    searchService,
    setSearch,
    setPropertySearch,
    setServiceSearch,
    onInputChange,
    closeModal,
  };
};
