import types from '../types.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import { ACTIVE } from '../../constants/properties.js';
import { PROPERTIES } from '../../constants/routes.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  typeFilter: ACTIVE,
  activeTab: 'projects',
  tableSearchString: '',
  modals: {
    sidebar: null,
    people: null,
  },
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_PROPERTIES_FILTER: {
      return {
        ...state,
        typeFilter: action.payload,
      };
    }
    case types.SET_PROPERTY_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case types.SET_PROPERTY_SIDEBAR: {
      return {
        ...state,
        modals: {
          ...state.modals,
          sidebar: action.payload,
        },
      };
    }
    case PROPERTIES: {
      return {
        ...state,
        tableSearchString: '',
      };
    }
    case types.SET_PROPERTIES_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_PROPERTIES_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_PROPERTIES_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_PROPERTIES_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_PROPERTIES_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    case types.SET_PROPERTY_PEOPLE_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          people: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
