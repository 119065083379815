import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import { boolToYesNo, highlightTextToReactElement } from 'poly-client-utils';
import { Grid, L, S } from 'poly-book';
import { PurchaseOrderStatus } from 'poly-constants';

import { EntityStatusFormatter } from 'poly-site-ui';
import { PURCHASE_ORDER } from '../../constants/routes.js';
import { getGlCodeOptionsByPurchaseOrder } from '../../containers/purchase-order/tabs/gl-codes-utils.js';
import { invoiceLinkFormatter } from '../invoices/index.js';
import { fieldFormatter, isExist } from '../../util/general.js';
import { highlightProject } from '../highlight/index.js';
import { poStatusUIData } from './pos-utils.js';

const InvertedCell = styled(Grid.Cell)`
  justify-content: end;
`;
const A = L.withComponent('span');
export const Linked = styled(A)`
  cursor: pointer;
`;

export const POFormatter = (search, getStatus) =>
  function (props) {
    return (
      <div>
        <Link
          to={{ type: PURCHASE_ORDER, payload: { id: R.prop('_id', props) } }}
        >
          <A bold>
            {isExist(search)
              ? highlightTextToReactElement(search, R.prop('cardNumber', props))
              : R.prop('cardNumber', props)}
          </A>
        </Link>
        {!!getStatus && <S textColor="#ff8d02">{getStatus(props) || ''}</S>}
      </div>
    );
  };

// getPOParamsForLinkFormatter :: PurchaseOrder -> POLinkFormatterParams
// POLinkFormatterParams = {
//    amount: Float
//    status: String
//    supplierId: ID
//    project: String
//    cardNumber: String
//    invoices: [Invoice]
//    purchaseOrderId: ID
//    glCodes: [GlCodeOption]
// }
const getPOParamsForLinkFormatter = R.applySpec({
  amount: R.propOr(0, 'amount'),
  status: R.propOr('', 'status'),
  purchaseOrderId: R.prop('_id'),
  invoices: R.propOr([], 'invoices'),
  cardNumber: R.propOr('', 'cardNumber'),
  glCodes: getGlCodeOptionsByPurchaseOrder,
  supplierId: R.pathOr('', ['supplier', '_id']),
  project: R.pathOr('', ['project', 'projectId']),
});

export const projectInvoiceLinkFormatter = (dispatch, action) =>
  function (props) {
    const {
      amount,
      status,
      glCodes,
      project,
      invoices,
      cardNumber,
      supplierId,
      purchaseOrderId,
    } = getPOParamsForLinkFormatter(props);

    switch (status) {
      case PurchaseOrderStatus.APPROVED:
        return R.isEmpty(invoices) ? (
          <Linked
            bold
            textColor="#ff8c00"
            onClick={() =>
              dispatch(
                action({
                  createInvoice: {
                    amount,
                    project,
                    glCodes,
                    cardNumber,
                    supplierId,
                    purchaseOrderId,
                    balance: amount,
                  },
                }),
              )
            }
          >
            ATTACH
          </Linked>
        ) : (
          invoiceLinkFormatter(invoices[0])
        );
      case PurchaseOrderStatus.PENDING_APPROVAL:
      default:
        return R.isEmpty(invoices) ? (
          <S type="content" bold>
            —
          </S>
        ) : (
          invoiceLinkFormatter(invoices[0])
        );
    }
  };

export const scopeFormatter = (search) => (scope) =>
  scope ? fieldFormatter('In', search) : fieldFormatter('Out', search);

export const projectPOFormatter = (search) => (project) => {
  if (R.isNil(project)) {
    return fieldFormatter('Non WO', search);
  }
  return highlightProject(search)(project);
};

export const lineStatusFormatter = (status) => status.split('_').join(' ');

/**
 * isInScopeFormatter :: Boolean -> String
 */
export const isInScopeFormatter = boolToYesNo;

export const propertySelector = ({ project, property }) =>
  R.isNil(project)
    ? R.prop('name', property)
    : R.path(['property', 'name'], project);

export function InvertedRow(props) {
  return (
    <Grid.Row>
      <Grid.Cell>
        <S type="title" textColor="#5d688c">
          {R.prop('title', props)}
        </S>
      </Grid.Cell>
      <InvertedCell>
        <S type="content" bold>
          {R.prop('value', props) || '—'}
        </S>
      </InvertedCell>
    </Grid.Row>
  );
}

export const highlightPOStatus = (search) => (status) =>
  EntityStatusFormatter(
    R.pipe(poStatusUIData, R.prop('color'))(status),
    lineStatusFormatter(status),
    search,
  );
