import { gql } from '@apollo/client';

export const createInvoice = gql`
  mutation createPOInvoice($input: POInvoiceCreateInput!) {
    createPOInvoice(input: $input) {
      poInvoice {
        _id
      }
    }
  }
`;
