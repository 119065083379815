import * as R from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  UPDATE_USER_PERMISSION,
  USER_GROUP_ID_AIT,
  CLIENT_ID_AIT,
  FLIPPED_CONTAINS_AIT_OPERATOR,
} from 'poly-security';
import {
  hasUserPermissionsWithAits,
  prepareAccessItemsForUserByTypes,
} from 'poly-client-utils/src/hooks/useHasUserAccessWithPermission.js';

import { StaffSidebar } from '../../components/staff/staff-sidebar.js';
import { getUserCellPhone, getUserWorkPhone } from '../../utils/staff/index.js';
import { useUserQuery } from '../../hooks/staff.js';
import {
  setStaffSidebarActiveTab as setStaffSidebarActiveTabAction,
  setStaffModal as setStaffModalAction,
} from '../../redux/actions/index.js';

// getFirstUserGroupId :: User -> ID
const getFirstUserGroupId = R.path(['userGroups', 0, 'userGroup', '_id']);

// transformUserFields :: User -> Object
const transformUserFields = (userValue) => ({
  ...R.pick(
    [
      '_id',
      'email',
      'phone',
      'activeProjectsCounts',
      'overdueProjectsCounts',
      'lastThirtyProjectsCounts',
      'status',
      'fullName',
      'firstName',
      'lastName',
      'technicianSkills',
      'isSignedUp',
    ],
    userValue,
  ),
  userGroupId: getFirstUserGroupId(userValue),
  officePhone: getUserWorkPhone(userValue),
  mobile: getUserCellPhone(userValue),
});

// getUserUserGroupIds :: User -> [ID]
const getUserUserGroupIds = R.compose(
  R.map(R.path(['userGroup', '_id'])),
  R.propOr([], 'userGroups'),
);

const UserStaffSidebar = React.memo(() => {
  const dispatch = useDispatch();
  const staff = useSelector((state) => state.staff.modals.sidebar);
  const [hasEditUserPermission, setHasEditUserPermission] = useState(false);

  const { userId, tabs } = staff;

  const { user, loading } = useUserQuery(userId);

  const activeUser = useSelector((state) => state.user);

  const openModal = useCallback(
    () =>
      dispatch(
        setStaffModalAction({
          type: 'edit',
          user: transformUserFields(user),
        }),
      ),
    [setStaffModalAction, activeUser, user],
  );

  const setSidebarActiveTab = useCallback(
    (tab) => () => dispatch(setStaffSidebarActiveTabAction(tab)),
    [setStaffSidebarActiveTabAction, dispatch],
  );

  const selectedUser = transformUserFields(user);

  useEffect(() => {
    const ait = {
      [CLIENT_ID_AIT]: activeUser?.clientId,
      [USER_GROUP_ID_AIT]: {
        [FLIPPED_CONTAINS_AIT_OPERATOR]: getUserUserGroupIds(user),
      },
    };

    const preparedActiveUser =
      prepareAccessItemsForUserByTypes(ait)(activeUser);

    setHasEditUserPermission(
      hasUserPermissionsWithAits(
        preparedActiveUser,
        [UPDATE_USER_PERMISSION],
        ait,
      ),
    );
  }, [activeUser, user]);

  if (!userId) return null;

  return (
    <StaffSidebar
      loading={loading}
      openModal={openModal}
      setSidebarActiveTab={setSidebarActiveTab}
      activeTab={tabs.activeTab}
      tabsNames={tabs.tabsNames}
      selectedUser={selectedUser}
      isAllowedToEdit={hasEditUserPermission}
    />
  );
});

export default UserStaffSidebar;
