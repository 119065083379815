import { gql } from '@apollo/client';

import { defaultProjectFragment } from '../fragments.js';

export const fullProjectFragment = gql`
  fragment fullProjectFragment on Project {
    ...defaultProjectFragment
    projectId
    type
    updatedAt
    parent {
      _id
      projectId
    }
  }

  ${defaultProjectFragment}
`;

export const projectQuery = gql`
  query getProject($id: ID) {
    project(projectId: $id) {
      ...fullProjectFragment
    }
  }

  ${fullProjectFragment}
`;
