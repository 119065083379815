import { ALERTS } from 'poly-site-ui';
import * as R from 'ramda';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
} from 'poly-client-utils';
import { toast } from 'react-toastify';
import { formatDateIfExists } from '../../util/general.js';

const COLUMN_WIDTH = [15, 25, 20, 15, 15, 25, 30];

const TITLE_ROW = [
  'WO #',
  'Description',
  'Equipment Type',
  'Due Date',
  'Completion Date',
  'Technician',
  'Property',
];

// getCompletedPMRowConfig :: Project -> [ExcelExportDataCell]
const getCompletedPMRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 7),
  ]),
  R.juxt([
    R.propOr('', 'projectId'),
    R.propOr('', 'description'),
    R.pathOr('', ['assets', 0, 'equipmentType']),
    R.compose(formatDateIfExists, R.prop('endDate')),
    R.compose(formatDateIfExists, R.prop('workCompletionDate')),
    R.pathOr('', ['technicians', 0, 'fullName']),
    R.pathOr('', ['property', 'name']),
  ]),
);

// getCompletedPMExcelConfig :: [Project] -> Object
export const getCompletedPMExcelConfig = (projects) => ({
  exportFileName: 'completed_pm_export.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 7)],
      TITLE_ROW,
    ),
    ...R.map(getCompletedPMRowConfig, projects),
  ],
});

export const exportCompletedPMToXLS = R.compose(
  performExcelExport,
  getCompletedPMExcelConfig,
  R.path(['data', 'searchProjects', 'hits']),
);

// getCompletedWOStatsRow :: PMTStats -> [[ExcelExportDataCell]]
// PMTStats = Object
const getExportCompletedPMTStatsRow = R.compose(
  R.map(
    R.compose(
      R.zipWith(createExcelExportCell, [
        ...R.repeat(ExcelExportCellType.default, 2),
      ]),
      R.juxt([R.propOr('', 'name'), R.propOr('', 'total')]),
    ),
  ),
  R.prop('rows'),
);

// getHeaderCompletedPMTStats :: String -> [ExcelExportDataCell]
const getHeaderCompletedPMTStats = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.tableHeader, 2),
  ]),
  R.map(R.toUpper),
  R.prop('headers'),
);

// getExportCompletedPMTStatsConfig :: (CorrectivesStats, PmsStats) -> ExcelExportConfig
// ExcelExportConfig = Object
// CorrectivesStats = Object
// PmsStats = Object
const getExportCompletedPMTStatsConfig = (correctives, pms) => ({
  exportFileName: 'completed_pm_stats_export.xlsx',
  columnWidths: [35, 25],
  rows: [
    getHeaderCompletedPMTStats(pms),
    ...getExportCompletedPMTStatsRow(pms),
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.default, 2)],
      [...R.repeat('', 2)],
    ),
    getHeaderCompletedPMTStats(correctives),
    ...getExportCompletedPMTStatsRow(correctives),
  ],
});

// exportCompletedPMTStatsToXLS :: TableProps -> Boolean
// TableProps -> Object
export const exportCompletedPMTStatsToXLS = ({ correctives, pms }) => {
  if (!correctives || !pms) {
    toast.error(ALERTS.NoDataToDisplay);
    return false;
  }
  const exportConfig = getExportCompletedPMTStatsConfig(correctives, pms);
  performExcelExport(exportConfig);
  return true;
};
