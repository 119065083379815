import { handleActions } from 'redux-actions';

import { PO_LOG } from '../../constants/routes.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import {
  setPOLogPaginationCurrentPage,
  setPOLogPaginationDropdownLabel,
  setPOLogPaginationPageSize,
  setPOLogPaginationTotal,
  setPOLogTableSearchString,
} from '../actions/index.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  tableSearchString: '',
};

export default handleActions(
  {
    [PO_LOG]: (state) => ({ ...state, tableSearchString: '' }),
    [setPOLogTableSearchString]: (state, { payload }) => ({
      ...state,
      tableSearchString: payload,
    }),
    [setPOLogPaginationTotal]: (state, { payload }) => ({
      ...state,
      total: payload,
    }),
    [setPOLogPaginationCurrentPage]: (state, { payload }) => ({
      ...state,
      currentPage: payload,
    }),
    [setPOLogPaginationPageSize]: (state, { payload }) => ({
      ...state,
      pageSize: payload,
    }),
    [setPOLogPaginationDropdownLabel]: (state, { payload }) => ({
      ...state,
      paginationDropdownLabel: payload,
    }),
  },
  defaultState,
);
