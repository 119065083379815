import { gql } from '@apollo/client';

export const doesProjectExist = gql`
  query doesProjectExist($id: ID) {
    project(projectId: $id) {
      _id
      type
    }
  }
`;

export const doesRecurringProjectExist = gql`
  query doesRecurringProjectExist($id: ID) {
    recurringProject(projectId: $id) {
      _id
    }
  }
`;
