import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs } from 'poly-book';

import { setProjectActiveTab } from '../../redux/actions/index.js';

const tabs = [
  { title: 'Updates', value: 'updates' },
  { title: 'Job Costs', value: 'costs' },
  { title: 'Assets', value: 'assets' },
  { title: 'Files', value: 'files' },
];

function ProjectNavigation({ activeTab, dispatch }) {
  return (
    <Tabs>
      <Tabs.Items>
        {tabs.map(({ value, title }) => (
          <Tabs.Item
            bold
            key={value}
            href={`#${value}`}
            active={value === activeTab}
            onClick={() => dispatch(setProjectActiveTab(value))}
          >
            {title}
          </Tabs.Item>
        ))}
      </Tabs.Items>
    </Tabs>
  );
}

ProjectNavigation.propTypes = {
  activeTab: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default function (props) {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.projects.activeTab);

  return (
    <ProjectNavigation {...props} activeTab={activeTab} dispatch={dispatch} />
  );
}
