import { gql } from '@apollo/client';

export const assetDetailsFields = gql`
  fragment assetDetailsFields on Asset {
    _id
    status
    description
    manufacturer
    model
    serial
    location
    room
    startUp
    equipmentType
    property {
      _id
      name
    }
  }
`;
