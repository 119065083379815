import React from 'react';
import PropTypes from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { UPDATE_ASSET_PERMISSION } from 'poly-security';
import { Button, S } from 'poly-book';

import ButtonLoader from '../loader/button.js';

export function EditAssetButton({ openEditAssetModal, loading }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_ASSET_PERMISSION,
  );
  return (
    ifHasPermission && (
      <Button onClick={openEditAssetModal} disabled={loading}>
        {loading && <ButtonLoader />}
        <S type="title">Edit Asset</S>
      </Button>
    )
  );
}

EditAssetButton.displayName = 'EditAssetButton';
EditAssetButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  openEditAssetModal: PropTypes.func.isRequired,
};
