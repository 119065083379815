import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Conversation, Grid, L, S, Stars } from 'poly-book';
import { supplierRatingShape } from 'poly-client-utils';

import { noDataToDisplay } from '../../../../util/general.js';
import { href, Loader, renderButton } from './common.js';

function Supplier({
  _id,
  name,
  email,
  hover,
  rating,
  selected,
  setHover,
  onButtonClick,
}) {
  const onClick = onButtonClick({ _id, name, email }, !!selected);

  return (
    <Grid.Row>
      <Grid.Cell vertical="center">
        <L
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          {...{ href, onClick }}
          bold
        >
          {name}
        </L>
      </Grid.Cell>
      <Grid.Cell vertical="center">
        <S type="content" bold>
          {email || '—'}
        </S>
      </Grid.Cell>
      <Grid.Cell vertical="center">
        {rating && <Stars count={rating.average} />}
      </Grid.Cell>
      <Grid.Cell vertical="center">
        {renderButton(selected, onClick, hover)}
      </Grid.Cell>
    </Grid.Row>
  );
}

Supplier.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  selected: PropTypes.bool,
  rating: supplierRatingShape,
  _id: PropTypes.string.isRequired,
  hover: PropTypes.bool.isRequired,
  setHover: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

Supplier.defaultProps = {
  email: '—',
  name: null,
  rating: null,
  selected: false,
};

function SupplierContainer(props) {
  const [hover, setHover] = useState(false);

  return <Supplier {...props} hover={hover} setHover={setHover} />;
}

function SuppliersTab({
  projectSuppliers,
  allSuppliers,
  handleSuppliersClick,
  addLoading,
}) {
  return (
    <>
      {addLoading && <Loader />}
      {!!projectSuppliers.length && (
        <>
          <Conversation.Item badge>
            <S type="badge" uppercase>
              Assigned To
            </S>
          </Conversation.Item>
          <Conversation.Item>
            <Grid columns="1fr 1fr auto 36px" simple>
              {projectSuppliers.map((supplier) => (
                <SupplierContainer
                  {...supplier}
                  key={supplier._id}
                  onButtonClick={handleSuppliersClick}
                  selected
                />
              ))}
            </Grid>
          </Conversation.Item>
        </>
      )}
      <Conversation.Item badge>
        <S type="badge" uppercase>
          Available
        </S>
      </Conversation.Item>
      <Conversation.Item>
        {allSuppliers.length > 0 ? (
          <Grid columns="1fr 1fr auto 36px" simple>
            {allSuppliers.map((supplier) => (
              <SupplierContainer
                {...supplier}
                key={supplier._id}
                onButtonClick={handleSuppliersClick}
              />
            ))}
          </Grid>
        ) : (
          noDataToDisplay('Data')()
        )}
      </Conversation.Item>
    </>
  );
}

SuppliersTab.propTypes = {
  allSuppliers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  projectSuppliers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string,
      rating: PropTypes.shape({ start: PropTypes.arrayOf(PropTypes.number) }),
    }),
  ),
  handleSuppliersClick: PropTypes.func.isRequired,
  addLoading: PropTypes.bool,
};

SuppliersTab.defaultProps = {
  allSuppliers: [],
  projectSuppliers: [],
  addLoading: false,
};

export default SuppliersTab;
