import { ALERTS as ALERTS_BASE } from 'poly-site-ui';

const ALERTS = {
  AddProjectSuccess: 'Work Order Successfully Created',
  AddReportSuccess: 'Master Recurring Work Order Successfully Created',
  EditReportSuccess: 'Master Recurring Work Order Successfully Updated',
  EditProjectSuccess: 'Work Order Edits Successfully Saved',
  AddResponseTeamSuccess: 'Response Team Successfully Added',
  EditResponseTeamSuccess: 'Response Team Successfully Edited',
  AddTechnicianTimeSuccess: 'Technician Time Successfully Added',
  EditTechnicianTimeSuccess: 'Technician Time Successfully Edited',
  DeleteTechnicianTimeSuccess: 'Technician Time Successfully Deleted',
  AddMaterialSuccess: 'Material Successfully Added',
  EditMaterialSuccess: 'Material Successfully Edited',
  DeleteMaterialSuccess: 'Material Successfully Deleted',
  EditFileSuccess: 'File Name Edit Successfully Saved',
  DeleteFileSuccess: 'File Successfully Deleted',
  AddNonWOPOSuccess: 'Non Work Order PO Request Successfully Submitted',
  AddWOPOSuccess: 'Work Order PO Request Successfully Submitted',
  EditNonWOPOSuccess: 'Non Work Order PO Request Edits Successfully Saved',
  EditWOPOSuccess: 'Work Order PO Request Edits Successfully Saved',
  ApprovePOSuccess: (id) => `You successfully approved ${id}`,
  SendPOConfirmationSuccess: 'Purchase Order Confirmation successfully send',
  ApproveSeveralPOSuccess: (number) =>
    `You successfully approved ${number} PO Requests`,
  ClosePOSuccess: (id) => `You Successfully Closed ${id}`,
  AddSupplierSuccess: 'Supplier Successfully Added',
  EditSupplierSuccess: 'Supplier Edits Successfully Saved',
  AddPeopleSuccess: 'Contact Successfully Added',
  EditPeopleSuccess: 'Contact Edits Successfully Saved',
  AddStaffSuccess: 'Staff Successfully Added',
  EditStaffEnrollmentResent: 'New user email setup successfully sent',
  EditStaffSuccess: 'Staff Edits Successfully Saved',
  AddInvoiceSuccess: 'Invoice Successfully Created',
  EditInvoiceSuccess: 'Invoice Edits Successfully Saved',
  BlockInvoiceSuccess: (id) => `You Successfully Blocked Invoice ${id}`,
  ApproveSeveralInvoicesSuccess: (number) =>
    `You Successfully Approved ${number} Invoices`,
  PostSeveralInvoicesSuccess: (number) =>
    `You Successfully Posted ${number} Invoices`,
  AddAssetToProjectSuccess: 'Asset Successfully Added to Project',
  RemoveAssetFromProjectSuccess: 'Asset Successfully Removed from Project',
  GeneralError: ALERTS_BASE.GeneralError,
  InvoiceNumberError: 'Invoice number already in system',
  ApproveInvoiceError: 'You are not authorized to approve invoices',
  PostInvoiceError: 'You are not authorized to post invoices',
  EditStaffError: 'You do not have permission to edit staff',
  SupplierNameError: (name) => `Supplier "${name}" already exists`,
  EditPOError: 'You do not have permission to edit PO',
  AddressFormatError: 'Incorrect address format',
  EditAssetSuccess: 'Asset Successfully Updated',
  CreateAssetSuccess: 'Asset Successfully Created',
};

// eslint-disable-next-line import/no-unused-modules
export const {
  AddProjectSuccess,
  AddReportSuccess,
  EditReportSuccess,
  EditProjectSuccess,
  AddResponseTeamSuccess,
  EditResponseTeamSuccess,
  AddTechnicianTimeSuccess,
  EditTechnicianTimeSuccess,
  DeleteTechnicianTimeSuccess,
  AddMaterialSuccess,
  EditMaterialSuccess,
  DeleteMaterialSuccess,
  AddFileSuccess,
  EditFileSuccess,
  DeleteFileSuccess,
  AddNonWOPOSuccess,
  AddWOPOSuccess,
  EditNonWOPOSuccess,
  EditWOPOSuccess,
  ApprovePOSuccess,
  SendPOConfirmationSuccess,
  ApproveSeveralPOSuccess,
  ClosePOSuccess,
  AddSupplierSuccess,
  EditSupplierSuccess,
  AddPeopleSuccess,
  EditPeopleSuccess,
  AddStaffSuccess,
  EditStaffSuccess,
  EditStaffEnrollmentResent,
  AddInvoiceSuccess,
  EditInvoiceSuccess,
  BlockInvoiceSuccess,
  ApproveSeveralInvoicesSuccess,
  PostSeveralInvoicesSuccess,
  AddAssetToProjectSuccess,
  RemoveAssetFromProjectSuccess,
  GeneralError,
  InvoiceNumberError,
  ApproveInvoiceError,
  PostInvoiceError,
  EditStaffError,
  SupplierNameError,
  EditPOError,
  AddressFormatError,
  EditAssetSuccess,
  CreateAssetSuccess,
} = ALERTS;
