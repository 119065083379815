import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChangePasswordPageBase } from 'poly-common-ui';
import { routeForgotPassword, routeMain } from '../../redux/actions/index.js';
import logIn from '../../redux/thunks/logIn.js';
import { APP_TITLE } from '../Login/LoginPage.js';

export function ChangePasswordPage() {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.location.payload);

  const onSuccess = () => {
    dispatch(logIn());
    dispatch(routeMain());
  };

  const navigateToForgotPassword = () => dispatch(routeForgotPassword());

  return (
    <ChangePasswordPageBase
      onSuccess={onSuccess}
      token={token}
      title={APP_TITLE}
      navigateToForgotPassword={navigateToForgotPassword}
    />
  );
}
