import React from 'react';
import { Layout } from 'poly-book';

import AuthContainer from '../hooks/auth-container.js';
import PropertiesFilter from '../containers/properties/filter.js';
import Navigation from '../containers/navbar/navigation-container.js';
import PropertiesPageLayout from '../layouts/properties-page-layout.js';
import { LayoutContent } from '../styled.js';

function Properties() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <PropertiesFilter />
        </Layout.Header>
        <LayoutContent>
          <PropertiesPageLayout />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default Properties;
