export const useRefHandlers = () => {
  let newRef;

  const setRef = (innerRef) => {
    newRef = innerRef;
  };
  const getRef = () => newRef;

  return {
    setRef,
    getRef,
  };
};
