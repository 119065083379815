import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PeopleModal from '../../../../components/contact/add-edit-modal.js';
import {
  setPropertyPeopleModal,
  setPropertySidebar,
} from '../../../../redux/actions/index.js';
import { usePeopleEnhancer } from '../../../contact/modal-enhancer.js';

export default function (props) {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.properties.modals.people);
  const linkId = useSelector((state) => state.location.payload.id);

  const isAdd = modal?.type === 'add';

  const selectedUser = useSelector((state) => state.properties.modals.sidebar);
  const defaultAddress = R.pathOr('', ['data', 'defaultAddress'], modal);

  const onModalClose = useCallback(
    () => dispatch(setPropertyPeopleModal(null)),
    [dispatch, setPropertyPeopleModal],
  );

  const onComplete = useCallback(
    (user) => dispatch(setPropertySidebar(user)),
    [dispatch, setPropertySidebar],
  );

  const additionalProps = usePeopleEnhancer({
    modal,
    selectedUser,
    onModalClose,
    onComplete,
    defaultAddress,
    isAdd,
    linkId,
    linkProp: 'propertyId',
  });

  return modal ? <PeopleModal {...props} {...additionalProps} /> : null;
}
