import * as R from 'ramda';
import {
  performExcelExport,
  createExcelExportCell,
  ExcelExportCellType,
} from 'poly-client-utils';
import { forceTitleCase } from 'poly-utils';
import { formatDateIfExists } from '../../util/general.js';

const COLUMN_WIDTH = [15, 20, 30, 15, 15, 10];

const TITLE_ROW = [
  'WO #',
  'WO Type',
  'Description',
  'Assigned To',
  'Start',
  'End',
  'Status',
];

// getMasterRecurrentPMRowConfig :: Plan -> [ExcelExportDataCell]
const getMasterRecurrentPMRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 7),
  ]),
  R.juxt([
    R.propOr('', 'maintenancePlanId'),
    R.always('Master Recurring PM'),
    R.propOr('', 'description'),
    R.always('PM Team'),
    R.compose(formatDateIfExists, R.prop('startDate')),
    R.compose(formatDateIfExists, R.prop('endDate')),
    R.compose(forceTitleCase, R.propOr('', 'status')),
  ]),
);

// getMasterRecurrentPMExcelConfig :: [Plans] -> Object
export const getMasterRecurrentPMExcelConfig = (plans) => ({
  exportFileName: 'master_recurrent_pm_export.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 7)],
      TITLE_ROW,
    ),
    ...R.map(getMasterRecurrentPMRowConfig, plans),
  ],
});

export const exportMasterRecurrentPMToXLS = R.compose(
  performExcelExport,
  getMasterRecurrentPMExcelConfig,
  R.pathOr([], ['data', 'searchMaintenancePlans', 'hits']),
);
