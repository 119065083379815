import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useUpdateProjects } from '../../../hooks/projects.js';
import { useTechnicians } from '../../../hooks/staff.js';
import TechniciansTab from '../../../components/project/details/response-team/technicians-tab.js';
import {
  setPmProjectsTechnicianModal,
  setSelectedPmProjects,
} from '../../../redux/actions/index.js';
import { DropDownLoader } from '../../../util/dropdown.js';
import {
  AddResponseTeamSuccess,
  EditResponseTeamSuccess,
  GeneralError,
} from '../../../constants/alerts.js';
import { prepareTechniciansWithoutSelected } from './prepareTechniciansWithoutSelected.js';

function Technicians(props) {
  const dispatch = useDispatch();

  const selectedProjects = useSelector(
    (state) => state.PMProjects.selectedProjects,
  );

  const clientId = useSelector((state) => state.user.clientId);

  const [selectedTechnicianState, setSelectedTechnicianState] = useState(null);
  const [addLoadingState, setAddLoadingState] = useState(false);

  const selectTechnician = (technician) =>
    setSelectedTechnicianState(technician);

  const setLoading = (loading) => setAddLoadingState(loading);

  const { loading, technicians } = useTechnicians(props.searchTerm, clientId);

  const { updateProjects } = useUpdateProjects();

  const allTechnicians = prepareTechniciansWithoutSelected(
    technicians,
    selectedTechnicianState,
  );

  const handleTechniciansClick = (data, isSelected) => () => {
    setLoading(true);

    const techniciansValue = isSelected ? [] : [data._id];

    updateProjects(selectedProjects, { technicians: techniciansValue })
      .then(() => {
        setLoading(false);
        dispatch(setPmProjectsTechnicianModal(null));
        dispatch(setSelectedPmProjects([]));
        toast.success(
          isSelected ? EditResponseTeamSuccess : AddResponseTeamSuccess,
        );
      })
      .catch(() => {
        setLoading(false);
        toast.error(GeneralError);
      });

    selectTechnician(isSelected ? null : data);
  };

  if (loading) return <DropDownLoader />;

  return (
    <TechniciansTab
      {...props}
      selectedProjects={selectedProjects}
      clientId={clientId}
      addLoading={addLoadingState}
      technicians={technicians}
      selectedTechnician={selectedTechnicianState}
      projectTechnician={selectedTechnicianState}
      allTechnicians={allTechnicians}
      handleTechniciansClick={handleTechniciansClick}
      selectTechnician={selectTechnician}
    />
  );
}

Technicians.propTypes = {
  searchTerm: PropTypes.string,
};

export default Technicians;
