import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  S,
  Button,
  Heading,
  Holder,
  Icon,
  IconButton,
  Modal,
} from 'poly-book';
import { DatePicker } from 'poly-site-ui';

import Select from '../../select/index.js';
import ServerError from '../../server-error/index.js';
import ButtonLoader from '../../loader/button.js';
import { formatPlaceholder } from '../../../util/select.js';
import { ValidationMessage } from '../../../styled.js';
import { Title } from '../../../util/forms/index.js';

const ModalGrid = Modal.Item.withComponent(Grid);

const generateTime = ({ from = 0, to, step }) => {
  const items = [];

  for (let item = from; item <= to; item += step) {
    items.push({
      value: item,
      label: item,
    });
  }

  return items;
};

function Technician({ label }) {
  return (
    <S type="title" title={label}>
      {label}
    </S>
  );
}

Technician.propTypes = {
  label: PropTypes.string.isRequired,
};

function Time({ label }) {
  return (
    <S type="header" textColor="#5e6271">
      {label}
    </S>
  );
}

Time.propTypes = {
  label: PropTypes.string.isRequired,
};

const getPlaceholder = (text) => (
  <S type="badge" textColor="#babfd2">
    {text}
  </S>
);

function AddTime({
  activeProjectId,
  selectedTechnician,
  technicians,
  startDate,
  minutes,
  hours,
  isAdd,
  onTechnicianChange,
  onStartDateChange,
  onHoursChange,
  onMinutesChange,
  closeModal,
  submitTimeLog,
  loading,
  errors,
  technicianLoading,
  setTechnicianSearch,
}) {
  return (
    <Modal show>
      <Modal.Item>
        <Heading>
          <Heading.h5>{activeProjectId}</Heading.h5>
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <Modal.Item>
        <Modal.Content>
          <Heading.h3 lighter>
            {isAdd ? 'Add' : 'Edit'} Technician Time
          </Heading.h3>
        </Modal.Content>
      </Modal.Item>
      <ServerError error={R.prop('server', errors)} style={{ maxWidth: 430 }} />
      <ModalGrid columns="100px minmax(200px, 320px)" margin={30} simple>
        <Grid.Row>
          <Grid.Cell vertical="center">
            <Title type="title" required>
              Technician
            </Title>
          </Grid.Cell>
          <Grid.Cell horizontal="stretch">
            <Select
              options={technicians}
              onChange={onTechnicianChange}
              onInputChange={setTechnicianSearch}
              isLoading={technicianLoading}
              value={R.prop('value', selectedTechnician)}
              placeholder={formatPlaceholder('Technician')}
              optionRenderer={Technician}
              invalid={!!R.prop('technician', errors)}
              autoFocus
            />
          </Grid.Cell>
          <ValidationMessage error={R.prop('technician', errors)} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Cell vertical="center">
            <Title type="title" required>
              Start Date
            </Title>
          </Grid.Cell>
          <Grid.Cell horizontal="stretch">
            <DatePicker
              dateFormat="MM-dd-yyyy"
              onChange={onStartDateChange}
              selected={startDate}
              invalid={!!R.prop('startDate', errors)}
              required
            />
          </Grid.Cell>
          <ValidationMessage error={R.prop('startDate', errors)} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Cell vertical="center">
            <Title type="title" required>
              Time
            </Title>
          </Grid.Cell>
          <Grid.Cell horizontal="stretch">
            <Holder margin={15}>
              <Select
                value={hours}
                options={generateTime({ to: 12, step: 1 })}
                onChange={onHoursChange}
                placeholder={getPlaceholder('HH')}
                invalid={!!R.prop('time', errors)}
                optionRenderer={Time}
                style={{ minWidth: 150 }}
                clearable={false}
              />
              <Select
                value={minutes}
                options={generateTime({ to: 45, step: 15 })}
                onChange={onMinutesChange}
                placeholder={getPlaceholder('MM')}
                invalid={!!R.prop('time', errors)}
                optionRenderer={Time}
                style={{ minWidth: 150 }}
                clearable={false}
              />
            </Holder>
          </Grid.Cell>
          <ValidationMessage error={R.prop('time', errors)} />
        </Grid.Row>
      </ModalGrid>
      <Modal.Buttons>
        <Button
          type="reset"
          mode="gray"
          onClick={closeModal}
          disabled={loading}
        >
          <S type="title">Cancel</S>
        </Button>
        <Button mode="orange" onClick={submitTimeLog} disabled={loading}>
          {loading && <ButtonLoader />}
          <S type="title">{isAdd ? 'Add Time' : 'Save Edits'}</S>
        </Button>
      </Modal.Buttons>
    </Modal>
  );
}

AddTime.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  minutes: PropTypes.number,
  hours: PropTypes.number,
  errors: PropTypes.shape({
    technician: PropTypes.string,
    startDate: PropTypes.string,
    time: PropTypes.string,
    server: PropTypes.string,
  }),
  activeProjectId: PropTypes.string.isRequired,
  selectedTechnician: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  technicians: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  submitTimeLog: PropTypes.func.isRequired,
  onTechnicianChange: PropTypes.func.isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  onMinutesChange: PropTypes.func.isRequired,
  onHoursChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isAdd: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  technicianLoading: PropTypes.bool.isRequired,
  setTechnicianSearch: PropTypes.func.isRequired,
};

AddTime.defaultProps = {
  selectedTechnician: null,
  startDate: new Date(),
  technicians: [],
  minutes: null,
  hours: null,
  errors: {},
};

export default AddTime;
