import types from '../types.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import { TECHNICIAN_STATS } from '../../constants/routes.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  tableSearchString: '',
  dateFilter: {
    startDate: null,
    endDate: null,
  },
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_TECHNICIAN_STATS_DATE: {
      return {
        ...state,
        dateFilter: action.payload,
      };
    }
    case TECHNICIAN_STATS: {
      return {
        ...state,
        tableSearchString: '',
        dateFilter: {
          startDate: null,
          endDate: null,
        },
      };
    }
    case types.SET_TECHNICIAN_STATS_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_TECHNICIAN_STATS_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_TECHNICIAN_STATS_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_TECHNICIAN_STATS_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_TECHNICIAN_STATS_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
