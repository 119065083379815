import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';

import ProjectsStatsFilter from '../../../components/work-orders-stats/projects-stats-filters.js';
import { getDate } from '../utils.js';
import { eyesWideOpenTypes as eyesWideOpenStats } from '../../../constants/stats.js';
import { setProjectsPaginationCurrentPage } from '../../../redux/actions/index.js';

const titles = {
  [eyesWideOpenStats.TOTAL]: "TOTAL WO'S",
  [eyesWideOpenStats.FACILITIES]: "FACILITIES WO'S",
  [eyesWideOpenStats.CLIENT]: "CLIENT WO'S",
};

export default function (props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProjectsPaginationCurrentPage(1));
  }, []);

  const { type, year, monthIndex } = useSelector(
    (state) => state.location.payload,
  );

  const date = getDate(year, monthIndex);
  const title = titles[type];

  return <ProjectsStatsFilter {...props} date={date} title={title} />;
}
