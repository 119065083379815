import {
  bool,
  shape,
  string,
  number,
  object,
  arrayOf,
  oneOfType,
} from 'prop-types';
import React from 'react';
import { BreadCrumbs, Grid, Heading, Pip, S, Widget } from 'poly-book';
import { PageHeader } from 'poly-site-ui';

import { MASTER_PROJECT_STATUS_COLORS } from '../../constants/report/master-recurring.js';
import { schedulerFormatter } from '../../util/project-scheduler-utils.js';
import { isExist, noDataToDisplay } from '../../util/general.js';
import {
  getTechnicianTypeByUser,
  propertyFormatter,
} from '../../util/project-utils.js';
import {
  SupplierTeamMember,
  UserTeamMember,
  DetailsRow,
} from '../../utils/wrappers.js';

function Content({ children }) {
  return (
    <S type="content" bold>
      {children || '—'}
    </S>
  );
}

Content.propTypes = {
  children: string,
};

Content.defaultProps = {
  children: '—',
};

function RecurringProjectDetails({
  project: {
    status,
    type,
    description,
    details,
    location,
    property,
    client,
    startDate,
    endDate,
    schedule,
    manager,
    technicians,
    suppliers,
  },
}) {
  return (
    <>
      <Widget.Item>
        <PageHeader>
          <Heading.h5>Work Order Detail</Heading.h5>
          <Pip color={MASTER_PROJECT_STATUS_COLORS[status]}>
            <S type="title">{status}</S>
          </Pip>
        </PageHeader>
        <Grid columns="100px 1fr" simple>
          <DetailsRow title="Type" value={type} color="#ff8c00" />
          <DetailsRow title="Description" value={description} />
          <DetailsRow title="Details" value={details} withEditor />
          <DetailsRow title="Location" value={location} />
          <DetailsRow title="Property" value={propertyFormatter(property)} />
          <DetailsRow title="Client" value={client} />
          <Grid.Row>
            <Grid.Cell vertical="center">
              <S type="title">Planning</S>
            </Grid.Cell>
            <Grid.Cell>
              <BreadCrumbs>
                <BreadCrumbs.Item>
                  <Content>{startDate}</Content>
                </BreadCrumbs.Item>
                <BreadCrumbs.Item>
                  <Content>{endDate}</Content>
                </BreadCrumbs.Item>
              </BreadCrumbs>
            </Grid.Cell>
          </Grid.Row>
          <DetailsRow
            title="Repeats"
            value={schedulerFormatter({ ...schedule, startDate })}
          />
        </Grid>
      </Widget.Item>
      <Widget.Item>
        <PageHeader>
          <Heading.h5>Response Team</Heading.h5>
        </PageHeader>
        <Grid columns="repeat(2, minmax(100px, 1fr))" simple>
          {[manager, technicians, suppliers].some(isExist) ? (
            <>
              {isExist(manager) && (
                <UserTeamMember member={manager} type="Manager" />
              )}
              {suppliers.map((supplier) => (
                <SupplierTeamMember
                  member={supplier}
                  key={supplier._id}
                  type="Supplier"
                />
              ))}
              {technicians.map((technician) => (
                <UserTeamMember
                  member={technician}
                  key={technician._id}
                  type={getTechnicianTypeByUser(technician)}
                />
              ))}
            </>
          ) : (
            noDataToDisplay('Members')()
          )}
        </Grid>
      </Widget.Item>
    </>
  );
}

RecurringProjectDetails.propTypes = {
  project: shape({
    status: string,
    type: string,
    description: string,
    details: oneOfType([string, object]),
    location: string,
    property: shape({
      _id: string.isRequired,
      name: string.isRequired,
    }),
    client: string,
    startDate: string,
    endDate: string,
    manager: shape({
      name: string,
      phone: string,
      email: string,
    }),
    schedule: shape({
      repeats: string,
      days: arrayOf(number),
      weekDay: bool,
      every: number,
    }),
    suppliers: arrayOf(
      shape({
        _id: string.isRequired,
      }).isRequired,
    ),
    technicians: arrayOf(
      shape({
        _id: string.isRequired,
      }).isRequired,
    ),
  }).isRequired,
};

export default RecurringProjectDetails;
