import React from 'react';
import { useSelector } from 'react-redux';
import { TableSearchComponent, isDateFilterNil } from 'poly-site-ui';
import {
  setCompletedPMsTableSearchString,
  setCompletedPMsPaginationCurrentPage,
} from '../../redux/actions/index.js';

export default function () {
  const completedPMProjects = useSelector((state) => state.completedPMProjects);
  const { tableSearchString } = completedPMProjects.tableSearchString;
  const disabled = isDateFilterNil(completedPMProjects);

  return (
    <TableSearchComponent
      tableSearchString={tableSearchString}
      disabled={disabled}
      setTableSearchString={setCompletedPMsTableSearchString}
      setPaginationCurrentPage={setCompletedPMsPaginationCurrentPage}
    />
  );
}
