import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Heading, Holder, Button, Title, S } from 'poly-book';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { UPDATE_SUPPLIER_PERMISSION } from 'poly-security';

import BackArrow from '../back-arrow/index.js';
import InfoLoader from '../loader/info.js';
import ButtonLoader from '../loader/button.js';

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

const TitleItem = styled(Title.Item)`
  max-width: 50vw;
  min-width: 270px;
`;

const StyledH1 = styled(Heading.h1)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

function EditSupplierButton({ loading, openEditSupplierModal }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_SUPPLIER_PERMISSION,
  );

  return (
    ifHasPermission && (
      <Button onClick={openEditSupplierModal} disabled={loading}>
        {loading && <ButtonLoader />}
        <S type="title">Edit Supplier</S>
      </Button>
    )
  );
}
EditSupplierButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  openEditSupplierModal: PropTypes.func.isRequired,
};

function ProjectInfo({ supplier, loading, openEditSupplierModal }) {
  return (
    <Header.Sub>
      <HeaderRow>
        <Header.Block>
          <Holder margin={20}>
            <BackArrow />
            {loading ? (
              <InfoLoader />
            ) : (
              <Title>
                <Title.Block>
                  <TitleItem>
                    <StyledH1 lighter>
                      {R.path(['company', 'name'], supplier)}
                    </StyledH1>
                  </TitleItem>
                  <Title.Item>
                    <Heading.h4 lighter>Supplier</Heading.h4>
                  </Title.Item>
                </Title.Block>
              </Title>
            )}
          </Holder>
        </Header.Block>
        <Header.Block>
          <EditSupplierButton {...{ loading, openEditSupplierModal }} />
        </Header.Block>
      </HeaderRow>
    </Header.Sub>
  );
}

ProjectInfo.propTypes = {
  loading: PropTypes.bool.isRequired,
  openEditSupplierModal: PropTypes.func.isRequired,
  supplier: PropTypes.shape({
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

ProjectInfo.defaultProps = {
  supplier: {},
};

export default ProjectInfo;
