import styled from 'styled-components';

const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

List.Item = styled.li`
  &:not(:empty) {
    display: block;
    padding-left: 25px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &::before {
      width: 8px;
      height: 8px;
      display: block;
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      border-radius: 50%;
      background-color: #6f7a9f;
    }
  }
`;

List.displayName = 'List';
List.Item.displayName = 'List.Item';

export default List;
