import types from '../types.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import { ASSETS } from '../../constants/routes.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  tableSearchString: '',
  filter: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case ASSETS: {
      return {
        ...state,
        tableSearchString: '',
      };
    }
    case types.SET_ASSETS_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_ASSETS_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case types.SET_ASSETS_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_ASSETS_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_ASSETS_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_ASSETS_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
