import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { S, Pip } from 'poly-book';

import { STATUS_COLORS } from '../../constants/report/master-pm.js';

export const statusTransform = (status) => {
  const color = STATUS_COLORS[status];

  return <Pip {...{ color }} />;
};

export function Content({ children }) {
  return (
    <S type="content" textColor="#2d2f33">
      {children || '—'}
    </S>
  );
}

Content.propTypes = {
  children: PropTypes.string,
};

Content.defaultProps = {
  children: '—',
};

export const renderList = (items, callback) =>
  items.length > 0 ? items.map(callback) : <Content />;

export const makeListFromString = R.ifElse(
  R.is(String),
  R.compose(R.map(R.trim), R.split('\n', R.__)),
  R.always([]),
);
