import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterComponent from '../../components/date-filter/completed-wos.js';
import {
  setCompletedPMsDate,
  setCompletedPMsActiveTab,
  setCompletedPMsTableSearchString,
  setCompletedPMsPaginationCurrentPage,
} from '../../redux/actions/index.js';

export default function (props) {
  const activeTab = useSelector((state) => state.completedPMProjects.activeTab);

  const dispatch = useDispatch();

  const [startDate, changeStartDate] = useState(null);
  const [endDate, changeEndDate] = useState(null);

  const onPreview = useCallback(() => {
    dispatch(setCompletedPMsDate({ startDate, endDate }));
    dispatch(setCompletedPMsTableSearchString(''));
    dispatch(setCompletedPMsPaginationCurrentPage(1));
  }, [
    dispatch,
    startDate,
    endDate,
    setCompletedPMsDate,
    setCompletedPMsTableSearchString,
    setCompletedPMsPaginationCurrentPage,
  ]);

  const onClick = useCallback(
    (tab) => dispatch(setCompletedPMsActiveTab(tab)),
    [setCompletedPMsActiveTab, dispatch],
  );

  return (
    <FilterComponent
      {...props}
      activeTab={activeTab}
      title="Completed PMs"
      isPM
      onClick={onClick}
      onPreview={onPreview}
      changeStartDate={changeStartDate}
      changeEndDate={changeEndDate}
      startDate={startDate}
      endDate={endDate}
    />
  );
}
