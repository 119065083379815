import { useEntitySubscriptionQuery } from 'poly-client-utils/src/entitySubscription.js';
import * as R from 'ramda';
import { CHILD_PROJECT_ELASTIC_QUERY } from 'poly-client-utils';
import { useQuery } from '@apollo/client';
import {
  prefetchPMProject,
  PROJECT_PM_CHANGED,
  prefetchProject,
  PROJECT_CHANGED,
  prefetchProperty,
  prefetchMasterPmProject,
  prefetchPurchaseOrder,
  prefetchSupplier,
} from '../queries/index.js';
import { MAX_SIZE } from '../constants/index.js';
import { WORK_ORDER } from '../constants/projects.js';

// projectSubscriptionParamsFromProps :: Properties -> Object
const projectSubscriptionParamsFromProps = R.compose(
  R.objOf('id'),
  R.prop('_id'),
);

// projectQueryParamsFromProps :: Properties -> Object
const projectQueryParamsFromProps = R.compose(
  R.objOf('id'),
  R.prop('projectId'),
);

export const usePrefetchPMProject = (props) => {
  const { subscribeToMore, restProps } = useEntitySubscriptionQuery({
    gqlQuery: prefetchPMProject,
    queryEndpointName: 'project',
    gqlSubscription: PROJECT_PM_CHANGED,
    subscriptionEndpointName: 'projectChanged',
    subscriptionParamsFromProps: projectSubscriptionParamsFromProps,
    queryParamsFromProps: projectQueryParamsFromProps,
    propsOfComponent: props,
    alias: 'usePrefetchPMProject',
  });

  return {
    subscribeToMore,
    restProps,
  };
};

export const usePrefetchProject = (props) => {
  const { subscribeToMore, restProps } = useEntitySubscriptionQuery({
    gqlQuery: prefetchProject,
    queryEndpointName: 'project',
    gqlSubscription: PROJECT_CHANGED,
    subscriptionEndpointName: 'projectChanged',
    subscriptionParamsFromProps: projectSubscriptionParamsFromProps,
    queryParamsFromProps: projectQueryParamsFromProps,
    propsOfComponent: props,
    alias: 'usePrefetchProject',
  });

  return {
    subscribeToMore,
    restProps,
  };
};

export const usePrefetchProperty = (propertyId) => {
  const { data, loading } = useQuery(prefetchProperty, {
    alias: 'usePrefetchProperty',
    variables: {
      propertyId,
      searchWOsInput: {
        query: {
          match: { type: WORK_ORDER },
        },
        size: MAX_SIZE,
      },
      searchChildRecurringInput: {
        query: CHILD_PROJECT_ELASTIC_QUERY,
        size: MAX_SIZE,
      },
      searchRecurringInput: {
        size: MAX_SIZE,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    data,
    loading,
  };
};

export const usePrefetchMasterPmProject = (id) => {
  const { data, loading } = useQuery(prefetchMasterPmProject, {
    alias: 'usePrefetchMasterPmProject',
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    data,
  };
};

export const usePrefetchPurchaseOrder = (id) => {
  const { data, loading } = useQuery(prefetchPurchaseOrder, {
    alias: 'usePrefetchPurchaseOrder',
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  return {
    data,
    loading,
  };
};

export const usePrefetchSupplier = (supplierId) => {
  const { data, loading } = useQuery(prefetchSupplier, {
    variables: {
      supplierId,
      searchWOsInput: {
        query: { match: { type: WORK_ORDER } },
      },
      searchRecurringInput: {
        query: CHILD_PROJECT_ELASTIC_QUERY,
      },
    },
    notifyOnNetworkStatusChange: true,
    alias: 'usePrefetchSupplier',
  });

  return {
    data,
    loading,
  };
};
