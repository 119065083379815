import { gql } from '@apollo/client';

export const getProjectsStats = gql`
  query getProjectsStats($input: CollectionSearchParams) {
    searchProjects(input: $input) {
      total
      hits {
        _id
        startDate
        endDate
        workCompletionDate
        priority
        type
      }
    }
  }
`;
