import types from '../types.js';

export const setApprovedPOsTableSearchString = (query) => ({
  type: types.SET_APPROVED_POS_TABLE_SEARCH_STRING,
  payload: query,
});

export const setApprovedPOsPaginationCurrentPage = (current) => ({
  type: types.SET_APPROVED_POS_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setApprovedPOsPaginationTotal = (total) => ({
  type: types.SET_APPROVED_POS_PAGINATION_TOTAL,
  payload: total,
});

export const setApprovedPOsPaginationPageSize = (size) => ({
  type: types.SET_APPROVED_POS_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setApprovedPOsPaginationDropdownLabel = (label) => ({
  type: types.SET_APPROVED_POS_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});
