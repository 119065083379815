import { format, startOfToday, startOfTomorrow } from 'date-fns';
import { CHILD_PROJECT_ELASTIC_QUERY } from 'poly-client-utils';
import {
  RecurringProjectStatuses,
  onHoldWorkOrderStatuses,
  RecurringProjectTypes,
  WorkOrderStatus,
} from 'poly-constants';

import {
  QUEUE,
  ACTIVE,
  ON_HOLD,
  PAST_DUE,
  COMPLETED,
  DUE_TODAY,
  WORK_ORDER,
  DUE_TOMORROW,
  PREVENTIVE_MAINTENANCE,
} from '../../constants/projects.js';
import { SORTING } from '../../constants/index.js';
import { CORRECTIVES, MAIN, PMS, REPORT } from '../../constants/routes.js';

const HOLD_STATUS_QUERY = [
  { match: { status: onHoldWorkOrderStatuses[0] } },
  { match: { status: onHoldWorkOrderStatuses[1] } },
  { match: { status: onHoldWorkOrderStatuses[2] } },
];

const BLOCKED_STATUS_QUERY = [{ match: { status: WorkOrderStatus.REJECTED } }];

const COMPLETED_STATUS_QUERY = [
  { match: { status: WorkOrderStatus.COMPLETED } },
];

const getNestedExistFieldQuery = (field) => ({
  nested: {
    path: field,
    query: {
      exists: { field },
    },
  },
});

const formQuery = ({ typeFilter, locationType, startDate, endDate }) => {
  const queryExtension = () => {
    switch (locationType) {
      case REPORT:
        return [
          {
            match: { type: RecurringProjectTypes.recurringProject },
          },
        ];
      case PMS:
        return [{ match: { type: PREVENTIVE_MAINTENANCE } }];
      case CORRECTIVES:
        return [{ match: { type: PREVENTIVE_MAINTENANCE } }];
      case MAIN:
      default:
        return [{ match: { type: WORK_ORDER } }, CHILD_PROJECT_ELASTIC_QUERY];
    }
  };

  const getRangeQueryWithinDate = (field, date) => ({
    range: {
      [field]: {
        lte: date,
        gte: date,
      },
    },
  });

  const queryType = {
    [DUE_TODAY]: {
      bool: {
        should: [
          ...queryExtension(),
          { match: { status: WorkOrderStatus.ACTIVE } },
          { match: { status: WorkOrderStatus.IN_PROCESS } },
        ],
        minimum_should_match: 2,
        must_not: [
          ...HOLD_STATUS_QUERY,
          ...BLOCKED_STATUS_QUERY,
          ...COMPLETED_STATUS_QUERY,
        ],
        must: getRangeQueryWithinDate(
          'endDate',
          format(startOfToday(), 'yyyy-MM-dd'),
        ),
      },
    },
    [DUE_TOMORROW]: {
      bool: {
        should: [
          ...queryExtension(),
          { match: { status: WorkOrderStatus.ACTIVE } },
          { match: { status: WorkOrderStatus.IN_PROCESS } },
        ],
        minimum_should_match: 2,
        must_not: [
          ...HOLD_STATUS_QUERY,
          ...BLOCKED_STATUS_QUERY,
          ...COMPLETED_STATUS_QUERY,
        ],
        must: getRangeQueryWithinDate(
          'endDate',
          format(startOfTomorrow(), 'yyyy-MM-dd'),
        ),
      },
    },
    [PAST_DUE]: {
      bool: {
        must_not: [
          ...HOLD_STATUS_QUERY,
          ...BLOCKED_STATUS_QUERY,
          ...COMPLETED_STATUS_QUERY,
        ],
        should: [
          ...queryExtension(),
          { match: { status: WorkOrderStatus.ACTIVE } },
          { match: { status: WorkOrderStatus.IN_PROCESS } },
        ],
        minimum_should_match: 2,
        filter: {
          range: {
            endDate: { lte: format(startOfToday(), 'yyyy-MM-dd') },
          },
        },
      },
    },
    [QUEUE]: {
      bool: {
        must_not: [
          getNestedExistFieldQuery('technicians'),
          getNestedExistFieldQuery('suppliers'),
          { exists: { field: 'manager' } },
          ...HOLD_STATUS_QUERY,
          ...BLOCKED_STATUS_QUERY,
        ],
        should: [
          ...queryExtension(),
          { match: { status: WorkOrderStatus.ACTIVE } },
        ],
        minimum_should_match: 2,
      },
    },
    [ACTIVE]: {
      bool: {
        should: [
          ...queryExtension(),
          { match: { status: WorkOrderStatus.ACTIVE } },
          { match: { status: WorkOrderStatus.IN_PROCESS } },
        ],
        minimum_should_match: 2,
        must_not: BLOCKED_STATUS_QUERY,
      },
    },
    [RecurringProjectStatuses.CLOSED]: {
      bool: {
        should: queryExtension(),
        minimum_should_match: 1,
        must: { match: { status: RecurringProjectStatuses.CLOSED } },
      },
    },
    [ON_HOLD]: {
      bool: {
        must: [
          { bool: { should: queryExtension() } },
          {
            bool: {
              should: HOLD_STATUS_QUERY,
            },
          },
        ],
      },
    },
    [COMPLETED]: {
      bool: {
        should: [
          ...queryExtension(),
          { match: { status: WorkOrderStatus.COMPLETED } },
        ],
        minimum_should_match: 2,
        must_not: [...HOLD_STATUS_QUERY, ...BLOCKED_STATUS_QUERY],
        filter: {
          range: {
            workCompletionDate: {
              ...(startDate && { gte: startDate }),
              ...(endDate && { lte: endDate }),
            },
          },
        },
      },
    },
  };
  return queryType[typeFilter];
};

export const useProjectFilters = (props) => {
  const searchTerm = props.tableSearchString || '';
  const query = formQuery(props);
  const { sort } = SORTING;

  return {
    searchTerm,
    query,
    sort,
  };
};
