import { gql } from '@apollo/client';

import { assetDetailsFields } from './fragments.js';

export const assetDetailsSubscription = gql`
  subscription assetDetailsSubscription($input: SingleDocSubInput!) {
    assetChanged(input: $input) {
      id
      type
      document {
        ...assetDetailsFields
      }
    }
  }

  ${assetDetailsFields}
`;
