import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Icon, IconButton, S, Widget } from 'poly-book';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import {
  CREATE_PURCHASE_ORDER_PERMISSION,
  CREATE_MATERIAL_SPEND_PERMISSION,
  CREATE_TIME_LOG_PERMISSION,
} from 'poly-security';
import { TableComponent } from 'poly-site-ui';

import RemoveMaterialsModal from '../../../containers/project/tabs/job-costs/remove-materials.js';
import RemoveTimeLogsModal from '../../../containers/project/tabs/job-costs/remove-timelogs.js';
import MaterialsModal from '../../../containers/project/tabs/job-costs/materials.js';
import TimeLogsModal from '../../../containers/project/tabs/job-costs/timelogs.js';
import ClosePurchaseOrder from '../../../containers/purchase-order/close-modal.js';
import EditPOModal from '../../../containers/purchase-order/edit-po-request.js';
import AddInvoice from '../../../containers/invoice/add-invoice.js';
import { shouldDisplayTable } from '../../../util/job-costs.js';

const TablePropTypes = {
  headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  columns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  formats: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  props: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.objectOf(PropTypes.string),
    }),
  ),
  gridColumns: PropTypes.string,
};

TableComponent.defaultProps = {
  props: [],
  gridColumns: 'repeat(4, minmax(80px, 400px))',
};

function TableBuilder(entity, gridColumns) {
  return (
    shouldDisplayTable(entity) && (
      <TableComponent {...entity} {...{ gridColumns }} />
    )
  );
}

function AddEntityButtonComp({ onClick, withTitle, title }) {
  return (
    <IconButton fill="#ff8d02" onClick={onClick} hover>
      {withTitle && <S type="title">{title}</S>}
      <Icon name="add" fill="#ff8d02" dimensions={{ width: 30 }} />
    </IconButton>
  );
}

AddEntityButtonComp.propTypes = {
  withTitle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

AddEntityButtonComp.defaultProps = { withTitle: true };

function AddEntityButton(props) {
  const { permissions, ...restProps } = props;

  const ifHasPermission = useHasUserAccessWithPermission(permissions);

  return ifHasPermission ? <AddEntityButtonComp {...restProps} /> : null;
}

AddEntityButton.propTypes = {
  permissions: PropTypes.string,
};

function JobCosts({
  materials,
  timeLogs,
  purchaseOrders,
  openTimeLogsModal,
  openMaterialsModal,
  addPOModal,
}) {
  return (
    <>
      <Widget.Item>
        <Heading>
          <Heading.h5>Purchase Order</Heading.h5>
          <AddEntityButton
            title="Create PO"
            onClick={addPOModal}
            permissions={CREATE_PURCHASE_ORDER_PERMISSION}
          />
          <EditPOModal />
          <ClosePurchaseOrder />
          <AddInvoice />
        </Heading>
        {TableBuilder(
          purchaseOrders,
          `
          minmax(50px, 100px)
          minmax(100px, 150px)
          minmax(100px, 1fr)
          minmax(100px, 150px)
          minmax(50px, 100px)
          30px
        `,
        )}
      </Widget.Item>
      <Widget.Item>
        <Heading>
          <Heading.h5>Technician Time</Heading.h5>
          <AddEntityButton
            title="Add Technician Time"
            withTitle={!shouldDisplayTable(timeLogs)}
            onClick={openTimeLogsModal}
            permissions={CREATE_TIME_LOG_PERMISSION}
          />
          <TimeLogsModal />
          <RemoveTimeLogsModal />
        </Heading>
        {TableBuilder(
          timeLogs,
          `
          minmax(100px, 1fr)
          repeat(2, minmax(50px, 100px))
          repeat(2, 20px)
        `,
        )}
      </Widget.Item>
      <Widget.Item>
        <Heading>
          <Heading.h5>Materials</Heading.h5>
          <AddEntityButton
            title="Add Material"
            withTitle={!shouldDisplayTable(materials)}
            onClick={openMaterialsModal}
            permissions={CREATE_MATERIAL_SPEND_PERMISSION}
          />
          <MaterialsModal />
          <RemoveMaterialsModal />
        </Heading>
        {TableBuilder(
          materials,
          `
          minmax(100px, 1fr)
          repeat(3, minmax(50px, 150px))
          repeat(2, 20px)
        `,
        )}
      </Widget.Item>
    </>
  );
}

const tableDataPattern = PropTypes.shape(TablePropTypes).isRequired;

JobCosts.propTypes = {
  purchaseOrders: tableDataPattern,
  materials: tableDataPattern,
  timeLogs: tableDataPattern,
  openTimeLogsModal: PropTypes.func.isRequired,
  openMaterialsModal: PropTypes.func.isRequired,
  addPOModal: PropTypes.func.isRequired,
};

JobCosts.defaultProps = {
  purchaseOrders: {},
  materials: {},
  timeLogs: {},
};

export default JobCosts;
