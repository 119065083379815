import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { useEntitySubscriptionQuery } from 'poly-client-utils/src/entitySubscription.js';

import {
  projectQuery,
  pmProjectQuery,
  mrProjectQuery,
  MRProjectSubscription,
} from '../queries/index.js';

export const useProjectDetails = (projectId) => {
  const { data } = useQuery(projectQuery, {
    alias: 'useProjectDetails',
    variables: { id: projectId },
    notifyOnNetworkStatusChange: true,
  });

  return {
    project: data?.project,
  };
};

export const useProjectPMDetails = (projectId) => {
  const { data } = useQuery(pmProjectQuery, {
    alias: 'useProjectPMDetails',
    variables: { id: projectId },
    notifyOnNetworkStatusChange: true,
  });
  return {
    project: data?.project,
    restProjectPMDetailsProps: data,
  };
};

export const useMRProjectDetails = (projectId) => {
  const { data, loading } = useQuery(mrProjectQuery, {
    alias: 'useMRProjectDetails',
    variables: {
      id: projectId,
      from: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    project: data?.recurringProject,
  };
};

export const useMRProjectSubscriptionQuery = (props) => {
  const { subscribeToMore, restProps } = useEntitySubscriptionQuery({
    gqlQuery: mrProjectQuery,
    queryEndpointName: 'recurringProject',
    gqlSubscription: MRProjectSubscription,
    subscriptionEndpointName: 'recurringProjectChanged',
    queryParamsFromProps: R.compose(R.objOf('id'), R.prop('projectId')),
    subscriptionParamsFromProps: R.compose(R.objOf('id'), R.prop('_id')),
    propsOfComponent: props,
    alias: 'useMRProjectSubscriptionQuery',
  });

  return {
    subscribeToMore,
    restProps,
  };
};
