import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, objectOf } from 'prop-types';
import { ADDRESS_PARTS_KEYS } from 'poly-constants';
import { Grid, S } from 'poly-book';

import { isNotNilOrEmpty } from '../utils/general.js';

const { AREA, CITY, CODE, HOUSE, STREET, SUFFIX } = ADDRESS_PARTS_KEYS;

const GridCellGap = styled(Grid.Cell)`
  grid-row-gap: 4px;
`;

// getLocalPart :: String -> String
const getLocalPart = (local) => (isNotNilOrEmpty(local) ? `${local}, ` : '');

// getHousePart :: String -> String
const getHousePart = (house) => (isNotNilOrEmpty(house) ? `${house} ` : '');

export function AddressDetailsRow(props) {
  const house = R.path(['value', HOUSE], props);
  const street = R.path(['value', STREET], props);
  const local = R.path(['value', CITY], props);
  const area = R.path(['value', AREA], props);
  const code = R.path(['value', CODE], props);
  const suffix = R.path(['value', SUFFIX], props);
  const firstString = `${getHousePart(house)}${
    isNotNilOrEmpty(street) ? street : ''
  }`;
  const secondString = `${getLocalPart(local)}${
    isNotNilOrEmpty(area) ? `${area} ` : ''
  }${isNotNilOrEmpty(code) ? code : ''}${
    isNotNilOrEmpty(suffix) ? `-${suffix}` : ''
  }`;
  return (
    <Grid.Row>
      <Grid.Cell {...(!isNotNilOrEmpty(props.value) && { vertical: 'center' })}>
        <S type="title" textColor="#5d688c">
          {props.title}
        </S>
      </Grid.Cell>
      <GridCellGap>
        {isNotNilOrEmpty(props.value) ? (
          <>
            <S type="content" bold>
              {firstString}
            </S>
            {isNotNilOrEmpty(props.additional) && (
              <S type="content" bold>
                {props.additional}
              </S>
            )}
            {isNotNilOrEmpty(secondString) && (
              <S type="content" bold>
                {secondString}
              </S>
            )}
          </>
        ) : (
          <S type="content" bold>
            {' '}
            —{' '}
          </S>
        )}
      </GridCellGap>
    </Grid.Row>
  );
}

AddressDetailsRow.propTypes = {
  title: string,
  additional: string,
  value: objectOf(string),
};
