import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Heading,
  Icon,
  IconButton,
  Modal,
  S,
  TextArea,
} from 'poly-book';

import ServerError from '../server-error/index.js';
import ButtonLoader from '../loader/button.js';
import { Rows } from '../../util/forms/index.js';
import { MultipleInput } from '../multiple-input/index.js';

const Header = Modal.Item.withComponent(Heading);
const Form = Modal.Item.withComponent('form');
const ModalGrid = Modal.Item.withComponent(Grid);

function SendSupConfModal({
  loading,
  errors,
  onChange,
  closeModal,
  onSubmit,
  cardNumber,
  amount,
  emails,
  onAddEmail,
  onRemoveEmail,
}) {
  const items = [
    {
      title: {
        value: 'Email',
        props: ['required'],
      },
      error: R.prop('email', errors),
      item: (
        <MultipleInput
          {...{ onChange, errors }}
          disabledFirst
          name="email"
          type="email"
          emails={emails}
          onAddField={onAddEmail}
          onRemoveEmail={onRemoveEmail}
          addButtonText="Add Email"
          onFieldChoose={() => {}}
        />
      ),
    },
    {
      title: {
        value: 'Instructions',
        props: ['required', 'instructions'],
      },
      error: R.prop('instructions', errors),
      item: (
        <TextArea
          name="instructions"
          placeholder="Comments"
          invalid={!!R.prop('instructions', errors)}
          {...{ onChange }}
        />
      ),
    },
  ];

  return (
    <Modal show>
      <Header margin={20}>
        <Heading.h4 lighter>
          <p>Send Supplier PO Confirmation</p>
        </Heading.h4>
        <IconButton onClick={closeModal}>
          <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
        </IconButton>
      </Header>
      <Header margin={20}>
        <Heading.h5 lighter>
          <p>
            PO# {cardNumber} | {amount}
          </p>
        </Heading.h5>
      </Header>

      <Form onSubmit={onSubmit}>
        <ModalGrid columns="110px minmax(200px, 320px)" margin={30} simple>
          <Rows items={items} />
        </ModalGrid>
        <ServerError
          error={R.prop('server', errors)}
          style={{ maxWidth: 430 }}
        />
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">Submit</S>
          </Button>
        </Modal.Buttons>
      </Form>
    </Modal>
  );
}

SendSupConfModal.propTypes = {
  cardNumber: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.shape({
    email: PropTypes.string,
    instructions: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  emails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
  onAddEmail: PropTypes.func.isRequired,
  onRemoveEmail: PropTypes.func.isRequired,
};

SendSupConfModal.defaultProps = {
  errors: {},
  loading: false,
};

export default SendSupConfModal;
