import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { Grid, Heading, Icon, IconButton, S, Widget } from 'poly-book';
import { NOTHING_UI_STRING } from 'poly-constants';

import { PageHeader } from './PageHeader.js';

function RowBuilder({ title, value }) {
  return (
    <Grid.Row>
      <Grid.Cell vertical="center">
        <S type="title">{title}</S>
      </Grid.Cell>
      <Grid.Cell>
        <S type="content" bold>
          {value || '—'}
        </S>
      </Grid.Cell>
    </Grid.Row>
  );
}

RowBuilder.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

RowBuilder.defaultProps = {
  value: '',
};

// formatDate :: Date -> String
const formatDate = R.ifElse(
  R.identity,
  R.compose(
    (date) => format(date, 'MM-dd-yyyy'),
    R.when(R.is(String), parseISO),
  ),
  R.always(NOTHING_UI_STRING),
);

export function ProjectAssetDetailsTabBase({
  project,
  removeAsset,
  allowRemoveAsset,
  assetsPath = ['searchAssets', 'hits'],
}) {
  const assets = R.pathOr([], assetsPath)(project);
  return assets.map(
    ({
      _id,
      room,
      model,
      serial,
      startUp,
      location,
      description,
      manufacturer,
      equipmentType,
    }) => (
      <Widget.Item key={_id}>
        <PageHeader>
          <Heading.h1 lighter>{equipmentType}</Heading.h1>
          {allowRemoveAsset && (
            <IconButton onClick={() => removeAsset({ _id, equipmentType })}>
              <Icon name="remove" fill="#9dacdc" dimensions={{ height: 14 }} />
            </IconButton>
          )}
        </PageHeader>
        <Grid columns="120px 1fr" simple>
          <RowBuilder title="Description" value={description} />
          <RowBuilder title="Manufacturer" value={manufacturer} />
          <RowBuilder title="Model Number" value={model} />
          <RowBuilder title="Serial Number" value={serial} />
          <RowBuilder title="Location" value={location} />
          <RowBuilder title="Room" value={room} />
          <RowBuilder title="Start Up" value={formatDate(startUp)} />
        </Grid>
      </Widget.Item>
    ),
  );
}

ProjectAssetDetailsTabBase.propTypes = {
  project: PropTypes.shape({
    searchAssets: PropTypes.shape({
      hits: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          sap: PropTypes.string,
          bms: PropTypes.string,
          room: PropTypes.string,
          model: PropTypes.string,
          serial: PropTypes.string,
          startUp: PropTypes.string,
          location: PropTypes.string,
          description: PropTypes.string,
          warrantyEnd: PropTypes.string,
          manufacturer: PropTypes.string,
          equipmentType: PropTypes.string,
        }),
      ),
    }),
    property: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
  removeAsset: PropTypes.func.isRequired,
  allowRemoveAsset: PropTypes.bool.isRequired,
  assetsPath: PropTypes.arrayOf(PropTypes.string),
};
