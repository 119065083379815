import { gql } from '@apollo/client';

import { defaultRecurringWOFragment } from '../fragments.js';

const fullMRProjectFragment = gql`
  fragment fullMRProjectFragment on RecurringProject {
    ...defaultRecurringWOFragment
    schedule {
      repeats
      weekDay
      every
      days
    }
  }

  ${defaultRecurringWOFragment}
`;

export const mrProjectQuery = gql`
  query mrProjectQuery($id: ID) {
    recurringProject(projectId: $id) {
      ...fullMRProjectFragment
    }
  }

  ${fullMRProjectFragment}
`;

export const MRProjectSubscription = gql`
  subscription recurringProjectChanged($input: SingleDocSubInput!) {
    recurringProjectChanged(input: $input) {
      id
      type
      document {
        ...fullMRProjectFragment
      }
    }
  }
  ${fullMRProjectFragment}
`;
