import { gql } from '@apollo/client';

import { updateFragment } from '../fragments.js';

export const getPropertyUpdates = gql`
  query getPropertyUpdates(
    $propertyId: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    property(id: $propertyId) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...updateFragment
        }
        total
      }
    }
  }

  ${updateFragment}
`;
