import * as R from 'ramda';
import { useCallback, useState } from 'react';

import { validateAdditionalEmails } from './index.js';

export const useMailTo = (errors, setError) => {
  const [additionalEmails, setAdditionalEmails] = useState([]);

  const selectAdditionalEmails = useCallback(
    (emails) => {
      setAdditionalEmails(emails);
      if (validateAdditionalEmails(emails)) {
        setError({
          ...errors,
          mailTo: 'One of the emails is not valid',
        });
      } else {
        setError(R.omit(['mailTo'], errors));
      }
    },
    [setAdditionalEmails, errors, setError],
  );

  return {
    selectAdditionalEmails,
    additionalEmails,
  };
};
