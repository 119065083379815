import { gql } from '@apollo/client';

export const getStaff = gql`
  query getStaff(
    $input: CollectionSearchParams
    $activeProjectsQuery: JSON
    $overdueProjectsQuery: JSON
    $lastThirtyProjectsQuery: JSON
  ) {
    searchUsers(input: $input) {
      total
      hits {
        _id
        email
        profile {
          cellPhoneNumber
          workPhoneNumber
          faxPhoneNumber
        }
        fullName
        status
        userGroups {
          userGroup {
            _id
            name
          }
        }
        activeProjectsCount: searchStaffProjects(
          input: { query: $activeProjectsQuery }
        ) {
          total
        }
        overdueProjectsCount: searchStaffProjects(
          input: { query: $overdueProjectsQuery }
        ) {
          total
        }
        lastThirtyProjectsCount: searchStaffProjects(
          input: { query: $lastThirtyProjectsQuery }
        ) {
          total
        }
      }
    }
  }
`;
