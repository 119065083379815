import * as R from 'ramda';
import { useCallback, useState } from 'react';

import schema from '../utils/validation/index.js';

let timer = null;

const useValidation = ({ changeHandlerName = 'onChange', validationRules }) => {
  const [errors, setError] = useState({});

  const validator = schema(
    R.is(Function, validationRules)
      ? validationRules({ changeHandlerName, validationRules })
      : validationRules,
  );

  const changeNameHandler = useCallback(
    ({ target: { name, value } }) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setError({
          ...errors,
          [name]: validator.validateField(name, value),
        });
      }, 300);
      return timer;
    },
    [errors, validator, setError],
  );

  const validateOnBlur = useCallback(
    ({ target: { name, value } }) =>
      setError({ ...errors, [name]: validator.validateField(name, value) }),
    [errors, validator, setError],
  );

  const resetError = useCallback(
    ({ target: { name } }) => {
      setError({
        ...errors,
        [name]: null,
      });
    },
    [errors, setError],
  );

  const validate = useCallback(
    (data) => {
      const errorMessage = validator.validate(data);
      setError(errorMessage);
      return errorMessage;
    },
    [setError, validator],
  );

  const validateField = useCallback(
    (name, value) => {
      const errorMessage = validator.validateField(name, value);

      if (errorMessage !== errors[name]) {
        setError({
          ...errors,
          [name]: errorMessage,
        });
      }
    },
    [errors, validator, setError],
  );

  return {
    errors,
    setError,
    validator,
    [changeHandlerName]: changeNameHandler,
    validateOnBlur,
    resetError,
    validate,
    validateField,
  };
};

export default useValidation;
