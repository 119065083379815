import * as R from 'ramda';
import { MAX_SIZE } from '../../constants/index.js';

export const useInvoicesFilters = (props) => {
  const searchTerm = R.propOr('', 'tableSearchString', props);
  const query = { match: { status: R.prop('type', props) } };
  const size = R.propOr(MAX_SIZE, 'pageSize', props);

  return {
    searchTerm,
    query,
    size,
  };
};
