import { gql } from '@apollo/client';

export const approveMultiplePOInvoices = gql`
  mutation approveMultiplePOInvoices($input: POInvoiceApproveInput!) {
    approveMultiplePOInvoices(input: $input) {
      poInvoices {
        _id
      }
    }
  }
`;
