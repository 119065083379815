import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from 'poly-book';
import { TableComponent } from 'poly-site-ui';

function Projects({ assets }) {
  return (
    <Widget.Item>
      <TableComponent
        gridColumns="
        minmax(50px, 150px)
        minmax(100px, 300px)
        minmax(100px, 200px)
        minmax(75px, 170px)
        minmax(75px, 150px)
        minmax(50px, 80px)
      "
        {...assets}
      />
    </Widget.Item>
  );
}

Projects.propTypes = {
  assets: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    columns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
    formats: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  }).isRequired,
};

export default Projects;
