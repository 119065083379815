import { gql } from '@apollo/client';

import { supplierProjectsFragment } from './get-projects.js';
import { fileFragment } from '../fragments.js';
import { supplierCompanyFields } from './fragments.js';

export const prefetchSupplier = gql`
  query prefetchSupplier(
    $supplierId: ID!
    $searchWOsInput: CollectionSearchParams!
    $searchRecurringInput: CollectionSearchParams!
  ) {
    supplier(id: $supplierId) {
      _id
      company {
        ...supplierCompanyFields
      }
      wos: searchProjects(input: $searchWOsInput) {
        ...supplierProjectsFragment
      }
      recurring: searchProjects(input: $searchRecurringInput) {
        ...supplierProjectsFragment
      }
      files {
        ...fileFragment
      }
    }
  }
  ${supplierProjectsFragment}
  ${fileFragment}
  ${supplierCompanyFields}
`;
