import * as R from 'ramda';
import { S } from 'poly-book';
import React from 'react';
import { arrayOf, func, shape, string, bool, oneOfType } from 'prop-types';
import { SelectBase } from './SelectBase.js';

function ServiceTypeRenderer({ label }) {
  return (
    <S type="title" textColor="#ff8c00">
      {label}
    </S>
  );
}

ServiceTypeRenderer.propTypes = {
  label: string.isRequired,
};

function ServiceTitle({ label }) {
  return <S type="title">{label}</S>;
}

ServiceTitle.propTypes = {
  label: string.isRequired,
};

// formatServiceTypesOptions :: [ServiceType] -> [Option]
// Option = { value: String, label: String }
const formatServiceTypesOptions = R.map(
  R.applySpec({
    value: R.prop('_id'),
    label: R.prop('name'),
  }),
);

export function ServiceTypeSelectorBase({
  value,
  onChange,
  onBlur,
  onFocus,
  setServiceSearch,
  serviceTypes,
  loading,
  invalid,
}) {
  return (
    <SelectBase
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onInputChange={setServiceSearch}
      onSelectResetsInput={false}
      isLoading={loading}
      placeholder={<S type="content">Please Select Service Type...</S>}
      options={formatServiceTypesOptions(serviceTypes)}
      optionRenderer={ServiceTitle}
      valueRenderer={ServiceTypeRenderer}
      invalid={invalid}
    />
  );
}

ServiceTypeSelectorBase.propTypes = {
  value: oneOfType([string, shape({ value: string, label: string })]),
  onChange: func.isRequired,
  onBlur: func,
  onFocus: func,
  serviceTypes: arrayOf(
    shape({
      name: string.isRequired,
      _id: string.isRequired,
    }),
  ),
  setServiceSearch: func.isRequired,
  invalid: bool,
  loading: bool,
};
