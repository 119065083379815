import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { Icon, IconButton, S } from 'poly-book';

import { formatCurrency, formatDateWithDashes } from './general.js';

export const dateFormatter = (value) => (
  <S type="content">{formatDateWithDashes(value) || '—'}</S>
);

export const currencyFormatter = (value) => (
  <S type="content" bold>
    {formatCurrency(value)}
  </S>
);

export const durationFormatter = (value) => (
  <S type="content">
    {value ? `${(value / (1000 * 60 * 60)).toFixed(2)} hours` : '—'}
  </S>
);

export const shouldDisplayTable = (entity) =>
  !!R.path(['rows', 'length'], entity);

export function EditIcon({
  field,
  action,
  dispatch,
  disableIf,
  additional,
  permissions = [],
  ...data
}) {
  const ifHasPermission = useHasUserAccessWithPermission(permissions);

  const fieldData = field
    ? {
        [field]: {
          ...data,
          ...additional,
        },
      }
    : {
        type: 'edit',
        data,
      };

  return (
    ifHasPermission && (
      <IconButton
        onClick={() => (disableIf?.(data) ? null : dispatch(action(fieldData)))}
      >
        <Icon name="edit" fill="#9fa1ab" dimensions={{ height: 14 }} />
      </IconButton>
    )
  );
}

EditIcon.propTypes = {
  field: PropTypes.string,
  action: PropTypes.func,
  dispatch: PropTypes.func,
  disableIf: PropTypes.func,
  permissions: PropTypes.string,
  additional: PropTypes.shape({}),
};

export function RemoveIcon({ dispatch, action, permissions = [], ...data }) {
  const ifHasPermission = useHasUserAccessWithPermission(permissions);

  return (
    ifHasPermission && (
      <IconButton
        onClick={() => {
          dispatch(
            action({
              type: 'remove',
              data,
            }),
          );
        }}
      >
        <Icon name="remove" fill="#9fa1ab" dimensions={{ height: 14 }} />
      </IconButton>
    )
  );
}

RemoveIcon.propTypes = {
  action: PropTypes.func,
  dispatch: PropTypes.func,
  permissions: PropTypes.string,
};
