import React from 'react';
import { Layout } from 'poly-book';

import { LayoutContent } from '../styled.js';
import AuthContainer from '../hooks/auth-container.js';
import Navigation from '../containers/navbar/navigation-container.js';
import CompletedProjectsLayout from '../layouts/completed-projects.js';
import CompletedProjectsFilter from '../containers/completed-projects/filter.js';

const excludeOnHoldFilter = {
  bool: {
    must_not: {
      term: {
        wasInOnHoldStatus: true,
      },
    },
  },
};

function CompletedProjects() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <CompletedProjectsFilter title="Completed WOs" />
        </Layout.Header>
        <LayoutContent>
          <CompletedProjectsLayout filters={[excludeOnHoldFilter]} />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default CompletedProjects;
