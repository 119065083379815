import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applySpecWithFields } from 'poly-utils';
import { UPDATE_PURCHASE_ORDER_PERMISSION } from 'poly-security';
import { AbsoluteLoader } from 'poly-site-ui';
import TableCheckbox from '../../../components/checkboxes/table-checkbox.js';
import HeaderCheckbox from '../../../components/checkboxes/header-checkbox.js';
import { TableWithSavingDataForExport } from '../../../components/table/table.js';
import { modifyPOToAccessItemTypes } from '../../../utils/purchase-orders/pos-utils.js';
import {
  highlightCurrency,
  highlightField,
} from '../../../utils/highlight/index.js';
import { usePOsFilters } from '../../../utils/purchase-orders/pos-filters.js';
import { checkPermissionByOneAccessItem } from '../../../utils/user/index.js';
import { noDataToDisplay } from '../../../util/general.js';
import {
  approvePOFormatter,
  isPOAllowedToApprove,
} from '../../../utils/purchase-orders/pos-hocs.js';
import {
  projectPOFormatter,
  propertySelector,
  scopeFormatter,
} from '../../../utils/purchase-orders/index.js';
import {
  setPOsPaginationCurrentPage,
  setPOsPaginationDropdownLabel,
  setPOsPaginationPageSize,
  setPOsPaginationTotal,
  setSelectedPOs,
  togglePO,
} from '../../../redux/actions/index.js';
import { usePurchaseOrdersQuery } from '../../../hooks/purchase-orders.js';
import usePagination from '../../../hooks/usePagination.js';

const STATE_PATH = ['purchaseOrders', 'selectedPOs'];

// applyFieldsToPOs :: User -> [PurchaseOrder] -> [PurchaseOrder]
const applyFieldsToPOs = (user) =>
  R.map(
    applySpecWithFields({
      isOwn: R.pathEq(['createdBy', '_id'], R.prop('_id', user)),
      isNotAllowed: R.pipe(
        modifyPOToAccessItemTypes,
        checkPermissionByOneAccessItem(UPDATE_PURCHASE_ORDER_PERMISSION, user),
        R.not,
      ),
    }),
  );

// purchaseOrdersToIds :: [PurchaseOrder] -> [ID]
const purchaseOrdersToIds = R.compose(
  R.map(R.prop('_id')),
  R.filter(isPOAllowedToApprove),
);

const getColumns = ({ requests, dispatch, tableSearchString: search }) => [
  [
    <HeaderCheckbox
      key="header-checkbox"
      statePath={STATE_PATH}
      pageItems={purchaseOrdersToIds(requests)}
      onChange={R.compose(dispatch, setSelectedPOs)}
    />,
    R.identity,
    (purchaseOrder) =>
      isPOAllowedToApprove(purchaseOrder) && (
        <TableCheckbox
          key="table-checkbox"
          statePath={STATE_PATH}
          onChange={R.compose(dispatch, togglePO)}
          id={purchaseOrder._id}
        />
      ),
  ],
  ['PO#', R.identity, approvePOFormatter(search)],
  ['Amount', R.prop('amount'), highlightCurrency(search)],
  ['Scope', R.prop('isInScope'), scopeFormatter(search)],
  ['WO#', R.prop('project'), projectPOFormatter(search)],
  ['Supplier', R.path(['supplier', 'company', 'name']), highlightField(search)],
  ['Property', propertySelector, highlightField(search)],
];

export default function (props) {
  const dispatch = useDispatch();

  const purchaseOrders = useSelector((state) => state.purchaseOrders);
  const user = useSelector((state) => state.user);

  const {
    type,
    currentPage,
    pageSize,
    paginationDropdownLabel,
    tableSearchString,
  } = purchaseOrders;

  const from = (currentPage - 1) * pageSize;

  const propsForFilter = { tableSearchString, type };

  const { searchTerm, query, sort } = usePOsFilters(propsForFilter);

  const { total, requests, requestsLoading, restPurchaseOrdersProps } =
    usePurchaseOrdersQuery({ searchTerm, query, pageSize, from, sort });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setPOsPaginationTotal,
    setPageSize: setPOsPaginationPageSize,
    setCurrent: setPOsPaginationCurrentPage,
    setPaginationDropdownLabel: setPOsPaginationDropdownLabel,
    total,
    currentPage,
  });

  if (requestsLoading) return <AbsoluteLoader />;
  if (!requests.length) return noDataToDisplay('PO Pending Approvals')();

  const requestsValue = applyFieldsToPOs(user)(requests);

  const cols = getColumns({ requests, dispatch, tableSearchString });

  const columns = R.map(R.nth(1), cols);
  const headers = R.map(R.nth(0), cols);
  const formats = R.map(R.compose(R.defaultTo(R.identity), R.nth(2)), cols);

  const colsPropsValue = R.map(R.nth(3), cols);

  const gridColumns = `
        16px
        minmax(80px, 150px)
        100px
        100px
        minmax(80px, 150px)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
      `;

  return (
    <TableWithSavingDataForExport
      {...props}
      {...restPurchaseOrdersProps}
      props={colsPropsValue}
      rows={requestsValue}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      searchTerm={searchTerm}
    />
  );
}
