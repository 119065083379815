import { WorkOrderStatus } from 'poly-constants';

import { SORTING } from '../../constants/index.js';
import { ALL_SERVICE_TYPES_VALUE } from '../../components/date-filter/service-type-selector.js';

export const useCompletedWOsFilters = ({
  startDate,
  endDate,
  serviceTypeId,
  filters,
  tableSearchString = '',
}) => {
  const { sort } = SORTING;
  const query = {
    bool: {
      filter: [
        // child PM are handled by another report
        {
          bool: {
            must_not: {
              exists: {
                field: 'maintenancePlanId',
              },
            },
          },
        },
        { match: { status: WorkOrderStatus.COMPLETED } },
        {
          range: {
            workCompletionDate: {
              ...(startDate && { gte: startDate }),
              ...(endDate && { lte: endDate }),
            },
          },
        },
        ...(serviceTypeId && serviceTypeId !== ALL_SERVICE_TYPES_VALUE
          ? [{ match: { serviceTypeId } }]
          : []),
        ...filters,
      ],
    },
  };

  return {
    searchTerm: tableSearchString,
    query,
    sort,
  };
};
