import * as R from 'ramda';
import { FORGOT_PASSWORD_ROUTE, ProjectType } from 'poly-constants';

import checkIfPageExist from './redux/thunks/checkPageExist.js';
import {
  doesProjectExist,
  doesRecurringProjectExist,
  isAssetExist,
  isMaintenancePlanExist,
  isPOExist,
  isPropertyExist,
  isSupplierExist,
} from './queries/index.js';
import {
  APPROVED_INVOICES,
  APPROVED_POS,
  ASSET,
  ASSETS,
  CHANGE_PASSWORD,
  COMPLETED_PMS,
  COMPLETED_WOS,
  COMPLETED_ON_HOLD_WOS,
  CONTRACT_EXPIRATION_REPORT,
  EYES_WIDE_OPEN,
  FORGOT_PASSWORD,
  INVOICE_LOG,
  INVOICES,
  LOGIN,
  MAIN,
  MR_PM_PROJECT,
  MR_PROJECT,
  PM_PROJECT,
  PMS,
  PO_LOG,
  PROJECT,
  PROPERTIES,
  PROPERTY,
  PURCHASE_ORDER,
  PURCHASE_ORDERS,
  REPORT,
  REPORT_PM,
  REPORTS,
  STAFF,
  SUPPLIER,
  SUPPLIERS,
  TECHNICIAN_STATS,
  PROJECTS_STATS,
  INCORRECT_DEVICE,
} from './constants/routes.js';

export const whitelist = [
  LOGIN,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  INCORRECT_DEVICE,
];

// additionalPMProjectExistCheck :: Project -> Boolean
const additionalPMProjectExistCheck = R.compose(
  R.not,
  R.propEq('type', ProjectType.PREVENTIVE_MAINTENANCE),
);

export default {
  [MAIN]: '/',
  [LOGIN]: '/login',
  [FORGOT_PASSWORD]: FORGOT_PASSWORD_ROUTE,
  [CHANGE_PASSWORD]: '/set-password/:token',
  [PROJECT]: {
    path: '/project/:id',
    thunk: checkIfPageExist(doesProjectExist, ['project']),
  },
  [PROPERTIES]: '/properties',
  [PROPERTY]: {
    path: '/property/:id',
    thunk: checkIfPageExist(isPropertyExist, ['property']),
  },
  [REPORTS]: '/reports',
  [REPORT]: {
    path: '/report/master-recurring-work-orders',
  },
  [REPORT_PM]: {
    path: '/report/master-recurring-pm-work-orders',
  },
  [PMS]: '/pm-projects',
  [EYES_WIDE_OPEN]: '/eyes-wide-open',
  [MR_PM_PROJECT]: {
    path: '/mr-pm-project/:id',
    thunk: checkIfPageExist(isMaintenancePlanExist, ['maintenancePlan']),
  },
  [PM_PROJECT]: {
    path: '/pm-project/:id',
    thunk: checkIfPageExist(
      doesProjectExist,
      ['project'],
      additionalPMProjectExistCheck,
    ),
  },
  [STAFF]: '/staff',
  [ASSETS]: '/assets',
  [ASSET]: {
    path: '/asset/:id',
    thunk: checkIfPageExist(isAssetExist, ['asset']),
  },
  [SUPPLIERS]: '/suppliers',
  [SUPPLIER]: {
    path: '/supplier/:id',
    thunk: checkIfPageExist(isSupplierExist, ['supplier']),
  },
  [MR_PROJECT]: {
    path: '/mr-project/:id',
    thunk: checkIfPageExist(doesRecurringProjectExist, ['recurringProject']),
  },
  [PURCHASE_ORDERS]: '/purchase-orders',
  [PURCHASE_ORDER]: {
    path: '/purchase-order/:id',
    thunk: checkIfPageExist(isPOExist, ['purchaseOrder']),
  },
  [APPROVED_POS]: '/approved-pos',
  [INVOICES]: '/invoices',
  [APPROVED_INVOICES]: '/approved-invoices',
  [COMPLETED_WOS]: '/completed-projects',
  [COMPLETED_ON_HOLD_WOS]: '/completed-on-hold-projects',
  [COMPLETED_PMS]: '/completed-pm-projects',
  [TECHNICIAN_STATS]: '/technician-stats',
  [INVOICE_LOG]: '/invoice-log',
  [PO_LOG]: '/po-log',
  [CONTRACT_EXPIRATION_REPORT]: '/contract-expiration-report',
  [PROJECTS_STATS]: '/projects-stats/:type/:year/:monthIndex',
  [INCORRECT_DEVICE]: '/incorrect-device',
};
