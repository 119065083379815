import * as R from 'ramda';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
} from 'poly-client-utils';

import { forceTitleCase } from 'poly-utils';
import { getUserCellPhone } from '../../utils/staff/index.js';
import { getUserTypeName } from '../../util/staff.js';

const COLUMN_WIDTH = [25, 15, 20, 15, 15, 10, 10, 10, 10];

const TITLE_ROW = [
  'User',
  'Type',
  'Mobile',
  'Email',
  'Active',
  'Overdue',
  'Last 30',
  'Status',
];

// getStaffDirectoryRowConfig :: User -> [ExcelExportDataCell]
const getStaffDirectoryRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 8),
  ]),
  R.juxt([
    R.propOr('', 'fullName'),
    R.compose(forceTitleCase, getUserTypeName),
    R.compose(R.defaultTo(''), getUserCellPhone),
    R.propOr('', 'email'),
    R.compose(Number, R.path(['activeProjectsCount', 'total'])),
    R.compose(Number, R.path(['overdueProjectsCount', 'total'])),
    R.compose(Number, R.path(['lastThirtyProjectsCount', 'total'])),
    R.compose(forceTitleCase, R.prop('status')),
  ]),
);

// getStaffDirectoryExcelConfig :: [User] -> Object
export const getStaffDirectoryExcelConfig = (users) => ({
  exportFileName: 'staff_directory_export.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 8)],
      TITLE_ROW,
    ),
    ...R.map(getStaffDirectoryRowConfig, users),
  ],
});

export const exportStaffDirectoryToXLS = R.compose(
  performExcelExport,
  getStaffDirectoryExcelConfig,
  R.pathOr([], ['data', 'searchUsers', 'hits']),
);
