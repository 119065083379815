import { gql } from '@apollo/client';

import { invoiceFragment } from '../fragments.js';

export const getPOInvoices = gql`
  query getPOInvoices(
    $id: ID!
    $input: CollectionSearchParams
    $glCodesInput: ClientGLCodesInput
  ) {
    purchaseOrder(id: $id) {
      _id
      cardNumber
      amount
      project {
        _id
        projectId
      }
      client {
        _id
        glCodes(input: $glCodesInput) {
          code
          name
        }
      }
      supplier {
        _id
      }
      searchPOInvoices(input: $input) {
        hits {
          ...invoiceFragment
        }
      }
    }
  }
  ${invoiceFragment}
`;
