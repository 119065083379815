import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_PO_INVOICE_PERMISSION } from 'poly-security';
import { Loader } from 'poly-site-ui';
import { MODAL_STATUSES } from '../../../constants/invoices.js';
import InvoicesComponent from '../../../components/purchase-order/invoices.js';
import { dateFormatter, EditIcon } from '../../../util/job-costs.js';
import { setInvoicesModal } from '../../../redux/actions/index.js';
import {
  entityFormatter,
  fieldFormatter,
  formatCurrency,
} from '../../../util/general.js';
import {
  calculateTotal,
  invoiceLinkFormatter,
  invoiceAmountFormatter,
} from '../../../utils/invoices/index.js';
import {
  convertGlCodeValueToLabel,
  getGlCodeOptionsByPurchaseOrder,
} from './gl-codes-utils.js';
import { usePOInvoicesQuery } from '../../../hooks/purchase-orders.js';

const getAlign = (horizontal) => ({
  align: { vertical: 'center', horizontal },
});

const invoiceColumns = (dispatch, info) => [
  ['Invoice #', R.identity, invoiceLinkFormatter],
  ['Received Date', R.prop('receiveDate'), dateFormatter],
  ['Invoice Date', R.prop('invoiceDate'), dateFormatter],
  [
    'GL CODE',
    R.compose(convertGlCodeValueToLabel(info.glCodes), R.prop('glCode')),
    fieldFormatter,
  ],
  ['Amount', R.identity, invoiceAmountFormatter, getAlign('end')],
  [
    '',
    R.identity,
    (data) => (
      <EditIcon
        {...{
          dispatch,
          action: setInvoicesModal,
          field: 'editInvoice',
          additional: info,
          disableIf: R.propEq('status', MODAL_STATUSES.BLOCKED),
          permissions: CREATE_PO_INVOICE_PERMISSION,
          ...data,
        }}
      />
    ),
    getAlign('end'),
  ],
];

export default function (props) {
  const dispatch = useDispatch();

  const [type, setType] = useState(null);

  const modalEdit = useSelector((state) => state.invoices.modals.editInvoice);

  useEffect(() => {
    if (modalEdit) {
      setType('edit');
    }
  }, [modalEdit]);

  const id = useSelector((state) => state.location.payload.id);

  const { data, loading } = usePOInvoicesQuery({
    additionalVars: { glCodesInput: { isActive: true }, id },
  });

  const purchaseOrder = data?.purchaseOrder;

  if (!purchaseOrder || loading) return <Loader />;

  const amount = purchaseOrder?.amount;
  const hits = purchaseOrder?.searchPOInvoices.hits;

  const defaultInfo = {
    amount,
    cardNumber: purchaseOrder?.cardNumber,
    glCodes: getGlCodeOptionsByPurchaseOrder(purchaseOrder),
    purchaseOrderId: purchaseOrder?._id,
    supplierId: purchaseOrder?.supplier?._id,
    project: purchaseOrder?.project?.projectId,
    balance: amount - calculateTotal(hits),
  };

  const addInvoice = () => {
    setType('add');
    dispatch(
      setInvoicesModal({
        createInvoice: defaultInfo,
      }),
    );
  };

  const total = formatCurrency(calculateTotal(hits));

  const colsPropsValue = entityFormatter(
    hits,
    invoiceColumns(dispatch, defaultInfo),
  );

  const gridColumns = `
      minmax(70px, 120px)
      repeat(2, minmax(80px, 170px))
      minmax(100px, 1fr)
      minmax(70px, 100px)
      30px
    `;

  return (
    <InvoicesComponent
      {...props}
      {...data}
      {...colsPropsValue}
      dispatch={dispatch}
      isEmpty={!hits.length}
      total={total}
      gridColumns={gridColumns}
      type={type}
      addInvoice={addInvoice}
    />
  );
}
