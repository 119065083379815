import React from 'react';
import { useSelector } from 'react-redux';
import { S } from 'poly-book';
import PropTypes from 'prop-types';
import { highlightTextToReactElement } from 'poly-client-utils';

import { projectPriorityText } from '../../util/project-utils.js';
import { isExist } from '../../util/general.js';
import { projectPriorityColors } from '../../constants/projects.js';

function PriorityComponent({ text, search, textColor }) {
  return (
    <S type="title" textColor={textColor}>
      {isExist(search) ? highlightTextToReactElement(search, text) : text}
    </S>
  );
}

PriorityComponent.propTypes = {
  textColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  search: PropTypes.string,
};

export function ProjectPriority(props) {
  const projectPrioritiesHours = useSelector(
    (state) => state.user.clientConfig?.projectPrioritiesHours,
  );

  const textColor = projectPriorityColors(props.priority);

  const text = projectPriorityText(projectPrioritiesHours, props.priority);

  return <PriorityComponent {...props} textColor={textColor} text={text} />;
}

ProjectPriority.propTypes = {
  priority: PropTypes.string.isRequired,
  search: PropTypes.string,
};

ProjectPriority.displayName = 'ProjectPriority';
