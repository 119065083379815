import { gql } from '@apollo/client';

export const getReportsPmQuery = gql`
  query getReportsPmQuery($input: CollectionSearchParams) {
    searchMaintenancePlans(input: $input) {
      total
      hits {
        _id
        maintenancePlanId
        description
        startDate
        endDate
        status
      }
    }
  }
`;
