export default {
  SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
  SIGN_IN_ERROR: 'SIGN_IN_ERROR',
  SET_FORGOT_PASSWORD_MESSAGE: 'SET_FORGOT_PASSWORD_MESSAGE',
  SET_PROJECTS_FILTER: 'SET_PROJECTS_FILTER',
  SET_USER: 'SET_USER',
  SET_ACTIVE_PROJECT: 'SET_ACTIVE_PROJECT',
  SET_PROJECT_ACTIVE_TAB: 'SET_PROJECT_ACTIVE_TAB',
  SET_PROPERTY_ACTIVE_TAB: 'SET_PROPERTY_ACTIVE_TAB',
  SET_PROPERTY_SIDEBAR: 'SET_PROPERTY_SIDEBAR',
  SET_PROPERTY_PEOPLE_MODAL: 'SET_PROPERTY_PEOPLE_MODAL',
  SET_ASSETS_FILTER: 'SET_ASSETS_FILTER',
  SET_ASSET_ACTIVE_TAB: 'SET_ASSET_ACTIVE_TAB',
  SET_ASSETS_PAGINATION_TOTAL: 'SET_ASSETS_PAGINATION_TOTAL',
  SET_ASSETS_PAGINATION_CURRENT_PAGE: 'SET_ASSETS_PAGINATION_CURRENT',
  SET_ASSETS_PAGINATION_PAGE_SIZE: 'SET_ASSETS_PAGINATION_PAGE_SIZE',
  SET_ASSETS_PAGINATION_DROPDOWN_LABEL: 'SET_ASSETS_PAGINATION_DROPDOWN_LABEL',
  SET_ASSETS_TABLE_SEARCH_STRING: 'SET_ASSETS_TABLE_SEARCH_STRING',
  SET_TIMELOGS_MODAL: 'SET_TIMELOGS_MODAL',
  SET_MATERIALS_MODAL: 'SET_MATERIALS_MODAL',
  SET_RESPONSE_TEAM_MODAL: 'SET_RESPONSE_TEAM_MODAL',
  SET_EDIT_SUPPLIER_MODAL: 'SET_EDIT_SUPPLIER_MODAL',
  SET_ADD_SUPPLIER_MODAL: 'SET_ADD_SUPPLIER_MODAL',
  SET_SUPPORT_MODAL: 'SET_SUPPORT_MODAL',
  SET_PROPERTIES_FILTER: 'SET_PROPERTIES_FILTER',
  SET_PROPERTIES_TABLE_SEARCH_STRING: 'SET_PROPERTIES_TABLE_SEARCH_STRING',
  SET_PROPERTIES_PAGINATION_TOTAL: 'SET_PROPERTIES_PAGINATION_TOTAL',
  SET_PROPERTIES_PAGINATION_CURRENT_PAGE: 'SET_PROPERTIES_PAGINATION_CURRENT',
  SET_PROPERTIES_PAGINATION_PAGE_SIZE: 'SET_PROPERTIES_PAGINATION_PAGE_SIZE',
  SET_PROPERTIES_PAGINATION_DROPDOWN_LABEL:
    'SET_PROPERTIES_PAGINATION_DROPDOWN_LABEL',
  SET_STAFF_FILTER: 'SET_STAFF_FILTER',
  SET_STAFF_SIDEBAR: 'SET_STAFF_SIDEBAR',
  SET_STAFF_SIDEBAR_ACTIVE_TAB: 'SET_STAFF_SIDEBAR_ACTIVE_TAB',
  SET_STAFF_TABLE_SEARCH_STRING: 'SET_STAFF_TABLE_SEARCH_STRING',
  SET_STAFF_MODAL: 'SET_STAFF_MODAL',
  SET_EDIT_PROJECT_MODAL: 'SET_EDIT_PROJECT_MODAL',
  SET_PROJECT_MODAL: 'SET_PROJECT_MODAL',
  SET_PROJECTS_TABLE_SEARCH_STRING: 'SET_PROJECTS_TABLE_SEARCH_STRING',
  SET_SUPPLIERS_FILTER: 'SET_SUPPLIERS_FILTER',
  SET_SUPPLIERS_TABLE_SEARCH_STRING: 'SET_SUPPLIERS_TABLE_SEARCH_STRING',
  SET_SUPPLIER_ACTIVE_TAB: 'SET_SUPPLIER_ACTIVE_TAB',
  SET_SUPPLIER_SIDEBAR: 'SET_SUPPLIER_SIDEBAR',
  SET_SUPPLIER_PEOPLE_MODAL: 'SET_SUPPLIER_PEOPLE_MODAL',
  SET_IS_PAGE_CHECKING: 'SET_IS_PAGE_CHECKING',
  SET_REPORT_PROJECT_FILTER: 'SET_REPORT_PROJECT_FILTER',
  SET_REPORT_ACTIVE_PROJECT: 'SET_REPORT_ACTIVE_PROJECT',
  SET_REPORT_PROJECT_ACTIVE_TAB: 'SET_REPORT_PROJECT_ACTIVE_TAB',
  SET_REPORT_PROJECT_ASSETS_ACTIVE_TAB: 'SET_REPORT_PROJECT_ASSETS_ACTIVE_TAB',
  SET_REPORT_TABLE_SEARCH_STRING: 'SET_REPORT_TABLE_SEARCH_STRING',
  SET_REPORT_MODAL: 'SET_REPORT_MODAL',
  SET_REPORT_PM_PROJECT_FILTER: 'SET_REPORT_PM_PROJECT_FILTER',
  SET_REPORT_PM_PROJECT_ACTIVE_TAB: 'SET_REPORT_PM_PROJECT_ACTIVE_TAB',
  SET_REPORT_PM_TABLE_SEARCH_STRING: 'SET_REPORT_PM_TABLE_SEARCH_STRING',
  SET_PM_PROJECTS_FILTER: 'SET_PM_PROJECTS_FILTER',
  SET_PM_PROJECTS_TABLE_SEARCH_STRING: 'SET_PM_PROJECTS_TABLE_SEARCH_STRING',
  SET_PM_PROJECTS_TECHNICIANS_MODAL: 'SET_PM_PROJECTS_TECHNICIANS_MODAL',
  SET_PM_PROJECT_ACTIVE_TAB: 'SET_PM_PROJECT_ACTIVE_TAB',
  SET_PM_PROJECT_ASSETS_ACTIVE_TAB: 'SET_PM_PROJECT_ASSETS_ACTIVE_TAB',
  TOGGLE_PM_PROJECT: 'TOGGLE_PM_PROJECT',
  SET_SELECTED_PM_PROJECTS: 'SET_SELECTED_PM_PROJECTS',
  SET_MR_PM_PROJECT_ACTIVE_TAB: 'SET_MR_PM_PROJECT_ACTIVE_TAB',
  SET_PROJECTS_PAGINATION_TOTAL: 'SET_PROJECTS_PAGINATION_TOTAL',
  SET_PROJECTS_PAGINATION_CURRENT_PAGE: 'SET_PROJECTS_PAGINATION_CURRENT',
  SET_PROJECTS_PAGINATION_PAGE_SIZE: 'SET_PROJECTS_PAGINATION_PAGE_SIZE',
  SET_PROJECTS_PAGINATION_DROPDOWN_LABEL:
    'SET_PROJECTS_PAGINATION_DROPDOWN_LABEL',
  SET_PM_PROJECTS_PAGINATION_CURRENT_PAGE:
    'SET_PM_PROJECTS_PAGINATION_CURRENT_PAGE',
  SET_PM_PROJECTS_PAGINATION_TOTAL: 'SET_PM_PROJECTS_PAGINATION_TOTAL',
  SET_PM_PROJECTS_PAGINATION_PAGE_SIZE: 'SET_PM_PROJECTS_PAGINATION_PAGE_SIZE',
  SET_PM_PROJECTS_PAGINATION_DROPDOWN_LABEL:
    'SET_PM_PROJECTS_PAGINATION_DROPDOWN_LABEL',
  SET_MR_PM_PROJECTS_PAGINATION_CURRENT_PAGE:
    'SET_MR_PM_PROJECTS_PAGINATION_CURRENT_PAGE',
  SET_MR_PM_PROJECTS_PAGINATION_TOTAL: 'SET_MR_PM_PROJECTS_PAGINATION_TOTAL',
  SET_MR_PM_PROJECTS_PAGINATION_PAGE_SIZE:
    'SET_MR_PM_PROJECTS_PAGINATION_PAGE_SIZE',
  SET_MR_PM_PROJECTS_PAGINATION_DROPDOWN_LABEL:
    'SET_MR_PM_PROJECTS_PAGINATION_DROPDOWN_LABEL',
  SET_SUPPLIERS_PAGINATION_TOTAL: 'SET_SUPPLIERS_PAGINATION_TOTAL',
  SET_SUPPLIERS_PAGINATION_CURRENT_PAGE: 'SET_SUPPLIERS_PAGINATION_CURRENT',
  SET_SUPPLIERS_PAGINATION_PAGE_SIZE: 'SET_SUPPLIERS_PAGINATION_PAGE_SIZE',
  SET_SUPPLIERS_PAGINATION_DROPDOWN_LABEL:
    'SET_SUPPLIERS_PAGINATION_DROPDOWN_LABEL',
  SET_REPORT_PROJECTS_PAGINATION_TOTAL: 'SET_REPORT_PROJECTS_PAGINATION_TOTAL',
  SET_REPORT_PROJECTS_PAGINATION_CURRENT_PAGE:
    'SET_REPORT_PROJECTS_PAGINATION_CURRENT_PAGE',
  SET_REPORT_PROJECTS_PAGINATION_PAGE_SIZE:
    'SET_REPORT_PROJECTS_PAGINATION_PAGE_SIZE',
  SET_REPORT_PROJECTS_PAGINATION_DROPDOWN_LABEL:
    'SET_REPORT_PROJECTS_PAGINATION_DROPDOWN_LABEL',
  SET_STAFF_PAGINATION_TOTAL: 'SET_STAFF_PAGINATION_TOTAL',
  SET_STAFF_PAGINATION_CURRENT_PAGE: 'SET_STAFF_PAGINATION_CURRENT',
  SET_STAFF_PAGINATION_PAGE_SIZE: 'SET_STAFF_PAGINATION_PAGE_SIZE',
  SET_STAFF_PAGINATION_DROPDOWN_LABEL: 'SET_STAFF_PAGINATION_DROPDOWN_LABEL',
  SET_POS_MODAL: 'SET_POS_MODAL',
  SET_POS_TABLE_SEARCH_STRING: 'SET_POS_TABLE_SEARCH_STRING',
  SET_POS_PAGINATION_CURRENT_PAGE: 'SET_POS_PAGINATION_CURRENT_PAGE',
  SET_POS_PAGINATION_TOTAL: 'SET_POS_PAGINATION_TOTAL',
  SET_POS_PAGINATION_PAGE_SIZE: 'SET_POS_PAGINATION_PAGE_SIZE',
  SET_POS_PAGINATION_DROPDOWN_LABEL: 'SET_POS_PAGINATION_DROPDOWN_LABEL',
  SET_SELECTED_POS: 'SET_SELECTED_POS',
  TOGGLE_PO: 'TOGGLE_PO',
  SET_PO_ACTIVE_TAB: 'SET_PO_ACTIVE_TAB',
  SET_APPROVED_POS_TABLE_SEARCH_STRING: 'SET_APPROVED_POS_TABLE_SEARCH_STRING',
  SET_APPROVED_POS_PAGINATION_CURRENT_PAGE:
    'SET_APPROVED_POS_PAGINATION_CURRENT_PAGE',
  SET_APPROVED_POS_PAGINATION_TOTAL: 'SET_APPROVED_POS_PAGINATION_TOTAL',
  SET_APPROVED_POS_PAGINATION_PAGE_SIZE:
    'SET_APPROVED_POS_PAGINATION_PAGE_SIZE',
  SET_APPROVED_POS_PAGINATION_DROPDOWN_LABEL:
    'SET_APPROVED_POS_PAGINATION_DROPDOWN_LABEL',
  SET_INVOICES_TABLE_SEARCH_STRING: 'SET_INVOICES_TABLE_SEARCH_STRING',
  SET_INVOICES_PAGINATION_CURRENT_PAGE: 'SET_INVOICES_PAGINATION_CURRENT_PAGE',
  SET_INVOICES_PAGINATION_TOTAL: 'SET_INVOICES_PAGINATION_TOTAL',
  SET_INVOICES_PAGINATION_PAGE_SIZE: 'SET_INVOICES_PAGINATION_PAGE_SIZE',
  SET_INVOICES_PAGINATION_DROPDOWN_LABEL:
    'SET_INVOICES_PAGINATION_DROPDOWN_LABEL',
  SET_SELECTED_INVOICES: 'SET_SELECTED_INVOICES',
  TOGGLE_INVOICE: 'TOGGLE_INVOICE',
  SET_INVOICES_MODAL: 'SET_INVOICES_MODAL',
  SET_COMPLETED_WOS_TABLE_SEARCH_STRING:
    'SET_COMPLETED_WOS_TABLE_SEARCH_STRING',
  SET_COMPLETED_WOS_PAGINATION_CURRENT_PAGE:
    'SET_COMPLETED_WOS_PAGINATION_CURRENT_PAGE',
  SET_COMPLETED_WOS_PAGINATION_TOTAL: 'SET_COMPLETED_WOS_PAGINATION_TOTAL',
  SET_COMPLETED_WOS_PAGINATION_PAGE_SIZE:
    'SET_COMPLETED_WOS_PAGINATION_PAGE_SIZE',
  SET_COMPLETED_WOS_PAGINATION_DROPDOWN_LABEL:
    'SET_COMPLETED_WOS_PAGINATION_DROPDOWN_LABEL',
  SET_COMPLETED_WOS_DATE: 'SET_COMPLETED_WOS_DATE',
  SET_COMPLETED_WOS_SERVICE_TYPE: 'SET_COMPLETED_WOS_SERVICE_TYPE',
  SET_COMPLETED_WOS_ACTIVE_TAB: 'SET_COMPLETED_WOS_ACTIVE_TAB',
  SET_COMPLETED_PMS_TABLE_SEARCH_STRING:
    'SET_COMPLETED_PMS_TABLE_SEARCH_STRING',
  SET_COMPLETED_PMS_PAGINATION_CURRENT_PAGE:
    'SET_COMPLETED_PMS_PAGINATION_CURRENT_PAGE',
  SET_COMPLETED_PMS_PAGINATION_TOTAL: 'SET_COMPLETED_PMS_PAGINATION_TOTAL',
  SET_COMPLETED_PMS_PAGINATION_PAGE_SIZE:
    'SET_COMPLETED_PMS_PAGINATION_PAGE_SIZE',
  SET_COMPLETED_PMS_PAGINATION_DROPDOWN_LABEL:
    'SET_COMPLETED_PMS_PAGINATION_DROPDOWN_LABEL',
  SET_COMPLETED_PMS_DATE: 'SET_COMPLETED_PMS_DATE',
  SET_COMPLETED_PMS_SERVICE_TYPE: 'SET_COMPLETED_PMS_SERVICE_TYPE',
  SET_COMPLETED_PMS_ACTIVE_TAB: 'SET_COMPLETED_PMS_ACTIVE_TAB',
  SET_TECHNICIAN_STATS_TABLE_SEARCH_STRING:
    'SET_TECHNICIAN_STATS_TABLE_SEARCH_STRING',
  SET_TECHNICIAN_STATS_PAGINATION_CURRENT_PAGE:
    'SET_TECHNICIAN_STATS_PAGINATION_CURRENT_PAGE',
  SET_TECHNICIAN_STATS_PAGINATION_TOTAL:
    'SET_TECHNICIAN_STATS_PAGINATION_TOTAL',
  SET_TECHNICIAN_STATS_PAGINATION_PAGE_SIZE:
    'SET_TECHNICIAN_STATS_PAGINATION_PAGE_SIZE',
  SET_TECHNICIAN_STATS_PAGINATION_DROPDOWN_LABEL:
    'SET_TECHNICIAN_STATS_PAGINATION_DROPDOWN_LABEL',
  SET_TECHNICIAN_STATS_DATE: 'SET_TECHNICIAN_STATS_DATE',
  SET_FILE_MODAL: 'SET_FILE_MODAL',
  SET_PRINT_TABLE_PROPS: 'SET_PRINT_TABLE_PROPS',
  SET_ASSET_MODAL: 'SET_ASSET_MODAL',
};
