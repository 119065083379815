import { gql } from '@apollo/client';

import { contactUserFragment } from '../fragments.js';
import { supplierCompanyFields } from './fragments.js';

export const getSupplierPeople = gql`
  query getSupplierPeople($supplierId: ID!, $input: CollectionSearchParams) {
    supplier(id: $supplierId) {
      _id
      company {
        ...supplierCompanyFields
      }
      searchContacts(input: $input) {
        hits {
          ...contactUserFragment
        }
      }
    }
  }
  ${contactUserFragment}
  ${supplierCompanyFields}
`;
