import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportPMInfo from '../../components/report-pm/info.js';
import { ALL_RESULTS } from '../../constants/pagination.js';
import {
  setReportPmPaginationCurrentPage,
  setReportPmPaginationPageSize,
  setReportPmPaginationTotal,
  setReportPmProjectFilter,
} from '../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const activeFilter = useSelector((state) => state.reportPM.typeFilter);
  const label = useSelector((state) => state.reportPM.paginationDropdownLabel);

  const onClick = useCallback(
    (filter, total) => {
      if (label === ALL_RESULTS) {
        dispatch(setReportPmPaginationPageSize(total));
      }

      dispatch(setReportPmProjectFilter(filter));
      dispatch(setReportPmPaginationTotal(total));
      dispatch(setReportPmPaginationCurrentPage(1));
    },
    [
      dispatch,
      setReportPmPaginationPageSize,
      setReportPmProjectFilter,
      setReportPmPaginationTotal,
      setReportPmPaginationCurrentPage,
    ],
  );

  return (
    <ReportPMInfo {...props} onClick={onClick} activeFilter={activeFilter} />
  );
}
