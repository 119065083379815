import types from '../types.js';

export const setInvoicesModal = (modal) => ({
  type: types.SET_INVOICES_MODAL,
  payload: modal,
});

export const setSelectedInvoices = (ids) => ({
  type: types.SET_SELECTED_INVOICES,
  payload: ids,
});

export const toggleInvoice = (id) => ({
  type: types.TOGGLE_INVOICE,
  payload: id,
});

export const setInvoicesTableSearchString = (query) => ({
  type: types.SET_INVOICES_TABLE_SEARCH_STRING,
  payload: query,
});

export const setInvoicesPaginationCurrentPage = (current) => ({
  type: types.SET_INVOICES_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setInvoicesPaginationTotal = (total) => ({
  type: types.SET_INVOICES_PAGINATION_TOTAL,
  payload: total,
});

export const setInvoicesPaginationPageSize = (size) => ({
  type: types.SET_INVOICES_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setInvoicesPaginationDropdownLabel = (label) => ({
  type: types.SET_INVOICES_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});
