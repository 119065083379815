import React from 'react';
import * as R from 'ramda';
import { bool, string, shape, func, arrayOf, objectOf } from 'prop-types';
import {
  IconButton,
  Heading,
  Button,
  Editor,
  Input,
  Modal,
  Grid,
  Icon,
  Pip,
  S,
} from 'poly-book';

import {
  PROJECT_STATUS_COLOR_MAP,
  PROJECT_STATUS_TEXT_MAP,
} from 'poly-site-ui';
import Select from '../select/index.js';
import ServerError from '../server-error/index.js';
import ButtonLoader from '../loader/button.js';
import {
  formatPlaceholder,
  formatSearchPlaceholder,
} from '../../util/select.js';
import { Rows } from '../../util/forms/index.js';
import { ProjectStatusesArray } from '../../constants/projects.js';

const Form = Modal.Item.withComponent(Grid);

const MAX_WIDTH = 320;

function SimpleTitle({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

SimpleTitle.propTypes = {
  label: string.isRequired,
};

function ColoredTitle({ label }) {
  return (
    <Pip color={PROJECT_STATUS_COLOR_MAP[label]}>
      <S type="title">{PROJECT_STATUS_TEXT_MAP[label]}</S>
    </Pip>
  );
}

ColoredTitle.propTypes = {
  label: string.isRequired,
};

function EditProject({
  editProjectModal,
  projectId,
  isModalOpen: show,

  // handlers
  setRef,
  onSubmit,
  closeModal,
  selectProperty,
  selectStatus,
  setPropertySearch,

  // values
  selectedProperty,
  selectedStatus,
  properties,
  loading,
  errors,
  propertyLoading,
  noPropertyResults,
}) {
  return (
    <Modal {...{ show }}>
      <Modal.Item>
        <Heading>
          <Heading.h5>{projectId}</Heading.h5>
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <Modal.Item margin={20}>
        <Heading.h3 lighter>Edit PM Recurring Work Order</Heading.h3>
      </Modal.Item>
      <Modal.Item margin={20}>
        <Heading.h5>WO Information</Heading.h5>
      </Modal.Item>
      <form {...{ onSubmit }}>
        <Form
          columns={`120px minmax(200px, ${MAX_WIDTH}px)`}
          margin={30}
          simple
        >
          <Rows
            items={[
              {
                title: {
                  value: 'Status',
                },
                item: (
                  <Select
                    value={selectedStatus}
                    onChange={selectStatus}
                    placeholder={formatPlaceholder('Status')}
                    options={ProjectStatusesArray.map((status) => ({
                      value: status,
                      label: status,
                    }))}
                    optionRenderer={ColoredTitle}
                  />
                ),
              },
              {
                title: {
                  value: 'Description',
                  props: ['required'],
                },
                error: R.prop('description', errors),
                item: (
                  <Input
                    name="description"
                    placeholder="Description"
                    defaultValue={R.path(
                      ['data', 'description'],
                      editProjectModal,
                    )}
                    invalid={!!R.prop('description', errors)}
                    disabled
                  />
                ),
              },
              {
                title: {
                  value: 'Details',
                  props: ['', 'description'],
                },
                item: (
                  <Editor
                    registerRef={setRef}
                    placeholder="Details"
                    defaultValue={R.path(
                      ['data', 'invoiceDescription'],
                      editProjectModal,
                    )}
                    readOnly
                    style={{
                      paddingTop: '15px',
                    }}
                  />
                ),
              },
              {
                title: {
                  value: 'Location',
                },
                item: (
                  <Input
                    name="location"
                    placeholder="Enter a Location"
                    defaultValue={R.path(
                      ['data', 'location'],
                      editProjectModal,
                    )}
                    disabled
                  />
                ),
              },
              {
                title: {
                  value: 'Property',
                  props: ['required'],
                },
                error: R.prop('property', errors),
                item: (
                  <Select
                    value={selectedProperty}
                    onChange={selectProperty}
                    onInputChange={setPropertySearch}
                    isLoading={propertyLoading}
                    placeholder={formatSearchPlaceholder('Property')}
                    noResultsText={noPropertyResults}
                    invalid={!!R.prop('property', errors)}
                    options={properties.map((property) => ({
                      value: R.prop('_id', property),
                      label: R.prop('name', property),
                    }))}
                    optionRenderer={SimpleTitle}
                    disabled
                  />
                ),
              },
            ]}
          />
        </Form>
        <ServerError
          error={R.prop('server', errors)}
          style={{ maxWidth: 450 }}
        />
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">Save Changes</S>
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
}

EditProject.propTypes = {
  projectId: string.isRequired,
  editProjectModal: shape({
    type: string,
    data: shape({
      description: string,
      invoiceDescription: shape({
        ops: arrayOf(objectOf(string)),
      }),
      location: string,
    }),
  }),
  loading: bool.isRequired,
  propertyLoading: bool.isRequired,
  errors: shape({
    server: string,
    priority: string,
    property: string,
    description: string,
  }),
  isModalOpen: bool.isRequired,
  closeModal: func.isRequired,
  onSubmit: func.isRequired,
  properties: arrayOf(
    shape({
      _id: string,
      name: string,
    }),
  ),
  selectedStatus: string,
  selectedProperty: shape({
    value: string,
    label: string,
  }),
  setRef: func.isRequired,
  selectStatus: func.isRequired,
  selectProperty: func.isRequired,
  setPropertySearch: func.isRequired,
  noPropertyResults: string,
};

EditProject.defaultProps = {
  properties: [],
  noPropertyResults: null,
  selectedStatus: null,
  selectedProperty: null,
  editProjectModal: null,
  errors: {},
};

export default EditProject;
