import React from 'react';
import { Widget } from 'poly-book';
import styled from 'styled-components';

import ProjectMrPmInfoContainer from '../containers/mr-pm-project/left-block/index.js';
import ProjectMrPmDataContainer from '../containers/mr-pm-project/right-block/index.js';

const WidgetBlock = styled(Widget)`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-rows: auto 1fr;
`;

function MrPmProjectPageLayout() {
  return (
    <>
      <Widget>
        <ProjectMrPmInfoContainer />
      </Widget>
      <WidgetBlock>
        <ProjectMrPmDataContainer />
      </WidgetBlock>
    </>
  );
}

export default MrPmProjectPageLayout;
