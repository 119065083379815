import { RecurringProjectStatuses } from 'poly-constants';

import types from '../types.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import { REPORT } from '../../constants/routes.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  activeProject: null,
  typeFilter: RecurringProjectStatuses.ACTIVE,
  activeTab: 'wos',
  tableSearchString: '',
  assets: {
    activeTab: 'details',
  },
  modal: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_REPORT_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: action.payload,
      };
    }
    case types.SET_REPORT_PROJECT_FILTER: {
      return {
        ...state,
        typeFilter: action.payload,
      };
    }
    case types.SET_REPORT_PROJECT_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case types.SET_REPORT_MODAL: {
      return {
        ...state,
        modal: action.payload,
      };
    }
    case REPORT: {
      return {
        ...state,
        tableSearchString: '',
      };
    }
    case types.SET_REPORT_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_REPORT_PROJECT_ASSETS_ACTIVE_TAB: {
      return {
        ...state,
        assets: { activeTab: action.payload },
      };
    }
    case types.SET_REPORT_PROJECTS_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_REPORT_PROJECTS_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_REPORT_PROJECTS_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_REPORT_PROJECTS_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
