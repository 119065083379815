import * as R from 'ramda';
import {
  highlightField,
  highlightProject,
  highlightProjectStatus,
} from '../../utils/highlight/index.js';
import { dateFormatter } from '../../utils/projects/index.js';
import { setResponseTeamModal } from '../../redux/actions/index.js';
import { projectTypeConverter } from '../../util/project-utils.js';
import {
  HoveredGridRow,
  assignedToFormatter,
} from '../../components/projects/assigned-to.js';

const getAlign = (horizontal) => ({ align: { vertical: 'start', horizontal } });

const columns = (search, dispatch) => [
  ['WO #', R.identity, highlightProject(search)],
  ['CORRIGO WO #', R.prop('externalNumber'), highlightField(search)],
  ['WO Type', R.prop('type'), projectTypeConverter(search)],
  ['Service Type', R.path(['serviceType', 'name']), highlightField(search)],
  ['Description', R.prop('description'), highlightField(search)],
  ['Property', R.path(['property', 'name']), highlightField(search)],
  [
    'Assigned To',
    R.identity,
    assignedToFormatter(search, dispatch, setResponseTeamModal),
    getAlign('stretch'),
    R.path(['technicians', 0, 'fullName']),
  ],
  ['Due', R.prop('endDate'), dateFormatter(search)],
  ['Start', R.prop('startDate'), dateFormatter(search)],
  ['Status', R.identity, highlightProjectStatus(search)],
];

export default function usePropsToProjectsTable({
  projects,
  dispatch,
  tableSearchString,
}) {
  const columnsValue = R.map(R.nth(1), columns(tableSearchString));
  const headers = R.map(R.nth(0), columns(tableSearchString));

  const valuesToSort = R.map(
    (item) => R.compose(R.defaultTo(R.nth(1, item)), R.nth(4))(item),
    columns(tableSearchString),
  );

  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    columns(tableSearchString, dispatch),
  );

  const propsObj = R.map(R.nth(3), columns(tableSearchString));

  const gridColumns = `
        110px
        120px
        minmax(80px, 200px)
        repeat(3, minmax(100px, 700px))
        minmax(80px, 150px)
        minmax(80px, 120px)
        minmax(80px, 120px)
        minmax(80px, 160px)
      `;
  const GridRowComponent = HoveredGridRow;

  return {
    rowsValue: projects,
    columns: columnsValue,
    headers,
    valuesToSort,
    formats,
    propsObj,
    gridColumns,
    GridRowComponent,
  };
}
