import * as R from 'ramda';
import React, { useCallback } from 'react';
import { Checkbox } from 'poly-book';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function TableCheckbox({ statePath, onChange, id, ...commonProps }) {
  const selectedProjects = useSelector((state) => R.path(statePath, state));

  const checked = selectedProjects.includes(id);
  const onChangeValue = useCallback(() => onChange(id), [onChange, id]);

  return (
    <Checkbox
      {...commonProps}
      id={id}
      onChange={onChangeValue}
      statePath={statePath}
      checked={checked}
    />
  );
}
export default TableCheckbox;

TableCheckbox.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string,
  statePath: PropTypes.arrayOf(PropTypes.string),
};
