import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from 'redux-actions';
import { LoginPageBase } from 'poly-common-ui';
import logIn from '../../redux/thunks/logIn.js';
import { routeForgotPassword, routeMain } from '../../redux/actions/index.js';
import * as routes from '../../constants/routes.js';
import { whitelist } from '../../routes-map.js';

export const APP_TITLE = 'Site';

export function LoginPage() {
  const dispatch = useDispatch();
  const { forgotPasswordMessage } = useSelector((state) => state.authorization);

  const { prev } = useSelector((state) => state.location);

  const goToForgotPassword = () => dispatch(routeForgotPassword());

  const onSuccess = () => {
    dispatch(logIn());

    if (prev?.type && !whitelist.includes(prev?.type)) {
      // eslint-disable-next-line import/namespace
      dispatch(createAction(routes[prev.type])(prev.payload));
    } else {
      dispatch(routeMain());
    }
  };

  return (
    <LoginPageBase
      forgotPasswordMessage={forgotPasswordMessage}
      goToForgotPassword={goToForgotPassword}
      onSubmitSuccess={onSuccess}
      title={APP_TITLE}
    />
  );
}
