import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Loader, projectStatusUITransform } from 'poly-site-ui';
import Projects from '../../../components/property/tabs/projects.js';
import { projectFormatter } from '../../../util/project-utils.js';
import { dateFormatter } from '../../../util/job-costs.js';
import {
  entityFormatter,
  fieldFormatter,
  noDataToDisplayWidget,
} from '../../../util/general.js';
import {
  usePropertyChildRecurringProjects,
  usePropertyProjects,
} from '../../../hooks/property/projects.js';

const projectsColumns = [
  ['WO #', R.prop('projectId'), projectFormatter],
  ['Description', R.prop('description'), fieldFormatter],
  ['End Date', R.prop('endDate'), dateFormatter],
  ['Status', R.identity, projectStatusUITransform],
];

export default function (props) {
  const propertyId = useSelector((state) => state.location.payload.id);

  const { projects, projectsLoading } = usePropertyProjects(propertyId);

  const { childRecurringProjects, childRecurringProjectsLoading } =
    usePropertyChildRecurringProjects(propertyId);

  if (projectsLoading || childRecurringProjectsLoading) return <Loader />;

  if (!projects.length && !childRecurringProjects.length)
    return noDataToDisplayWidget('Projects')();

  const projectsObj = entityFormatter(
    [...projects, ...childRecurringProjects],
    projectsColumns,
  );

  return <Projects {...props} projects={projectsObj} />;
}
