import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { Loader } from 'poly-site-ui';
import Checklist from '../../../components/mr-pm-project/right-block/checklist.js';

import { makeListFromString } from '../../../utils/mr-pm-projects/index.js';
import { usePmProjectChecklist } from '../../../hooks/project-job-costs.js';

export default function (props) {
  const projectId = useSelector((state) => state.location.payload.id);

  const { loading, restPmProjectChecklistProps } =
    usePmProjectChecklist(projectId);

  const { checklist, materials, notes, searchFiles } =
    restPmProjectChecklistProps;

  const checklistValue = makeListFromString(checklist);
  const materialsValue = makeListFromString(materials);
  const notesValue = notes || '';
  const filesValue = R.pathOr([], ['hits'], searchFiles);

  if (loading) return <Loader />;

  return (
    <Checklist
      {...props}
      checklist={checklistValue}
      materials={materialsValue}
      notes={notesValue}
      files={filesValue}
    />
  );
}
