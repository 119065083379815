import { gql } from '@apollo/client';

export const invoicesQuery = gql`
  query invoicesQuery($input: CollectionSearchParams) {
    searchPOInvoices(input: $input) {
      total
      hits {
        _id
        total
        postedAt
        invoiceNumber
        invoiceFile {
          url
        }
        purchaseOrder {
          _id
          cardNumber
          isInScope
          status
          searchPOInvoices(input: {}) {
            total
          }
        }
        property {
          _id
          name
        }
        project {
          _id
          projectId
          type
          property {
            _id
            name
          }
        }
        supplier {
          _id
          company {
            name
          }
        }
      }
    }
  }
`;
