import types from '../types.js';

export const setAssetsTableSearchString = (query) => ({
  type: types.SET_ASSETS_TABLE_SEARCH_STRING,
  payload: query,
});

export const setAssetsFilter = (property) => ({
  type: types.SET_ASSETS_FILTER,
  payload: property,
});

export const setAssetsPaginationCurrentPage = (current) => ({
  type: types.SET_ASSETS_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setAssetsPaginationTotal = (total) => ({
  type: types.SET_ASSETS_PAGINATION_TOTAL,
  payload: total,
});

export const setAssetsPaginationPageSize = (size) => ({
  type: types.SET_ASSETS_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setAssetsPaginationDropdownLabel = (label) => ({
  type: types.SET_ASSETS_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});
