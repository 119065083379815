import { useMutation } from '@apollo/client';
import { sendPOSupplierConfirmation } from '../../queries/index.js';

export const usePOSupplierConfirmationSend = () => {
  const [mutate, { loading }] = useMutation(sendPOSupplierConfirmation);

  return {
    sendConfirmation: (purchaseOrderId, emails, instructions) =>
      mutate({
        variables: { purchaseOrderId, emails, instructions },
      }),
    loading,
  };
};
