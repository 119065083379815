import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, updatesSubscription, usePaginatorState } from 'poly-site-ui';
import { paginationToQueryParams, useReactiveQuery } from 'poly-client-utils';
import { gql } from '@apollo/client';
import UpdatesComponent from '../../../components/project/project-updates.js';
import { updateFragment } from '../../../queries/fragments.js';
import useFilesUpdates from '../../../hooks/useFiles.js';

function UpdatesComponentWithFiles(props) {
  const additionalFilesProps = useFilesUpdates({
    documentId: props.documentId,
    collection: props.collection,
  });

  return <UpdatesComponent {...props} {...additionalFilesProps} />;
}

UpdatesComponentWithFiles.propTypes = {
  documentId: PropTypes.string,
  collection: PropTypes.string,
};

const PO_UPDATES_QUERY = gql`
  query getPOUpdates($id: ID!, $documentUpdatesInput: DocumentUpdatesInput!) {
    purchaseOrder(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...updateFragment
        }
        total
      }
    }
  }
  ${updateFragment}
`;

const FILES_SUBSCRIPTION = gql`
  subscription filesChanged($input: CollectionSearchParams!) {
    searchFileChanged(input: $input) {
      id
      type
    }
  }
`;

// getPurchaseOrderUpdateProps :: QueryData - > POUpdateData
// POUpdateData = {total: Number, documentId: ID, updates: [Update] }
const getPurchaseOrderUpdateProps = R.compose(
  R.applySpec({
    updates: R.pathOr([], ['documentUpdates', 'hits']),
    total: R.path(['documentUpdates', 'total']),
    documentId: R.path(['_id']),
  }),
  R.prop('purchaseOrder'),
);

export function PurchaseOrderUpdates(props) {
  const id = useSelector(R.path(['location', 'payload', 'id']));
  const { paginator } = usePaginatorState();
  const dispatch = useDispatch();
  const { size, currentPage } = paginator;
  const { data, loading } = useReactiveQuery(
    PO_UPDATES_QUERY,
    [updatesSubscription, FILES_SUBSCRIPTION],
    {
      queryOptions: {
        variables: {
          documentUpdatesInput: {
            ...paginationToQueryParams({ itemsPerPage: size, currentPage }),
          },
          id,
          fetchPolicy: 'network-only',
        },
      },
      subscriptionOptions: [
        {
          variables: { input: { documentId: id } },
        },
        {
          variables: { input: {} },
        },
      ],
    },
  );

  const updateProps = {
    ...props,
    ...getPurchaseOrderUpdateProps(data),
    collection: 'purchaseOrders',
    dispatch,
  };

  if (loading) {
    return <Loader />;
  }

  return <UpdatesComponentWithFiles {...updateProps} />;
}
