import React, { useCallback } from 'react';
import { startOfDay } from 'date-fns';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { highlightTextToReactElement } from 'poly-client-utils';
import { NOTHING_UI_STRING } from 'poly-constants';
import { L } from 'poly-book';
import { alwaysNewDate } from 'poly-utils';
import { AbsoluteLoader } from 'poly-site-ui';
import { TECHNICIAN_STATS_SIDEBAR_TABS } from './constants.js';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import { commonUsersQuery } from '../../utils/staff/staff-filters.js';
import { isExist, noDataToDisplay } from '../../util/general.js';
import { SelectDateRangeMessage } from '../../utils/projects/index.js';
import { highlightField } from '../../utils/highlight/index.js';
import {
  setTechniciansSidebar,
  setTechnicianStatsPaginationDropdownLabel,
  setTechnicianStatsPaginationCurrentPage,
  setTechnicianStatsPaginationPageSize,
  setTechnicianStatsPaginationTotal,
} from '../../redux/actions/index.js';
import { useTechnicianStats, SORTING } from '../../hooks/staff.js';
import usePagination from '../../hooks/usePagination.js';
import { useSortableTable } from '../../hooks/table-enhancers.js';
import { hasUserTechnicianGroup } from '../../utils/user/index.js';

const numberFormatter = (search) => (num) =>
  highlightField(search)(num.toFixed(2).replace(/\.00$/, ''));

function OpenSidebarLink(props) {
  const dispatch = useDispatch();

  const { user, dateRange, activeTab } = props;

  const onClick = useCallback(() =>
    dispatch(setTechniciansSidebar(user._id, dateRange, activeTab)),
  );

  return <L {...props} onClick={onClick} />;
}

OpenSidebarLink.propTypes = {
  activeTab: PropTypes.string,
  bold: PropTypes.bool,
  dateRange: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
  }),
  user: PropTypes.shape({
    _id: PropTypes.string,
  }),
};

// excludeNonTechniciansStats :: (User -> String) -> User -> String
const excludeNonTechniciansStats = (getStats) =>
  R.ifElse(hasUserTechnicianGroup, getStats, R.always(NOTHING_UI_STRING));

const countWithLink = (getCount, dateRange, activeTab, search) =>
  function (user) {
    const count = getCount(user);
    const content = isExist(search)
      ? highlightTextToReactElement(search, count)
      : count;
    return (
      <OpenSidebarLink
        user={user}
        bold
        dateRange={dateRange}
        activeTab={activeTab}
      >
        {content}
      </OpenSidebarLink>
    );
  };

const getColumns = (search, dateRange) => [
  ['Technician', R.identity, R.prop('fullName')],
  [
    'WOs Created',
    R.identity,
    countWithLink(
      R.path(['technicianStats', 'createdCount']),
      dateRange,
      TECHNICIAN_STATS_SIDEBAR_TABS.CREATED,
      search,
    ),
  ],
  [
    'WOs Completed',
    R.identity,
    countWithLink(
      excludeNonTechniciansStats(R.path(['technicianStats', 'woCompleted'])),
      dateRange,
      TECHNICIAN_STATS_SIDEBAR_TABS.COMPLETED,
      search,
    ),
  ],
  [
    'PMs Completed',
    excludeNonTechniciansStats(R.path(['technicianStats', 'pmCompleted'])),
    highlightField(search),
  ],
  [
    'Overdue',
    excludeNonTechniciansStats(R.path(['technicianStats', 'overdueCount'])),
    highlightField(search),
  ],
  [
    'Avg Days to Complete',
    excludeNonTechniciansStats(R.path(['technicianStats', 'avgDaysSpend'])),
    R.when(R.is(Number), numberFormatter(search)),
  ],
];

// getDateRange :: Date -> Object
const getDateRange = (startDate, endDate) => ({
  from: isExist(startDate)
    ? startOfDay(startDate)
    : startOfDay(new Date('12-31-1999')),
  to: isExist(endDate) ? startOfDay(endDate) : startOfDay(alwaysNewDate()),
});

function TechnicianStatsList() {
  const dispatch = useDispatch();

  const technicianStats = useSelector((state) => state.technicianStats);
  const user = useSelector((state) => state.user);

  const {
    currentPage,
    pageSize,
    dateFilter,
    tableSearchString,
    paginationDropdownLabel,
  } = technicianStats;

  const startDate = dateFilter?.startDate;
  const endDate = dateFilter?.endDate;

  const from = (currentPage - 1) * pageSize;

  const clientId = user?.clientId;

  const dateRange = getDateRange(startDate, endDate);

  const query = { bool: { filter: commonUsersQuery(clientId) } };

  const { data, loading, technicians, total, fetchMore } = useTechnicianStats({
    searchTerm: tableSearchString,
    from,
    size: pageSize,
    dateRange,
    query,
  });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setTechnicianStatsPaginationTotal,
    setCurrent: setTechnicianStatsPaginationCurrentPage,
    setPageSize: setTechnicianStatsPaginationPageSize,
    setPaginationDropdownLabel: setTechnicianStatsPaginationDropdownLabel,
    total,
    currentPage,
  });

  const cols = getColumns(tableSearchString, dateRange);

  const columns = R.map(R.nth(1), cols);

  const headers = R.map(R.nth(0), cols);

  const valuesToSort = R.map(
    (item) => R.compose(R.defaultTo(R.nth(1, item)), R.nth(4))(item),
    cols,
  );

  const formats = R.map(R.compose(R.defaultTo(R.identity), R.nth(2)), cols);
  const colsPropsValue = R.map(R.nth(3), cols);

  const gridColumns = `
    minmax(200px, 1fr)
    repeat(5, minmax(100px, 200px))
  `;

  const { rows, setSorting, sorting } = useSortableTable(
    technicians,
    valuesToSort,
  );

  if (!startDate && !endDate) return <SelectDateRangeMessage />;
  if (loading) return <AbsoluteLoader />;
  if (!technicians.length) return noDataToDisplay('Technician Stats')();

  return (
    <TableWithSavingDataForExport
      {...data}
      {...SORTING}
      fetchMore={fetchMore}
      props={colsPropsValue}
      sorting={sorting}
      setSorting={setSorting}
      tableSearchString={tableSearchString}
      searchTerm={tableSearchString}
      rows={rows}
      columns={columns}
      headers={headers}
      formats={formats}
      valuesToSort={valuesToSort}
      gridColumns={gridColumns}
      dispatch={dispatch}
      size={pageSize}
      currentPage={currentPage}
      from={from}
      query={query}
      total={total}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      paginationDropdownLabel={paginationDropdownLabel}
    />
  );
}

export default TechnicianStatsList;
