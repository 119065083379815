import { DESC_SORT_ORDER } from 'poly-constants';

// It shouldn't be used anywhere except top user
// configuration logic
// Please try to use `user.clientConfig`
export const EUREST_TIAA_ID = 'TQvAe4aPxdN9AoXqN';

export const EUREST_TIAA_CLIENTS = {
  name: 'TIAA',
  company: {
    name: 'Eurest',
    logo: '/logos/eurest.png',
    url: 'https://www.eurestservices.us',
    title: '"One-stop-shop" for all facilities management needs',
  },
  logo: '/logos/tiaa.svg',
  url: 'https://www.eurestservices.us',
  title: '"One-stop-shop" for all facilities management needs',
};

export const SUPPORT = {
  mail: 'support@askpoly.com',
  phone: '877-272-9440',
};

export const STANDARD_SIZE = 50;

export const MAX_SIZE = 10000;

export const ERROR_MESSAGE = `Something Bad Happened. Please Contact Support at ${SUPPORT.phone}`;

export const SORTING = { sort: [{ createdAt: DESC_SORT_ORDER }] };
