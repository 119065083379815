import React from 'react';
import { useSelector } from 'react-redux';
import PurchaseOrderInfo from '../../components/purchase-order/po-info.js';
import { usePurchaseOrderSub } from '../../hooks/purchase-orders.js';
import { usePOPermissionsHelper } from '../../utils/purchase-orders/pos-hooks.js';

export default function (props) {
  const user = useSelector((state) => state.user);
  const id = useSelector((state) => state.location.payload.id);

  const propsOfComponent = { ...props, entityId: id, user };

  const { restProps } = usePurchaseOrderSub(propsOfComponent);

  const { openEditModal, ifHasPermission } = usePOPermissionsHelper({
    orderProp: 'purchaseOrder',
    userProp: 'user',
    handlerName: 'openEditModal',
    restProps,
  });

  const purchaseOrder = restProps?.purchaseOrder;
  const loading = restProps?.loading;

  return (
    <PurchaseOrderInfo
      {...props}
      {...restProps}
      openEditModal={openEditModal}
      ifHasPermission={ifHasPermission}
      purchaseOrder={purchaseOrder}
      loading={loading}
    />
  );
}
