import { gql } from '@apollo/client';

export const updateSupplier = gql`
  mutation UpdateSupplier($id: ID, $update: UpdateSupplierInput!) {
    updateSupplier(id: $id, update: $update) {
      supplier {
        _id
      }
    }
  }
`;
