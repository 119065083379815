import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  Holder,
  Icon,
  IconButton,
  Input,
  Modal,
  Radio,
  S,
} from 'poly-book';
import styled from 'styled-components';

import Technicians from '../../../../containers/project/details/response-team/technicians.js';
import Managers from '../../../../containers/project/details/response-team/managers.js';
import Suppliers from '../../../../containers/project/details/response-team/suppliers.js';

const Content = styled(Modal.Item.withComponent(Modal.Content))`
  width: 550px;
  height: 300px;
  padding-right: 5px;
  position: relative;
`;

const tabsContent = {
  technicians: Technicians,
  manager: Managers,
  suppliers: Suppliers,
};

function EditResponseTeam({
  isModalOpen: show,
  isAssignType,
  closeModal,
  projectId,
  activeTab,
  setActiveTab,
  onInputChange: onChange,
  searchTerm,
  project,
}) {
  const Container = tabsContent[activeTab];
  const placeholder = {
    technicians: 'Start Searching by Name',
    manager: 'Start Searching by Name',
    suppliers: 'Search Supplier',
  }[activeTab];

  return (
    <Modal {...{ show }}>
      <Modal.Item>
        <Heading>
          <Heading.h5>{projectId}</Heading.h5>
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <Modal.Item margin={20}>
        <Heading.h3 lighter>
          {isAssignType ? 'Assign' : 'Edit'} Response Team
        </Heading.h3>
      </Modal.Item>
      <Modal.Item margin={25}>
        <Input name="search-by-name" {...{ placeholder, onChange }} autoFocus />
      </Modal.Item>
      <Modal.Item margin={30}>
        <Holder margin={30}>
          <S type="badge" uppercase>
            Select:
          </S>
          <Holder margin={40}>
            <Radio
              name="members"
              value="technicians"
              id="technicians"
              content={<S type="title">Technicians</S>}
              onChange={setActiveTab}
              defaultChecked
            />
            <Radio
              name="members"
              value="manager"
              id="manager"
              content={<S type="title">Manager</S>}
              onChange={setActiveTab}
            />
            <Radio
              name="members"
              value="suppliers"
              id="supplier"
              content={<S type="title">Supplier</S>}
              onChange={setActiveTab}
            />
          </Holder>
        </Holder>
      </Modal.Item>
      <Content scroll>
        <Container
          {...{
            searchTerm,
          }}
          assignedManager={R.prop('manager', project)}
          assignedSuppliers={R.prop('suppliers', project)}
          assignedTechnicians={R.prop('technicians', project)}
        />
      </Content>
    </Modal>
  );
}

EditResponseTeam.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  projectId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  isAssignType: PropTypes.bool,
  project: PropTypes.shape({
    technicians: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
    ),
    suppliers: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }),
    ),
    manager: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
    }),
  }),
};

EditResponseTeam.defaultProps = {
  isAssignType: false,
  project: {},
};

export default EditResponseTeam;
