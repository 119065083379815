import { useQuery } from '@apollo/client';
import { updatesSubscription } from 'poly-site-ui';
import {
  maintenancePlanQuery,
  maintenancePlanChecklistQuery,
  maintenancePlanHistoryQuery,
  maintenancePlanUpdatesQuery,
} from '../queries/index.js';
import { useReactiveUpdates } from './useReactiveUpdates.js';

export const useMRProjectInfoQuery = (projectId) => {
  const { data, loading } = useQuery(maintenancePlanQuery, {
    alias: 'useProjectInfoQuery',
    variables: { id: projectId },
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    info: data?.maintenancePlan,
  };
};

export const useMRProjectChecklist = (projectId) => {
  const { data, loading } = useQuery(maintenancePlanChecklistQuery, {
    alias: 'useMRProjectChecklist',
    variables: { id: projectId },
    notifyOnNetworkStatusChange: true,
  });

  const maintenancePlan = data?.maintenancePlan;

  const { checklist, materials, notes, searchFiles } = maintenancePlan;

  return {
    loading,
    checklist,
    materials,
    notes,
    searchFiles,
  };
};

export const useMRProjectHistory = (projectId) => {
  const { data, loading } = useQuery(maintenancePlanHistoryQuery, {
    alias: 'useMRProjectHistory',
    variables: { id: projectId },
    notifyOnNetworkStatusChange: true,
  });

  const projects = data?.maintenancePlan.projects;

  return {
    loading,
    projects,
  };
};

export const useMRProjectUpdatesQuery = ({
  queryEndpointName,
  additionalVars,
  from,
  size,
}) => {
  const { result, loading } = useReactiveUpdates({
    gqlQuery: maintenancePlanUpdatesQuery,
    gqlSubscription: updatesSubscription,
    from,
    size,
    queryEndpointName,
    additionalVars,
    alias: 'useMRProjectUpdates',
  });

  return {
    result,
    loading,
  };
};
