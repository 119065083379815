import {
  getReportsPmQuery,
  maintenancePlansChanged,
} from '../queries/index.js';
import { useReactiveEntities } from './useReactiveEntities.js';

export const useReportsPMQuery = ({
  query,
  sort,
  from,
  searchTerm,
  pageSize,
}) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: getReportsPmQuery,
      gqlChangedQuery: maintenancePlansChanged,
      query,
      sort,
      from,
      searchTerm,
      pageSize,
      alias: 'useReportsPMQuery',
    });

  const maintenancePlans = data?.searchMaintenancePlans.hits || [];
  const total = data?.searchMaintenancePlans.total || 0;

  return {
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
    maintenancePlans,
    total,
  };
};

export const useReportsPMCountQuery = ({ sort, query }) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: getReportsPmQuery,
      gqlChangedQuery: maintenancePlansChanged,
      sort,
      query,
      alias: 'useReportsPMCountQuery',
    });

  const count = data?.searchMaintenancePlans.total || 0;

  return {
    count,
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};
