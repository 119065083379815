import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
import { collectionNames } from 'poly-constants';
import { DataWrapper } from '../project/data.js';
import NavigationContainer from './navigation.js';
import ChildProjectsContainer from './tabs/wos.js';
import AssetsContainer from './tabs/assets.js';
import FilesContainer from '../project/tabs/files.js';
import { RecurringProjectsUpdates } from '../project/tabs/updates.js';
import AddAssetToProject from '../project/tabs/assets/add-asset-to-project.js';
import RemoveAssetConfirmModal from '../project/tabs/assets/confirm-remove-asset.js';
import EditMRProject from '../master-recurring-work-orders/edit-master-wo-modal.js';

const tabs = {
  wos: ChildProjectsContainer,
  updates: RecurringProjectsUpdates,
  assets: AssetsContainer,
  files: (props) => (
    <FilesContainer {...props} collection={collectionNames.recurringProjects} />
  ),
};

function ProjectDataContainer({
  activeTab,
  isAddAssetModal,
  isRemoveAssetModal,
  isEditProjectModal,
}) {
  const Container = tabs[activeTab];

  return (
    <>
      <NavigationContainer />
      <DataWrapper>
        <Container />
        {isAddAssetModal && <AddAssetToProject />}
        {isRemoveAssetModal && <RemoveAssetConfirmModal />}
        {isEditProjectModal && <EditMRProject />}
      </DataWrapper>
    </>
  );
}

ProjectDataContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  isAddAssetModal: PropTypes.bool.isRequired,
  isRemoveAssetModal: PropTypes.bool.isRequired,
  isEditProjectModal: PropTypes.bool.isRequired,
};

export default function (props) {
  const activeTab = useSelector((state) => state.report.activeTab);
  const projectId = useSelector((state) => state.location.payload.id);

  const isAddAssetModal = useSelector(
    (state) => !!state.projectAssets.modals.addAsset,
  );

  const isRemoveAssetModal = useSelector(
    (state) => !!state.projectAssets.modals.removeAsset,
  );

  const isEditProjectModal = useSelector(
    (state) => !!state.projects.modals.editProject,
  );

  return (
    <ProjectDataContainer
      {...props}
      activeTab={activeTab}
      projectId={projectId}
      isAddAssetModal={isAddAssetModal}
      isRemoveAssetModal={isRemoveAssetModal}
      isEditProjectModal={isEditProjectModal}
    />
  );
}
