import React from 'react';
import { Tabs } from 'poly-book';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Files from './tabs/files.js';
import Projects from './tabs/projects.js';
import { setAssetActiveTab } from '../../redux/actions/asset.js';

const CONTAINERS = {
  history: Projects,
  files: Files,
};

const TABS = [
  { title: 'WO History', value: 'history' },
  { title: 'Files', value: 'files' },
];

const StyledTabs = styled(Tabs)`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-rows: auto 1fr;
`;

export default function AssetDataContainer() {
  const dispatch = useDispatch();

  const activeTab = useSelector((state) => state.asset.activeTab);

  const Container = CONTAINERS[activeTab];

  return (
    <StyledTabs>
      <Tabs.Header>
        {TABS.map(({ value, title }) => (
          <Tabs.Tab
            bold
            key={value}
            href={`#${value}`}
            active={value === activeTab}
            onClick={() => dispatch(setAssetActiveTab(value))}
          >
            {title}
          </Tabs.Tab>
        ))}
      </Tabs.Header>
      <Tabs.Content>
        <Container />
      </Tabs.Content>
    </StyledTabs>
  );
}
