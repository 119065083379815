import { gql } from '@apollo/client';

export const maintenancePlanHistoryFragment = gql`
  fragment maintenancePlanHistoryFragment on Project {
    _id
    projectId
    description
    endDate
  }
`;

export const maintenancePlanHistoryQuery = gql`
  query projectHistoryQuery($id: ID!) {
    maintenancePlan(id: $id) {
      _id
      projects {
        ...maintenancePlanHistoryFragment
      }
    }
  }

  ${maintenancePlanHistoryFragment}
`;
