import { gql } from '@apollo/client';

export const updateProject = gql`
  mutation projectUpdate($id: ID!, $update: ProjectUpdate!) {
    updateProject(id: $id, update: $update) {
      _id
    }
  }
`;

export const updateMRProject = gql`
  mutation updateRecurringProject(
    $id: ID!
    $update: RecurringProjectUpdateInput!
  ) {
    updateRecurringProject(id: $id, update: $update) {
      projectId
    }
  }
`;
