import { gql } from '@apollo/client';

export const assetsQuery = gql`
  query AssetsQuery($input: CollectionSearchParams) {
    searchAssets(input: $input) {
      total
      hits {
        _id
        equipmentType
        description
        serial
        warrantyEnd
        property {
          _id
          name
        }
      }
    }
  }
`;
