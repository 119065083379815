import React from 'react';
import { useSelector } from 'react-redux';
import { SupplierEmailsTypes } from 'poly-constants';
import { Loader } from 'poly-site-ui';
import SupplierDetails from '../../components/supplier/details.js';
import {
  companyPhoneFormatter,
  emailFormatter,
} from '../../utils/suppliers/index.js';

import { useSupplierDetailsSub } from '../../hooks/suppliers.js';

const { SERVICE, ACCOUNT } = SupplierEmailsTypes;

export default function (props) {
  const supplierId = useSelector((state) => state.location.payload.id);

  const propsOfComponent = { ...props, supplierId };
  const { restProps } = useSupplierDetailsSub(propsOfComponent);

  if (restProps.loading) return <Loader />;

  const status = restProps?.supplier.status;
  const remarks = restProps?.supplier.remarks;

  const addressParts =
    restProps?.supplier.company.address.address_parts || null;

  const addressTwo = restProps?.supplier.company.addressTwo || null;

  const phone = companyPhoneFormatter(restProps?.supplier.company.phones || []);

  const services = restProps?.supplier.company.services || [];

  const serviceEmails = emailFormatter(
    restProps?.supplier.company.emails || [],
    SERVICE,
  );

  const accountingEmails = emailFormatter(
    restProps?.supplier.company.emails,
    ACCOUNT,
  );

  return (
    <SupplierDetails
      {...props}
      {...restProps}
      status={status}
      remarks={remarks}
      addressTwo={addressTwo}
      addressParts={addressParts}
      services={services}
      phone={phone}
      serviceEmails={serviceEmails}
      accountingEmails={accountingEmails}
    />
  );
}
