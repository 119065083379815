import React from 'react';
import PropTypes from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { CREATE_ASSET_PERMISSION } from 'poly-security';
import { Icon, IconButton, S } from 'poly-book';

export function AddAssetButton({ openAddAssetModal }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    CREATE_ASSET_PERMISSION,
  );

  return (
    ifHasPermission && (
      <IconButton fill="#ff8d02" onClick={openAddAssetModal} hover>
        <S type="title">Add Asset</S>
        <Icon name="add" fill="#ff8d02" dimensions={{ width: 30 }} />
      </IconButton>
    )
  );
}

AddAssetButton.displayName = 'AddAssetButton';
AddAssetButton.propTypes = {
  openAddAssetModal: PropTypes.func.isRequired,
};
