import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { DESC_SORT_ORDER } from 'poly-constants';
import { AbsoluteLoader } from 'poly-site-ui';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import {
  highlightCurrency,
  highlightField,
} from '../../utils/highlight/index.js';
import { useInvoicesFilters } from '../../utils/invoices/invoices-filters.js';
import { useSortableTable } from '../../hooks/table-enhancers.js';
import usePagination from '../../hooks/usePagination.js';
import { InvoiceFormatter } from '../../utils/invoices/index.js';
import { dateFormatter } from '../../utils/projects/index.js';
import { noDataToDisplay } from '../../util/general.js';
import { useInvoicesQuery } from '../../hooks/invoices.js';
import {
  POFormatter,
  projectPOFormatter,
  propertySelector,
} from '../../utils/purchase-orders/index.js';
import {
  setInvoiceLogPaginationCurrentPage,
  setInvoiceLogPaginationDropdownLabel,
  setInvoiceLogPaginationPageSize,
  setInvoiceLogPaginationTotal,
} from '../../redux/actions/index.js';

const getColumns = ({ tableSearchString: search }) => [
  [
    'PO #',
    R.prop('purchaseOrder'),
    POFormatter(search),
    R.path(['purchaseOrder', 'cardNumber']),
  ],
  ['Invoice #', R.identity, InvoiceFormatter(search), R.prop('invoiceNumber')],
  ['Amount', R.prop('total'), highlightCurrency(search)],
  ['Posted', R.prop('postedAt'), dateFormatter(search)],
  [
    'WO #',
    R.prop('project'),
    projectPOFormatter(search),
    R.path(['project', 'projectId']),
  ],
  ['Supplier', R.path(['supplier', 'company', 'name']), highlightField(search)],
  ['Property', propertySelector, highlightField(search)],
];

export default function (props) {
  const invoiceLog = useSelector((state) => state.invoiceLog);

  const {
    currentPage,
    pageSize,
    type,
    tableSearchString,
    paginationDropdownLabel,
  } = invoiceLog;

  const from = (currentPage - 1) * pageSize;
  const sort = [{ postedAt: DESC_SORT_ORDER }];

  const propsForFilter = {
    tableSearchString,
    type,
    pageSize,
  };

  const { searchTerm, query, size } = useInvoicesFilters(propsForFilter);

  const { invoices, total, invoicesLoading, restInvoicesProps } =
    useInvoicesQuery({ searchTerm, query, pageSize: size, from, sort });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setInvoiceLogPaginationTotal,
    setPageSize: setInvoiceLogPaginationPageSize,
    setCurrent: setInvoiceLogPaginationCurrentPage,
    setPaginationDropdownLabel: setInvoiceLogPaginationDropdownLabel,
    total,
    currentPage,
  });

  const columns = R.map(R.nth(1), getColumns({ tableSearchString }));
  const headers = R.map(R.nth(0), getColumns({ tableSearchString }));
  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    getColumns({ tableSearchString }),
  );

  const valuesToSort = R.map(
    (item) => R.compose(R.defaultTo(R.nth(1, item)), R.nth(3))(item),
    getColumns({ tableSearchString }),
  );

  const gridColumns = `
        minmax(80px, 150px)
        minmax(80px, 150px)
        100px
        minmax(80px, 150px)
        minmax(80px, 150px)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
      `;

  const { rows, sorting, setSorting } = useSortableTable(
    invoices,
    valuesToSort,
  );

  if (invoicesLoading) return <AbsoluteLoader />;
  if (!invoices.length) return noDataToDisplay('Invoices')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...restInvoicesProps}
      rows={rows}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      sorting={sorting}
      setSorting={setSorting}
      valuesToSort={valuesToSort}
      searchTerm={searchTerm}
    />
  );
}
