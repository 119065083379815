import * as R from 'ramda';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { UPDATE_PURCHASE_ORDER_PERMISSION } from 'poly-security';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { useDispatch } from 'react-redux';
import { EditPOError } from '../../constants/alerts.js';
import { modifyPOToAccessItemTypes } from './pos-utils.js';

import {
  CANCELLED,
  PENDING_APPROVAL,
} from '../../constants/purchase-orders.js';
import { checkIfAllInvoicesAttached } from '../invoices/index.js';
import { setPOsModal } from '../../redux/actions/index.js';
import { STATUSES } from '../../constants/invoices.js';

// getCreatedById :: PurchaseOrder -> ID
const getCreatedById = R.pathOr(null, ['createdBy', '_id']);

/**
 * usePOPermissionsHelper :: String -> String -> {Helpers}
 */
export const usePOPermissionsHelper = ({
  orderProp,
  userProp,
  handlerName = null,
  restProps,
}) => {
  const dispatch = useDispatch();

  const order = R.propOr({}, orderProp, restProps);
  const user = R.propOr({}, userProp, restProps);

  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_PURCHASE_ORDER_PERMISSION,
    modifyPOToAccessItemTypes(order),
  );

  const isOwnPo = user._id === getCreatedById(order);

  const isReadyToClose = checkIfAllInvoicesAttached(order);

  const isAvailableForAll =
    (order.status === STATUSES.APPROVED ||
      order.status === PENDING_APPROVAL ||
      order.status === CANCELLED) &&
    isOwnPo;

  const modalHandler = () => {
    if (isAvailableForAll || ifHasPermission) {
      if (isReadyToClose) {
        return dispatch(setPOsModal({ closePO: { ...order, goToPO: true } }));
      }
      return dispatch(setPOsModal({ editPORequest: order }));
    }
    return toast.error(EditPOError);
  };

  return {
    [handlerName]: modalHandler,
    isAvailableForAll,
    isReadyToClose,
    ifHasPermission,
  };
};

export const usePOFileHandler = () => {
  const [file, setFileState] = useState(null);
  const [wasAdded, setWasAddedState] = useState(false);
  const [wasRemoved, setWasRemovedState] = useState(false);

  const setFile = (fileValue) => {
    setFileState(fileValue);
  };

  const setWasAdded = (isAdded) => setWasAddedState(isAdded);

  const setWasRemoved = (isRemoved) => setWasRemovedState(isRemoved);

  const onAddFile = (fileInputValue) => {
    setFile(fileInputValue);
    setWasAdded(true);
  };

  const onRemoveFile = () => {
    setFile(null);
    setWasRemoved(true);
  };

  return {
    file,
    wasAdded,
    wasRemoved,
    onAddFile,
    onRemoveFile,
    setFile,
    setWasAdded,
    setWasRemoved,
  };
};
