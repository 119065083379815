import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AbsoluteLoader } from 'poly-site-ui';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import {
  highlightField,
  highlightMaintenance,
} from '../../utils/highlight/index.js';
import { projectTypeConverter } from '../../util/project-utils.js';
import { useMRPmFilters } from '../../utils/mr-pm-projects/filter.js';
import { statusTransform } from '../../utils/mr-pm-projects/index.js';
import { dateFormatter } from '../../utils/projects/index.js';
import { noDataToDisplay } from '../../util/general.js';
import {
  setReportPmPaginationCurrentPage,
  setReportPmPaginationDropdownLabel,
  setReportPmPaginationPageSize,
  setReportPmPaginationTotal,
} from '../../redux/actions/index.js';
import { useReportsPMQuery } from '../../hooks/report.js';
import usePagination from '../../hooks/usePagination.js';
import { useSortableTable } from '../../hooks/table-enhancers.js';

const columns = (search) => [
  ['WO #', R.identity, highlightMaintenance(search)],
  ['WO Type', R.always('Master Recurring PM'), projectTypeConverter(search)],
  ['Description', R.prop('description'), highlightField(search)],
  ['Assigned To', R.always('PM Team'), highlightField(search)],
  ['Start', R.prop('startDate'), dateFormatter(search)],
  ['End', R.prop('endDate'), dateFormatter(search)],
  ['', R.prop('status'), statusTransform, true],
];

export default function (props) {
  const dispatch = useDispatch();

  const reportPM = useSelector((state) => state.reportPM);

  const {
    currentPage,
    pageSize,
    typeFilter,
    tableSearchString,
    paginationDropdownLabel,
  } = reportPM;

  const from = (currentPage - 1) * pageSize;

  const { sort, query } = useMRPmFilters({ typeFilter });

  const { data, loading, result, maintenancePlans, total } = useReportsPMQuery({
    query,
    sort,
    from,
    searchTerm: tableSearchString,
    pageSize,
  });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setReportPmPaginationTotal,
    setPageSize: setReportPmPaginationPageSize,
    setCurrent: setReportPmPaginationCurrentPage,
    setPaginationDropdownLabel: setReportPmPaginationDropdownLabel,
    total,
    currentPage,
  });

  const columnsValue = R.map(R.nth(1), columns(tableSearchString));
  const headers = R.map(R.nth(0), columns(tableSearchString));

  const valuesToSort = R.map(R.nth(1), columns(tableSearchString));
  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    columns(tableSearchString),
  );

  const omitSorting = R.map(R.propOr(false, 3), columns(tableSearchString));

  const gridColumns = `
      minmax(80px, 150px)
      minmax(80px, 200px)
      repeat(2, minmax(100px, 1fr))
      repeat(2, 80px)
      10px
    `;

  const { rows, sorting, setSorting } = useSortableTable(
    maintenancePlans,
    valuesToSort,
  );

  if (loading) return <AbsoluteLoader />;
  if (!maintenancePlans.length) return noDataToDisplay('Projects')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...data}
      {...result}
      rows={rows}
      columns={columnsValue}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      valuesToSort={valuesToSort}
      omitSorting={omitSorting}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      sorting={sorting}
      setSorting={setSorting}
      dispatch={dispatch}
      searchTerm={tableSearchString}
    />
  );
}
