import * as R from 'ramda';
import {
  performExcelExport,
  createExcelExportCell,
  ExcelExportCellType,
} from 'poly-client-utils';

const COLUMN_WIDTH = [30, 25, 25, 25, 25, 25];

const TITLE_ROW = [
  'Technician',
  'WOs Created',
  'WOs Completed',
  'PMs Completed',
  'Overdue',
  'Avg Days to Complete',
];

// getEyesWideOpenStatsRowConfig :: User -> [ExcelExportDataCell]
const getTechnicianStatsRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 6),
  ]),
  R.juxt([
    R.propOr('', 'fullName'),
    R.compose(Number, R.pathOr('', ['technicianStats', 'createdCount'])),
    R.compose(Number, R.pathOr('', ['technicianStats', 'woCompleted'])),
    R.compose(Number, R.pathOr('', ['technicianStats', 'pmCompleted'])),
    R.compose(Number, R.pathOr('', ['technicianStats', 'overdueCount'])),
    R.compose(
      (number) => Math.round(number * 100) / 100,
      R.pathOr('', ['technicianStats', 'avgDaysSpend']),
    ),
  ]),
);

// getTechnicianStatsExcelConfig :: [User] -> Object
export const getTechnicianStatsExcelConfig = (users) => ({
  exportFileName: 'technician_stats_export.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 6)],
      TITLE_ROW,
    ),
    ...R.map(getTechnicianStatsRowConfig, users),
  ],
});

export const exportTechnicianStatsToXLS = R.compose(
  performExcelExport,
  getTechnicianStatsExcelConfig,
  R.pathOr([], ['data', 'searchUsers', 'hits']),
);
