import types from '../types.js';

export const setProjectsFilter = (filter) => ({
  type: types.SET_PROJECTS_FILTER,
  payload: filter,
});

export const setActiveProject = (project) => ({
  type: types.SET_ACTIVE_PROJECT,
  payload: project,
});

export const setProjectActiveTab = (tab) => ({
  type: types.SET_PROJECT_ACTIVE_TAB,
  payload: tab,
});

export const setTimelogsModal = (modal) => ({
  type: types.SET_TIMELOGS_MODAL,
  payload: modal,
});

export const setMaterialsModal = (modal) => ({
  type: types.SET_MATERIALS_MODAL,
  payload: modal,
});

export const setResponseTeamModal = (modal) => ({
  type: types.SET_RESPONSE_TEAM_MODAL,
  payload: modal,
});

export const setEditProjectModal = (modal) => ({
  type: types.SET_EDIT_PROJECT_MODAL,
  payload: modal,
});

export const setTableSearchString = (query) => ({
  type: types.SET_PROJECTS_TABLE_SEARCH_STRING,
  payload: query,
});

export const setProjectModal = (modal) => ({
  type: types.SET_PROJECT_MODAL,
  payload: modal,
});

export const setProjectsPaginationCurrentPage = (current) => ({
  type: types.SET_PROJECTS_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setProjectsPaginationTotal = (total) => ({
  type: types.SET_PROJECTS_PAGINATION_TOTAL,
  payload: total,
});

export const setProjectsPaginationPageSize = (size) => ({
  type: types.SET_PROJECTS_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setProjectsPaginationDropdownLabel = (label) => ({
  type: types.SET_PROJECTS_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});
