import { gql } from '@apollo/client';

import { updateFragment } from '../fragments.js';

export const getProjectUpdatesQuery = gql`
  query getProjectUpdates(
    $projectId: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    project(projectId: $projectId) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...updateFragment
          createdBy {
            _id
            fullName
            firstName
            lastName
            userGroups {
              userGroup {
                _id
                name
              }
            }
          }
        }
        total
      }
    }
  }
  ${updateFragment}
`;

export const getRecurringProjectUpdates = gql`
  query getRecurringProjectUpdates(
    $projectId: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    recurringProject(projectId: $projectId) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...updateFragment
        }
        total
      }
    }
  }
  ${updateFragment}
`;
