import { ensureIsDate } from 'poly-utils';
import { endOfDay, startOfDay } from 'date-fns';
import {
  ChildType,
  RecurringProjectStatuses,
  RecurringProjectTypes,
  RWOTaskListType,
} from 'poly-constants';
import { getValue } from '../../util/general.js';

// checkIsNever :: Object, Bool -> Date || Null
const checkIsNever = (date, isNever) =>
  isNever ? null : new Date(endOfDay(ensureIsDate(date)));

// getStaffData :: Object -> Object
const getStaffData = ({
  isProjectAssigned,
  projectSuppliers,
  projectTechnician,
}) =>
  isProjectAssigned
    ? {
        suppliers: projectSuppliers.map(({ _id }) => ({
          supplierId: _id,
        })),
        technicians: projectTechnician ? [projectTechnician._id] : [],
      }
    : {};

// getRequestDataForEditMasterWO :: Object -> Object
export function getRequestDataForEditMasterWO({
  selectedStatus,
  selectedService,
  startDateObj,
  endDateObj,
  selectedProperty,
  projectManager,
  isProjectAssigned,
  projectSuppliers,
  projectTechnician,
  isNever,
  getRef,
  event: e,
}) {
  return {
    status: selectedStatus,
    serviceTypeId: selectedService,
    startDate: startOfDay(ensureIsDate(startDateObj)),
    endDate: checkIsNever(endDateObj, isNever),
    propertyId: selectedProperty.value,
    location: getValue('location', e),
    description: getValue('description', e),
    managerId: projectManager?._id,
    payload: {
      [ChildType.TM]: {
        ...getStaffData({
          isProjectAssigned,
          projectSuppliers,
          projectTechnician,
        }),
        invoiceDescription: getRef().getEditor().getContents(),
      },
    },
  };
}

// getRequestDataForCreateMasterWO :: Object -> Object
export function getRequestDataForCreateMasterWO({
  selectedService,
  startDateObj,
  endDateObj,
  selectedProperty,
  projectManager,
  isProjectAssigned,
  projectSuppliers,
  projectTechnician,
  getRef,
  event: e,
}) {
  return {
    disableChildrenGeneration: false,
    status: RecurringProjectStatuses.ACTIVE,
    taskListType: RWOTaskListType.SHORT_LIST,
    serviceTypeId: selectedService,
    startDate: startOfDay(ensureIsDate(startDateObj)),
    endDate: checkIsNever(endDateObj),
    propertyId: selectedProperty.value,
    location: getValue('location', e),
    description: getValue('description', e),
    ...(projectManager && { managerId: projectManager._id }),
    payload: {
      [ChildType.TM]: {
        invoiceDescription: getRef().getEditor().getContents(),
        ...getStaffData({
          isProjectAssigned,
          projectSuppliers,
          projectTechnician,
        }),
      },
    },
    childType: ChildType.TM,
    type: RecurringProjectTypes.recurringProject,
  };
}
