import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { S, Pip, Grid, Heading, Widget, Editor } from 'poly-book';
import { PageHeader } from 'poly-site-ui';

import { STATUS_COLORS } from '../../constants/suppliers.js';
import { DetailsRow, EmailRow, AddressRow } from '../../utils/wrappers.js';

const ServiceName = styled(S)`
  margin-bottom: 10px;
`;

function PropertyDetails({
  status,
  remarks,
  addressTwo,
  addressParts,
  services,
  phone,
  serviceEmails,
  accountingEmails,
}) {
  return (
    <>
      <Widget.Item>
        <PageHeader>
          <Heading.h5>Supplier Details</Heading.h5>
          <Pip color={STATUS_COLORS[status.toLowerCase()]}>
            <S type="title" textColor="#4b5a8f">
              {status}
            </S>
          </Pip>
        </PageHeader>
        <Grid columns="130px 1fr" simple>
          <AddressRow
            title="Address"
            value={addressParts}
            additional={addressTwo}
          />
          <DetailsRow title="Phone" value={phone} />
          <EmailRow title="Service Email" array={serviceEmails} />
          <EmailRow title="Accounting Email" array={accountingEmails} />
          <Grid.Row>
            <Grid.Cell>
              <S type="title" textColor="#5d688c">
                Service Type
              </S>
            </Grid.Cell>
            <Grid.Cell>
              {services.length ? (
                services.map((service) => (
                  <ServiceName key={R.prop('_id', service)} type="content" bold>
                    {R.prop('name', service)}
                  </ServiceName>
                ))
              ) : (
                <S type="content" bold>
                  {' '}
                  —{' '}
                </S>
              )}
            </Grid.Cell>
          </Grid.Row>
        </Grid>
      </Widget.Item>
      <Widget.Item>
        <PageHeader>
          <Heading.h5>Remarks</Heading.h5>
        </PageHeader>
        <Editor value={remarks} readOnly />
      </Widget.Item>
    </>
  );
}

PropertyDetails.propTypes = {
  status: PropTypes.string.isRequired,
  remarks: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // eslint-disable-next-line
  addressParts: PropTypes.object,
  addressTwo: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  phone: PropTypes.string,
  serviceEmails: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
    }),
  ).isRequired,
  accountingEmails: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
    }),
  ).isRequired,
};

PropertyDetails.defaultProps = {
  phone: null,
  addressParts: null,
  addressTwo: null,
  remarks: null,
};

export default PropertyDetails;
