import { gql } from '@apollo/client';

export const updateInvoice = gql`
  mutation updatePOInvoice($id: ID!, $update: POInvoiceUpdateInput) {
    updatePOInvoice(poInvoiceId: $id, update: $update) {
      poInvoice {
        _id
      }
    }
  }
`;
