import * as R from 'ramda';

/**
 * generateAssignedTeam :: Project -> [Assignee]
 */
export const generateAssignedTeam = R.compose(
  R.reject(R.isNil),
  R.flatten,
  R.juxt([
    R.pipe(R.prop('technicians'), R.defaultTo([]), R.map(R.prop('fullName'))),
    R.path(['manager', 'fullName']),
    R.pipe(
      R.prop('suppliers'),
      R.defaultTo([]),
      R.map(R.path(['company', 'name'])),
    ),
  ]),
);
