const STAFF_OPTIONS = {
  TECHNICIANS: 'technicians',
  ALL_STAFF: 'all-staff',
};

export const STAFF_SIDEBAR_TABS = {
  ACTIVE: 'active',
  OVERDUE: 'overdue',
};

export const { TECHNICIANS, ALL_STAFF } = STAFF_OPTIONS;

export const STAFF_FILTERS = [
  {
    title: 'All Staff',
    type: ALL_STAFF,
    color: '#9bdc53',
    showPip: true,
  },
  {
    title: 'Technicians',
    type: TECHNICIANS,
    color: '#ff8c00',
    showPip: true,
  },
];

export const STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const { ACTIVE, INACTIVE } = STATUSES;

export const STATUS_COLORS = {
  [ACTIVE]: '#9bdc53',
  [INACTIVE]: '#9ea2a9',
};
