import { STANDARD_SIZE } from './index.js';

export const INITIAL_PAGINATION_STATE = {
  total: 0,
  currentPage: 1,
  pageSize: STANDARD_SIZE,
  paginationDropdownLabel: `${STANDARD_SIZE} / page`,
};

export const ALL_RESULTS = 'All Results';
