import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from 'poly-book';
import PropTypes from 'prop-types';

export default function HeaderCheckbox(props) {
  const { statePath, pageItems, onChange, ...restProps } = props;

  const selectedItems = useSelector((state) => R.path(statePath, state));
  const pageSelected = R.intersection(selectedItems, pageItems);

  const id = 'select-all';
  const checked = pageSelected.length === pageItems.length;
  const indeterminate =
    pageSelected.length > 0 && pageSelected.length < pageItems.length;

  const onChangeValue = useCallback(() => {
    onChange(
      pageSelected.length
        ? R.difference(selectedItems, pageSelected)
        : selectedItems.concat(pageItems),
    );
  }, [selectedItems, pageSelected, pageItems]);

  return (
    <Checkbox
      {...restProps}
      id={id}
      indeterminate={indeterminate}
      onChange={onChangeValue}
      statePath={statePath}
      pageItems={pageItems}
      checked={checked}
    />
  );
}

HeaderCheckbox.propTypes = {
  statePath: PropTypes.arrayOf(PropTypes.string.isRequired),
  pageItems: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func.isRequired,
};
