import { gql } from '@apollo/client';

import { assetDetailsFields } from './fragments.js';

export const assetDetailsQuery = gql`
  query assetDetailsQuery($assetId: ID!) {
    asset(id: $assetId) {
      ...assetDetailsFields
    }
  }

  ${assetDetailsFields}
`;
