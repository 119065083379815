import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TransitionGroup } from 'react-transition-group';
import { UPDATE_CONTACT_USER_PERMISSION } from 'poly-security';
import {
  getFirstLetterOfNames,
  useHasUserAccessWithPermission,
} from 'poly-client-utils';

import {
  IconButton,
  Heading,
  Sidebar,
  Widget,
  Avatar,
  Editor,
  Modal,
  Icon,
  Grid,
  S,
} from 'poly-book';

import { fieldFormatter } from '../../util/general.js';
import { formattedAddToAddParts } from '../../utils/formatters/index.js';
import { DetailsRow, AddressRow } from '../../utils/wrappers.js';
import { emailLinkFormatter } from '../../util/project-utils.js';
import {
  getUserWorkPhone,
  getUserCellPhone,
  getUserFaxPhone,
} from '../../utils/staff/index.js';

const COLUMNS = 'minmax(80px, 115px) 1fr';

const StyledHeading = styled(Heading)`
  margin: 15px 0;
`;

const SidebarTitle = styled(Heading)`
  margin-bottom: 15px;
`;

const SidebarGrid = Modal.Item.withComponent(Grid);

const Item = styled(Widget.Item)`
  padding-right: 0;
  padding-left: 0;
`;

function EditPeopleIcon({ onEdit }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_CONTACT_USER_PERMISSION,
  );

  return (
    ifHasPermission && (
      <IconButton onClick={onEdit}>
        <Icon name="edit" fill="#9fa1ab" dimensions={{ height: 14 }} />
      </IconButton>
    )
  );
}

function PeopleSidebar({ selectedUser, onClose, onEdit }) {
  return (
    <TransitionGroup>
      {!!selectedUser && (
        <Sidebar style={{ top: 60 }}>
          <Sidebar.Close onClick={onClose} />
          <SidebarTitle>
            <Heading.h3>Profile</Heading.h3>
          </SidebarTitle>
          <Item>
            <SidebarGrid columns={COLUMNS} margin={35} simple>
              <Grid.Row>
                <Grid.Cell>
                  <Avatar
                    content={getFirstLetterOfNames(
                      selectedUser.firstName,
                      selectedUser.lastName,
                    )}
                    type="text"
                    size={80}
                  />
                </Grid.Cell>
                <Grid.Cell horizontal="stretch">
                  <StyledHeading>
                    <Heading.h3>
                      {selectedUser.firstName} {selectedUser.lastName}
                    </Heading.h3>
                    {!selectedUser.isStaff && (
                      <EditPeopleIcon {...{ onEdit }} />
                    )}
                  </StyledHeading>
                  <S type="title" textColor="#5e6271">
                    {fieldFormatter(R.prop('title', selectedUser))}
                  </S>
                </Grid.Cell>
              </Grid.Row>
            </SidebarGrid>
            <SidebarGrid columns={COLUMNS} simple>
              <Grid.Row>
                <Grid.Cell>
                  <S type="title" textColor="#5d688c">
                    Email
                  </S>
                </Grid.Cell>
                <Grid.Cell>
                  {emailLinkFormatter({ bold: true })(
                    R.prop('email', selectedUser),
                  )}
                </Grid.Cell>
              </Grid.Row>
              <DetailsRow title="Work" value={getUserWorkPhone(selectedUser)} />
              <DetailsRow
                title="Mobile"
                value={getUserCellPhone(selectedUser)}
              />
              <DetailsRow title="Fax" value={getUserFaxPhone(selectedUser)} />
              <AddressRow
                title="Address"
                value={R.pathOr(
                  formattedAddToAddParts(
                    R.path(['address', 'formatted_address'], selectedUser),
                  ),
                  ['address', 'address_parts'],
                  selectedUser,
                )}
                additional={R.prop('addressTwo', selectedUser)}
              />
            </SidebarGrid>
          </Item>
          <Item>
            <SidebarTitle>
              <Heading.h5>Remarks</Heading.h5>
            </SidebarTitle>
            <Editor
              style={{ minWidth: 440 }}
              value={R.prop('remarks', selectedUser)}
              readOnly
            />
          </Item>
        </Sidebar>
      )}
    </TransitionGroup>
  );
}

PeopleSidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    phone: PropTypes.string,
    addressTwo: PropTypes.string,
    address: PropTypes.shape({
      formatted_address: PropTypes.string,
    }),
    isStaff: PropTypes.bool,
  }),
};

PeopleSidebar.defaultProps = {
  selectedUser: null,
};

EditPeopleIcon.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

export default PeopleSidebar;
