import React, { useState, useCallback } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { S } from 'poly-book';

import Select from '../select/index.js';
import { formatPlaceholder } from '../../util/select.js';
import { useServiceTypes } from '../../hooks/projects.js';

function SimpleTitle({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

SimpleTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

const mapper = (value) => ({
  value: R.prop('_id', value),
  label: R.prop('name', value),
});

export const ALL_SERVICE_TYPES_VALUE = 'ALL_SERVICE_TYPES_VALUE';
const ALL_SERVICE_TYPES_LABEL = 'All Service Types';

const defaultOption = {
  label: ALL_SERVICE_TYPES_LABEL,
  value: ALL_SERVICE_TYPES_VALUE,
};

export function ServiceTypeSelector(props) {
  const { setSearchServiceType, onChange, ...restProps } = props;

  const user = useSelector((state) => state.user);
  const supplierSource = R.path(['clientConfig', 'supplierSource'], user);

  const [searchServiceValue, setSearchServiceValue] = useState('');

  const { serviceTypes, serviceLoading } = useServiceTypes(
    supplierSource,
    searchServiceValue,
  );

  const placeholder = formatPlaceholder('Service Type');

  const options = R.compose(
    R.prepend(defaultOption),
    R.map(mapper),
  )(serviceTypes);

  const onChangeValue = R.compose(onChange, R.prop('value'));

  const setServiceType = useCallback((value) => {
    setSearchServiceValue(value);
  });

  return (
    <Select
      {...restProps}
      placeholder={placeholder}
      optionRenderer={SimpleTitle}
      options={options}
      onChange={onChangeValue}
      onInputChange={setSearchServiceType}
      supplierSource={supplierSource}
      serviceTypes={serviceTypes}
      serviceLoading={serviceLoading}
      setSearchServiceType={setServiceType}
      searchService={searchServiceValue}
    />
  );
}

ServiceTypeSelector.propTypes = {
  setSearchServiceType: PropTypes.func,
  onChange: PropTypes.func,
};
