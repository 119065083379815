import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { S, Holder, Button, Input } from 'poly-book';
import styled from 'styled-components';
import { DatePicker } from 'poly-site-ui';

import { ServiceTypeSelector } from './service-type-selector.js';

const StyledInput = styled(Input)`
  padding: 5px 15px;
`;

// eslint-disable-next-line
class DateInput extends Component {
  render() {
    return <StyledInput {...this.props} />;
  }
}

function DateSelector({
  startDate,
  endDate,
  changeStartDate,
  changeEndDate,
  onPreview,
  changeServiceType,
  serviceTypeId,
}) {
  return (
    <Holder margin={30} center>
      <S type="title" uppercase>
        Date Selector:
      </S>
      <Holder margin={10} center>
        <S type="badge">From</S>
        <DatePicker
          input={<DateInput name="start-date" />}
          dateFormat="MM-dd-yyyy"
          onChange={(e) => changeStartDate(e)}
          selected={startDate}
          placeholderText="Select date"
          maxDate={endDate}
        />
        <S type="badge">to</S>
        <DatePicker
          input={<DateInput name="end-date" />}
          dateFormat="MM-dd-yyyy"
          onChange={(e) => changeEndDate(e)}
          selected={endDate}
          placeholderText="Select date"
          minDate={startDate}
        />
      </Holder>
      {!!changeServiceType && (
        <Holder width="230px">
          <ServiceTypeSelector
            onChange={changeServiceType}
            value={serviceTypeId}
          />
        </Holder>
      )}
      <Button onClick={onPreview}>
        <S type="title">Preview</S>
      </Button>
    </Holder>
  );
}

DateSelector.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  changeStartDate: PropTypes.func.isRequired,
  changeEndDate: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  changeServiceType: PropTypes.func,
  serviceTypeId: PropTypes.string,
};

DateSelector.defaultProps = {
  startDate: null,
  endDate: null,
};

export default DateSelector;
