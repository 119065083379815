import { createActions } from 'redux-actions';

// named _P_O_ in constants to have appropriate action

export const {
  setPOLogPaginationTotal,
  setPOLogTableSearchString,
  setPOLogPaginationPageSize,
  setPOLogPaginationCurrentPage,
  setPOLogPaginationDropdownLabel,
} = createActions(
  'SET_P_O_LOG_PAGINATION_TOTAL',
  'SET_P_O_LOG_TABLE_SEARCH_STRING',
  'SET_P_O_LOG_PAGINATION_PAGE_SIZE',
  'SET_P_O_LOG_PAGINATION_CURRENT_PAGE',
  'SET_P_O_LOG_PAGINATION_DROPDOWN_LABEL',
);
