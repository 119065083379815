import types from '../types.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import { PENDING_APPROVAL } from '../../constants/purchase-orders.js';
import { PURCHASE_ORDERS } from '../../constants/routes.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  type: PENDING_APPROVAL,
  tableSearchString: '',
  activeTab: 'updates',
  selectedPOs: [],
  modals: {
    addPORequest: null,
    confirmAddPO: null,
    editPORequest: null,
    confirmApproveOne: null,
    confirmApproveMultiple: false,
    editApprovePO: null,
    sendPOSupplierConfirm: null,
    closePO: null,
  },
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_POS_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          ...action.payload,
        },
      };
    }
    case types.SET_SELECTED_POS: {
      return {
        ...state,
        selectedPOs: action.payload,
      };
    }
    case types.TOGGLE_PO: {
      const index = state.selectedPOs.indexOf(action.payload);

      return {
        ...state,
        selectedPOs:
          index === -1
            ? [...state.selectedPOs, action.payload]
            : state.selectedPOs.filter((id) => id !== action.payload),
      };
    }
    case PURCHASE_ORDERS: {
      return {
        ...state,
        tableSearchString: '',
      };
    }
    case types.SET_POS_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_PO_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case types.SET_POS_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_POS_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_POS_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_POS_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
