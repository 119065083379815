import { createActions } from 'redux-actions';

export const {
  setMRProjectWOSPaginationTotal,
  setMRProjectWOSPaginationPageSize,
  setMRProjectWOSPaginationCurrentPage,
  setMRProjectWOSPaginationDropdownLabel,
} = createActions(
  'SET_M_R_PROJECT_W_O_S_PAGINATION_TOTAL',
  'SET_M_R_PROJECT_W_O_S_TABLE_SEARCH_STRING',
  'SET_M_R_PROJECT_W_O_S_PAGINATION_PAGE_SIZE',
  'SET_M_R_PROJECT_W_O_S_PAGINATION_CURRENT_PAGE',
  'SET_M_R_PROJECT_W_O_S_PAGINATION_DROPDOWN_LABEL',
);
