import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Heading,
  Holder,
  Icon,
  IconButton,
  Modal,
  S,
  TextArea,
} from 'poly-book';
import { EntityStatusFormatter, FileArea } from 'poly-site-ui';

import Select from '../select/index.js';
import InputNumber from '../input-number/index.js';
import ServerError from '../server-error/index.js';
import ButtonLoader from '../loader/button.js';
import { APPROVED } from '../../constants/purchase-orders.js';
import {
  formatPlaceholder,
  formatSearchPlaceholder,
} from '../../util/select.js';
import { lessThen } from '../../util/general.js';
import { Rows } from '../../util/forms/index.js';
import { lineStatusFormatter } from '../../utils/purchase-orders/index.js';
import { poStatusUIData } from '../../utils/purchase-orders/pos-utils.js';

let supplierTimer = null;
let propertyTimer = null;

const Header = Modal.Item.withComponent(Heading);

const Form = Modal.Item.withComponent('form');

const ModalGrid = Modal.Item.withComponent(Grid);

function TitleWithPip({ label }) {
  return EntityStatusFormatter(
    R.pipe(poStatusUIData, R.prop('color'))(label),
    lineStatusFormatter(label),
  );
}

TitleWithPip.propTypes = {
  label: PropTypes.string.isRequired,
};

function SimpleTitle({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

SimpleTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddEditPORequest({
  type,
  file,
  loading,
  errors,
  onChange,
  closeModal,
  onSubmit,
  number,
  status,
  project,
  amount,
  description,
  availableStatuses,
  shouldDisplayStatus,
  code,
  glCodes,

  supplierLoading,
  allSuppliers,
  selectedSupplier,
  selectSupplier,
  setSupplierSearch,

  scopeTypes,
  selectedScope,
  setSelectedScope,

  isAmountChanged,
  propertyLoading,
  properties,
  selectedProperty,
  selectProperty,
  setPropertySearch,
  selectStatus,
  validateOnBlur,
  resetError,
  onAmountEdit,
  noPropertyResults,
  noSupplierResults,
  onAddFile,
  onRemoveFile,
  onSelectCode,
  onRejectPO,
}) {
  const isEditApprovedType = type === 'edit' && status === APPROVED;
  const items = [
    ...(shouldDisplayStatus
      ? [
          {
            title: { value: 'Status', props: ['required'] },
            item: (
              <Select
                value={status}
                onChange={selectStatus}
                options={availableStatuses}
                optionRenderer={TitleWithPip}
                clearable={false}
              />
            ),
          },
        ]
      : []),
    {
      title: {
        value: 'Supplier',
        props: ['required'],
      },
      error: R.prop('supplier', errors),
      item: (
        <Select
          value={selectedSupplier}
          onChange={selectSupplier}
          onInputChange={(e) => {
            clearTimeout(supplierTimer);
            supplierTimer = setTimeout(() => setSupplierSearch(e), 300);
          }}
          isLoading={supplierLoading}
          placeholder={formatSearchPlaceholder('Supplier')}
          noResultsText={noSupplierResults}
          options={allSuppliers.map((supplier) => ({
            value: R.prop('_id', supplier),
            label: R.path(['company', 'name'], supplier),
          }))}
          optionRenderer={SimpleTitle}
          invalid={!!R.prop('supplier', errors)}
          disabled={isEditApprovedType}
        />
      ),
    },
    {
      title: { value: 'GL Code' },
      error: R.prop('code', errors),
      item: (
        <Select
          value={code}
          onChange={onSelectCode}
          placeholder={formatPlaceholder('GL Code')}
          options={glCodes}
          invalid={!!R.prop('code', errors)}
          optionRenderer={SimpleTitle}
        />
      ),
    },
    {
      title: {
        value: 'Amount',
        props: ['required'],
      },
      error: R.prop('amount', errors),
      item: (
        <Holder margin={15}>
          <InputNumber
            style={{ minWidth: 0 }}
            prefix="$"
            name="amount"
            placeholder="$ 0.0"
            thousandSeparator=","
            decimalScale={2}
            isAllowed={lessThen(100000000)}
            allowNegative={false}
            onChange={type === 'edit' ? onAmountEdit : onChange}
            defaultValue={amount}
            invalid={!!R.prop('amount', errors)}
            onBlur={validateOnBlur}
            onFocus={resetError}
            // fixedDecimalScale
          />
          <Select
            value={selectedScope}
            onChange={setSelectedScope}
            options={scopeTypes}
            optionRenderer={SimpleTitle}
            clearable={false}
          />
        </Holder>
      ),
    },
    ...(isAmountChanged
      ? [
          {
            title: {
              value: 'Update',
              props: ['required', 'description'],
            },
            error: R.prop('update', errors),
            item: (
              <TextArea
                name="update"
                placeholder="Enter reason for Amount change"
                invalid={!!R.prop('update', errors)}
                {...{ onChange }}
              />
            ),
          },
        ]
      : []),
    {
      title: {
        value: 'Description',
        props: ['required', 'description'],
      },
      error: R.prop('description', errors),
      item: (
        <TextArea
          name="description"
          placeholder="Description"
          invalid={!!R.prop('description', errors)}
          {...{ onChange }}
          defaultValue={description}
        />
      ),
    },
    ...(R.isNil(project) && !R.equals('approve', type)
      ? [
          {
            title: {
              value: 'Property',
              props: ['required'],
            },
            error: R.prop('property', errors),
            item: (
              <Select
                value={selectedProperty}
                onChange={selectProperty}
                onInputChange={(e) => {
                  clearTimeout(propertyTimer);
                  propertyTimer = setTimeout(() => setPropertySearch(e), 300);
                }}
                isLoading={propertyLoading}
                placeholder={formatSearchPlaceholder('Property')}
                noResultsText={noPropertyResults}
                options={properties.map((property) => ({
                  value: R.prop('_id', property),
                  label: R.prop('name', property),
                }))}
                optionRenderer={SimpleTitle}
                invalid={!!R.prop('property', errors)}
              />
            ),
          },
        ]
      : []),
    {
      title: { value: 'Attach File' },
      item: <FileArea {...{ onAddFile, onRemoveFile, file }} />,
    },
  ];

  return (
    <Modal show>
      <Header margin={20}>
        <Heading.h5 lighter>
          {project ? `WO# ${project}` : 'Non Work Order'}
        </Heading.h5>
        <IconButton onClick={closeModal}>
          <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
        </IconButton>
      </Header>
      <Modal.Item margin={20}>
        <Heading.h3>
          {type === 'approve' && 'Edit and Approve '}
          {type === 'edit' && 'Edit '}
          Purchase Order Request
        </Heading.h3>
      </Modal.Item>
      {(R.equals('approve', type) || R.equals('edit', type)) && (
        <Modal.Item margin={20}>
          <Heading.h5 lighter>
            PO#
            {number}
          </Heading.h5>
        </Modal.Item>
      )}
      <Form {...{ onSubmit }}>
        <ModalGrid columns="100px minmax(200px, 320px)" margin={30} simple>
          <Rows {...{ items }} />
        </ModalGrid>
        <ServerError
          error={R.prop('server', errors)}
          style={{ maxWidth: 430 }}
        />
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>

          {type === 'approve' && (
            <Button mode="grayWhite" onClick={onRejectPO} disabled={loading}>
              {loading && <ButtonLoader />}
              <S type="title">REJECT PO</S>
            </Button>
          )}

          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">{type === 'approve' ? 'Approve PO' : 'Submit'}</S>
          </Button>
        </Modal.Buttons>
      </Form>
    </Modal>
  );
}

const defaultOptionType = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

AddEditPORequest.propTypes = {
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.shape({
    server: PropTypes.string,
    supplier: PropTypes.string,
    amount: PropTypes.string,
    description: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  number: PropTypes.string,
  project: PropTypes.string,
  status: PropTypes.string,
  amount: PropTypes.number,
  description: PropTypes.string,
  supplierLoading: PropTypes.bool.isRequired,
  allSuppliers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      company: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
  selectedSupplier: defaultOptionType,
  selectSupplier: PropTypes.func.isRequired,
  setSupplierSearch: PropTypes.func.isRequired,
  scopeTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.bool,
      label: PropTypes.string,
    }),
  ).isRequired,
  selectedScope: PropTypes.shape({
    value: PropTypes.bool,
    label: PropTypes.string,
  }),
  setSelectedScope: PropTypes.func.isRequired,
  propertyLoading: PropTypes.bool,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  selectedProperty: defaultOptionType,
  selectProperty: PropTypes.func,
  setPropertySearch: PropTypes.func,
  selectStatus: PropTypes.func,
  availableStatuses: PropTypes.arrayOf(defaultOptionType),
  shouldDisplayStatus: PropTypes.bool,
  isAmountChanged: PropTypes.bool,
  validateOnBlur: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  onAmountEdit: PropTypes.func,
  noPropertyResults: PropTypes.string,
  noSupplierResults: PropTypes.string,
  file: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string,
    size: PropTypes.number,
  }),
  onAddFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onSelectCode: PropTypes.func.isRequired,
  glCodes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onRejectPO: PropTypes.func,
};

AddEditPORequest.defaultProps = {
  errors: {},
  file: null,
  number: null,
  status: null,
  project: null,
  amount: null,
  description: null,
  allSuppliers: [],
  selectedSupplier: null,
  selectedScope: null,
  propertyLoading: false,
  properties: [],
  selectedProperty: null,
  selectProperty: () => undefined,
  setPropertySearch: () => undefined,
  onAmountEdit: () => undefined,
  selectStatus: () => undefined,
  availableStatuses: [],
  shouldDisplayStatus: false,
  isAmountChanged: false,
  noPropertyResults: null,
  noSupplierResults: null,
  code: null,
  loading: false,
  onRejectPO: () => undefined,
};

export default AddEditPORequest;
