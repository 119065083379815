import { CHILD_PROJECT_ELASTIC_QUERY } from 'poly-client-utils';
import { useQuery } from '@apollo/client';
import {
  getPropertyChildRecurringProjectsQuery,
  getPropertyRecurringProjectsQuery,
  getPropertyProjectsQuery,
  searchProjectChanged,
} from '../../queries/index.js';
import { WORK_ORDER } from '../../constants/projects.js';
import { MAX_SIZE } from '../../constants/index.js';
import { useReactiveEntities } from '../useReactiveEntities.js';

export const usePropertyProjects = (propertyId) => {
  const query = {
    bool: {
      must: { match: { type: WORK_ORDER } },
      must_not: CHILD_PROJECT_ELASTIC_QUERY,
    },
  };

  const { data, loading } = useReactiveEntities({
    gqlQuery: getPropertyProjectsQuery,
    gqlChangedQuery: searchProjectChanged,
    additionalVars: { propertyId },
    query,
    size: MAX_SIZE,
    alias: 'usePropertyProjects',
  });

  return {
    projectsLoading: loading,
    projects: data?.property.searchProjects?.hits || [],
  };
};

export const usePropertyChildRecurringProjects = (propertyId) => {
  const { data, loading } = useReactiveEntities({
    gqlQuery: getPropertyChildRecurringProjectsQuery,
    gqlChangedQuery: searchProjectChanged,
    additionalVars: { propertyId },
    query: CHILD_PROJECT_ELASTIC_QUERY,
    size: MAX_SIZE,
    alias: 'usePropertyChildRecurringProjects',
  });

  return {
    childRecurringProjectsLoading: loading,
    childRecurringProjects: data?.property.searchProjects?.hits || [],
  };
};

export const usePropertyRecurringProjects = (propertyId) => {
  const { data, loading } = useQuery(getPropertyRecurringProjectsQuery, {
    alias: 'usePropertyRecurringProjects',
    variables: {
      propertyId,
      input: { size: MAX_SIZE },
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    projects: data?.property?.searchRecurringProjects?.hits,
  };
};
