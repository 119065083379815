import types from '../types.js';

const defaultState = {
  activeTab: 'history',
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_ASSET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case types.SET_ASSET_MODAL: {
      return {
        ...state,
        modal: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
