import React from 'react';
import { useSelector } from 'react-redux';
import { Loader, usePaginatorState } from 'poly-site-ui';
import ProjectUpdatesComponent from '../../../components/project/project-updates.js';
import { usePropertyUpdatesQuery } from '../../../hooks/property/updates.js';
import useFilesUpdates from '../../../hooks/useFiles.js';

export default function (props) {
  const propertyId = useSelector((state) => state.location.payload.id);

  const { paginator } = usePaginatorState();

  const { size, currentPage } = paginator;

  const from = (currentPage - 1) * size;

  const { result } = usePropertyUpdatesQuery({
    additionalVars: { propertyId },
    queryEndpointName: 'property',
    from,
    size,
  });

  const documentId = result.property?._id;

  const additionalFilesProps = useFilesUpdates({
    documentId,
    collection: 'properties',
  });

  if (!result.property) return <Loader />;

  const updates = result?.property.documentUpdates.hits || [];
  const total = result?.property.documentUpdates.total;

  return (
    <ProjectUpdatesComponent
      {...props}
      {...result}
      {...additionalFilesProps}
      updates={updates}
      total={total}
    />
  );
}
