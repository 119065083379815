import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import { GlobalSearch } from 'poly-site-ui';

import { Nav, Header, S } from 'poly-book';
import UserMenuContainer from '../../containers/navbar/user-menu-container.js';
import AddItemsContainer from '../../containers/navbar/add-items-container.js';
import SupportModalContainer from '../../containers/user-menu/support-modal.js';
import {
  routeProperties,
  routeMain,
  routePMS,
  routeReports,
  routeStaff,
  routeSuppliers,
} from '../../redux/actions/router.js';
import {
  MAIN,
  PROJECT,
  PMS,
  PM_PROJECT,
  PROPERTIES,
  PROPERTY,
  STAFF,
  SUPPLIERS,
  SUPPLIER,
  REPORTS,
  REPORT,
  REPORT_PM,
  MR_PROJECT,
  MR_PM_PROJECT,
  ASSETS,
  ASSET,
  APPROVED_POS,
  PURCHASE_ORDERS,
  PURCHASE_ORDER,
  COMPLETED_WOS,
  COMPLETED_ON_HOLD_WOS,
  COMPLETED_PMS,
  TECHNICIAN_STATS,
  INVOICES,
} from '../../constants/routes.js';
import { closeAllSidebars } from '../../utils/sidebars/index.js';
import GlobalSearchDataContainer from '../../containers/global-search/data.js';

const LinkWrapper = Nav.Item.withComponent(Link);

const Logo = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  min-width: 400px;
  align-items: center;
  justify-content: space-around;
`;

const ItemWrapper = styled(Header.Item)`
  transition: 0.3s;
  width: 100%;
  overflow: ${({ over }) => over && 'hidden'};
  margin-right: ${({ trigger }) => trigger && '0 !important'};
`;

const UserWrapper = styled(ItemWrapper)`
  width: ${({ trigger }) => trigger && 0};
`;

const isActive = (activeTab, tabsNames) =>
  R.any(R.equals(activeTab), tabsNames) ? 'true' : undefined;

function Navigation({ tab, isSearchFocused, overTrigger, logo, title }) {
  return (
    <Header.Main>
      <Header.Row>
        <Header.Block>
          <Header.Item>
            <Logo to={routeMain()}>
              <img src={logo} title={title} alt={title} width={50} />
            </Logo>
          </Header.Item>
          <Header.Item>
            <Nav>
              <LinkWrapper
                to={routeMain()}
                active={isActive(tab, [MAIN, PROJECT])}
              >
                <S type="header">Work Orders</S>
              </LinkWrapper>
              <LinkWrapper
                to={routePMS()}
                active={isActive(tab, [PMS, PM_PROJECT])}
              >
                <S type="header">PM’s</S>
              </LinkWrapper>
              <LinkWrapper
                to={routeProperties()}
                active={isActive(tab, [PROPERTIES, PROPERTY])}
              >
                <S type="header">Properties</S>
              </LinkWrapper>
              <LinkWrapper to={routeStaff()} active={isActive(tab, [STAFF])}>
                <S type="header">Staff</S>
              </LinkWrapper>
              <LinkWrapper
                to={routeSuppliers()}
                active={isActive(tab, [SUPPLIERS, SUPPLIER])}
              >
                <S type="header">Suppliers</S>
              </LinkWrapper>
              <LinkWrapper
                to={routeReports()}
                active={isActive(tab, [
                  REPORTS,
                  REPORT,
                  REPORT_PM,
                  MR_PROJECT,
                  MR_PM_PROJECT,
                  ASSETS,
                  ASSET,
                  PURCHASE_ORDERS,
                  PURCHASE_ORDER,
                  APPROVED_POS,
                  COMPLETED_PMS,
                  COMPLETED_WOS,
                  COMPLETED_ON_HOLD_WOS,
                  TECHNICIAN_STATS,
                  INVOICES,
                ])}
              >
                <S type="header">Reports</S>
              </LinkWrapper>
            </Nav>
          </Header.Item>
        </Header.Block>
        <Header.Block>
          <AddItemsContainer />
          <SearchWrapper>
            <ItemWrapper trigger={isSearchFocused} over={overTrigger}>
              <GlobalSearch onFocus={closeAllSidebars}>
                <GlobalSearchDataContainer />
              </GlobalSearch>
            </ItemWrapper>
            <UserWrapper trigger={isSearchFocused} over={overTrigger}>
              <UserMenuContainer />
              <SupportModalContainer />
            </UserWrapper>
          </SearchWrapper>
        </Header.Block>
      </Header.Row>
    </Header.Main>
  );
}

Navigation.propTypes = {
  tab: PropTypes.string.isRequired,
  isSearchFocused: PropTypes.bool.isRequired,
  overTrigger: PropTypes.bool.isRequired,
  logo: PropTypes.string,
  title: PropTypes.string,
};

Navigation.defaultProps = {
  logo: null,
  title: null,
};

export default Navigation;
