import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AbsoluteLoader } from 'poly-site-ui';
import { string } from 'prop-types';

import { TableWithSavingDataForExport } from '../../components/table/table.js';
import { noDataToDisplay } from '../../util/general.js';
import {
  setProjectsPaginationCurrentPage,
  setProjectsPaginationDropdownLabel,
  setProjectsPaginationPageSize,
  setProjectsPaginationTotal,
} from '../../redux/actions/index.js';
import { getProjectsStatsQueries } from './utils.js';
import { useSortableTable } from '../../hooks/table-enhancers.js';
import { useProjectsQuery } from '../../hooks/projects.js';
import usePagination from '../../hooks/usePagination.js';
import usePropsToProjectsTable from './hooks.js';
import { useProjectFilters } from '../../utils/projects/projects-filter.js';

export default function ProjectStatsTable({ clientId }) {
  const dispatch = useDispatch();

  const projectsValue = useSelector((state) => state.projects);

  const { type, year, monthIndex } = useSelector(
    (state) => state.location.payload,
  );

  const {
    currentPage,
    pageSize,
    tableSearchString,
    paginationDropdownLabel,
    typeFilter,
  } = projectsValue;

  const from = (currentPage - 1) * pageSize;

  const propsForFilter = {
    typeFilter,
    tableSearchString,
  };

  const query = getProjectsStatsQueries(
    clientId,
    year,
    monthIndex === 'all' ? undefined : monthIndex,
  )(type);

  const { searchTerm, sort } = useProjectFilters(propsForFilter);

  const { projects, restProjectsProps, total, result } = useProjectsQuery({
    searchTerm,
    query,
    pageSize,
    from,
    sort,
    skipQuery: !year && !monthIndex,
  });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setProjectsPaginationTotal,
    setCurrent: setProjectsPaginationCurrentPage,
    setPageSize: setProjectsPaginationPageSize,
    setPaginationDropdownLabel: setProjectsPaginationDropdownLabel,
    total,
    currentPage,
  });

  const {
    rowsValue,
    columns,
    headers,
    valuesToSort,
    formats,
    propsObj,
    gridColumns,
    GridRowComponent,
  } = usePropsToProjectsTable({ projects, dispatch, tableSearchString });

  const { rows, sorting, setSorting } = useSortableTable(
    rowsValue,
    valuesToSort,
  );

  if (restProjectsProps.loading) return <AbsoluteLoader />;
  if (!projects.length) return noDataToDisplay('Projects')();

  return (
    <TableWithSavingDataForExport
      {...result}
      {...restProjectsProps}
      props={propsObj}
      sorting={sorting}
      setSorting={setSorting}
      searchTerm={searchTerm}
      tableSearchString={tableSearchString}
      rows={rows}
      columns={columns}
      headers={headers}
      formats={formats}
      valuesToSort={valuesToSort}
      gridColumns={gridColumns}
      dispatch={dispatch}
      size={pageSize}
      currentPage={currentPage}
      from={from}
      query={query}
      projects={projects}
      total={total}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      paginationDropdownLabel={paginationDropdownLabel}
      GridRowComponent={GridRowComponent}
      typeFilter={typeFilter}
    />
  );
}

ProjectStatsTable.propTypes = {
  clientId: string.isRequired,
};
