import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'poly-utils';
import { EUREST_TIAA_CLIENTS } from '../../constants/index.js';
import NavigationComponent from '../../components/navigation/nav.js';

export default function NavComposer(props) {
  const [overTrigger, setOverTrigger] = useState(false);

  const tab = useSelector((state) => state.location.type);
  const isSearchFocused = useSelector(
    (state) => state.globalSearch.isSearchFocused,
  );

  const clientId = useSelector((state) => state.user.clientId);

  const logotype = EUREST_TIAA_CLIENTS.company.logo;
  const titleName = EUREST_TIAA_CLIENTS.company.title;

  const debouncedTrigger = useCallback(debounce(300)(setOverTrigger), []);

  useEffect(() => {
    if (isSearchFocused) {
      setOverTrigger(true);
    } else {
      debouncedTrigger(false);
    }
  }, [isSearchFocused]);

  return (
    <NavigationComponent
      {...props}
      tab={tab}
      isSearchFocused={isSearchFocused}
      clientId={clientId}
      overTrigger={overTrigger}
      logo={logotype}
      title={titleName}
    />
  );
}
