import React, { useCallback } from 'react';
import moment from 'moment';
import { push } from 'redux-first-router';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { getValue } from '../../util/general.js';
import { setEditProjectModal } from '../../redux/actions/projects.js';
import { prepareEditMRProjectFormData } from './utils.js';
import AddMasterWorkOrder from '../../components/project/add-master-work-order.js';
import { EditReportSuccess, GeneralError } from '../../constants/alerts.js';
import { omitBlockedSuppliersQuery } from '../../utils/suppliers/suppliers-filter.js';
import {
  useUpdateRecurringProject,
  useServiceTypes,
} from '../../hooks/projects.js';
import { useClientDetails } from '../../hooks/client.js';
import { useTechnicians, useManagers } from '../../hooks/staff.js';
import { useSuppliers } from '../../hooks/suppliers.js';
import { usePropertiesQuery } from '../../hooks/properties.js';
import { useCommonFormLogic, useMasterWOCommonHandlers } from './hooks.js';
import { useRefHandlers } from '../../hooks/useRefHandlers.js';
import { getRequestDataForEditMasterWO } from './helpers.js';

export default function (props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const projects = useSelector((state) => state.projects);

  const clientId = user?.clientId;
  const clientConfig = user?.clientConfig;
  const supplierSource = clientConfig?.supplierSource;

  const project = projects.activeProject;

  const { updateMRProject } = useUpdateRecurringProject();

  const { client } = useClientDetails(clientId);

  const {
    searchTerm,
    search,
    searchService,
    setSearch,
    setPropertySearch,
    setServiceSearch,
    onInputChange,
    closeModal,
  } = useMasterWOCommonHandlers();

  const { serviceTypes, serviceLoading } = useServiceTypes(
    supplierSource,
    searchService,
  );

  const { technicians } = useTechnicians(searchTerm, clientId);

  const { managers } = useManagers(searchTerm, clientId);

  const { allSuppliers } = useSuppliers({
    searchTerm,
    query: omitBlockedSuppliersQuery,
  });

  const { propertyLoading, properties, noPropertyResults } =
    usePropertiesQuery(search);

  const initialFormData = prepareEditMRProjectFormData(project);

  const {
    projectManager,
    projectSuppliers,
    projectTechnician,
    startDate,
    endDate,
    isNever,
    selectedProperty,
    selectedService,
    selectedStatus,
    setLoading,
    isProjectAssigned,
    setError,
    validate,
    ...restCommonFormLogicProps
  } = useCommonFormLogic({ clientConfig, initialFormData });

  const { setRef, getRef } = useRefHandlers();

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const startDateObj = moment.isMoment(startDate)
        ? startDate.toDate()
        : startDate;

      const endDateObj = moment.isMoment(endDate) ? endDate.toDate() : endDate;

      setLoading(true);

      const errorsValue = validate({
        property: selectedProperty?.value,
        service: selectedService,
        description: getValue('description', e),
        manager: { clientConfig, projectManager },
        endDate: { endDate: endDateObj, isNever },
        startDate: startDateObj,
      });

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const requestData = getRequestDataForEditMasterWO({
        selectedStatus,
        selectedService,
        startDateObj,
        endDateObj,
        selectedProperty,
        projectManager,
        isProjectAssigned,
        projectSuppliers,
        projectTechnician,
        isNever,
        getRef,
        event: e,
      });

      return updateMRProject(project._id, requestData)
        .then(
          ({
            data: {
              updateRecurringProject: { projectId },
            },
          }) => {
            setLoading(false);
            setError(null);
            dispatch(setEditProjectModal(null));
            push(`/mr-project/${projectId}`);
            toast.success(EditReportSuccess);
          },
        )
        .catch((error) => {
          setLoading(false);
          setError({ server: error.message });
          toast.error(GeneralError);
        });
    },
    [
      selectedStatus,
      startDate,
      endDate,
      isNever,
      selectedProperty,
      selectedService,
      getRef,
      dispatch,
      setError,
      validate,
      setLoading,
      updateMRProject,
      project,
      projectManager,
      projectSuppliers,
      projectTechnician,
      isProjectAssigned,
      clientConfig,
    ],
  );

  if (!project) return null;

  return (
    <AddMasterWorkOrder
      {...props}
      {...restCommonFormLogicProps}
      isProjectAssigned={isProjectAssigned}
      setRef={setRef}
      isNever={isNever}
      closeModal={closeModal}
      propertyLoading={propertyLoading}
      client={client}
      startDate={startDate}
      endDate={endDate}
      selectedProperty={selectedProperty}
      selectedService={selectedService}
      selectedStatus={selectedStatus}
      serviceTypes={serviceTypes}
      properties={properties}
      onSubmit={onSubmit}
      managers={managers}
      technicians={technicians}
      allSuppliers={allSuppliers}
      projectTechnician={projectTechnician}
      projectManager={projectManager}
      projectSuppliers={projectSuppliers}
      setSearch={setSearch}
      onInputChange={onInputChange}
      setPropertySearch={setPropertySearch}
      serviceLoading={serviceLoading}
      setServiceSearch={setServiceSearch}
      noPropertyResults={noPropertyResults}
      title="Edit Master Recurring Work Order"
      submitBtnCaption="Save"
      isEdit
    />
  );
}
