import { gql } from '@apollo/client';

export const blockMultiplePOInvoices = gql`
  mutation blockMultiplePOInvoices($input: POInvoiceBlockInput!) {
    blockMultiplePOInvoices(input: $input) {
      poInvoices {
        _id
      }
    }
  }
`;
