import { ASC_SORT_ORDER, SupplierStatuses } from 'poly-constants';

const { ACTIVE, BLOCKED } = SupplierStatuses;

export const STATUS_COLORS = {
  [ACTIVE]: '#9bdc53',
  [BLOCKED]: '#9ea2a9',
};

export const SUPPLIERS_FILTERS = [
  {
    title: 'Active',
    type: ACTIVE,
    color: '#9bdc53',
    showPip: true,
  },
  {
    title: 'Blocked',
    type: BLOCKED,
    color: '#9ea2a9',
    showPip: true,
  },
];

export const SUPPLIERS_EMAILS = {
  SERVICE: 'SERVICE',
  ACCOUNT: 'ACCOUNT',
};

export const PEOPLE_STATUS_COLORS = {
  active: '#6f7a9f',
  inactive: '#9ea2a9',
  primary: '#ff8c00',
};

export const SUPPLIERS_SORTING = {
  sort: [{ 'company.name.keyword': ASC_SORT_ORDER }],
};
