import { gql } from '@apollo/client';

import { propertyRecurringProjectsFragment } from './get-project.js';

export const getPropertyRecurringProjectsQuery = gql`
  query getPropertyRecurringProjectsQuery(
    $propertyId: ID!
    $input: CollectionSearchParams
  ) {
    property(id: $propertyId) {
      _id
      searchRecurringProjects(input: $input) {
        ...propertyRecurringProjectsFragment
      }
    }
  }

  ${propertyRecurringProjectsFragment}
`;
