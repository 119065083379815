import { gql } from '@apollo/client';

import { updateFragment } from '../fragments.js';

export const maintenancePlanUpdatesQuery = gql`
  query maintenancePlanUpdatesQuery(
    $projectId: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    maintenancePlan(id: $projectId) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...updateFragment
        }
        total
      }
    }
  }

  ${updateFragment}
`;
