import styled from 'styled-components';

const BreadCrumbs = styled.span`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
`;

BreadCrumbs.Item = styled.span`
  position: relative;

  &:not(:last-child) {
    margin-right: 20px;
    padding-right: 32px;

    &::before,
    &::after {
      content: '\\232A'; // '〉'
      width: 6px;
      height: 12px;
      display: inline-block;
      position: absolute;
      top: calc(50% - 6px);
      right: 0;
      line-height: 13px;
      font-size: 11px;
    }

    &::before {
      right: 4px;
    }
  }

  q {
    quotes: '\\00ab''\\00bb'; // '«' '»'
  }
`;

BreadCrumbs.displayName = 'BreadCrumbs';
BreadCrumbs.Item.displayName = 'BreadCrumbs.Item';

export default BreadCrumbs;
