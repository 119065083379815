import { useEffect } from 'react';
import { useExportTableContext } from '../components/PrintAndExportButtons.js';

export function useSaveDataForTableExport(props) {
  const exportTableContext = useExportTableContext();

  useEffect(() => {
    exportTableContext.setExportTableProps(props);

    return () => {
      exportTableContext.setExportTableProps({});
    };
  }, [props]);

  useEffect(() => {
    if (exportTableContext.exportTableProps === props.exportTableProps) {
      exportTableContext.setExportTableProps(
        exportTableContext.exportTableProps,
        exportTableContext.setExportTableProps,
      );
    }
  }, [exportTableContext.exportTableProps, props.exportTableProps]);

  return {
    ...props,
    exportTableProps: exportTableContext.exportTableProps,
    setExportTableProps: exportTableContext.setExportTableProps,
  };
}
