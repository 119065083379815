import React from 'react';
import { TableSearchComponent } from 'poly-site-ui';
import {
  setInvoicesTableSearchString,
  setInvoicesPaginationCurrentPage,
} from '../../redux/actions/index.js';

export default function (props) {
  return (
    <TableSearchComponent
      {...props}
      setTableSearchString={setInvoicesTableSearchString}
      setPaginationCurrentPage={setInvoicesPaginationCurrentPage}
    />
  );
}
