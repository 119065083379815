import React from 'react';
import { Layout } from 'poly-book';

import { LayoutContent } from '../styled.js';
import AuthContainer from '../hooks/auth-container.js';
import Navigation from '../containers/navbar/navigation-container.js';
import CompletedPMProjectsLayout from '../layouts/completed-pm-projects.js';
import CompletedPMProjectsFilter from '../containers/completed-pm-projects/filter.js';

function CompletedPMProjects() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <CompletedPMProjectsFilter />
        </Layout.Header>
        <LayoutContent>
          <CompletedPMProjectsLayout />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default CompletedPMProjects;
