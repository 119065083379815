import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CompletedProjectsList from './list.js';
import CompletedProjectsStats from './stats.js';

function CompletedProjectsData({ filters }) {
  const activeTab = useSelector((state) => state.completedProjects.activeTab);

  const tabs = {
    projects: CompletedProjectsList,
    stats: CompletedProjectsStats,
  };

  const Container = tabs[activeTab];

  return <Container filters={filters} />;
}

CompletedProjectsData.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
};

export default CompletedProjectsData;
