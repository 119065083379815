import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useState, useCallback, useEffect } from 'react';
import { getGlCodeOptionsByClientGLCodes } from './tabs/gl-codes-utils.js';

// getGlCodes :: State -> ReduxState
const getGlCodes = R.compose(
  getGlCodeOptionsByClientGLCodes,
  R.path(['user', 'clientGLCodes']),
);

export const useGlCodeSelectLogic = (modalInfo) => {
  const [code, setCode] = useState(null);

  const glCodes = useSelector((state) => getGlCodes(state));

  useEffect(
    () =>
      setCode(glCodes.find((codeElem) => codeElem.value === modalInfo.glCode)),
    [modalInfo],
  );

  const onSelectCode = useCallback(
    (codeValue) => {
      setCode(codeValue);
    },
    [setCode],
  );

  return {
    code,
    glCodes,
    setCode,
    onSelectCode,
  };
};
