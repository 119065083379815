import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import AddMaterials from '../../../../components/project/job-costs/materials.js';
import {
  getValue,
  removeCurrencyFormatting,
} from '../../../../util/general.js';
import { setMaterialsModal } from '../../../../redux/actions/index.js';
import {
  AddMaterialSuccess,
  EditMaterialSuccess,
  GeneralError,
} from '../../../../constants/alerts.js';
import {
  useAddMaterials,
  useEditMaterials,
} from '../../../../hooks/project-job-costs.js';
import useValidation from '../../../../hooks/useValidation.js';

function AddEditMaterial(props) {
  const dispatch = useDispatch();

  const { materialsModal } = props;

  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (materialsModal.data) {
      setQuantity(materialsModal.data.quantity);
      setPrice(materialsModal.data.price);
      setTotal(materialsModal.data.total);
    }

    return () => setTotal(0);
  }, []);

  const projectId = useSelector((state) => state.projects.activeProject._id);

  const activeProjectId = useSelector((state) => state.location.payload.id);

  const isAdd = materialsModal?.type === 'add';

  const { createMaterial } = useAddMaterials();
  const { updateMaterial } = useEditMaterials();

  const { errors, setError, onChange, validate, validateField } = useValidation(
    {
      validationRules: {
        description: [{ rule: 'required' }],
        quantity: [{ rule: 'required' }],
        unitPrice: [{ rule: 'required' }],
      },
    },
  );

  const closeModal = useCallback(
    () => dispatch(setMaterialsModal(null)),
    [dispatch, setMaterialsModal],
  );

  const onQuantityChange = useCallback(
    (e) => {
      const quantityValue = +e.target.value;
      setQuantity(quantityValue);
      validateField(e.target.name, e.target.value);
      setTotal(quantityValue * price);
    },
    [setTotal, validateField, setQuantity, price],
  );

  const onPriceChange = useCallback(
    (e) => {
      const priceValue = removeCurrencyFormatting(e.target.value);
      setPrice(priceValue);
      validateField(e.target.name, e.target.value);
      setTotal(quantity * priceValue);
    },
    [setTotal, validateField, setPrice, quantity],
  );

  const submitMaterial = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      const errorsValue = validate({
        description: getValue('description', e),
        quantity: getValue('quantity', e),
        unitPrice: getValue('unitPrice', e),
      });

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const materialId = materialsModal.data?._id;

      const data = {
        description: getValue('description', e),
        price,
        projectId,
        quantity,
      };

      const mutationPromise = isAdd
        ? createMaterial(data)
        : updateMaterial(materialId, data);

      return mutationPromise
        .then(() => {
          setLoading(false);
          dispatch(setMaterialsModal(null));
          toast.success(isAdd ? AddMaterialSuccess : EditMaterialSuccess);
        })
        .catch((error) => {
          setLoading(false);
          setError({ server: error.message });
          toast.error(GeneralError);
        });
    },
    [
      quantity,
      price,
      projectId,
      setLoading,
      setError,
      dispatch,
      materialsModal,
      validate,
      isAdd,
    ],
  );

  const showModal =
    materialsModal.type === 'add' || materialsModal.type === 'edit';

  return showModal ? (
    <AddMaterials
      {...props}
      materialsModal={materialsModal}
      activeProjectId={activeProjectId}
      onQuantityChange={onQuantityChange}
      submitMaterial={submitMaterial}
      onPriceChange={onPriceChange}
      closeModal={closeModal}
      onChange={onChange}
      loading={loading}
      errors={errors}
      total={total}
      isAdd={isAdd}
    />
  ) : null;
}

AddEditMaterial.propTypes = {
  materialsModal: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      quantity: PropTypes.number,
      price: PropTypes.number,
      total: PropTypes.number,
      _id: PropTypes.string,
    }),
  }),
};

export default function (props) {
  const materialsModal = useSelector(
    (state) => state.projects.modals.materials,
  );

  return materialsModal ? (
    <AddEditMaterial {...props} materialsModal={materialsModal} />
  ) : null;
}
