import * as R from 'ramda';
import {
  performExcelExport,
  createExcelExportCell,
  ExcelExportCellType,
} from 'poly-client-utils';
import { forceTitleCase } from 'poly-utils';
import { formatDateIfExists } from '../../util/general.js';

const COLUMN_WIDTH = [15, 25, 30, 35, 25, 15, 15, 10];

const TITLE_ROW = [
  'WO #',
  'WO Type',
  'Description',
  'Property',
  'Assigned To',
  'Start Date',
  'End Date',
  'Status',
];

// getMasterRecurringRowConfig :: Project -> [ExcelExportDataCell]
const getMasterRecurringRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 8),
  ]),
  R.juxt([
    R.pathOr('', ['projectId']),
    R.always('Master Recurring'),
    R.pathOr('', ['description']),
    R.pathOr('', ['property', 'name']),
    R.pathOr('', ['technicians', 0, 'fullName']),
    R.compose(formatDateIfExists, R.propOr('', 'startDate')),
    R.compose(formatDateIfExists, R.propOr('', 'endDate')),
    R.compose(forceTitleCase, R.pathOr('', ['status'])),
  ]),
);

// getMasterRecurringExcelConfig :: [Project] -> Object
export const getMasterRecurringExcelConfig = (projects) => ({
  exportFileName: 'master_recurring_work_orders.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 8)],
      TITLE_ROW,
    ),
    ...R.map(getMasterRecurringRowConfig, projects),
  ],
});

export const exportMasterRecurringWOToXLS = ({ data = {} }) => {
  const projects = R.pathOr([], ['searchRecurringProjects', 'hits'], data);
  const excelConfig = getMasterRecurringExcelConfig(projects);
  performExcelExport(excelConfig);
};
