import store from '../../redux/store.js';
import {
  setStaffSidebar,
  setPropertySidebar,
  setSupplierSidebar,
} from '../../redux/actions/index.js';

export const closeAllSidebars = () => {
  store.dispatch(setStaffSidebar(null));
  store.dispatch(setPropertySidebar(null));
  store.dispatch(setSupplierSidebar(null));
};
