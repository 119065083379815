import { gql } from '@apollo/client';

export const addMaterials = gql`
  mutation addMaterials(
    $description: NonEmptyString!
    $price: Money!
    $projectId: ID!
    $quantity: Float!
  ) {
    createMaterialSpend(
      description: $description
      price: $price
      projectId: $projectId
      quantity: $quantity
    ) {
      _id
      createdAt
      description
      price
      quantity
      total
    }
  }
`;
