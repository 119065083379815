import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ConfirmModal } from 'poly-site-ui';

import {
  GeneralError,
  RemoveAssetFromProjectSuccess,
} from '../../../../constants/alerts.js';
import { setProjectAssetsModal } from '../../../../redux/actions/index.js';
import {
  useUpdateProject,
  useUpdateRecurringProject,
} from '../../../../hooks/projects.js';

export default function (props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const removeAsset = useSelector(
    (state) => state.projectAssets.modals.removeAsset,
  );
  const { isRecurring, childType, documentId } = removeAsset;

  const asset = useSelector((state) => state.asset);

  const { updateProject } = useUpdateProject();
  const { updateMRProject } = useUpdateRecurringProject();

  const text =
    'You are about to remove asset from the project. Approve to process.';

  const closeModal = useCallback(
    () => dispatch(setProjectAssetsModal({ removeAsset: null })),
    [dispatch, setProjectAssetsModal],
  );

  const onConfirm = useCallback(() => {
    setLoading(true);
    const mutation = isRecurring ? updateMRProject : updateProject;

    const update = isRecurring
      ? { payload: { [childType]: { assetIds: [] } } }
      : { assetIds: [] };
    return mutation(documentId, update)
      .then(() => {
        setLoading(false);
        toast.success(RemoveAssetFromProjectSuccess);
        closeModal();
      })
      .catch(() => {
        setLoading(false);
        toast.error(GeneralError);
      });
  }, [
    documentId,
    closeModal,
    setLoading,
    updateProject,
    updateMRProject,
    isRecurring,
    childType,
  ]);

  return (
    <ConfirmModal
      {...props}
      showModal
      closeModal={closeModal}
      heading={asset.equipmentType}
      subHeading="Confirmation"
      onConfirm={onConfirm}
      text={text}
      loading={loading}
    />
  );
}
