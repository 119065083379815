import * as R from 'ramda';

import {
  AREA,
  CITY,
  CODE,
  COUNTRY,
  HOUSE,
  STREET,
  SUFFIX,
} from '../../constants/address.js';
import { SupplierNameError } from '../../constants/alerts.js';
import { phoneFormatter } from '../formatters/index.js';

export const getEmailByType = (emails, type) => {
  const emailObject = emails.find(R.propEq('type', type));

  return R.propOr('', 'email', emailObject);
};

export const companyPhoneFormatter = (phones) =>
  R.compose(
    phoneFormatter,
    R.propOr('', 'phone'),
    R.find(
      R.both(R.propEq('isPrimary', true), R.propEq('legacyFormat', false)),
    ),
  )(phones);

export const emailFormatter = (emails, type) =>
  emails.length ? R.filter(R.propEq('type', type), emails) : [];

export const filterMultipleEmails = (name, emails, event) => {
  const result = {};
  if (emails.length > 1) {
    R.drop(1, emails).forEach((item, index) => {
      const pick = `${name}${index + 1}`;
      result[pick] = event ? event.target[pick].value : [{ rule: 'email' }];
    });
  }
  return result;
};

export const addressPartsFormatter = (array = []) => {
  const exam = [HOUSE, STREET, CITY, AREA, CODE, SUFFIX, COUNTRY];
  const filter = array.filter((item) =>
    R.includes(R.path(['types', 0], item), exam),
  );
  return R.converge(R.zipObj, [
    R.map(R.path(['types', 0])),
    R.map(R.prop('short_name')),
  ])(filter);
};

/**
 * checkSupplierNameError :: Error -> String -> Boolean
 */
export const checkSupplierNameError = R.curry((name, error = '') =>
  R.allPass([R.includes('GraphQL error'), R.includes(SupplierNameError(name))])(
    error,
  ),
);
