import PropTypes from 'prop-types';

export const formValuesPropTypes = PropTypes.shape({
  status: PropTypes.string,
  description: PropTypes.string,
  manufacturer: PropTypes.string,
  model: PropTypes.string,
  serial: PropTypes.string,
  location: PropTypes.string,
  room: PropTypes.string,
  startUp: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  equipmentType: PropTypes.string,
  property: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
});
