export const MAIN = 'MAIN';
export const LOGIN = 'LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const PROJECT = 'PROJECT';
export const PROPERTIES = 'PROPERTIES';
export const PROPERTY = 'PROPERTY';
export const REPORTS = 'REPORTS';
export const REPORT = 'REPORT';
export const REPORT_PM = 'REPORT_PM';
export const MR_PM_PROJECT = 'MR_PM_PROJECT';
export const STAFF = 'STAFF';
export const ASSETS = 'ASSETS';
export const ASSET = 'ASSET';
export const SUPPLIERS = 'SUPPLIERS';
export const SUPPLIER = 'SUPPLIER';
export const PMS = 'PMS';
export const MR_PROJECT = 'MR_PROJECT';
export const PM_PROJECT = 'PM_PROJECT';
export const PURCHASE_ORDERS = 'PURCHASE_ORDERS';
export const PURCHASE_ORDER = 'PURCHASE_ORDER';
export const APPROVED_POS = 'APPROVED_POS';
export const INVOICES = 'INVOICES';
export const APPROVED_INVOICES = 'APPROVED_INVOICES';
export const COMPLETED_WOS = 'COMPLETED_WOS';
export const COMPLETED_ON_HOLD_WOS = 'COMPLETED_ON_HOLD_WOS';
export const COMPLETED_PMS = 'COMPLETED_PMS';
export const TECHNICIAN_STATS = 'TECHNICIAN_STATS';
export const CORRECTIVES = 'CORRECTIVES';
export const EYES_WIDE_OPEN = 'EYES_WIDE_OPEN';
export const INVOICE_LOG = 'INVOICE_LOG';
export const PO_LOG = 'PO_LOG';
export const CONTRACT_EXPIRATION_REPORT = 'CONTRACT_EXPIRATION_REPORT';
export const PROJECTS_STATS = 'PROJECTS_STATS';
export const INCORRECT_DEVICE = 'INCORRECT_DEVICE';
