import { ACTIVE, INACTIVE } from '../../constants/report/master-pm.js';
import { SORTING } from '../../constants/index.js';

const filterQueryHash = {
  [ACTIVE]: {
    bool: {
      must: { match: { status: ACTIVE } },
    },
  },
  [INACTIVE]: {
    bool: {
      must: { match: { status: INACTIVE } },
    },
  },
};

export const useMRPmFilters = ({ typeFilter }) => {
  const { sort } = SORTING;
  const query = filterQueryHash[typeFilter];

  return {
    sort,
    query,
  };
};
