import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { STANDARD_SIZE } from '../constants/index.js';
import { useSearchChangedSubscription } from './useReactiveEntities.js';

// getDocumentIdByProps :: Object -> String
const getDocumentIdByProps = R.compose(
  R.prop('_id'),
  R.converge(R.prop, [R.prop('queryEndpointName'), R.identity]),
);

export const useReactiveUpdates = ({
  gqlQuery,
  gqlSubscription,
  from = 0,
  size = STANDARD_SIZE,
  sort = { createdAt: -1 },
  skipQuery = false,
  queryEndpointName = '',
  additionalVars = {},
  alias = 'useReactiveUpdates',
}) => {
  const {
    data,
    loading,
    refetch,
    called,
    client,
    fetchMore,
    observable,
    previousData,
    startPolling,
    stopPolling,
    updateQuery,
    variables,
    networkStatus,
  } = useQuery(gqlQuery, {
    alias,
    variables: {
      documentUpdatesInput: {
        sort,
        from,
        size,
      },
      ...additionalVars,
    },
    skip: skipQuery,
  });

  const result = {
    ...data,
    ...additionalVars,
    loading,
    refetch,
    networkStatus,
    called,
    client,
    fetchMore,
    observable,
    previousData,
    startPolling,
    stopPolling,
    variables,
    updateQuery,
    queryEndpointName,
  };

  useSearchChangedSubscription({
    gqlChangedQuery: gqlSubscription,
    refetch,
    result,
    extractSubscriptionOptions: R.compose(
      R.assocPath(['variables', 'input', 'documentId'], R.__, {}),
      getDocumentIdByProps,
    ),
    debounceTime: 0,
  });

  return {
    loading,
    result,
  };
};
