import { createActions } from 'redux-actions';

export const {
  setInvoiceLogPaginationTotal,
  setInvoiceLogTableSearchString,
  setInvoiceLogPaginationPageSize,
  setInvoiceLogPaginationCurrentPage,
  setInvoiceLogPaginationDropdownLabel,
} = createActions(
  'SET_INVOICE_LOG_PAGINATION_TOTAL',
  'SET_INVOICE_LOG_TABLE_SEARCH_STRING',
  'SET_INVOICE_LOG_PAGINATION_PAGE_SIZE',
  'SET_INVOICE_LOG_PAGINATION_CURRENT_PAGE',
  'SET_INVOICE_LOG_PAGINATION_DROPDOWN_LABEL',
);
