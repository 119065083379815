import types from '../types.js';

export const setPmProjectsFilter = (filter) => ({
  type: types.SET_PM_PROJECTS_FILTER,
  payload: filter,
});

export const setPmProjectsTableSearchString = (query) => ({
  type: types.SET_PM_PROJECTS_TABLE_SEARCH_STRING,
  payload: query,
});

export const setPmProjectsTechnicianModal = (modal) => ({
  type: types.SET_PM_PROJECTS_TECHNICIANS_MODAL,
  payload: modal,
});

export const togglePmProject = (id) => ({
  type: types.TOGGLE_PM_PROJECT,
  payload: id,
});

export const setSelectedPmProjects = (ids) => ({
  type: types.SET_SELECTED_PM_PROJECTS,
  payload: ids,
});

export const setPmProjectActiveTab = (tab) => ({
  type: types.SET_PM_PROJECT_ACTIVE_TAB,
  payload: tab,
});

export const setPmProjectAssetsActiveTab = (tab) => ({
  type: types.SET_PM_PROJECT_ASSETS_ACTIVE_TAB,
  payload: tab,
});

export const setPmProjectsPaginationCurrentPage = (current) => ({
  type: types.SET_PM_PROJECTS_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setPmProjectsPaginationTotal = (total) => ({
  type: types.SET_PM_PROJECTS_PAGINATION_TOTAL,
  payload: total,
});

export const setPmProjectsPaginationPageSize = (size) => ({
  type: types.SET_PM_PROJECTS_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setPmProjectsPaginationDropdownLabel = (label) => ({
  type: types.SET_PM_PROJECTS_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});
