import { gql } from '@apollo/client';

import { contactUserFragment } from '../fragments.js';

export const getPropertyUsers = gql`
  query getPropertyUsers($propertyId: ID!, $input: CollectionSearchParams) {
    property(id: $propertyId) {
      _id
      address {
        formatted_address
        address_parts {
          street_number
          route
          locality
          administrative_area_level_1
          administrative_area_level_2
          country
          postal_code
          postal_code_suffix
          neighborhood
        }
        geo {
          type
          coordinates
        }
      }
      searchContacts(input: $input) {
        hits {
          ...contactUserFragment
        }
      }
    }
  }

  ${contactUserFragment}
`;
