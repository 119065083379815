import * as R from 'ramda';
import PropTypes, { bool } from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { Avatar, Grid, Heading, Icon, IconButton, S, Tabs } from 'poly-book';
import { Loader } from 'poly-site-ui';
import { useSelector } from 'react-redux';
import { getFirstLetterOfNames } from 'poly-client-utils';
import { SkillsView } from './fields/skill-view.js';
import UserProjectsContainer from '../../containers/staff/user-projects.js';
import { getUserTypeName, statusFormatter } from '../../util/staff.js';
import { emailLinkFormatter } from '../../util/project-utils.js';
import { useUserProjectsCountQuery } from '../../hooks/staff.js';

const SidebarTitle = styled(Heading)`
  margin-bottom: 40px;
`;

const UserInfoWrapper = styled(Grid)`
  margin-bottom: 50px;
`;

const UserDetails = styled(Grid.Cell)`
  margin-top: 15px;
  grid-row-gap: 10px;
`;

const SidebarTabs = styled(Tabs)`
  // fix overflow bug in firefox
  min-height: 0;
  display: flex;
  flex-direction: column;
`;

const TabsHeader = styled(Tabs.Header)`
  min-height: min-content;
`;

const TabsContent = styled(Tabs.Content)`
  overflow: auto;
`;

function TabsTitle({ id, title }) {
  const tabs = useSelector((state) => state.staff.modals.sidebar.tabs);

  const { count } = useUserProjectsCountQuery({
    id,
    query: tabs.tabsQueries[title],
    projectsResultPropName: tabs.projectsResultPropName,
  });

  return (
    <>
      {title}
      {`(${count})`}
    </>
  );
}

TabsTitle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
};

export function StaffSidebar({
  selectedUser,
  openModal,
  activeTab,
  setSidebarActiveTab,
  tabsNames,
  loading,
  isAllowedToEdit,
}) {
  return loading ? (
    <Loader />
  ) : (
    <>
      <SidebarTitle>
        <Heading.h3 lighter>Profile</Heading.h3>
        {statusFormatter()(selectedUser)}
      </SidebarTitle>
      <UserInfoWrapper columns="minmax(80px, 115px) 1fr" marin={50} simple>
        <Grid.Row>
          <Grid.Cell>
            <Avatar
              content={getFirstLetterOfNames(
                selectedUser.firstName,
                selectedUser.lastName,
              )}
              type="text"
              size={80}
            />
          </Grid.Cell>
          <UserDetails horizontal="stretch">
            <Heading>
              <Heading.h3>
                {selectedUser.firstName} {selectedUser.lastName}
              </Heading.h3>
              {isAllowedToEdit && (
                <IconButton onClick={openModal}>
                  <Icon
                    name="edit"
                    fill="#9fa1ab"
                    dimensions={{ height: 14 }}
                  />
                </IconButton>
              )}
            </Heading>
            <S type="title" textColor="#a1a9c3">
              {getUserTypeName(selectedUser)}
            </S>
            <Heading.h4 lighter>
              {R.prop('mobile', selectedUser) || '—'}
            </Heading.h4>
            <Heading.h4 lighter>
              {R.ifElse(
                R.prop('email'),
                R.compose(emailLinkFormatter(), R.prop('email')),
                R.always('—'),
              )(selectedUser)}
            </Heading.h4>
            {R.ifElse(
              R.pathSatisfies(R.lt(0), ['technicianSkills', 'length']),
              R.compose(
                (skills) => <SkillsView {...{ skills }} />,
                R.prop('technicianSkills'),
              ),
              R.always(null),
            )(selectedUser)}
          </UserDetails>
        </Grid.Row>
      </UserInfoWrapper>
      <SidebarTabs>
        <TabsHeader>
          {tabsNames.map((title) => (
            <Tabs.Tab
              key={title}
              href={`#${title}`}
              active={title === activeTab}
              onClick={setSidebarActiveTab(title)}
              bold
            >
              <TabsTitle id={R.prop('_id', selectedUser)} {...{ title }} />
            </Tabs.Tab>
          ))}
        </TabsHeader>
        <TabsContent>
          <UserProjectsContainer />
        </TabsContent>
      </SidebarTabs>
    </>
  );
}
const defaultTotalPropType = PropTypes.shape({
  total: PropTypes.number,
});

StaffSidebar.propTypes = {
  activeTab: PropTypes.string.isRequired,
  tabsNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  openModal: PropTypes.func.isRequired,
  setSidebarActiveTab: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userGroups: PropTypes.arrayOf(
      PropTypes.shape({
        userGroup: PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
        }),
      }),
    ),
    activeProjectsCount: defaultTotalPropType,
    overdueProjectsCount: defaultTotalPropType,
    lastThirtyProjectsCount: defaultTotalPropType,
    email: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    phone: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    status: PropTypes.string,
    avatar: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool,
  isAllowedToEdit: bool,
};
