import { useState } from 'react';
import * as R from 'ramda';

import { ramdaUseWith } from 'poly-utils';

const listIsType = ramdaUseWith(R.all, [R.is, R.identity]);

const getSortOrder =
  (order) =>
  ([a, b]) =>
    ((a > b) - (b > a)) * order;

const applySort = R.curry((sort, a, b) =>
  R.cond([
    // pass numbers straight to sorting
    [listIsType(Number), sort],
    // convert dates to millisecond equiv
    [
      listIsType(Date),
      R.compose(
        sort,
        R.map((x) => x.getTime()),
      ),
    ],
    // set strings to lower case
    [listIsType(String), R.compose(sort, R.map(R.toLower))],
    // user wants to sort it nevertheless there are equal types of values or not
    [R.T, R.compose(sort, R.map(R.compose(R.toLower, R.toString)))],
  ])([a, b]),
);

const getSort = ramdaUseWith(applySort, [getSortOrder, R.identity, R.identity]);

const sortTableRows = (valuesToSort, { key, dir: order }) =>
  R.sort((row1, row2) =>
    getSort(order)(
      valuesToSort[key](row1), // values to compare
      valuesToSort[key](row2), // values to compare
    ),
  );

export const useSortableTable = (rowsProp, valuesToSort) => {
  const [sorting, setSorting] = useState();

  const rows = sorting
    ? sortTableRows(valuesToSort, sorting)(rowsProp)
    : rowsProp;

  return {
    rows,
    setSorting,
    sorting,
  };
};
