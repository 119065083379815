import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HiddenInput from '../HiddenInput/index.js';
import Icon from '../Icon/index.js';

const WIDTH = 16;
const HEIGHT = 16;

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Label = styled.label`
  display: block;
  min-height: ${HEIGHT}px;
  padding-left: ${WIDTH}px;
  position: relative;
  cursor: pointer;

  &:not(:empty) {
    padding-left: ${WIDTH + 10}px;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
  }

  &::before {
    width: ${WIDTH}px;
    height: ${HEIGHT}px;
    top: 0;
    left: 0;
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #babfd2;

    ${({ indeterminate }) => indeterminate && 'border-color: #2065bd;'};
  }

  &::after {
    width: 8px;
    height: 2px;
    top: 7px;
    left: 4px;
    background-color: #2065bd;
    opacity: ${({ indeterminate }) => (indeterminate ? 1 : 0)};
    will-change: opacity;
  }

  svg {
    position: absolute;
    top: 3px;
    left: 3px;
    opacity: 0;
    will-change: opacity;
  }
`;

const Input = styled(HiddenInput)`
  &:checked {
    + ${Label}::before {
      background-color: #2065bd;
      border-color: transparent;
    }

    + ${Label} svg {
      opacity: 1;
    }
  }
`;

function Checkbox(props) {
  const { indeterminate, content, id, disabled } = props;

  return (
    <Wrapper>
      <Input type="checkbox" {...{ disabled, ...props }} />
      <Label
        htmlFor={id}
        {...{ indeterminate }}
        onClick={disabled ? props.showErrorMessage : () => null}
      >
        <Icon name="check" dimensions={{ width: 12 }} fill="#fff" />
        {content}
      </Label>
    </Wrapper>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  indeterminate: PropTypes.bool,
  content: PropTypes.node,
  disabled: PropTypes.bool,
  showErrorMessage: PropTypes.func,
};

Wrapper.displayName = 'Wrapper';
Label.displayName = 'Label';
HiddenInput.displayName = 'HiddenInput';
Checkbox.displayName = 'Checkbox';

export default Checkbox;
