import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Heading, S } from 'poly-book';
import styled from 'styled-components';
import React from 'react';
import { forceTitleCase } from 'poly-utils';

const SkillWrapper = styled.div`
  display: inline-flex;

  &:not(:last-child) {
    &:after {
      display: inline-flex;
      content: ',';
      margin-right: 5px;
    }
  }
`;

export function SkillsView({ skills }) {
  return (
    <>
      <S type="title" textColor="#a1a9c3">
        SKILLS
      </S>
      <Heading.h4 lighter>
        {R.map(
          (skill) => (
            <SkillWrapper key={skill}>{forceTitleCase(skill)}</SkillWrapper>
          ),
          skills,
        )}
      </Heading.h4>
    </>
  );
}

SkillsView.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.string).isRequired,
};
