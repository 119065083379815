import * as R from 'ramda';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import {
  GeneralError,
  EditInvoiceSuccess,
  InvoiceNumberError,
} from '../../constants/alerts.js';
import { MODAL_STATUSES } from '../../constants/invoices.js';
import AddEditInvoice from '../../components/invoices/add-edit-modal.js';
import { checkInvoiceNumberError } from '../../utils/invoices/index.js';

import { setInvoicesModal } from '../../redux/actions/index.js';
import {
  isExist,
  getValue,
  removeCurrencyFormatting,
} from '../../util/general.js';
import { validateInvoiceAmountByPoBalance } from './helpers.js';
import useValidation from '../../hooks/useValidation.js';
import { useUpdateInvoice } from '../../hooks/invoices.js';
import { useRefHandlers } from '../../hooks/useRefHandlers.js';

// getBalance :: Modal -> Number
const getBalance = (modal) =>
  R.sum([R.prop('balance', modal), R.prop('total', modal)]);

export const pdfValidationConfig = {
  rule: (file) => {
    if (file?.fileType) {
      return file.fileType !== 'application/pdf';
    }
    return false;
  },
  message: 'File must have PDF format',
};

function InvoiceEdit(props) {
  const dispatch = useDispatch();

  const { modalInfo } = props;

  const {
    project,
    cardNumber,
    amount,
    total,
    invoiceNumber,
    glCodes,
    glCode,
    comments,
  } = modalInfo;

  const [status, setStatus] = useState(MODAL_STATUSES.ACTIVE);
  const [code, setCode] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [receiveDate, setReceiveDate] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);

  useEffect(() => {
    setCode(glCodes.find((codeItem) => codeItem.value === glCode));
    setFile({
      name: modalInfo.invoiceFile.fileName,
      src: modalInfo.invoiceFile.url,
      size: modalInfo.invoiceFile.fileSize,
    });
    setReceiveDate(new Date(modalInfo.receiveDate));
    setInvoiceDate(new Date(modalInfo.invoiceDate));
  }, []);

  const invoiceId = modalInfo._id;
  const balance = getBalance(modalInfo);

  const { setRef, getRef } = useRefHandlers();

  const { updateInvoice } = useUpdateInvoice();

  const { errors, setError, onChange, validate, validateField } = useValidation(
    {
      validationRules: {
        invoiceNumber: [{ rule: 'required' }],
        invoiceTotal: [
          {
            rule: validateInvoiceAmountByPoBalance,
            message: 'Invoice amount exceeds PO amount',
          },
          {
            rule: ({ invoiceTotal }) => invoiceTotal === 0,
            message: 'Value cannot be zero',
          },
          {
            rule: ({ invoiceTotal }) => !isExist(invoiceTotal),
            message: 'Please Fill Out This Field',
          },
        ],
        receiveDate: [{ rule: 'required' }],
        invoiceDate: [{ rule: 'required' }],
        code: [{ rule: 'required' }],
        file: [{ rule: 'required' }, pdfValidationConfig],
      },
    },
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      const invoiceTotal = isExist(getValue('invoiceTotal', e))
        ? removeCurrencyFormatting(getValue('invoiceTotal', e))
        : null;

      const invoiceNumberValue = getValue('invoiceNumber', e);
      const glCodeValue = code.value;
      const commentsValue = getValue('comments', e);

      const errorsValue = validate({
        invoiceNumber: invoiceNumberValue,
        invoiceTotal: { invoiceTotal, balance },
        receiveDate,
        invoiceDate,
        code,
        file,
      });

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const update = {
        invoiceDate,
        receiveDate,
        total: invoiceTotal,
        invoiceNumber: invoiceNumberValue,
        comments: commentsValue,
        glCode: glCodeValue,
      };

      if (!R.has('options', file)) {
        update.invoiceFile = R.prop('file', file);
      }

      return updateInvoice(invoiceId, update)
        .then(() => {
          dispatch(setInvoicesModal({ editInvoice: null }));
          if (status === MODAL_STATUSES.BLOCKED) {
            dispatch(
              setInvoicesModal({
                blockInvoice: { invoiceId, invoiceNumber: invoiceNumberValue },
              }),
            );
          } else {
            toast.success(EditInvoiceSuccess);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (checkInvoiceNumberError(err.message)) {
            setError({ invoiceNumber: InvoiceNumberError });
            return;
          }
          setError({ server: err.message });
          toast.error(GeneralError);
        });
    },
    [
      code,
      file,
      status,
      balance,
      validate,
      dispatch,
      setError,
      invoiceId,
      setLoading,
      receiveDate,
      invoiceDate,
      updateInvoice,
    ],
  );

  const closeModal = useCallback(
    () => dispatch(setInvoicesModal({ editInvoice: null })),
    [dispatch],
  );

  const onInvoiceTotalChange = useCallback(
    (e) => {
      const value = R.path(['target', 'value'], e);
      if (isExist(value)) {
        const invoiceTotalValue = removeCurrencyFormatting(value);
        return validateField('invoiceTotal', {
          invoiceTotal: invoiceTotalValue,
          balance,
        });
      }
      return validateField('invoiceTotal', { invoiceTotal: null, balance });
    },
    [balance, validateField],
  );

  const onReceiveDateChange = useCallback(
    (date) => {
      setReceiveDate(date);
      validateField('receiveDate', date);
    },
    [validateField, setReceiveDate],
  );

  const onInvoiceDateChange = useCallback(
    (date) => {
      setInvoiceDate(date);
      validateField('invoiceDate', date);
    },
    [validateField, setInvoiceDate],
  );

  const onSelectCode = useCallback(
    (codeValue) => {
      setCode(codeValue);
      validateField('code', codeValue?.value);
    },
    [setCode, validateField],
  );

  const onAddFile = useCallback(
    (fileValue) => {
      setError({ ...errors, file: null });
      setFile(fileValue);
    },
    [setFile, setError, errors],
  );

  const onRemoveFile = useCallback(() => setFile(null), [setFile]);

  const onStatusSelect = useCallback(
    (statusValue) => setStatus(statusValue?.value),
    [setStatus],
  );

  return (
    <AddEditInvoice
      {...props}
      setRef={setRef}
      getRef={getRef}
      project={project}
      closeModal={closeModal}
      type="edit"
      code={code}
      file={file}
      cardNumber={cardNumber}
      amount={amount}
      balance={balance}
      total={total}
      receiveDate={receiveDate}
      invoiceDate={invoiceDate}
      invoiceNumber={invoiceNumber}
      comments={comments}
      onSubmit={onSubmit}
      errors={errors}
      loading={loading}
      onChange={onChange}
      onInvoiceTotalChange={onInvoiceTotalChange}
      onReceiveDateChange={onReceiveDateChange}
      onInvoiceDateChange={onInvoiceDateChange}
      onSelectCode={onSelectCode}
      glCodes={glCodes}
      status={status}
      onStatusSelect={onStatusSelect}
      onAddFile={onAddFile}
      onRemoveFile={onRemoveFile}
    />
  );
}

InvoiceEdit.propTypes = {
  modalInfo: PropTypes.shape({
    invoiceFile: PropTypes.shape({
      fileName: PropTypes.string,
      url: PropTypes.string,
      fileSize: PropTypes.number,
    }),
    receiveDate: PropTypes.string,
    invoiceDate: PropTypes.string,
    _id: PropTypes.string,
    amount: PropTypes.number,
    total: PropTypes.number,
    cardNumber: PropTypes.string,
    invoiceNumber: PropTypes.string,
    glCodes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    glCode: PropTypes.string,
    comments: PropTypes.string,
    project: PropTypes.string,
  }),
};

export default function (props) {
  const modalInfo = useSelector((state) => state.invoices.modals.editInvoice);

  return modalInfo ? <InvoiceEdit {...props} modalInfo={modalInfo} /> : null;
}
