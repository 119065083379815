import { gql } from '@apollo/client';

import { projectAssetsDetailsFragment as projectAssetsDetailsFragmentBase } from 'poly-site-ui';

export const getRecurringProjectAssets = gql`
  query getRecurringProjectAssets($id: ID) {
    recurringProject(projectId: $id) {
      _id
      childType
      property {
        _id
        name
      }
      assets {
        ...projectAssetsDetailsFragment
      }
    }
  }

  ${projectAssetsDetailsFragmentBase}
`;

export const projectAssetsDetailsFragment = projectAssetsDetailsFragmentBase;
