import types from '../types.js';

export const setCompletedWOsTableSearchString = (query) => ({
  type: types.SET_COMPLETED_WOS_TABLE_SEARCH_STRING,
  payload: query,
});

export const setCompletedWOsPaginationCurrentPage = (current) => ({
  type: types.SET_COMPLETED_WOS_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setCompletedWOsPaginationTotal = (total) => ({
  type: types.SET_COMPLETED_WOS_PAGINATION_TOTAL,
  payload: total,
});

export const setCompletedWOsPaginationPageSize = (size) => ({
  type: types.SET_COMPLETED_WOS_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setCompletedWOsPaginationDropdownLabel = (label) => ({
  type: types.SET_COMPLETED_WOS_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});

export const setCompletedWOsDate = (date) => ({
  type: types.SET_COMPLETED_WOS_DATE,
  payload: date,
});

export const setCompletedWOsServiceType = (serviceType) => ({
  type: types.SET_COMPLETED_WOS_SERVICE_TYPE,
  payload: serviceType,
});

export const setCompletedWOsActiveTab = (tab) => ({
  type: types.SET_COMPLETED_WOS_ACTIVE_TAB,
  payload: tab,
});
