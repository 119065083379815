import React from 'react';
import { Icon, IconButton } from 'poly-book';
import { HREF_PLACEHOLDER } from 'poly-constants';
import { Loader as PolyBookLoader } from 'poly-site-ui';

export const href = HREF_PLACEHOLDER;

export function Loader() {
  return <PolyBookLoader fixed />;
}

export const renderButton = (selected, onClick, active) => {
  const name = selected ? 'cancel' : 'add';
  const fill = selected ? '#a1a9c3' : '#ff8c00';

  return (
    <IconButton type="button" {...{ fill, onClick, active }} hover>
      <Icon {...{ name, fill }} dimensions={{ width: 30 }} />
    </IconButton>
  );
};
