import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { assocBy, forceTitleCase } from 'poly-utils';
import { AbsoluteLoader } from 'poly-site-ui';
import EyesWideOpenStatsComponent from '../../components/eyes-wide-open-stats/index.js';
import { entityFormatter, fieldFormatter } from '../../util/general.js';
import {
  ClientAmountWithPercents,
  FacilitiesAmountWithPercents,
  SelectYearMessage,
  TotalWOAmount,
} from './components.js';
import { useEyesWideOpen } from '../../hooks/projects.js';

const columnsByYear = (year) => [
  ['Month', R.compose(forceTitleCase, R.prop('monthName')), fieldFormatter],
  ["Total WO's", (props) => <TotalWOAmount {...props} year={year} />],
  ['Client', (props) => <ClientAmountWithPercents {...props} year={year} />],
  [
    'Facilities',
    (props) => <FacilitiesAmountWithPercents {...props} year={year} />,
  ],
];

// rows :: Object -> [Object]
const rows = R.converge(R.append, [
  R.mergeRight({
    monthName: 'YTD Total',
    _id: 'total',
  }),
  R.compose(R.map(assocBy('_id', R.prop('monthName'))), R.prop('byMonth')),
]);

function EyesWideOpen({ year, clientId }) {
  const { data, loading, fetchMore } = useEyesWideOpen({
    year,
    clientId,
  });

  if (!year) return <SelectYearMessage />;
  if (loading) return <AbsoluteLoader />;

  const eyesWideOpenStats = data.client?.eyesWideOpenStats;

  const additionalProps = {
    ...entityFormatter(rows(eyesWideOpenStats), columnsByYear(year)),
    gridColumns: 'repeat(4, minmax(100px, 1fr))',
    fetchMore,
  };

  return <EyesWideOpenStatsComponent {...additionalProps} />;
}

EyesWideOpen.propTypes = {
  clientId: PropTypes.string,
  year: PropTypes.number.isRequired,
};

export default function (props) {
  const clientId = useSelector((state) => state.user.clientId);

  return clientId ? (
    <EyesWideOpen {...props} clientId={clientId} />
  ) : (
    <AbsoluteLoader />
  );
}
