import React from 'react';

import ConfirmModal from '../containers/approved-invoices/confirm-modal.js';
import ApprovedInvoices from '../containers/approved-invoices/list.js';
import Footer from '../containers/approved-invoices/footer.js';

function ApprovedInvoicesLayout() {
  return (
    <>
      <ApprovedInvoices />
      <Footer />
      <ConfirmModal />
    </>
  );
}

export default ApprovedInvoicesLayout;
