import * as R from 'ramda';
import { renameProp } from 'poly-utils';
import {
  PO_AMOUNT_AIT,
  CLIENT_ID_AIT,
  CREATED_BY_AIT,
  PROPERTY_ID_AIT,
} from 'poly-security';

import { COLORS } from '../../constants/purchase-orders.js';

/**
 * mapStatusesToSelectProps :: [Statuses] -> { value: Status, label: Status }
 */
export const mapStatusesToSelectProps = R.pipe(
  R.reject(R.isNil),
  R.map((value) => ({ value, label: value })),
);

/**
 * modifyPOToAccessItemTypes :: PurchaseOrder -> AccessItemTypes
 */
export const modifyPOToAccessItemTypes = R.compose(
  R.reject(R.isNil),
  R.mergeAll,
  R.juxt([
    R.pipe(R.path(['createdBy', '_id']), R.objOf(CREATED_BY_AIT)),
    R.pipe(R.pick(['amount']), renameProp('amount', PO_AMOUNT_AIT)),
    R.pipe(R.path(['client', '_id']), R.objOf(CLIENT_ID_AIT)),
    R.pipe(
      R.ifElse(
        R.pathSatisfies(R.complement(R.isNil), ['property', '_id']),
        R.path(['property', '_id']),
        R.path(['project', 'property', '_id']),
      ),
      R.objOf(PROPERTY_ID_AIT),
    ),
  ]),
);

export const poStatusUIData = (status) =>
  COLORS.find((color) => color.type === status);
