import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'poly-site-ui';
import AssetDetails from '../../components/asset/details.js';
import { useAssetDetailsSub } from '../../hooks/assets.js';

export default function (props) {
  const assetId = useSelector((state) => state.location.payload.id);

  const propsForAssetDetailsSub = { assetId, ...props };

  const { restProps } = useAssetDetailsSub(propsForAssetDetailsSub);

  if (restProps.loading) return <Loader />;

  return <AssetDetails {...props} {...restProps} />;
}
