import React from 'react';
import { Layout } from 'poly-book';

import AuthContainer from '../hooks/auth-container.js';
import ReportsInfo from '../components/reports/info.js';
import ReportsPageLayout from '../layouts/reports-page-layout.js';
import Navigation from '../containers/navbar/navigation-container.js';

function Reports() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <ReportsInfo />
        </Layout.Header>
        <Layout.Content>
          <ReportsPageLayout />
        </Layout.Content>
      </Layout>
    </AuthContainer>
  );
}

export default Reports;
