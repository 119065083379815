import moment from 'moment';

import { MONTH } from '../../constants/report/master-recurring.js';
import { formatRepeatsArray } from '../../utils/mr-projects/index.js';

export const initialFormData = {
  repeatNum: 1,
  endDate: null,
  isNever: false,
  loading: false,
  repeatEvery: null,
  repeatTime: MONTH,
  startDate: new Date(),
  projectManager: null,
  projectSuppliers: [],
  selectedService: null,
  selectedProperty: null,
  projectTechnician: null,
  repeats: formatRepeatsArray([MONTH, MONTH], moment()),
  filterByStatus: 'queue',
  activeTab: 'technicians',
};
