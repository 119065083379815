import { gql } from '@apollo/client';

export const maintenancePlanChecklistQuery = gql`
  query maintenancePlanChecklistQuery($id: ID!) {
    maintenancePlan(id: $id) {
      _id
      checklist
      materials
      notes
      searchFiles {
        hits {
          _id
          url
          fileName
          fileType
        }
      }
    }
  }
`;
