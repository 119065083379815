import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'poly-site-ui';
import Suppliers from '../../../components/property/tabs/suppliers.js';
import {
  entityFormatter,
  fieldFormatter,
  noDataToDisplayWidget,
} from '../../../util/general.js';
import {
  statusFormatter,
  supplierFormatter,
} from '../../../utils/suppliers/constructions.js';
import { usePropertySuppliers } from '../../../hooks/property/suppliers.js';

const suppliersColumns = [
  ['Supplier Name', R.identity, supplierFormatter],
  [
    'Address',
    R.path(['company', 'address', 'formatted_address']),
    fieldFormatter,
  ],
  ['Service Type', R.prop('type'), fieldFormatter],
  ['Status', R.prop('status'), statusFormatter],
];

export default function (props) {
  const propertyId = useSelector((state) => state.location.payload.id);

  const { loading, suppliers } = usePropertySuppliers(propertyId);

  if (loading) return <Loader />;
  if (!suppliers.length) return noDataToDisplayWidget('Suppliers')();

  const suppliersObj = entityFormatter(suppliers, suppliersColumns);

  return <Suppliers {...props} suppliers={suppliersObj} />;
}
