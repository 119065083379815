import types from '../types.js';

export const setTechnicianStatsTableSearchString = (query) => ({
  type: types.SET_TECHNICIAN_STATS_TABLE_SEARCH_STRING,
  payload: query,
});

export const setTechnicianStatsPaginationCurrentPage = (current) => ({
  type: types.SET_TECHNICIAN_STATS_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setTechnicianStatsPaginationTotal = (total) => ({
  type: types.SET_TECHNICIAN_STATS_PAGINATION_TOTAL,
  payload: total,
});

export const setTechnicianStatsPaginationPageSize = (size) => ({
  type: types.SET_TECHNICIAN_STATS_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setTechnicianStatsPaginationDropdownLabel = (label) => ({
  type: types.SET_TECHNICIAN_STATS_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});

export const setTechnicianStatsDate = (date) => ({
  type: types.SET_TECHNICIAN_STATS_DATE,
  payload: date,
});
