import React from 'react';
import { Widget } from 'poly-book';
import { Table } from 'poly-site-ui';

function TableWithWidget(props) {
  return (
    <Widget.Item>
      <Table {...props} />
    </Widget.Item>
  );
}

export default TableWithWidget;
