import { gql } from '@apollo/client';

import { userFragment } from '../fragments.js';

export const createContactUser = gql`
  mutation createContactUser($input: CreateContactUserInput!) {
    createContactUser(input: $input) {
      user {
        ...userFragment
      }
    }
  }

  ${userFragment}
`;
