import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  UpdatesTabBase,
  setImagesGalleryModal,
  ImagesGallery,
  EditFileModal,
} from 'poly-site-ui';
import { SYSTEM_USER_ID } from 'poly-constants/src/misc.js';

import { useOpenUserStaffSidebar } from '../../utils/staff/useOpenUserStaffSidebar.js';

function ProjectUpdates({
  addUpdate,
  updates,
  onFileSelect,
  attachments,
  onFileRemove,
  onTextAreaChange,
  onFileConfirmed,
  scrollbar,
  loading,
  setRef,
  getRef,
  total,
}) {
  const dispatch = useDispatch();
  const onImageClick = (images, index) =>
    dispatch(
      setImagesGalleryModal({
        images,
        currentIndex: index,
      }),
    );

  const openUserStaffSidebar = useOpenUserStaffSidebar();

  const handleUserClick = (user) => {
    if (user?._id && user?._id !== SYSTEM_USER_ID) {
      openUserStaffSidebar(user);
    }
  };

  return (
    <>
      <UpdatesTabBase
        onUserClick={handleUserClick}
        {...{
          onImageClick,
          addUpdate,
          updates,
          onFileSelect,
          attachments,
          onFileRemove,
          onTextAreaChange,
          scrollbar,
          loading,
          setRef,
          getRef,
          total,
        }}
      />
      <EditFileModal onConfirm={onFileConfirmed} />
      <ImagesGallery />
    </>
  );
}

ProjectUpdates.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  updates: PropTypes.arrayOf(PropTypes.object.isRequired),
  attachments: PropTypes.array.isRequired,
  addUpdate: PropTypes.func.isRequired,
  onFileConfirmed: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired,
  onTextAreaChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  scrollbar: PropTypes.bool.isRequired,
  setRef: PropTypes.func.isRequired,
  getRef: PropTypes.func.isRequired,
  total: PropTypes.number,
};

export default ProjectUpdates;
