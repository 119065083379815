import { gql } from '@apollo/client';
import { supplierDetailsFields } from './fragments.js';

export const getSupplier = gql`
  query getSupplier($supplierId: ID!) {
    supplier(id: $supplierId) {
      ...supplierDetailsFields
    }
  }
  ${supplierDetailsFields}
`;
