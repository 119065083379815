import * as R from 'ramda';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectProperty from '../../components/assets/select.js';
import {
  setAssetsTableSearchString,
  setAssetsFilter,
} from '../../redux/actions/index.js';
import { usePropertiesListQuery } from '../../hooks/properties.js';

export const ALL_PROPERTIES_VALUE = 'ALL_PROPERTIES_VALUE';
const ALL_PROPERTIES_LABEL = 'All Properties';

// mapPropertiesToOptions :: [Property] -> [Option]
const mapPropertiesToOptions = R.compose(
  R.prepend({
    value: ALL_PROPERTIES_VALUE,
    label: ALL_PROPERTIES_LABEL,
  }),
  R.map(
    R.applySpec({
      value: R.prop('_id'),
      label: R.prop('name'),
    }),
  ),
  R.defaultTo([]),
);

export default function (props) {
  const dispatch = useDispatch();

  const filter = useSelector((state) => state.assets.filter);

  const [search, setSearch] = useState('');
  const [selectedProperty, setSelectedProperty] = useState(null);

  useEffect(() => {
    setSelectedProperty(filter);
  }, [filter]);

  const { propertyLoading, properties, data, result } = usePropertiesListQuery({
    forSelect: true,
    searchTerm: search,
  });

  const setPropertySearch = useCallback(
    (value) => setSearch(value),
    [setSearch],
  );

  const selectProperty = useCallback(
    (property) => {
      const propertyId = property?.value;

      dispatch(setAssetsFilter(propertyId));
      dispatch(setAssetsTableSearchString(''));
      setSelectedProperty(propertyId);
    },
    [setSelectedProperty, dispatch],
  );

  const propertiesObj = mapPropertiesToOptions(properties);

  return (
    <SelectProperty
      {...props}
      {...data}
      {...result}
      selectedProperty={selectedProperty}
      selectProperty={selectProperty}
      properties={propertiesObj}
      propertyLoading={propertyLoading}
      setPropertySearch={setPropertySearch}
    />
  );
}
