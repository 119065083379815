import * as R from 'ramda';
import { ASC_SORT_ORDER, ELASTIC_SCORE_FIELD } from 'poly-constants';
import { serviceTypesQuery, addProjectMutation } from 'poly-site-ui';
import { useMutation, useQuery } from '@apollo/client';
import { STANDARD_SIZE, MAX_SIZE } from '../constants/index.js';
import {
  updateProjects,
  updateProject,
  projectsChanged,
  projectsQuery,
  updateMRProject,
  getProjectsStats,
  addRecurringWO,
  recurringProjectChanged,
} from '../queries/index.js';
import { eyesWideOpenQuery } from '../queries/projects/eyes-wide-open.js';
import { useReactiveEntities } from './useReactiveEntities.js';
import { recurringProjectsQuery } from '../queries/projects/get-recurring-projects.js';

export const useServiceTypes = (supplierSource, searchService = '') => {
  const { loading, data } = useQuery(serviceTypesQuery, {
    alias: 'useServiceTypes',
    variables: {
      input: {
        size: STANDARD_SIZE,
        searchTerm: searchService,
        sort: [ELASTIC_SCORE_FIELD, { 'name.keyword': ASC_SORT_ORDER }],
        ...(supplierSource ? { query: { match: { supplierSource } } } : {}),
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const serviceTypes = data?.searchServiceTypes.hits || [];

  return {
    serviceTypes,
    serviceLoading: loading,
  };
};

export const useUpdateProjects = () => {
  const [mutate] = useMutation(updateProjects, {
    alias: 'useUpdateProjects',
    refetchQueries: ['getProjects'],
  });

  return {
    updateProjects: (ids, update) =>
      mutate({
        variables: {
          ids,
          update,
        },
      }),
  };
};

export const useUpdateProject = () => {
  const [mutate] = useMutation(updateProject, {
    alias: 'useUpdateProject',
  });

  return {
    updateProject: (id, update) =>
      mutate({
        variables: {
          id,
          update,
        },
      }),
  };
};

export const useProjectsQuery = ({
  searchTerm,
  query,
  pageSize,
  from,
  sort,
  skipQuery,
}) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: projectsQuery,
      gqlChangedQuery: projectsChanged,
      query,
      sort,
      from,
      pageSize,
      searchTerm,
      skipQuery,
      alias: 'useProjectsQuery',
    });

  const projects = data?.searchProjects.hits || [];
  const total = data?.searchProjects.total || 0;

  const restProjectsProps = {
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };

  return {
    projects,
    total,
    restProjectsProps,
    result,
  };
};

export const useAddProject = () => {
  const [mutate] = useMutation(addProjectMutation, {
    alias: 'useAddProject',
  });

  return {
    createProject: (input) =>
      mutate({
        variables: { input: R.omit(['clientId'], input) },
      }),
  };
};

export const useUpdateRecurringProject = () => {
  const [mutate] = useMutation(updateMRProject, {
    alias: 'useUpdateRecurringProject',
  });

  return {
    updateMRProject: (id, update) =>
      mutate({
        variables: {
          id,
          update,
        },
      }),
  };
};

export const useProjectsStatsQuery = ({ searchTerm, query, sort }) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: getProjectsStats,
      gqlChangedQuery: projectsChanged,
      query,
      sort,
      pageSize: MAX_SIZE,
      searchTerm,
      alias: 'useProjectsStatsQuery',
    });

  const stats = data?.searchProjects.hits;
  const statsTotal = data?.searchProjects.total;

  const restProjectsProps = {
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };

  return {
    stats,
    statsTotal,
    statsLoading: loading,
    restProjectsProps,
  };
};

export const useAddRecurringWOMutation = () => {
  const [mutate] = useMutation(addRecurringWO, {
    alias: 'useAddRecurringWOMutation',
  });

  return {
    createRecurringProject: (input) =>
      mutate({
        variables: { input: R.omit(['clientId'], input) },
      }),
  };
};

export const useRecurringProjectsQuery = ({
  searchTerm,
  query,
  sort,
  pageSize,
  from,
}) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: recurringProjectsQuery,
      gqlChangedQuery: recurringProjectChanged,
      query,
      sort,
      pageSize,
      from,
      searchTerm,
      alias: 'useRecurringProjectsQuery',
    });

  const projects = data?.searchRecurringProjects.hits || [];
  const total = data?.searchRecurringProjects.total || 0;

  const restProjectsProps = {
    ...data,
    ...result,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
  };

  return {
    projects,
    total,
    restProjectsProps,
  };
};

export const useProjectsCountQuery = ({ searchTerm, query, sort }) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: projectsQuery,
      gqlChangedQuery: projectsChanged,
      sort,
      query,
      searchTerm,
      alias: 'useProjectsCountQuery',
    });

  const count = data?.searchProjects.total || 0;

  return {
    count,
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};

export const useRecurringProjectsCountQuery = ({ searchTerm, query, sort }) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: recurringProjectsQuery,
      gqlChangedQuery: recurringProjectChanged,
      sort,
      query,
      searchTerm,
      alias: 'useRecurringProjectsCountQuery',
    });

  const count = data?.searchRecurringProjects.total || 0;

  return {
    count,
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};

export const useEyesWideOpen = ({ year, clientId }) => {
  const { data, loading, fetchMore } = useQuery(eyesWideOpenQuery, {
    alias: 'useEyesWideOpen',
    variables: { year, clientId },
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    fetchMore,
  };
};
