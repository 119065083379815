import { gql } from '@apollo/client';

import { purchaseOrderFragment } from '../fragments.js';

export const jobCostsFragment = gql`
  fragment jobCostsFragment on Project {
    purchaseOrders {
      ...purchaseOrderFragment
    }
    materialSpends {
      _id
      description
      price
      quantity
      total
    }
    timeLogs {
      _id
      duration
      endDate
      startDate
      updatedAt
      createdAt
      assignedTo {
        _id
        fullName
      }
    }
  }

  ${purchaseOrderFragment}
`;

export const jobCostsQuery = gql`
  query projectJobCosts($id: ID) {
    project(projectId: $id) {
      _id
      createdAt
      ...jobCostsFragment
    }
  }

  ${jobCostsFragment}
`;
