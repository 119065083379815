import React from 'react';
import { S } from 'poly-book';

export const formatPlaceholder = (placeholder = 'Value') => (
  <S type="content">{`Please Select ${placeholder}...`}</S>
);

export const formatSearchPlaceholder = (placeholder = 'Value') => (
  <S type="content">{`Start Typing ${placeholder}...`}</S>
);
