import * as R from 'ramda';
import {
  boolToYesNo,
  performExcelExport,
  createExcelExportCell,
  ExcelExportCellType,
} from 'poly-client-utils';
import { forceTitleCase } from 'poly-utils';

const COLUMN_WIDTH = [15, 10, 10, 15, 25, 25, 20];

const TITLE_ROW = [
  'PO #',
  'Amount',
  'Scope',
  'WO #',
  'Supplier',
  'Property',
  'Status',
];

// getPOLogRowConfig :: Order -> [ExcelExportDataCell]
const getPOLogRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ExcelExportCellType.default,
    ExcelExportCellType.defaultCurrency,
    ...R.repeat(ExcelExportCellType.default, 5),
  ]),
  R.juxt([
    R.propOr('', 'cardNumber'),
    R.compose(Number, R.propOr(0, 'amount')),
    R.compose(boolToYesNo, R.propOr('', 'isInScope')),
    R.pathOr('', ['project', 'projectId']),
    R.pathOr('', ['supplier', 'company', 'name']),
    R.either(
      R.pathOr('', ['project', 'property', 'name']),
      R.pathOr('', ['property', 'name']),
    ),
    R.compose(forceTitleCase, R.pathOr('', ['status'])),
  ]),
);

// getPOLogExcelConfig :: [Orders] -> Object
export const getPOLogExcelConfig = (projects) => ({
  exportFileName: 'po_log_export.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 7)],
      TITLE_ROW,
    ),
    ...R.map(getPOLogRowConfig, projects),
  ],
});

export const exportPOLogToXLS = R.compose(
  performExcelExport,
  getPOLogExcelConfig,
  R.pathOr([], ['data', 'searchPurchaseOrders', 'hits']),
);
