import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Widget } from 'poly-book';

import { Table } from '../../table/table.js';
import PeopleSidebar from '../../contact/index.js';
import { AddPeopleButton } from '../../property/tabs/people/index.js';
import PeopleModal from '../../../containers/supplier/tabs/people/add-edit-modal.js';
import { shouldDisplayTable } from '../../../util/job-costs.js';

function People({
  people,
  closeSidebar,
  selectedUser,
  openAddPeopleModal,
  openEditPeopleModal,
}) {
  return (
    <Widget.Item>
      <Heading>
        <Heading.h5>Profile</Heading.h5>
        <AddPeopleButton {...{ openAddPeopleModal }} />
        <PeopleModal />
      </Heading>
      {shouldDisplayTable(people) && <Table paddingTop={15} {...people} />}
      <PeopleSidebar
        {...{ selectedUser }}
        onClose={closeSidebar}
        onEdit={openEditPeopleModal}
      />
    </Widget.Item>
  );
}

People.propTypes = {
  openEditPeopleModal: PropTypes.func.isRequired,
  openAddPeopleModal: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  people: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    columns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
    formats: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    props: PropTypes.arrayOf(PropTypes.object),
    gridColumns: PropTypes.string.isRequired,
  }).isRequired,
  selectedUser: PropTypes.shape({
    _id: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.shape({
      formatted_address: PropTypes.string,
    }),
  }),
};

People.defaultProps = {
  selectedUser: null,
};

export default People;
