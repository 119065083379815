import { gql } from '@apollo/client';

export const getAssetProjectsQuery = gql`
  query getAssetProjectsQuery($input: CollectionSearchParams) {
    searchProjects(input: $input) {
      hits {
        _id
        type
        projectId
        description
        endDate
      }
    }
  }
`;
