import * as R from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';
import { Input, Holder, Button, S } from 'poly-book';
import styled from 'styled-components';

import { renderButton } from '../project/details/response-team/common.js';

const AddButton = styled(Button)`
  justify-content: center;
  border-radius: 5px;
  border-style: dashed;
  margin-top: 10px;
`;

const Wrapper = styled(Holder)`
  margin-top: 10px;
`;

export function MultipleInput({
  name,
  emails,
  errors,
  onRemoveEmail,
  onAddField,
  addButtonText,
  onChange,
  onFieldChoose,
  disabledFirst,
}) {
  return (
    <>
      {emails.map((item, index) => {
        const { id, email } = item;
        const formattedName = index === 0 ? name : `${name}${index}`;
        return (
          <Wrapper key={id}>
            <Input
              type="email"
              placeholder="Email"
              name={formattedName}
              defaultValue={email}
              disabled={disabledFirst && email && !index}
              invalid={!!R.prop(formattedName, errors)}
              {...{ onChange }}
              onFocus={onFieldChoose}
            />
            {emails.length > 1 && renderButton(true, () => onRemoveEmail(id))}
          </Wrapper>
        );
      })}
      <AddButton onClick={onAddField}>
        <S type="title">{addButtonText}</S>
      </AddButton>
    </>
  );
}

MultipleInput.propTypes = {
  name: PropTypes.string.isRequired,
  emails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
    }),
  ).isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
  }),
  onRemoveEmail: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddField: PropTypes.func.isRequired,
  onFieldChoose: PropTypes.func.isRequired,
  addButtonText: PropTypes.string,
  disabledFirst: PropTypes.bool,
};

MultipleInput.defaultProps = {
  errors: {},
  disabledFirst: false,
  addButtonText: 'Add field',
};
