import { useQuery } from '@apollo/client';
import { getPropertyAssets } from '../../queries/index.js';

export const usePropertyAssets = (propertyId) => {
  const { data, loading } = useQuery(getPropertyAssets, {
    alias: 'usePropertyAssets',
    variables: { propertyId },
    notifyOnNetworkStatusChange: true,
  });

  return {
    assetsLoading: loading,
    property: data?.property,
    assets: data?.property.assets,
  };
};
