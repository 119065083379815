import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../../components/pm-projects/footer.js';
import { setPOsModal } from '../../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const selectedPOs = useSelector((state) => state.purchaseOrders.selectedPOs);

  const onClick = useCallback(
    () => dispatch(setPOsModal({ confirmApproveMultiple: true })),
    [dispatch, setPOsModal],
  );

  return (
    <Footer
      {...props}
      title="Approve PO's"
      show={selectedPOs.length > 0}
      onClick={onClick}
    />
  );
}
