import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { scopeTypes } from '../../constants/purchase-orders.js';
import AddEditPORequest from '../../components/purchase-order/add-edit-modal.js';
import { setPOsModal } from '../../redux/actions/index.js';
import { useGlCodeSelectLogic } from './common.js';
import { useSuppliersToSelect } from '../../hooks/suppliers.js';
import { usePropertiesQuery } from '../../hooks/properties.js';
import useValidation from '../../hooks/useValidation.js';
import { usePOFileHandler } from '../../utils/purchase-orders/pos-hooks.js';
import { getValue } from '../../util/general.js';

export const useApprovePOPartLogic = () => {
  const [loading, setLoadingState] = useState(false);
  const [searchTerm, setSearchTermState] = useState('');
  const [selectedSupplier, setSelectedSupplierState] = useState(null);
  const [selectedScope, setSelectedScopeState] = useState(scopeTypes[0]);

  const setLoading = useCallback(
    (loadingValue) => setLoadingState(loadingValue),
    [setLoadingState],
  );

  const setSupplierSearch = useCallback(
    (searchTermValue) => setSearchTermState(searchTermValue),
    [setSearchTermState],
  );

  const setSelectedScope = useCallback(
    (selectedScopeValue) => setSelectedScopeState(selectedScopeValue),
    [setSelectedScopeState],
  );

  const setSupplier = useCallback(
    (selectedSupplierValue) => setSelectedSupplierState(selectedSupplierValue),
    [setSelectedSupplierState],
  );

  const { total, allSuppliers, supplierLoading, noSupplierResults } =
    useSuppliersToSelect(searchTerm);

  return {
    total,
    allSuppliers,
    supplierLoading,
    noSupplierResults,
    setLoading,
    setSupplierSearch,
    setSelectedScope,
    setSupplier,
    loading,
    selectedSupplier,
    selectedScope,
  };
};

export const useCommonPORequestLogic = () => {
  const [search, setSearchState] = useState('');
  const [selectedProperty, setSelectedPropertyState] = useState(null);

  const setPropertySearch = useCallback(
    (searchValue) => setSearchState(searchValue),
    [setSearchState],
  );

  const setProperty = useCallback(
    (selectedPropertyValue) => setSelectedPropertyState(selectedPropertyValue),
    [setSelectedPropertyState],
  );

  const {
    allSuppliers,
    supplierLoading,
    noSupplierResults,
    setLoading,
    setSupplierSearch,
    setSelectedScope,
    setSupplier,
    loading,
    selectedSupplier,
    selectedScope,
  } = useApprovePOPartLogic();

  const {
    propertyLoading,
    properties,
    data,
    result,
    searchTerm,
    query,
    noPropertyResults,
  } = usePropertiesQuery(search);

  return {
    search,
    selectedProperty,
    setPropertySearch,
    setProperty,
    allSuppliers,
    supplierLoading,
    noSupplierResults,
    setLoading,
    setSupplierSearch,
    setSelectedScope,
    setSupplier,
    loading,
    selectedSupplier,
    selectedScope,
    propertyLoading,
    properties,
    data,
    result,
    searchTerm,
    query,
    noPropertyResults,
  };
};

function PORequestModal(props) {
  const dispatch = useDispatch();

  const { modalInfo } = props;

  const project = modalInfo?.project;
  const projectId = modalInfo?.id;

  const {
    selectedProperty,
    setPropertySearch,
    setProperty,
    allSuppliers,
    supplierLoading,
    noSupplierResults,
    setLoading,
    setSupplierSearch,
    setSelectedScope,
    setSupplier,
    loading,
    selectedSupplier,
    selectedScope,
    propertyLoading,
    properties,
    data,
    result,
    noPropertyResults,
  } = useCommonPORequestLogic();

  const {
    errors,
    setError,
    onChange,
    validateOnBlur,
    resetError,
    validate,
    validateField,
  } = useValidation({
    validationRules: {
      supplier: [{ rule: 'required', message: 'Please Select Supplier' }],
      amount: [{ rule: 'currency' }, { rule: 'required' }],
      description: [{ rule: 'required' }],

      ...(!project && {
        property: [{ rule: 'required', message: 'Please Select Property' }],
      }),
    },
  });

  const { file, onAddFile, onRemoveFile } = usePOFileHandler();

  const { code, glCodes, onSelectCode } = useGlCodeSelectLogic(modalInfo);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const glCode = code?.value;

      const supplier = selectedSupplier;
      const amount = getValue('amount', e);
      const description = getValue('description', e);
      const property = selectedProperty;

      const errorsValue = validate({
        supplier,
        amount,
        description,
        ...(!project && {
          property,
        }),
      });

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const projectObj = {
        label: project,
        value: projectId,
      };

      const confirm = {
        project: projectObj,
        property: selectedProperty,
        supplier: selectedSupplier,
        amount: getValue('amount', e),
        isInScope: selectedScope.value,
        description: getValue('description', e),
        ...(!!file && {
          filesAttachments: [{ upload: file.file }],
        }),
        glCode,
      };

      setLoading(false);
      setError(null);

      return dispatch(
        setPOsModal({
          addPORequest: null,
          confirmAddPO: confirm,
        }),
      );
    },
    [
      code,
      selectedProperty,
      selectedSupplier,
      selectedScope,
      validate,
      setLoading,
      setError,
      dispatch,
      projectId,
      project,
      file,
      setPOsModal,
    ],
  );

  const closeModal = useCallback(
    () => dispatch(setPOsModal({ addPORequest: null })),
    [dispatch, setPOsModal],
  );

  const selectSupplier = useCallback(
    (supplier) => {
      setSupplier(supplier);
      validateField('supplier', supplier.value);
    },
    [setSupplier, validateField],
  );

  const selectProperty = useCallback(
    (property) => {
      setProperty(property);
      validateField('property', property.value);
    },
    [setProperty, validateField],
  );

  return (
    <AddEditPORequest
      {...props}
      {...data}
      {...result}
      {...modalInfo}
      type="add"
      file={file}
      loading={loading}
      errors={errors}
      onChange={onChange}
      closeModal={closeModal}
      onSubmit={onSubmit}
      project={project}
      code={code}
      glCodes={glCodes}
      supplierLoading={supplierLoading}
      allSuppliers={allSuppliers}
      selectedSupplier={selectedSupplier}
      selectSupplier={selectSupplier}
      setSupplierSearch={setSupplierSearch}
      scopeTypes={scopeTypes}
      selectedScope={selectedScope}
      setSelectedScope={setSelectedScope}
      propertyLoading={propertyLoading}
      properties={properties}
      selectedProperty={selectedProperty}
      selectProperty={selectProperty}
      setPropertySearch={setPropertySearch}
      validateOnBlur={validateOnBlur}
      resetError={resetError}
      noPropertyResults={noPropertyResults}
      noSupplierResults={noSupplierResults}
      onAddFile={onAddFile}
      onRemoveFile={onRemoveFile}
      onSelectCode={onSelectCode}
    />
  );
}

PORequestModal.propTypes = {
  modalInfo: PropTypes.shape({
    id: PropTypes.string,
    project: PropTypes.string,
  }),
};

export default function (props) {
  const modalInfo = useSelector(
    (state) => state.purchaseOrders.modals.addPORequest,
  );

  return modalInfo ? <PORequestModal {...props} modalInfo={modalInfo} /> : null;
}
