import { gql } from '@apollo/client';

import { defaultProjectFragment } from '../fragments.js';

export const fullPMProjectFragment = gql`
  fragment fullPMProjectFragment on Project {
    ...defaultProjectFragment
    maintenancePlan {
      _id
      maintenancePlanId
    }
  }

  ${defaultProjectFragment}
`;

export const pmProjectQuery = gql`
  query getPMProject($id: ID) {
    project(projectId: $id) {
      ...fullPMProjectFragment
    }
  }

  ${fullPMProjectFragment}
`;
