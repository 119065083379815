import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import { Heading, L, S } from 'poly-book';

import {
  APPROVED_INVOICES,
  APPROVED_POS,
  ASSETS,
  COMPLETED_PMS,
  COMPLETED_ON_HOLD_WOS,
  COMPLETED_WOS,
  CONTRACT_EXPIRATION_REPORT,
  EYES_WIDE_OPEN,
  INVOICE_LOG,
  INVOICES,
  PO_LOG,
  PURCHASE_ORDERS,
  REPORT,
  REPORT_PM,
  REPORTS,
  TECHNICIAN_STATS,
} from '../../constants/routes.js';

const A = L.withComponent('span');

const Count = styled(S)`
  margin-left: 7px;
  color: #ee0d45;
`;

function Title({ title, type, count }) {
  return (
    <Link to={{ ...{ type } }}>
      <A bold>
        {title}
        {!!count && <Count type="badge">({count})</Count>}
      </A>
    </Link>
  );
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  count: PropTypes.number,
};

Title.defaultProps = {
  type: REPORTS,
  count: null,
};

const Base = styled.section`
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: ${({ gap }) => gap};
`;

const Grid = styled(Base)`
  margin-top: 10px;
  grid-template-columns: repeat(3, 1fr);
`;

const List = styled(Base.withComponent('ul'))`
  margin: 0;
  padding: 0;
  list-style: none;
`;

function GridItem({ items, heading }) {
  return (
    <Base gap="25px">
      <Heading.h3 lighter>{heading}</Heading.h3>
      <List gap="15px">
        {items.map((item) => (
          <li key={item.title}>
            <Title {...item} />
          </li>
        ))}
      </List>
    </Base>
  );
}

GridItem.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      type: PropTypes.string,
      count: PropTypes.number,
    }).isRequired,
  ).isRequired,
  heading: PropTypes.string.isRequired,
};

function ReportsComponent({
  requestsCount,
  approvedPOsCount,
  pendingInvoicesCount,
  approvedInvoicesCount,
}) {
  return (
    <Grid gap="50px 10px">
      <GridItem
        heading="Work Orders"
        items={[
          {
            type: REPORT,
            title: 'Master Recurring Work Orders',
          },
          {
            type: REPORT_PM,
            title: 'Master Recurring PM Work Orders',
          },
          {
            title: 'Advanced Work Order Search',
          },
          {
            type: EYES_WIDE_OPEN,
            title: 'Eyes Wide Open',
          },
          {
            type: COMPLETED_ON_HOLD_WOS,
            title: 'On Hold Completed Work Orders',
          },
        ]}
      />
      <GridItem
        heading="Staff"
        items={[
          {
            type: TECHNICIAN_STATS,
            title: 'Technician Stats',
          },
        ]}
      />
      <GridItem
        heading="Contracts"
        items={[
          {
            type: CONTRACT_EXPIRATION_REPORT,
            title: 'Contract Expiration Report',
          },
        ]}
      />
      <GridItem
        heading="Assets"
        items={[
          {
            type: ASSETS,
            title: 'Asset Directory',
          },
          {
            title: 'Corrective Work Order Report',
          },
        ]}
      />
      <GridItem
        heading="KPI's"
        items={[
          {
            type: COMPLETED_WOS,
            title: 'Completed Work Orders',
          },
          {
            type: COMPLETED_PMS,
            title: 'Completed PM Work Orders',
          },
        ]}
      />
      <GridItem
        heading="Purchase Orders"
        items={[
          {
            type: PURCHASE_ORDERS,
            title: 'PO Requests',
            count: requestsCount,
          },
          {
            type: APPROVED_POS,
            title: "Approved PO's Waiting on Invoice",
            count: approvedPOsCount,
          },
          {
            type: INVOICES,
            title: 'Invoices Pending Approval',
            count: pendingInvoicesCount,
          },
          {
            type: APPROVED_INVOICES,
            title: 'Invoices Ready for AP Posting',
            count: approvedInvoicesCount,
          },
          {
            type: INVOICE_LOG,
            title: 'Invoice Log',
          },
          {
            type: PO_LOG,
            title: 'PO Log',
          },
        ]}
      />
    </Grid>
  );
}

ReportsComponent.propTypes = {
  requestsCount: PropTypes.number,
  approvedPOsCount: PropTypes.number,
  pendingInvoicesCount: PropTypes.number,
  approvedInvoicesCount: PropTypes.number,
};

ReportsComponent.defaultProps = {
  requestsCount: null,
  approvedPOsCount: null,
  pendingInvoicesCount: null,
  approvedInvoicesCount: null,
};

export default ReportsComponent;
