import * as R from 'ramda';
import {
  boolToYesNo,
  performExcelExport,
  createExcelExportCell,
  ExcelExportCellType,
} from 'poly-client-utils';

const COLUMN_WIDTH = [15, 15, 10, 15, 15, 25, 25];

const TITLE_ROW = [
  'PO #',
  'Invoice #',
  'Amount',
  'In Scope',
  'WO #',
  'Supplier',
  'Property',
];

// getInvoiceRowConfig :: Invoice -> [ExcelExportDataCell]
const getInvoiceRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 2),
    ExcelExportCellType.defaultCurrency,
    ...R.repeat(ExcelExportCellType.default, 4),
  ]),
  R.juxt([
    R.pathOr('', ['purchaseOrder', 'cardNumber']),
    R.propOr('', ['invoiceNumber']),
    R.compose(Number, R.prop('total')),
    R.compose(boolToYesNo, R.pathOr('', ['purchaseOrder', 'isInScope'])),
    R.pathOr('', ['project', 'projectId']),
    R.pathOr('', ['supplier', 'company', 'name']),
    R.either(
      R.pathOr('', ['project', 'property', 'name']),
      R.pathOr('', ['property', 'name']),
    ),
  ]),
);

// getInvoicesExcelConfig :: [Invoice] -> Object
export const getInvoicesExcelConfig = (fileName) => (invoices) => ({
  exportFileName: `${fileName}.xlsx`,
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 7)],
      TITLE_ROW,
    ),
    ...R.map(getInvoiceRowConfig, invoices),
  ],
});

export const exportApprovedInvoicesToXLS = R.compose(
  performExcelExport,
  getInvoicesExcelConfig('invoices_ready_for_ap_posting_export'),
  R.pathOr([], ['data', 'searchPOInvoices', 'hits']),
);

export const exportPendingApprovalInvoicesToXLS = R.compose(
  performExcelExport,
  getInvoicesExcelConfig('invoices_pending_approval_export'),
  R.path(['data', 'searchPOInvoices', 'hits']),
);
