import React from 'react';
import { Widget } from 'poly-book';
import styled from 'styled-components';

import PurchaseOrderDetails from '../containers/purchase-order/details.js';
import PurchaseOrderData from '../containers/purchase-order/data.js';

const WidgetBlock = styled(Widget)`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-rows: auto 1fr;
`;

function PurchaseOrderLayout() {
  return (
    <>
      <Widget>
        <PurchaseOrderDetails />
      </Widget>
      <WidgetBlock>
        <PurchaseOrderData />
      </WidgetBlock>
    </>
  );
}

export default PurchaseOrderLayout;
