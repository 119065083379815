import { useSelector, useDispatch } from 'react-redux';
import { useState, useCallback, useRef } from 'react';
import { debounce } from 'poly-utils';
import { push } from 'redux-first-router';
import { toast } from 'react-toastify';
import useValidation from '../../hooks/useValidation.js';
import { setProjectModal } from '../../redux/actions/index.js';
import { AddProjectSuccess, GeneralError } from '../../constants/alerts.js';
import { useCreateContactUser } from '../../hooks/property/people.js';
import { useAddProject } from '../../hooks/projects.js';
import { ACTIVE } from '../../constants/staff.js';
import { checkEmailError } from '../../utils/staff/index.js';

export const useCreateWOPartLogic = () => {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);

  const typeFilter = projects?.typeFilter;

  const user = useSelector((state) => state.user);

  const userId = user._id;
  const clientId = user?.clientId;
  const projectModal = projects.modals.project;
  const supplierSource = user?.clientConfig.supplierSource;

  const [activeTab, setActiveTabState] = useState('technicians');

  const [selectedPriority, setPriority] = useState(null);

  const [selectedProperty, setProperty] = useState(null);

  const [selectedService, setService] = useState('');
  const [selectedRequester, setRequester] = useState(null);

  const [projectTechnician, setTechnician] = useState(null);
  const [projectManager, setManager] = useState(null);
  const [projectSuppliers, setSuppliers] = useState([]);

  const [filterByStatus, setFilterByStatus] = useState('queue');
  const [loading, setLoading] = useState(false);

  const [isNewRequester, setIsNewRequester] = useState(false);
  const [newRequester, setNewRequester] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const [search, setSearchState] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchService, setSearchService] = useState('');
  const [searchRequesters, setSearchRequestersState] = useState('');

  const isProjectAssigned = filterByStatus === 'assign';

  const { errors, onChange, validate, validateField, setError } = useValidation(
    {
      validationRules: () => ({
        service: [{ rule: 'required', message: 'Please Select Service Type' }],
        priority: [{ rule: 'required', message: 'Please Select Priority' }],
        property: [{ rule: 'required', message: 'Please Select Property' }],
        description: [{ rule: 'required' }],

        ...(isNewRequester
          ? {
              requesterName: [{ rule: 'required' }],
              requesterPhone: [{ rule: 'phone' }, { rule: 'required' }],
              requesterEmail: [{ rule: 'email' }, { rule: 'required' }],
            }
          : {}),
      }),
    },
  );

  const setSearch = useCallback(
    (searchTermValue) => setSearchTerm(searchTermValue),
    [setSearchTerm],
  );

  const debouncedSearch = useCallback(debounce(300)(setSearch), []);

  const onInputChange = ({ target: { value } }) => {
    debouncedSearch(value.trim());
  };

  const setPropertySearch = useCallback(
    (searchValue) => setSearchState(searchValue),
    [setSearchState],
  );
  const setServiceSearch = useCallback(
    (searchServiceValue) => setSearchService(searchServiceValue),
    [setSearchService],
  );
  const setSearchRequesters = useCallback(
    (searchRequestersValue) => setSearchRequestersState(searchRequestersValue),
    [setSearchRequestersState],
  );

  const setActiveTab = useCallback(
    ({ target: { value } }) => setActiveTabState(value),
    [setActiveTabState],
  );

  const selectPriority = useCallback(
    (priority) => {
      const value = priority?.value;
      setPriority(value);
      validateField('priority', value);
    },
    [setPriority, validateField],
  );

  const closeModal = useCallback(
    () => dispatch(setProjectModal(null)),
    [dispatch, setProjectModal],
  );

  const handleTechniciansClick = useCallback(
    (data, isSelected) => () => setTechnician(isSelected ? null : data),
    [setTechnician],
  );

  const handleManagersClick = useCallback(
    (data, isSelected) => () => {
      setManager(isSelected ? null : data);
    },
    [setManager],
  );

  const toggleIsNewRequester = useCallback(() => {
    if (isNewRequester) {
      setError({
        ...errors,
        requesterName: null,
        requesterPhone: null,
        requesterEmail: null,
      });
    }
    setIsNewRequester(!isNewRequester);
  }, [isNewRequester, errors, setError, setIsNewRequester]);

  const handleSuppliersClick = useCallback(
    (data, isSelected) => () => {
      const suppliers = isSelected
        ? projectSuppliers.filter(({ _id }) => _id !== data._id)
        : [...projectSuppliers, data];
      setSuppliers(suppliers);
    },
    [setSuppliers, projectSuppliers],
  );

  const selectProperty = useCallback(
    (propertyValue) => {
      const value = propertyValue?.value;
      setProperty(propertyValue);
      validateField('property', value);
    },
    [setProperty, validateField],
  );

  const selectService = useCallback(
    (service) => {
      const value = service?.value;
      setService(value);
      validateField('service', value);
    },
    [setService, validateField],
  );

  const selectRequester = useCallback(
    (requester) => setRequester(requester),
    [setRequester],
  );

  const onNewRequesterChange = useCallback(
    (field) => (e) => {
      const { name, value } = e.target;
      setNewRequester({ ...newRequester, [field]: value });
      validateField(name, value);
    },
    [newRequester, setNewRequester, validateField],
  );

  return {
    projects,
    typeFilter,
    user,
    userId,
    clientId,
    projectModal,
    supplierSource,
    activeTab,
    setActiveTabState,
    selectedPriority,
    setPriority,
    selectedProperty,
    setProperty,
    selectedService,
    setService,
    selectedRequester,
    setRequester,
    projectTechnician,
    setTechnician,
    projectManager,
    setManager,
    projectSuppliers,
    setSuppliers,
    filterByStatus,
    setFilterByStatus,
    loading,
    setLoading,
    isNewRequester,
    setIsNewRequester,
    newRequester,
    setNewRequester,
    search,
    searchTerm,
    searchService,
    searchRequesters,
    setSearch,
    setPropertySearch,
    setServiceSearch,
    setSearchRequesters,
    setActiveTab,
    selectPriority,
    errors,
    onChange,
    validate,
    validateField,
    setError,
    onInputChange,
    closeModal,
    handleTechniciansClick,
    handleManagersClick,
    toggleIsNewRequester,
    handleSuppliersClick,
    selectProperty,
    selectService,
    selectRequester,
    onNewRequesterChange,
    isProjectAssigned,
  };
};

export const useCreateWOMutationHandlers = ({
  selectedProperty,
  setLoading,
  setError,
}) => {
  const dispatch = useDispatch();

  const { createProject } = useAddProject();

  const { createContactUser } = useCreateContactUser();

  const contactId = useRef('');

  const finalMutation = ({ requestData }) =>
    createProject({ ...requestData })
      .then(
        ({
          data: {
            createProject: {
              project: { projectId },
            },
          },
        }) => {
          setLoading(false);
          setError(null);
          dispatch(setProjectModal(null));
          push(`/project/${projectId}`);
          toast.success(AddProjectSuccess);
        },
      )
      .catch((errorObj) => {
        setLoading(false);
        setError({ server: errorObj.message });
        toast.error(GeneralError);
      });

  const createContactUserMutation = async ({
    requesterName,
    requesterEmail,
    requesterPhone,
    requestData,
  }) => {
    const requesterNameParts = requesterName.split(' ');

    return createContactUser({
      status: ACTIVE,
      firstName: requesterNameParts[0],
      lastName: requesterNameParts
        .splice(1, requesterNameParts.length)
        .join(' '),
      links: [{ propertyId: selectedProperty.value }],
      emails: [requesterEmail],
      profile: { workPhoneNumber: requesterPhone },
    })
      .then(
        ({
          data: {
            createContactUser: {
              user: { _id },
            },
          },
        }) => {
          contactId.current = _id;
        },
      )
      .then(() =>
        finalMutation({
          requestData: { ...requestData, contactId: contactId.current },
        }),
      )
      .catch((errorObj) => {
        setLoading(false);
        if (checkEmailError(errorObj.message)) {
          setError({
            requesterEmail: 'This email address is already in use',
          });
          return;
        }
        setError({
          server: errorObj.message,
        });
        toast.error(GeneralError);
      });
  };

  return {
    finalMutation,
    createContactUserMutation,
  };
};
