/* eslint-disable camelcase */
import * as R from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';
import { useLoadGoogleMapScript } from 'poly-client-utils';
import { Input } from 'poly-book';
import { Loader } from 'poly-site-ui';

import { addressPartsFormatter } from '../../utils/suppliers/index.js';

const { GOOGLE } = process.env;
const getParameter = (item) =>
  R.path(['geometry', 'location'], item.getPlace());
const getAddress = (item) => item.getPlace().formatted_address;
const getParts = (item) =>
  addressPartsFormatter(item.getPlace().address_components);

const getSupportedAddressObject = (item) => ({
  formatted_address: getAddress(item),
  address_parts: getParts(item),
  geo: {
    type: 'Point',
    coordinates: getParameter(item)
      ? [getParameter(item).lng(), getParameter(item).lat()]
      : [],
  },
});

class GeoSelectClass extends React.Component {
  constructor(props) {
    super(props);
    this.resetGeoSelectValue = this.resetGeoSelectValue.bind(this);
  }

  componentDidMount() {
    this.initGeoSelect();
  }

  resetGeoSelectValue() {
    this.props.onChange('');
    this.geoSelect.value = '';
  }

  initGeoSelect() {
    // eslint-disable-next-line
    const AutoComplete = new google.maps.places.Autocomplete(this.geoSelect, {
      types: ['address'],
      componentRestrictions: { country: ['US', 'CA'] },
    });
    AutoComplete.addListener('place_changed', () => {
      this.props.onChange(getSupportedAddressObject(AutoComplete));
      this.props.onBlur({
        target: {
          name: 'address',
          value: getSupportedAddressObject(AutoComplete),
        },
      });
    });
  }

  render() {
    const inputProps = R.omit(['onChange', 'onFocus', 'onBlur', 'value'])(
      this.props,
    );

    return (
      <>
        <input
          name="hidden"
          autoComplete="false"
          type="text"
          style={{ display: 'none' }}
        />
        <Input
          {...inputProps}
          // eslint-disable-next-line
          ref={(e) => (this.geoSelect = e)}
          autoComplete="false"
          onFocus={(e) =>
            this.geoSelect.value ? this.resetGeoSelectValue(e) : null
          }
        />
      </>
    );
  }
}

GeoSelectClass.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export function GeoSelect(props) {
  const loading = useLoadGoogleMapScript(GOOGLE);

  if (loading) {
    return <Loader />;
  }

  return <GeoSelectClass {...props} />;
}
