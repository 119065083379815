import types from '../types.js';

export const setSuppliersFilter = (filter) => ({
  type: types.SET_SUPPLIERS_FILTER,
  payload: filter,
});

export const setSuppliersTableSearchString = (query) => ({
  type: types.SET_SUPPLIERS_TABLE_SEARCH_STRING,
  payload: query,
});

export const setEditSupplierModal = (modal) => ({
  type: types.SET_EDIT_SUPPLIER_MODAL,
  payload: modal,
});

export const setAddSupplierModal = () => ({
  type: types.SET_ADD_SUPPLIER_MODAL,
});

export const setSupplierActiveTab = (tab) => ({
  type: types.SET_SUPPLIER_ACTIVE_TAB,
  payload: tab,
});

export const setSuppliersPaginationCurrentPage = (current) => ({
  type: types.SET_SUPPLIERS_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setSuppliersPaginationTotal = (total) => ({
  type: types.SET_SUPPLIERS_PAGINATION_TOTAL,
  payload: total,
});

export const setSuppliersPaginationPageSize = (size) => ({
  type: types.SET_SUPPLIERS_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setSuppliersPaginationDropdownLabel = (label) => ({
  type: types.SET_SUPPLIERS_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});

export const setSupplierPeopleModal = (modal) => ({
  type: types.SET_SUPPLIER_PEOPLE_MODAL,
  payload: modal,
});

export const setSupplierSidebar = (user) => ({
  type: types.SET_SUPPLIER_SIDEBAR,
  payload: user,
});
