import React from 'react';

import Invoices from '../containers/invoices/list.js';
import Footer from '../containers/invoices/footer.js';
import ConfirmModal from '../containers/invoices/confirm-modal.js';

function InvoicesLayout() {
  return (
    <>
      <Invoices />
      <Footer />
      <ConfirmModal />
    </>
  );
}

export default InvoicesLayout;
