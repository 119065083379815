import { isBefore } from 'date-fns';
import { ensureIsDate } from 'poly-utils';

import { fieldFormatter, formatDateWithDashes } from '../util/general.js';

const formatDateString = (date) => {
  if (!date) return '—';
  return isBefore(ensureIsDate(date), new Date())
    ? 'EXPIRED'
    : formatDateWithDashes(date);
};

export const warrantyEndFormatter = (search) => (date) =>
  fieldFormatter(formatDateString(date), search);
