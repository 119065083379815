import { gql } from '@apollo/client';

export const propertyProjectsFragment = gql`
  fragment propertyProjectsFragment on ProjectsSearchResult {
    hits {
      _id
      description
      startDate
      endDate
      projectId
      status
    }
    total
  }
`;

export const propertyRecurringProjectsFragment = gql`
  fragment propertyRecurringProjectsFragment on RecurringProjectSearchResult {
    hits {
      _id
      description
      startDate
      endDate
      projectId
      status
    }
    total
  }
`;

export const getPropertyProjectsQuery = gql`
  query getPropertyProjectsQuery(
    $propertyId: ID!
    $input: CollectionSearchParams!
  ) {
    property(id: $propertyId) {
      _id
      searchProjects(input: $input) {
        ...propertyProjectsFragment
      }
    }
  }

  ${propertyProjectsFragment}
`;
