import * as R from 'ramda';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuidV4 } from 'uuid';
import SendSupConfModal from '../../components/purchase-order/send-sup-conf-modal.js';
import { setPOsModal } from '../../redux/actions/index.js';
import { formatCurrency, getValue } from '../../util/general.js';
import {
  GeneralError,
  SendPOConfirmationSuccess,
} from '../../constants/alerts.js';
import { usePOSupplierConfirmationSend } from './hooks.js';
import useValidation from '../../hooks/useValidation.js';

const getEmailKey = (i) => `email${i || ''}`;
const getEmailsRules = R.compose(
  R.mergeAll,
  R.map((i) => ({ [getEmailKey(i)]: [{ rule: 'required', type: 'email' }] })),
  R.range(0),
  R.length,
);

function SendEmailSup(props) {
  const dispatch = useDispatch();

  const { sendConfirmation, loading } = usePOSupplierConfirmationSend();

  const { purchaseOrder } = props;

  const [emails, setEmails] = useState(() => {
    const supplierEmails = purchaseOrder?.supplier.company?.emails || [];
    const emailsValue = supplierEmails.length
      ? supplierEmails.map(R.path(['email']))
      : [''];

    return emailsValue.map((email) => ({ id: uuidV4(), email }));
  });

  const { errors, setError, onChange, validate } = useValidation({
    validationRules: () => ({
      instructions: [{ rule: 'required' }],
      ...getEmailsRules(emails),
    }),
  });

  const onAddEmail = useCallback(() => {
    setError(null);
    setEmails(emails.concat([{ id: uuidV4(), email: '' }]));
  }, [setError, setEmails, emails]);

  const onRemoveEmail = useCallback(
    (id) => {
      setError(null);
      setEmails(emails.filter((item) => item.id !== id));
    },
    [setError, setEmails, emails],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const emailInputValues = emails.map((_, i) => ({
        [getEmailKey(i)]: R.pathOr(
          '',
          ['target', getEmailKey(i), 'value'],
          e,
        ).trim(),
      }));
      const instructions = getValue('instructions', e);
      const validateRes = validate({
        instructions,
        ...R.mergeAll(emailInputValues),
      });
      if (validateRes.isInvalid) {
        return false;
      }
      const emailAddresses = R.pipe(R.mergeAll, R.values)(emailInputValues);
      return sendConfirmation(purchaseOrder._id, emailAddresses, instructions)
        .then(() => {
          dispatch(setPOsModal({ sendPOSupplierConfirm: null }));
          toast.success(SendPOConfirmationSuccess);
        })
        .catch(() => toast.error(GeneralError));
    },
    [emails, validate, purchaseOrder, sendConfirmation, dispatch, setPOsModal],
  );

  const closeModal = useCallback(
    () => dispatch(setPOsModal({ sendPOSupplierConfirm: null })),
    [dispatch, setPOsModal],
  );

  const amount = formatCurrency(purchaseOrder?.amount);

  return (
    <SendSupConfModal
      {...props}
      {...purchaseOrder}
      loading={loading}
      errors={errors}
      onChange={onChange}
      closeModal={closeModal}
      onSubmit={onSubmit}
      amount={amount}
      emails={emails}
      onAddEmail={onAddEmail}
      onRemoveEmail={onRemoveEmail}
    />
  );
}

SendEmailSup.propTypes = {
  purchaseOrder: PropTypes.shape({
    amount: PropTypes.number,
    cardNumber: PropTypes.string,
    _id: PropTypes.string,
    supplier: PropTypes.shape({
      _id: PropTypes.string,
      company: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        emails: PropTypes.array,
      }),
    }),
  }),
};

export default function (props) {
  const purchaseOrder = useSelector(
    (state) => state.purchaseOrders.modals.sendPOSupplierConfirm?.purchaseOrder,
  );

  return purchaseOrder ? (
    <SendEmailSup {...props} purchaseOrder={purchaseOrder} />
  ) : null;
}
