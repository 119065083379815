import PropTypes from 'prop-types';
import React from 'react';
import { withSaveDataForTableExport } from 'poly-site-ui';

import { Table } from '../table/table.js';

const CompletedPMsStats = withSaveDataForTableExport(
  ({ pms, correctives, gridColumns }) => (
    <>
      <Table {...pms} {...{ gridColumns }} />
      <Table {...correctives} {...{ gridColumns }} />
    </>
  ),
);

const TablePropTypes = {
  headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  columns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  formats: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  props: PropTypes.arrayOf(PropTypes.object),
};

CompletedPMsStats.propTypes = {
  pms: PropTypes.shape(TablePropTypes),
  correctives: PropTypes.shape(TablePropTypes),
  gridColumns: PropTypes.string.isRequired,
};

CompletedPMsStats.defaultProps = {
  pms: null,
  correctives: null,
};

export default CompletedPMsStats;
