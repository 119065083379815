import { gql } from '@apollo/client';

export const addUpdate = gql`
  mutation addUpdate($input: CreateUpdateInput!) {
    createUpdate(input: $input) {
      _id
      createdAt
      message
    }
  }
`;
