import * as R from 'ramda';
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ConfirmModal } from 'poly-site-ui';

import {
  ApprovePOSuccess,
  ApproveSeveralPOSuccess,
  GeneralError,
} from '../../../constants/alerts.js';
import { APPROVED } from '../../../constants/purchase-orders.js';
import { setPOsModal, setSelectedPOs } from '../../../redux/actions/index.js';
import { makeBold } from '../../../util/general.js';
import {
  useApproveMultiplePOs,
  useUpdatePORequest,
} from '../../../hooks/purchase-orders.js';

export default function (props) {
  const dispatch = useDispatch();

  const showMultiple = useSelector(
    (state) => state.purchaseOrders.modals.confirmApproveMultiple,
  );
  const approveOneModal = useSelector(
    (state) => state.purchaseOrders.modals.confirmApproveOne,
  );
  const selectedPOs = useSelector((state) => state.purchaseOrders.selectedPOs);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { updatePORequest } = useUpdatePORequest();

  const { approveMultiplePOs } = useApproveMultiplePOs();

  const closeModal = useCallback(
    () =>
      dispatch(
        setPOsModal({
          confirmApproveMultiple: false,
          confirmApproveOne: null,
        }),
      ),
    [dispatch, setPOsModal],
  );

  const onConfirm = useCallback(async () => {
    setLoading(true);

    if (approveOneModal) {
      const id = approveOneModal?.id;
      const input = {
        ...R.pick(
          [
            'amount',
            'isInScope',
            'supplierId',
            'description',
            'addFileAttachments',
            'removeFileAttachments',
            'glCode',
          ],
          approveOneModal,
        ),
        status: APPROVED,
      };

      return updatePORequest(id, input)
        .then(() => {
          setLoading(false);
          setError(null);
          dispatch(setPOsModal({ confirmApproveOne: null }));
          toast.success(ApprovePOSuccess(approveOneModal.title));
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
          toast.error(GeneralError);
        });
    }

    return approveMultiplePOs(selectedPOs)
      .then((res) => {
        const itemPath = [
          'data',
          'approveMultiplePurchaseOrders',
          0,
          'cardNumber',
        ];
        setLoading(false);
        setError(null);
        dispatch(setSelectedPOs([]));
        dispatch(setPOsModal({ confirmApproveMultiple: false }));
        toast.success(
          R.equals(1, selectedPOs.length)
            ? ApprovePOSuccess(R.path(itemPath, res))
            : ApproveSeveralPOSuccess(selectedPOs.length),
        );
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        toast.error(GeneralError);
      });
  }, [
    approveOneModal,
    selectedPOs,
    updatePORequest,
    approveMultiplePOs,
    setLoading,
    setError,
    dispatch,
  ]);

  if (!showMultiple && !approveOneModal) return null;

  const text = approveOneModal ? (
    <>
      We have saved your changes, and you are about to approve
      <br />
      {makeBold(approveOneModal.title)}. Please confirm.
    </>
  ) : (
    <>
      You are about to approve {selectedPOs.length} Purchase Order Requests.
      <br />
      Please confirm.
    </>
  );

  return (
    <ConfirmModal
      {...props}
      showModal
      closeModal={closeModal}
      subHeading="Confirmation"
      text={text}
      onConfirm={onConfirm}
      loading={loading}
      error={error}
    />
  );
}
